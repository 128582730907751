import { modal as Modal } from '@kandji-inc/bumblebee';
import { Button, Flex, Heading, css } from '@kandji-inc/nectar-ui';
import React, { useState } from 'react';
import { i18n } from 'src/i18n/index';
import ModalContent from './modal-content';

type Props = {
  isModalOpen: boolean;
  onClose: () => void;
  handleNext: () => void;
  onSave: () => void;
  isModalForBannerNavigation?: boolean;
};

const modalStyle = css({
  width: '560px',
});

const OktaNavigateAwayModal = (props: Props) => {
  const {
    isModalOpen,
    onClose,
    onSave,
    handleNext,
    isModalForBannerNavigation,
  } = props;
  const [isLoading, setIsLoading] = useState(false);

  const handleSave = async () => {
    setIsLoading(true);
    await onSave();
    handleNext();
    setIsLoading(false);
  };

  const saveButtonText = i18n.t('Save changes');

  return (
    <Modal
      isOpen={isModalOpen}
      onClose={onClose}
      disableCloseOnOutsideClick
      withoutCloseButton
      classes={{ root: modalStyle().className }}
      parts={{
        header: {
          children: (
            <Heading as="h2" css={{ fontSize: '$5' }}>
              {i18n.t('Are you sure you want to navigate away from this page?')}
            </Heading>
          ),
        },
        content: {
          children: (
            <ModalContent
              isModalForBannerNavigation={isModalForBannerNavigation}
            />
          ),
        },
        footer: {
          children: (
            <Flex gap="sm">
              <Button
                variant="subtle"
                onClick={onClose}
                data-testid="odt-navigate-away-modal:close-btn"
              >
                {i18n.t('Cancel')}
              </Button>
              <Button
                variant="primary"
                loading={isLoading}
                onClick={handleSave}
                data-testid="odt-navigate-away-modal:save-btn"
              >
                {saveButtonText}
              </Button>
            </Flex>
          ),
        },
      }}
    />
  );
};

export default OktaNavigateAwayModal;
