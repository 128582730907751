/* istanbul ignore file */
import {
  Banner,
  Body,
  Breadcrumbs,
  BreadcrumbsItem,
  Button,
  Flex,
  Heading,
  Icon,
  Link,
  Section,
  SectionBody,
  Stepper,
  Text,
  TextField,
  styled,
  useStepper,
} from '@kandji-inc/nectar-ui';
import classNames from 'classnames';
import { i18n } from 'i18n';
import get from 'lodash/get';
import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { AccountContext } from 'src/contexts/account';
import { InterfaceContext } from 'src/contexts/interface';
import useAdjustSidebarChatBubble from 'src/features/util/hooks/use-adjust-sidebar-chat-bubble';
import useWhiteBg from 'src/features/util/hooks/use-white-bg';
import styledComponent from 'styled-components';
import instruction3new from '../../assets/img/mdm/new-cert-1.png';
import instruction4new from '../../assets/img/mdm/new-cert-2.png';
import instruction3 from '../../assets/img/mdm/renew-cert-1.png';
import instruction4 from '../../assets/img/mdm/renew-cert-2.png';
import instruction5 from '../../assets/img/mdm/renew-cert-3.png';
import history from '../router/history';
import { moment } from './common/helpers';
import {
  getIntegration,
  getPushCSR,
  uploadPushCSR,
} from './integrations/Apple/api';

const ContentContainer = styled(Flex, {
  flexDirection: 'column',
  gap: '$5',
  background: '$neutral0',
  padding: '$5 0',
});

const InstructionImg = styled('img', {
  maxWidth: '600px',
});

const UploadAreaWrapper = styled('section', {
  maxWidth: '600px',
  marginBottom: '$3',
  background: '$neutral0',
});

const DashedArea = styled(Flex, {
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '138px',
  border: '1px dashed $neutral40',
  borderRadius: '$1',
  cursor: 'pointer',

  '&.highlight': {
    borderColor: '$yellow50',
  },
});

const Card = styledComponent('section')`
  background-color: ${(props) =>
    props.cardColor ? props.cardColor : '#F6F7F9'};
  border-radius: 4px;
  padding-right: 20px;
  display: grid;
  grid-template-areas: "icon header" "icon content";
  grid-template-columns: 86px auto;
  grid-template-rows: auto auto;
  color: #1a1d25;
  &.instruction {
    //grid-template-areas: "icon header" "icon content" "footer footer";
    //grid-template-columns: 86px auto;
    //grid-template-rows: auto auto 50px;
  }
  &.info {
    background: #eef0f6;
    color: #4d5a79;
  }
`;

const CardIcon = styledComponent('div')`
  grid-area: icon;
  width: 33px;
  height: 33px;
  border-radius: 100%;
  background: #1a1d25;
  margin: 31px 13px auto 40px;

  font-family: var(--font-heading-s-family);
  font-weight: var(--font-heading-s-weight);
  font-size: var(--font-heading-s-size);
  line-height: var(--font-heading-s-line-height);
  letter-spacing: var(--font-heading-s-letter-spacing);
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CardHeader = styledComponent('div')`
  grid-area: header;
  margin: 35px 0 10px;
  font-family: var(--font-heading-m-family);
  font-weight: var(--font-heading-m-weight);
  font-size: var(--font-heading-m-size);
  line-height: var(--font-heading-m-line-height);
  letter-spacing: var(--font-heading-m-letter-spacing);
`;

const CardContent = styledComponent('div')`
  grid-area: content;
  margin-bottom: 34px;

  font-family: var(--font-body-xs-family);
  font-weight: var(--font-body-xs-weight);
  font-size: var(--font-body-xs-size);
  line-height: var(--font-body-xs-line-height);
  letter-spacing: var(--font-body-xs-letter-spacing);
`;

const Action = styledComponent('div')`
  margin: 24px 0;
`;

const Instruction = styledComponent('img')`
  margin: 24px 0;
  max-width: 600px;
`;

export const StepCard = ({
  children,
  icon,
  title,
  instruction,
  action,
  fullWidthText,
  cardColor,
}) => (
  <Card className={classNames({ instruction })} cardColor={cardColor}>
    <CardIcon>{icon}</CardIcon>
    <CardHeader>{title}</CardHeader>
    <CardContent>
      <Text fullWidthText={fullWidthText}>{children}</Text>
      {action && <Action>{action}</Action>}
      {instruction &&
        instruction.map((element) => <Instruction src={element} alt={title} />)}
    </CardContent>
  </Card>
);

export function isCert(file) {
  const acceptedList = [
    'application/x-x509-ca-cert',
    'application/x-pem-file',
    // * Circumvent Windows bug
    '',
  ];
  return file && acceptedList.includes(file?.type);
}

export const UploadArea = ({ uploadError, setUploadError, file, setFile }) => {
  const fileInput = useRef(null);
  const preventDefaults = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const validateFile = (dfile) => {
    if (isCert(dfile)) {
      setFile(dfile);
      setUploadError(null);
    } else {
      setFile(null);
      setUploadError(i18n.t("This doesn't appear to be a valid certificate."));
    }
  };
  useLayoutEffect(() => {
    const dropArea = document.getElementById('drop-area');
    const highlight = () => dropArea.classList.add('highlight');
    const unhighlight = () => dropArea.classList.remove('highlight');
    const handleDrop = (e) => validateFile(e.dataTransfer.files[0]);
    if (dropArea) {
      ['dragenter', 'dragover', 'dragleave', 'drop'].forEach((eventName) => {
        dropArea.addEventListener(eventName, preventDefaults, false);
      });
      ['dragenter', 'dragover'].forEach((eventName) => {
        dropArea.addEventListener(eventName, highlight, false);
      });
      ['dragleave', 'drop'].forEach((eventName) => {
        dropArea.addEventListener(eventName, unhighlight, false);
      });
      dropArea.addEventListener('drop', handleDrop, false);
    }
  }, []);
  const handleFiles = (e) => validateFile(e.target.files[0]);
  const handleClick = () => fileInput.current && fileInput.current.click();
  return (
    <>
      <UploadAreaWrapper>
        <input
          hidden
          ref={fileInput}
          type="file"
          accept=".pem"
          onChange={handleFiles}
        />
        <DashedArea
          onClick={handleClick}
          data-testid="fileUpload"
          id="drop-area"
        >
          <Icon
            name="file-arrow-up"
            size="md"
            color="var(--colors-neutral40)"
          ></Icon>
          <Text>
            {get(file, 'name') ||
              i18n.$t('Drag here or {chooseFileLink}', {
                chooseFileLink: <Link>choose file</Link>,
              })}
          </Text>
        </DashedArea>
      </UploadAreaWrapper>
      {uploadError && (
        <Banner
          theme="warning"
          text={uploadError}
          css={{ marginBottom: '$3' }}
        />
      )}
    </>
  );
};

const MDMIntegrationPage = () => {
  useWhiteBg();
  useAdjustSidebarChatBubble();
  const SIDEBAR_DOCKED_OFFSET = 256;
  const SIDEBAR_CLOSE_OFFSET = 78;
  const { sidebarDocked } = useContext(InterfaceContext);
  const isRenewPage = window.location.pathname.indexOf('renew') >= 0;
  const [integrationData, setIntegrationData] = useState({});
  const [email, setEmail] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [file, setFile] = useState(null);
  const [uploadError, setUploadError] = useState(null);
  const { fetchAccount } = useContext(AccountContext);
  const [next, prev, steps, activeStep, isFinal, , goToStep] = useStepper([
    {
      id: '1',
      label: i18n.t('Get ready'),
      active: true,
    },
    {
      id: '2',
      label: isRenewPage
        ? i18n.t('Renew with Apple')
        : i18n.t('Create with Apple'),
      active: false,
    },
    {
      id: '3',
      label: i18n.t('Upload to Kandji'),
      active: false,
    },
  ]);

  useEffect(() => {
    if (isRenewPage) {
      getIntegration()
        .then((integrationDataResult) => {
          if (integrationDataResult.days_left === null) {
            history.push('/my-company/integrations');
          }
          setIntegrationData(integrationDataResult);
        })
        .catch(() => history.push('/my-company/integrations'));
    }
  }, [setIntegrationData]);
  useEffect(() => {
    if (email) {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
        setEmailError(i18n.t('Invalid Email Format'));
      } else {
        setEmailError(null);
      }
    }
  }, [email]);
  useEffect(() => {
    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      uploadPushCSR(formData, { validate: true }).catch(() => {
        setFile(null);
        setUploadError(
          i18n.t("This doesn't appear to be a valid certificate."),
        );
      });
    }
  }, [file]);

  const handleUploadComplete = async () => {
    await fetchAccount();
    history.push('/my-company/integrations');
  };

  const handleSubmit = () => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('apple_id', email);
    return uploadPushCSR(formData)
      .then(handleUploadComplete)
      .catch(() => {
        setFile(null);
        setUploadError(
          i18n.t("This doesn't appear to be a valid certificate."),
        );
      });
  };
  const isMdmExpired = get(integrationData, 'days_left') < 0;
  const timeTillExpired = moment(get(integrationData, 'expire_at')).fromNow();
  return (
    <>
      <Breadcrumbs isNavigation css={{ margin: '$4 0' }}>
        <BreadcrumbsItem
          item={{
            title: i18n.t('Settings'),
            url: '/my-company/integrations',
          }}
          itemRender={({ title, url }) => (
            <RouterLink to={url}>{title}</RouterLink>
          )}
        />
        <BreadcrumbsItem
          item={{
            title: isRenewPage
              ? i18n.t('Renew APNs Integration')
              : i18n.t('APNs Integration'),
          }}
        />
      </Breadcrumbs>
      <Heading size={2}>
        {isRenewPage
          ? i18n.t('Renew APNs Integration')
          : i18n.t('APNs Integration')}
      </Heading>
      <ContentContainer>
        {isRenewPage && get(integrationData, 'expire_at') && (
          <Banner
            theme="warning"
            text={
              isMdmExpired
                ? i18n.t('Your APNs certificate has expired.')
                : i18n.t(
                    `Your APNs certificate is expiring {timeTillExpired}.`,
                    {
                      timeTillExpired,
                    },
                  )
            }
          />
        )}
        <Stepper steps={steps} onClickStep={(step) => goToStep(step.id)} />
        {activeStep.id === '1' && (
          <Flex flow="column" gap="lg">
            <Section>
              <SectionBody>
                <Heading size={3}>{i18n.t('Download the Kandji CSR')}</Heading>
                <Body>
                  {i18n.ut(`By default, the <b>kandji-mdm.csr</b> file will appear
                  in your Downloads folder. You will upload this to Apple's Push
                  Certificate Portal in Step 4.`)}
                </Body>
                <Button
                  variant="primary"
                  onClick={getPushCSR}
                  icon={{ name: 'arrow-down-to-line' }}
                >
                  {i18n.t('Download push CSR')}
                </Button>
              </SectionBody>
            </Section>
            <Section>
              <SectionBody>
                <Heading size={3}>
                  {i18n.t('Next, sign in with your Apple Account')}
                </Heading>
                {i18n.$t(
                  `Visit {linkPushCert} and sign in using your Apple Account, we recommend that you use a dedicated Managed Apple Account from Apple Business Manager. Please reference this support article {linkLearnMore}.`,
                  {
                    linkPushCert: (
                      <Link
                        href="https://identity.apple.com/pushcert"
                        target="_blank"
                      >
                        identity.apple.com/pushcert
                      </Link>
                    ),
                    linkLearnMore: (
                      <Link
                        href="https://support.kandji.io/kb/getting-started-apple-integrations"
                        target="_blank"
                      >
                        {i18n.t('this support article')}
                      </Link>
                    ),
                  },
                )}
              </SectionBody>
            </Section>
          </Flex>
        )}
        {activeStep.id === '2' && (
          <Flex flow="column" gap="lg">
            <Section>
              <SectionBody>
                <Heading size={3}>
                  {isRenewPage
                    ? i18n.t(
                        'In your Apple account, begin renewing your current APNs certificate',
                      )
                    : i18n.t('Create a Push Certificate with Apple')}
                </Heading>
                {isRenewPage && (
                  <Body>
                    {i18n.ut(`Click the <b>Renew</b> button. You will be asked to
                    review and accept Apple's terms and conditions before continuing.`)}
                  </Body>
                )}
                {isRenewPage ? (
                  <InstructionImg
                    src={instruction3}
                    alt={i18n.t(
                      'In your Apple account, begin renewing your current APNs certificate',
                    )}
                  />
                ) : (
                  <InstructionImg
                    src={instruction3new}
                    alt={i18n.t('Create a Push Certificate with Apple')}
                  />
                )}
              </SectionBody>
            </Section>
            <Section>
              <SectionBody>
                <Heading size={3}>{i18n.t('Next, upload Kandji CSR')}</Heading>
                <Body>
                  {i18n.ut(
                    `Click the <b>Choose File</b> button and select the <b>kandji-mdm.csr</b> file you downloaded in Step 1. Then click the <b>Upload</b> button.`,
                  )}
                </Body>
                {isRenewPage ? (
                  <InstructionImg
                    src={instruction4}
                    alt={i18n.t('Upload Kandji CSR')}
                  />
                ) : (
                  <InstructionImg
                    src={instruction4new}
                    alt={i18n.t('Upload Kandji CSR')}
                  />
                )}
              </SectionBody>
            </Section>
            <Section>
              <SectionBody>
                <Heading size={3}>
                  {i18n.t('Finally, download the Push Certificate')}
                </Heading>
                <Body>
                  {i18n.ut(
                    `Click the <b>Download</b> button to download the Push
                    Certificate that was just created. By default, the <b>MDM_ Kandji, Inc._Certificate.pem</b> file will
                    appear in your Downloads folder.`,
                  )}
                </Body>
                <InstructionImg
                  src={instruction5}
                  alt={i18n.t('Download the Push Certificate')}
                />
              </SectionBody>
            </Section>
          </Flex>
        )}
        {activeStep.id === '3' && (
          <Flex flow="column" gap="lg">
            <Section>
              <SectionBody>
                <Heading size={3}>
                  {i18n.t('Upload the Push Certificate to Kandji here')}
                </Heading>
                <Body>
                  {i18n.ut(`Upload the <b>MDM_Kandji, Inc._Certificate.pem</b> you
                  downloaded from Apple.`)}
                </Body>
                <UploadArea
                  uploadError={uploadError}
                  setUploadError={setUploadError}
                  file={file}
                  setFile={setFile}
                />
                <TextField
                  css={{ maxWidth: '320px' }}
                  label={i18n.t('Apple account email address')}
                  value={email}
                  state={emailError ? 'error' : 'default'}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder={i18n.t('Enter the Apple account email address')}
                  hint={{
                    label: emailError
                      ? emailError
                      : i18n.t(
                          'Enter the email address of the Apple account you used to generate this Push Certificate.',
                        ),
                  }}
                />
              </SectionBody>
            </Section>
          </Flex>
        )}
      </ContentContainer>
      <Flex
        css={{
          position: 'fixed',
          bottom: '0',
          right: '0',
          justifyContent: activeStep.id !== '1' ? 'space-between' : 'flex-end',
          width: `calc(100% - ${sidebarDocked ? SIDEBAR_DOCKED_OFFSET : SIDEBAR_CLOSE_OFFSET}px)`,
          padding: '$3 $7',
          backgroundColor: '$neutral0',
          boxShadow: '0px -4px 5px rgb(0 0 0 / 4%)',
        }}
      >
        {activeStep.id !== '1' && (
          <Button
            variant="subtle"
            icon={{ name: 'arrow-left' }}
            onClick={() => prev()}
            css={{ placeSelf: 'start' }}
          >
            {i18n.t('Back')}
          </Button>
        )}
        <Flex gap="md">
          <Button
            variant="default"
            onClick={() => history.push('/my-company/integrations')}
          >
            {i18n.t('Cancel')}
          </Button>

          {activeStep.id === '1' && (
            <Button variant="primary" onClick={() => next()}>
              {i18n.t("I'm ready")}
            </Button>
          )}
          {activeStep.id === '2' && (
            <Button variant="primary" onClick={() => next()}>
              {i18n.t('Continue')}
            </Button>
          )}
          {isFinal && (
            <Button
              variant="primary"
              disabled={!file || !email || emailError || uploadError}
              className="pendo-complete-apns"
              onClick={handleSubmit}
            >
              {isRenewPage
                ? i18n.t('Complete APNs renewal')
                : i18n.t('Complete APNs setup')}
            </Button>
          )}
        </Flex>
      </Flex>
    </>
  );
};

export default MDMIntegrationPage;
