/* istanbul ignore file */
import { useQuery } from '@tanstack/react-query';
import { vulnerabilityApi } from 'src/app/api/base';
import type { DetectionDateFilterFields } from 'src/features/edr/common/common.types';
import { transformDaysAgoFilterToApi } from '../../helpers';

const useGetNewVulnerabilitiesDetected = (
  timespan: Partial<DetectionDateFilterFields> = null,
  keys: Array<any> = [],
  params: any = {},
) => {
  const { data: apiRes, isLoading } = useQuery({
    queryKey: ['new-vulns-detected', timespan, ...(keys || [])],
    queryFn: () =>
      vulnerabilityApi(`v1/dashboards/new_vulns_detected`).get({
        ...params,
        ...transformDaysAgoFilterToApi(timespan),
      }),
  });

  const data = apiRes?.data;
  const count: number = data?.count || 0;
  const trend: number = data?.trend || 0;

  return { count, trend, isLoading };
};

export { useGetNewVulnerabilitiesDetected };
