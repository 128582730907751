const computer = {
  SET_COMPUTERS: 'SET_COMPUTERS',
  SET_COMPUTERS_FOR_FILTERS: 'SET_COMPUTERS_FOR_FILTERS',
  SET_COMPUTERS_FOR_FILTERS_IS_FETCHING:
    'SET_COMPUTERS_FOR_FILTERS_IS_FETCHING',
  CLEAR_COMPUTERS: 'CLEAR_COMPUTERS',
  SET_COMPUTER: 'SET_COMPUTER',
  SET_COMPUTER_MDM_AGENT_LAST_CHECK_IN: 'SET_COMPUTER_MDM_AGENT_LAST_CHECK_IN',
  CLEAR_COMPUTER: 'CLEAR_COMPUTER',
  UPDATE_COMPUTER: 'UPDATE_COMPUTER',
  SET_DEVICE_BYPASS_CODE: 'SET_DEVICE_BYPASS_CODE',
  SET_DEVICE_RECOVERY_PASSWORD: 'SET_DEVICE_RECOVERY_PASSWORD',
  SET_PAGE_SCROLL: 'SET_PAGE_SCROLL',
};

const depComputer = {
  SET_DEP_COMPUTERS: 'SET_DEP_COMPUTERS',
  CLEAR_DEP_COMPUTERS: 'CLEAR_DEP_COMPUTERS',
  SET_DEP_COMPUTER: 'SET_DEP_COMPUTER',
  UPDATE_DEP_COMPUTER: 'UPDATE_DEP_COMPUTER',
};

const ui = {
  SET_MODAL: 'SET_MODAL',
  CLEAR_MODAL: 'CLEAR_MODAL',
  SET_SNACKBAR: 'SET_SNACKBAR',
  CLEAR_SNACKBAR: 'CLEAR_SNACKBAR',
  SET_SIDEBAR_OPENED: 'SET_SIDEBAR_OPENED',
  SET_SIDEBAR_DOCKED: 'SET_SIDEBAR_DOCKED',
  CLOSE_MODAL: 'CLOSE_MODAL',
};

const users = {
  COMPANY_USER_FETCHED: 'COMPANY_USER_FETCHED',
  COMPANY_USER_UPDATED: 'COMPANY_USER_UPDATED',
  COMPANY_USERS_FETCHED: 'COMPANY_USERS_FETCHED',
  COMPANY_USER_DELETED: 'COMPANY_USER_DELETED',
};

const account = {
  ACCOUNT_FETCHING: 'ACCOUNT_FETCHING',
  ACCOUNT_FETCHED: 'ACCOUNT_FETCHED',
  ACCOUNT_USER_FETCHED: 'ACCOUNT_USER_FETCHED',
  ACCOUNT_USER_UPDATED: 'ACCOUNT_USER_UPDATED',
  ACCOUNT_COMPANY_FETCHED: 'ACCOUNT_COMPANY_FETCHED',
  ACCOUNT_LOGOUT: 'ACCOUNT_LOGOUT',
  ACCOUNT_AUTH0_CREDS_FETCHED: 'ACCOUNT_AUTH0_CREDS_FETCHED',
  ACCOUNT_AUTH0_USER: 'ACCOUNT_AUTH0_USER',
  ACCOUNT_LOCALE_UPDATED: 'ACCOUNT_LOCALE_UPDATED',
  ACCOUNT_COMPUTER_LIST_FETCHED: 'ACCOUNT_COMPUTER_LIST_FETCHED',
};

const app = {
  APP_DATA_READY: 'APP_DATA_READY',
  RESET_PASSWORD_TOKEN_VALIDATED: 'RESET_PASSWORD_TOKEN_VALIDATED',
};

const blueprint = {
  SET_BLUEPRINTS: 'SET_BLUEPRINTS',
  SET_BLUEPRINT_MESSAGE: 'SET_BLUEPRINT_MESSAGE',
  ADD_BLUEPRINT: 'ADD_BLUEPRINT',
  DELETE_BLUEPRINT: 'DELETE_BLUEPRINT',
  SET_BLUEPRINT: 'SET_BLUEPRINT',
  CLEAR_BLUEPRINT: 'CLEAR_BLUEPRINT',
  UPDATE_BLUEPRINT: 'UPDATE_BLUEPRINT',
  GET_BLUEPRINT_PARAMS: 'GET_BLUEPRINT_PARAMS',
  SET_BLUEPRINT_PARAMS: 'SET_BLUEPRINT_PARAMS',
  TURN_ON_ENROLLMENT_CODE: 'TURN_ON_ENROLLMENT_CODE',
  CHANGE_ENROLLMENT_CODE: 'CHANGE_ENROLLMENT_CODE',
  CLEAR_BLUEPRINT_PARAMS: 'CLEAR_BLUEPRINT_PARAMS',
  SET_BLUEPRINT_WITHOUT_CHANGING_TAB: 'SET_BLUEPRINT_WITHOUT_CHANGING_TAB',
  DECREASE_BLUEPRINT_COMPUTERS_COUNT: 'DECREASE_BLUEPRINT_COMPUTERS_COUNT',
};

const params = {
  SET_PARAM_META: 'SET_PARAM_META',
  SET_TABS_STATUS: 'SET_TABS_STATUS',
  SET_TABS_SCROLL: 'SET_TABS_SCROLL',
  TAGS_FETCHED: 'TAGS_FETCHED',
};

const company = {
  UPDATE_COMPANY: 'UPDATE_COMPANY',
};

const editor = {
  SET_EDITOR: 'SET_EDITOR',
  CLEAR_EDITOR: 'CLEAR_EDITOR',
};

export {
  account,
  app,
  blueprint,
  company,
  computer,
  depComputer,
  editor,
  params,
  ui,
  users,
};
