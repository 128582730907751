import { Loader, Toaster as toaster } from '@kandji-inc/bumblebee';
/* istanbul ignore file */
import type React from 'react';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import { libraryAxios } from 'app/api/base';
import { links } from 'app/common/constants';
import { LineLoader } from 'app/components/interface/LineLoader';
import { profileLog, vppAppLog } from 'app/components/library/log';
import {
  BootstrapTable,
  TableHeaderColumn,
} from '../../../../app/components/common/BootstrapTable';
import Const from '../../../../app/components/common/BootstrapTable/Const';
import Basic from '../../../../theme/components/molecules/Basic';
import HoverTippy from '../../../util/components/hover-tippy';
import IconRules from '../../library/library-list/assets/icon_rules.svg';
import './new-status-tab.styles.scss';
import { i18n } from 'i18n';
import { formatStatusLogs } from 'src/features/device-status-tab/LibraryItemStatusLogs/lib/formatStatusLogs';
import { Status } from 'src/features/device-status-tab/device-status-tab.types';
import {
  ExpandColumnComponent,
  PaginationPanel,
  SizePerPageDropdown,
  StatusFilter,
  StatusSearch,
} from './parts';
import type { NewStatusTabProps, RouteParams, StatusCountsType } from './types';
import {
  ORDERING_FIELDS,
  STATUSES,
  composeApiOrdering,
  formatStatus,
  parseApiOrdering,
  transformFromOldApi,
} from './util';

type StatusQueryParamsType = {
  ordering: string;
  page: number;
  size_per_page: number;
  search: string;
  status: string;
  status__in?: string;
  sizePerPage?: number;
};

const LOADING_STATES = {
  NEW_ITEM: 'NEW_ITEM',
  NEW_PARAMS: 'NEW_PARAMS',
  NOT_LOADING: 'NOT_LOADING',
};

const DEFAULT_STATUS_COUNTS = {
  [STATUSES.ALL]: 0,
  [STATUSES.PENDING]: 0,
  [STATUSES.CHANGES_PENDING]: 0,
  [STATUSES.INCOMPATIBLE]: 0,
  [STATUSES.PASS]: 0,
  [STATUSES.AVAILABLE]: 0,
  [STATUSES.REMEDIATED]: 0,
  [STATUSES.ERROR]: 0,
  [STATUSES.EXCLUDED]: 0,
};

const DEFAULT_STATUS_QUERY_PARAMS: StatusQueryParamsType = {
  ordering: ORDERING_FIELDS.toApi.computer,
  page: 1,
  size_per_page: Const.SIZE_PER_PAGE_LIST[0],
  search: '',
  status: STATUSES.ALL,
};

const NewStatusTab: React.FC<NewStatusTabProps> = ({
  statusCounts: externalStatusCounts,
  setStatusCounts: setExternalStatusCounts = () => {},
  blueprints = [],
}) => {
  const routeParams = useParams<RouteParams>();
  const libraryItemId =
    routeParams.item || routeParams.profileId || routeParams.itemId;

  const [internalStatusCounts, setInternalStatusCounts] =
    useState<StatusCountsType | null>(null);
  const [statusesData, setStatusesData] = useState([]);
  const [params, setParams] = useState<StatusQueryParamsType>(
    DEFAULT_STATUS_QUERY_PARAMS,
  );
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [loadingState, setLoadingState] = useState(LOADING_STATES.NEW_ITEM);
  const [forceReload, setForceReload] = useState(0);

  const paramsAsDep = JSON.stringify(params);

  useEffect(() => {
    setStatusesData([]);
    const adjustedParams = { ...params };
    if (params.status === STATUSES.ALL) {
      delete adjustedParams.status;
    }
    if (params.status === STATUSES.PENDING) {
      delete adjustedParams.status;
      adjustedParams.status__in = 'PENDING, pending, INSTALLING, installing';
    }
    if (params.status === STATUSES.PASS) {
      delete adjustedParams.status;
      adjustedParams.status__in = 'PASS, success';
    }
    if (params.status === STATUSES.ERROR) {
      delete adjustedParams.status;
      adjustedParams.status__in = 'ERROR, failed';
    }
    if (adjustedParams.status) {
      adjustedParams.status__in = adjustedParams.status;
      delete adjustedParams.status;
    }
    adjustedParams.sizePerPage = adjustedParams.size_per_page;
    delete adjustedParams.size_per_page;

    libraryAxios(`library-items/statuses/${libraryItemId}`)
      .get(adjustedParams)
      .then(transformFromOldApi)
      .then(({ data: { results, count, status_counts, ...rest } }) => {
        const resultsWithDate = results.map((res: Record<string, string>) =>
          formatStatusLogs({
            date: res.reported_at,
            ...res,
          } as any),
        );
        setStatusesData(resultsWithDate);
        setTotalItemsCount(count);
        if (status_counts && !externalStatusCounts) {
          setInternalStatusCounts({ ...status_counts });
        }
        setLoadingState(LOADING_STATES.NOT_LOADING);
      })
      .catch((e) => {
        console.log(e);
        toaster(i18n.common.error());
      });
  }, [paramsAsDep, libraryItemId, forceReload]);

  useEffect(() => {
    setLoadingState(LOADING_STATES.NEW_ITEM);
  }, [libraryItemId]);

  useEffect(() => {
    setLoadingState(LOADING_STATES.NEW_PARAMS);
  }, [paramsAsDep, forceReload]);

  function changeParam(
    name: string,
    transformer: (...args: unknown[]) => unknown = (v) => v,
  ) {
    const shouldResetPage = ['status', 'search'].includes(name);
    return (...args) =>
      setParams((curr) => ({
        ...curr,
        page: shouldResetPage ? 1 : curr.page,
        [name]: transformer(...args),
      }));
  }

  const { sortName, sortOrder } = parseApiOrdering(params.ordering);
  const isReloading = loadingState !== LOADING_STATES.NOT_LOADING;
  const sortingIsEnabled = statusesData.length > 1 && !isReloading;

  return (
    <Basic section="view">
      {loadingState === LOADING_STATES.NEW_ITEM ? (
        <LineLoader />
      ) : (
        <div className="view-status">
          <div className="filter-block">
            <div className="paper">
              <div className="node">
                <div className="filters">
                  <StatusFilter
                    statusCounts={
                      externalStatusCounts ||
                      internalStatusCounts ||
                      DEFAULT_STATUS_COUNTS
                    }
                    selectedStatus={params.status}
                    setSelectedStatus={changeParam('status')}
                    disabled={isReloading}
                  />
                  <StatusSearch
                    onChange={changeParam('search')}
                    value={params.search}
                    disabled={isReloading}
                  />
                </div>
              </div>
            </div>
          </div>

          <BootstrapTable
            pagination
            data={statusesData}
            version="4"
            containerClass="old-table"
            remote
            options={{
              sortOrder,
              sortName,
              btnGroup: null,
              toolBar: null,
              page: params.page,
              sizePerPageList: Const.SIZE_PER_PAGE_LIST,
              sizePerPage: params.size_per_page,
              onPageChange: changeParam('page'),
              onSizePerPageList: changeParam('size_per_page'),
              onSortChange: changeParam('ordering', composeApiOrdering),
              paginationPanel: PaginationPanel,
              sizePerPageDropDown: (props) => (
                <SizePerPageDropdown
                  {...props}
                  disabled={!statusesData?.length}
                />
              ),
              noDataView:
                loadingState === LOADING_STATES.NEW_PARAMS ? (
                  <Loader cover={false} className="new-status-tab__loader" />
                ) : undefined,
            }}
            fetchInfo={{ dataTotalSize: totalItemsCount }}
            expandableRow={(row) => !!(row.log || row.last_audit_log)}
            expandColumnOptions={{
              expandColumnVisible: true,
              expandColumnComponent: ExpandColumnComponent,
              columnWidth: 60,
            }}
            expandComponent={(status) => (
              <div className="parameter-details">
                {status.type === 'profile'
                  ? profileLog(status)
                  : vppAppLog(status)}
              </div>
            )}
          >
            <TableHeaderColumn dataField="id" hidden isKey />

            <TableHeaderColumn
              dataField="computer"
              dataSort={sortingIsEnabled}
              dataFormat={(cell) => (
                <Link
                  role="presentation"
                  className="cursor-pointer underline-cell new-status-tab__link"
                  style={{ fontWeight: 500 }}
                  to={`${links.devices}/${cell.id}`}
                  title={cell.name}
                  onClick={(e) => e.stopPropagation()}
                >
                  {cell.name}
                </Link>
              )}
            >
              {i18n.t('Name')}
            </TableHeaderColumn>

            <TableHeaderColumn
              dataField="blueprint"
              dataSort={sortingIsEnabled}
              dataFormat={(cell) => {
                const blueprint = blueprints.find(
                  ({ value }) => value === cell.id,
                );
                const isClassicBlueprint =
                  !blueprint || blueprint?.type === 'form';

                return (
                  <div className="b-flex">
                    <Link
                      role="presentation"
                      className="cursor-pointer underline-cell b-mr-tiny new-status-tab__link"
                      to={`${links.blueprints}/${cell.id}`}
                      replace
                      title={cell.name}
                      onClick={(e) => e.stopPropagation()}
                    >
                      {cell.name}
                    </Link>
                    {isClassicBlueprint && cell.rulesPresent && (
                      <HoverTippy text="Assignment Rules" icon={IconRules} />
                    )}
                  </div>
                );
              }}
            >
              {i18n.t('Blueprint')}
            </TableHeaderColumn>

            <TableHeaderColumn
              dataField="reported_at"
              dataSort={sortingIsEnabled}
              dataFormat={(cell) => (cell ? i18n.format.datetime(cell) : '')}
            >
              {i18n.t('Install Date')}
            </TableHeaderColumn>

            <TableHeaderColumn
              dataField="status"
              dataSort={sortingIsEnabled}
              dataFormat={(cell, row) =>
                formatStatus(
                  cell,
                  row,
                  () => {},
                  () => setForceReload((curr) => curr + 1),
                )
              }
            >
              {i18n.t('Status')}
            </TableHeaderColumn>
          </BootstrapTable>
        </div>
      )}
    </Basic>
  );
};

export default NewStatusTab;
