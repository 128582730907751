/* istanbul ignore file */
import { Button, Dialog, Flex, Heading, Text } from '@kandji-inc/nectar-ui';
import React, { useContext, useMemo } from 'react';
import { i18n } from 'src/i18n';
import LibraryContext from '../../routes/library.context';

type AssignmentMapConflictsModalProps = {
  onClose: () => void;
  libraryItem: any;
  conflictingLibraryItem: any;
  onConfirmReassignment: () => void;
};

const AssignmentMapConflictsModal = (
  props: AssignmentMapConflictsModalProps,
) => {
  const {
    libraryItem,
    conflictingLibraryItem,
    onClose,
    onConfirmReassignment,
  } = props;
  const { getItemConfig } = useContext(LibraryContext);
  const liConfig = useMemo(
    () => getItemConfig(libraryItem?.type, libraryItem?.identifier),
    [libraryItem],
  );

  const title = (
    <Heading size="3">
      {i18n.t('Replace the current {itemType} Library Item?', {
        itemType: liConfig?.getName() || '',
      })}
    </Heading>
  );

  const content = (
    <Flex flow="column" gap="lg" css={{ minWidth: '512px', maxWidth: '650px' }}>
      <Text
        css={{
          lineHeight: '20px',
          '& b': {
            fontWeight: 500,
          },
        }}
      >
        {i18n.ut(
          'Only one {itemType} can be assigned here. Currently, <b>{itemName}</b> is assigned. Do you want to replace the currently assigned Library Item with this one? This change will be made when the Library Item is saved.',
          {
            itemType:
              liConfig?.getName() || i18n.t('of this type of Library Item'),
            itemName: `${conflictingLibraryItem.name}${
              conflictingLibraryItem.instance_name
                ? ` (${conflictingLibraryItem.instance_name})`
                : ''
            }`,
          },
        )}
      </Text>
    </Flex>
  );

  const footer = (
    <Flex gap="md" justifyContent="end">
      <Button compact variant="subtle" onClick={onClose}>
        {i18n.t('No, keep the current Library Item')}
      </Button>
      <Button
        compact
        variant="primary"
        onClick={() => {
          onConfirmReassignment();
          onClose();
        }}
      >
        {i18n.t('Yes, replace the current Library Item')}
      </Button>
    </Flex>
  );

  return (
    <Dialog
      isOpen
      closeOnEscape
      closeOnOutsideClick
      onOpenChange={onClose}
      title={title}
      content={content}
      footer={footer}
    />
  );
};

export default AssignmentMapConflictsModal;
