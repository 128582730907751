import RecoveryPasswordHistoryModal from 'app/components/interface/modals/RecoveryPasswordHistoryModal';
/* istanbul ignore file */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import featureFlags from 'src/config/feature-flags';
import ManageTags from 'src/features/tags/ManageTags';
import BulkEditComputersModal from 'src/features/tags/bulk-tag-actions';
import {
  changeDEPComputersAssetTag,
  changeDEPComputersBlueprint,
  editDEPComputerInfo,
  setDEPComputers,
  updateDEPComputer,
} from '../_actions/DEPComputer';
import {
  decreaseBlueprintComputersCount,
  getBlueprints,
  startGetBlueprint,
} from '../_actions/blueprint';
import {
  changeComputerBlueprint,
  deleteComputers,
  editComputerInfo,
  getComputer,
  getComputerBypassCode,
  getComputerRecoveryPassword,
  mergeComputers,
  setComputers,
  startGetComputers,
  updateComputer,
  updateComputerFields,
} from '../_actions/computer';
import { clearModal, closeModal, setModal, setSnackbar } from '../_actions/ui';
import AppBlacklist from './interface/modals/AppBlacklist';
import ApplicationBlocklistModal from './interface/modals/ApplicationBlocklist';
import ArchiveGSuiteUsers from './interface/modals/ArchiveGSuiteUsers';
import BlueprintCreateModal from './interface/modals/BlueprintCreate';
import BlueprintDelete from './interface/modals/BlueprintDelete';
import BlueprintDuplicate from './interface/modals/BlueprintDuplicate';
import BlueprintEdit from './interface/modals/BlueprintEdit';
import BypassModal from './interface/modals/BypassModal';
import ComputerChangeAssetTag from './interface/modals/ComputerChangeAssetTag';
import ComputerChangeBlueprint from './interface/modals/ComputerChangeBlueprint';
import ComputerChangeUser from './interface/modals/ComputerChangeUser';
import ComputerDelete from './interface/modals/ComputerDelete';
import ComputerDeleteUserAccount from './interface/modals/ComputerDeleteUserAccount';
import ComputerErase from './interface/modals/ComputerErase';
import ComputerIosLock from './interface/modals/ComputerIosLock';
import ComputerIosUnlock from './interface/modals/ComputerIosUnlock';
import ComputerLock from './interface/modals/ComputerLock';
import ComputerLogOutIPadUser from './interface/modals/ComputerLogOutIPadUser';
import ComputerMergeDuplicates from './interface/modals/ComputerMergeDuplicates';
import ComputerReinstallAgent from './interface/modals/ComputerReinstallAgent';
import ComputerRenewMDM from './interface/modals/ComputerRenewMDM';
import ComputerSetAutoAdminPassword from './interface/modals/ComputerSetAutoAdminPassword';
import ComputerSetMDMDeviceName from './interface/modals/ComputerSetMDMDeviceName';
import ComputerUnlockUserAccount from './interface/modals/ComputerUnlockUserAccount';
import DeleteGSuiteUsers from './interface/modals/DeleteGSuiteUsers';
import DisableLostMode from './interface/modals/DisableLostMode';
import DisconnectAppsAndBooks from './interface/modals/DisconnectAppsAndBooks';
import DisconnectAutoEnroll from './interface/modals/DisconnectAutoEnroll';
import EnableLostMode from './interface/modals/EnableLostMode';
import FeatureRequestModal from './interface/modals/FeatureRequestModal';
import GSuiteLogin from './interface/modals/GSuiteLogin';
import Office365Login from './interface/modals/Office365Login';
import RecoveryKeyModal from './interface/modals/RecoveryKeyModal';
import RecoveryLockPasswordModal from './interface/modals/RecoveryLockPasswordModal';
import RemoteDesktop from './interface/modals/RemoteDesktop';
import ResetMsDeviceRegistrationModal from './interface/modals/ResetMsDeviceRegistrationModal';
import RestoreGSuiteUsers from './interface/modals/RestoreGSuiteUsers';
import SimpleDialog from './interface/modals/SimpleDialog';
import TransferOwnershipModal from './interface/modals/TransferOwnershipModal';
import UserDelete from './interface/modals/UserDelete';
import UserInvite from './interface/modals/UserInvite';
import ViewSave from './interface/modals/ViewSave';
import WideDialog from './interface/modals/WideDialog';

const ModalConductor = (props) => {
  const staggeredModalClose = () => {
    props.closeModal();
    //  Delay for Bumblebee modal transition-out
    setTimeout(
      () => {
        props.clearModal();
      },
      props.isNewModal ? 350 : 0,
    );
  };

  return <Content {...props} clearModal={staggeredModalClose} />;
};

const Content = React.memo((props) => {
  const currentModal = props.currentModal || {};
  const { name, info } = currentModal;

  switch (name) {
    case 'BLUEPRINT_EDIT':
      return (
        <BlueprintEdit
          isOpen={props.isOpen}
          isNewModal={props.isNewModal}
          onHide={props.clearModal}
          getBlueprints={props.getBlueprints}
          blueprints={props.blueprints}
          info={info}
          blueprintRecord={props.blueprintRecord}
          setSnackbar={props.setSnackbar}
          startGetBlueprint={props.startGetBlueprint}
        />
      );
    case 'BLUEPRINT_DUPLICATE':
      return (
        <BlueprintDuplicate
          onHide={props.clearModal}
          getBlueprints={props.getBlueprints}
          info={info}
          blueprints={props.blueprints}
          setSnackbar={props.setSnackbar}
        />
      );
    case 'BLUEPRINT_DELETE':
      return (
        <BlueprintDelete
          onHide={props.clearModal}
          getBlueprints={props.getBlueprints}
          info={info}
          setSnackbar={props.setSnackbar}
          startGetComputers={props.startGetComputers}
        />
      );
    case 'COMPUTER_DELETE':
      return (
        <ComputerDelete
          decreaseBlueprintComputersCount={
            props.decreaseBlueprintComputersCount
          }
          deleteComputers={props.deleteComputers}
          getBlueprints={props.getBlueprints}
          info={info}
          name={props?.computerRecord?.name}
          onHide={props.clearModal}
          setComputers={props.setComputers}
        />
      );
    case 'COMPUTER_ERASE':
      return (
        <ComputerErase
          info={info}
          name={props?.computerRecord?.name}
          onHide={props.clearModal}
        />
      );
    case 'COMPUTER_RENEW_MDM':
      return <ComputerRenewMDM info={info} onHide={props.clearModal} />;
    case 'COMPUTER_LOCK':
      return <ComputerLock info={info} onHide={props.clearModal} />;
    case 'COMPUTER_LOG_OUT_IPAD_USER':
      return (
        <ComputerLogOutIPadUser
          getComputer={props.getComputer}
          info={info}
          onHide={props.clearModal}
        />
      );
    case 'COMPUTER_IOS_LOCK':
      return <ComputerIosLock info={info} onHide={props.clearModal} />;
    case 'COMPUTER_IOS_UNLOCK':
      return <ComputerIosUnlock info={info} onHide={props.clearModal} />;
    case 'COMPUTER_REINSTALL_AGENT':
      return <ComputerReinstallAgent info={info} onHide={props.clearModal} />;
    case 'COMPUTER_UNLOCK_USER':
      return (
        <ComputerUnlockUserAccount info={info} onHide={props.clearModal} />
      );
    case 'COMPUTER_DELETE_USER':
      return (
        <ComputerDeleteUserAccount info={info} onHide={props.clearModal} />
      );
    case 'COMPUTER_SET_MDM_DEVICE_NAME':
      return <ComputerSetMDMDeviceName info={info} onHide={props.clearModal} />;
    case 'COMPUTER_SET_AUTO_ADMIN_PASSWORD':
      return (
        <ComputerSetAutoAdminPassword info={info} onHide={props.clearModal} />
      );
    case 'COMPUTERS_BULK_EDIT_TAGS':
      return (
        <BulkEditComputersModal
          onHide={props.clearModal}
          computerIds={info.selectedComputerIds}
        />
      );
    case 'COMPUTERS_MANAGE_TAGS':
      return <ManageTags isOpen setIsOpen={props.clearModal} />;
    case 'COMPUTER_CHANGE_BLUEPRINT':
      return (
        <ComputerChangeBlueprint
          blueprintNames={props.blueprintNames}
          blueprintRecord={props.blueprintRecord}
          changeBlueprint={props.changeComputerBlueprint}
          getBlueprints={props.getBlueprints}
          getComputer={props.getComputer}
          info={info}
          onHide={props.clearModal}
          startGetBlueprint={props.startGetBlueprint}
          updateComputer={props.updateComputer}
          blueprints={props.blueprints}
        />
      );
    case 'DEP_COMPUTER_CHANGE_BLUEPRINT':
      return (
        <ComputerChangeBlueprint
          blueprintNames={props.blueprintNames}
          blueprintRecord={props.blueprintRecord}
          changeBlueprint={props.changeDEPComputersBlueprint}
          getBlueprints={props.getBlueprints}
          getComputer={props.getDEPComputer}
          info={info}
          onHide={props.clearModal}
          startGetBlueprint={props.startGetBlueprint}
          updateComputer={props.updateDEPComputer}
          blueprints={props.blueprints}
        />
      );
    case 'COMPUTER_CHANGE_ASSET_TAG':
      return (
        <ComputerChangeAssetTag
          onHide={props.clearModal}
          info={info}
          setSnackbar={props.setSnackbar}
          computerRecord={props.computerRecord}
          changeDEPComputersAssetTag={props.changeDEPComputersAssetTag}
        />
      );
    case 'COMPUTER_MERGE_DUPLICATES':
      return (
        <ComputerMergeDuplicates
          computerRecord={props.computerRecord}
          info={info}
          mergeComputers={props.mergeComputers}
          onHide={props.clearModal}
          startGetComputers={props.startGetComputers}
        />
      );
    case 'COMPUTER_CHANGE_USER':
      return (
        <ComputerChangeUser
          onHide={props.clearModal}
          info={info}
          computers={props.computers}
          setSnackbar={props.setSnackbar}
          computerRecord={props.computerRecord}
        />
      );
    case 'DISABLE_LOST_MODE':
      return (
        <DisableLostMode
          computer={props.computerRecord}
          info={info}
          name={props.computerRecord.name}
          onHide={props.clearModal}
          updateComputer={props.updateComputer}
        />
      );
    case 'ENABLE_REMOTE_DESKTOP':
      return (
        <RemoteDesktop
          computer={props.computerRecord}
          info={info}
          isEnabled
          onHide={props.clearModal}
        />
      );
    case 'DISABLE_REMOTE_DESKTOP':
      return (
        <RemoteDesktop
          computer={props.computerRecord}
          info={info}
          isEnabled={false}
          onHide={props.clearModal}
        />
      );
    case 'ENABLE_LOST_MODE':
      return (
        <EnableLostMode
          computer={props.computerRecord}
          info={info}
          onHide={props.clearModal}
          updateComputer={props.updateComputer}
        />
      );
    case 'FILE_VAULT_2_RECOVERY_KEY':
      return <RecoveryKeyModal info={info} onHide={props.clearModal} />;
    case 'BYPASS_CODE':
      return (
        <BypassModal
          bypassCode={props.computerRecord.bypassCode}
          bypassCodeFailed={info.bypassCodeFailed}
          computerId={info.computerId}
          getComputerBypassCode={props.getComputerBypassCode}
          onHide={props.clearModal}
        />
      );
    case 'RECOVERY_LOCK_PASSWORD':
      if (featureFlags.getFlag('dc-366-recovery-password-history')) {
        return (
          <RecoveryPasswordHistoryModal
            onHide={props.clearModal}
            computerId={info.deviceId}
          />
        );
      }
      return (
        <RecoveryLockPasswordModal
          onHide={props.clearModal}
          deviceId={info.deviceId}
          recoveryPassword={props.computerRecord.recoveryPassword}
          getComputerRecoveryPassword={props.getComputerRecoveryPassword}
        />
      );
    case 'USER_INVITE_CHANNEL':
      return <UserInvite onHide={props.clearModal} info={info} />;
    case 'USER_DELETE':
      return <UserDelete onHide={props.clearModal} info={info} />;
    case 'SAVE_NEW_VIEW':
      return <ViewSave onHide={props.clearModal} info={info} />;
    case 'G_SUITE_LOGIN':
      return <GSuiteLogin onHide={props.clearModal} info={info} />;
    case 'OFFICE365_LOGIN':
      return <Office365Login onHide={props.clearModal} info={info} />;
    case 'COMPUTER_APPLICATIONS_BLACKLIST':
      return (
        <ApplicationBlocklistModal onHide={props.clearModal} info={info} />
      );
    // return <AppBlacklist onHide={props.clearModal} info={info} />;
    case 'ARCHIVE_G_SUITE_USERS':
      return <ArchiveGSuiteUsers onHide={props.clearModal} info={info} />;
    case 'RESTORE_G_SUITE_USERS':
      return <RestoreGSuiteUsers onHide={props.clearModal} info={info} />;
    case 'DELETE_G_SUITE_USERS':
      return <DeleteGSuiteUsers onHide={props.clearModal} info={info} />;
    case 'BLUEPRINT_CREATE':
      return <BlueprintCreateModal onHide={props.clearModal} />;
    case 'SIMPLE_DIALOG':
      return <SimpleDialog onHide={props.clearModal} {...info} />;
    case 'WIDE_DIALOG':
      return <WideDialog onHide={props.clearModal} {...info} />;
    case 'TRANSFER_OWNERSHIP':
      return <TransferOwnershipModal onHide={props.clearModal} info={info} />;
    case 'FEATURE_REQUEST':
      return <FeatureRequestModal onHide={props.clearModal} info={info} />;
    case 'DISCONNECT_APPS_AND_BOOKS':
      return <DisconnectAppsAndBooks onHide={props.clearModal} info={info} />;
    case 'DISCONNECT_AUTO_ENROLL':
      return <DisconnectAutoEnroll onHide={props.clearModal} info={info} />;
    case 'RESET_MS_REGISTRATION':
      return (
        <ResetMsDeviceRegistrationModal onHide={props.clearModal} info={info} />
      );
    default:
      return null;
  }
});

const mapStateToProps = (state) => ({
  currentModal: state.ui.currentModal,
  isOpen: state.ui.isOpen,
  isNewModal: state.ui.isNewModal,

  blueprints: state.data.blueprints,
  blueprintNames: state.data.blueprintNames,
  computers: state.data.computers,
  blueprintRecord: state.blueprintRecord,
  updateComputer: state.updateComputer,
  computerRecord: state.computerRecord,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setModal,
      clearModal,
      closeModal,
      getBlueprints,
      setComputers,
      setDEPComputers,
      setSnackbar,
      startGetBlueprint,
      updateComputer,
      updateDEPComputer,
      startGetComputers,
      editComputerInfo,
      editDEPComputerInfo,
      mergeComputers,
      getComputer,
      deleteComputers,
      decreaseBlueprintComputersCount,
      updateComputerFields,
      changeDEPComputersAssetTag,
      changeComputerBlueprint,
      changeDEPComputersBlueprint,
      getComputerBypassCode,
      getComputerRecoveryPassword,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ModalConductor);
