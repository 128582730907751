import { Banner } from '@kandji-inc/bumblebee';
import { i18n } from 'i18n';
import React from 'react';

type ConfiguredPlatforms = {
  configuredPlatforms: string[];
};

const DeviceBannerText = ({ configuredPlatforms }: ConfiguredPlatforms) => {
  const hasIOS = configuredPlatforms?.includes('IOS');
  const hasMac = configuredPlatforms?.includes('Mac');

  const shouldDisplayBanner = !hasMac || !hasIOS;

  if (shouldDisplayBanner) {
    return (
      <Banner theme="warning" icon="triangle-exclamation">
        <p className="b-txt">
          {i18n.t(
            'Okta Device Trust is configured for {configuredOSFamily} devices only. Okta Verify will not be deployed to any {nonConfiguredOSFamily} devices that may be included in the assigned Blueprints for this Library Item.',
            {
              configuredOSFamily: hasIOS ? 'iOS' : 'macOS',
              nonConfiguredOSFamily: hasIOS ? 'macOS' : 'iOS',
            },
          )}
        </p>
      </Banner>
    );
  }
  return null;
};

export default DeviceBannerText;
