import { Checkbox } from '@kandji-inc/bumblebee';
import React, { useContext, memo } from 'react';

import { AccountContext } from 'contexts/account';
import { inclusionLvl } from 'contexts/environment';

import { Setting } from 'features/library-items/template';
import { i18n } from 'i18n';

const OptionsCard = (props) => {
  const { setting, update, isDisabled, isMac } = props;
  const { currentCompany } = useContext(AccountContext);

  if (!isMac && !currentCompany.is_vpp_auto_update_apps) {
    return null;
  }

  return (
    <Setting.Card>
      <Setting.Header>
        <h3 className="b-h3">Options</h3>
      </Setting.Header>
      <Setting.Rows>
        {currentCompany.is_vpp_auto_update_apps && (
          <Setting.Row>
            <Setting.Controls>
              <Checkbox
                checked={setting.isSkipAutoUpdate}
                onChange={() => update('isSkipAutoUpdate', (p) => !p)}
                disabled={isDisabled}
                label={i18n.t("Don't automatically update this app")}
              />
            </Setting.Controls>
            <Setting.Helpers>
              <p className="b-txt-light">
                {i18n.t(
                  'Choose to exclude this app from automatically updating when a new version is detected in the App Store.',
                )}
              </p>
            </Setting.Helpers>
          </Setting.Row>
        )}
        {inclusionLvl(1) && isMac && (
          <Setting.Row>
            <Setting.Controls>
              <Checkbox
                checked={setting.addToDockDuringInstall}
                onChange={() => update('addToDockDuringInstall', (p) => !p)}
                disabled={isDisabled}
                label={i18n.t('Add to Dock during install')}
              />
            </Setting.Controls>
            <Setting.Helpers>
              <p className="b-txt-light">
                {i18n.t(
                  'Kandji will add the app icon to the Dock during initial app install, or during re-install if the app is found to be missing. Kandji will not automatically re-add the icon to the Dock if the app is updated.',
                )}
              </p>
            </Setting.Helpers>
          </Setting.Row>
        )}
      </Setting.Rows>
    </Setting.Card>
  );
};

export default memo(OptionsCard);
