import { Button, Chip } from '@kandji-inc/bumblebee';
import { Setting } from 'features/library-items/template';
// TODO: test file once backend is in place to double check logic
import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { i18n } from 'i18n';
import { LibraryItemContext } from 'src/features/library-items/common/library-item-page/library-item-page';
import { useAppStoreAppsContext } from '../../hooks/app-store-apps-provider';
import DeviceBannerText from './components/device-banner-text';
import OktaNavigateAwayModal from './components/okta-navigate-away-modal';
import { LEARN_MORE_LINK } from './consts';
import useOktaDeviceTrustService from './hooks/use-okta-device-trust-service';
import OktaDeviceTrustModal from './okta-device-trust-modal/okta-device-trust-modal';
import { fixedConfiguredPlatforms } from './utils/okta-device-trust-utils';

type Setting = {
  isEnabled: boolean;
};

type AppConfigurationSetting = {
  value?: string;
};

type Props = {
  isDisabled: boolean;
  isEditing: boolean;
  setting: Setting;
  update: (name: string, updateFunc: (property: boolean) => boolean) => void;
  appConfigurationSetting: AppConfigurationSetting;
};

const ChipContainer = styled.div`
  display: flex;
  gap: var(--b-gap-tiny);
  margin-top: var(--b-gap);
`;

const StyledButton = styled(Button)``;
const SettingFooter = styled.div`
  border-top: 1px solid var(--b-primary-marengo-ultralight);
  padding-bottom: var(--b-gap1);

  & > ${StyledButton} {
    margin-left: var(--b-gap1);
    margin-top: var(--b-gap1);
  }
`;

export function updateHelper(value: boolean) {
  return !value;
}

const OktaDeviceTrust = (props: Props) => {
  const { setting, update, isDisabled, appConfigurationSetting, isEditing } =
    props;

  const [showODTModal, setShowODTModal] = useState(false);
  const [isOktaNavigateAwayModalOpen, setIsOktaNavigateAwayModalOpen] =
    useState(false);
  const { isEnabled } = setting;
  const history = useHistory();
  const { setIsAppConfigDisabled } = useAppStoreAppsContext();

  const { onSave, onConfirmNavModalLeave } = useContext(LibraryItemContext);

  const hasAppConfiguration = !!appConfigurationSetting?.value;

  const { data, loading, getConfig } = useOktaDeviceTrustService();

  const handleNavigation = () => {
    onConfirmNavModalLeave(); // this disables the default navigate away modal in favor of ODT navigate away modal
    history.push('/integrations/okta_device_trust');
  };

  const handleODTIntegrationClick = () => {
    if (isEditing) {
      setIsOktaNavigateAwayModalOpen(true);
    } else {
      handleNavigation();
    }
  };

  const handleToggleODT = () => {
    if (!isEnabled) {
      setShowODTModal(true);
      setIsAppConfigDisabled(false);
    }

    if (isEnabled) {
      update('isEnabled', updateHelper);
    }
  };

  const handleConfirmTurnOnODT = () => {
    update('isEnabled', updateHelper);
    setIsAppConfigDisabled(true);
    setShowODTModal(false);
  };

  const handleCloseODTModal = () => {
    setShowODTModal(false);
  };
  const handleCloseNavigateAwayModal = () => {
    setIsOktaNavigateAwayModalOpen(false);
  };

  const isODTConfigured = data && !!Object.keys(data).length;

  useEffect(() => {
    if (!data && !loading) {
      getConfig();
    }
  }, [data, loading, getConfig]);

  return (
    <>
      <Setting.Card>
        <Setting.Header>
          <h3 className="b-h3">{i18n.t('Okta Device Trust')}</h3>
          <Setting.HeaderToggle
            isEnabled={isEnabled ?? false}
            onChange={handleToggleODT}
            isDisabled={isDisabled || !isODTConfigured}
            chipText={{
              enabled: i18n.t('On'),
              disabled: i18n.t('Off'),
            }}
          />
        </Setting.Header>
        <Setting.SubHeader>
          <p className="b-txt">
            {i18n.t(
              'Turning on the Okta Device Trust integration allows you to manage device trust through Okta Verify. Additional settings will be applied to this Library Item that will protect corporate resources by ensuring a device is compliant before allowing access to applications through Okta.',
            )}{' '}
            <a
              href={LEARN_MORE_LINK}
              rel="noopener noreferrer"
              target="_blank"
              className="b-alink"
            >
              {i18n.t('Learn more')}
            </a>
          </p>
        </Setting.SubHeader>

        {isEnabled && (
          <>
            <Setting.Rows noBorder>
              <Setting.Row>
                <Setting.Controls>
                  <p className="b-txt-light">{i18n.t('Okta domain')}</p>
                  <p className="b-txt">{data?.domain}</p>
                </Setting.Controls>
                <Setting.Helpers>
                  <p className="b-txt-light">
                    {i18n.t(
                      'The domain that was used to connect the integration with Okta Device Trust.',
                    )}
                  </p>
                </Setting.Helpers>
              </Setting.Row>
              <Setting.Row>
                <p className="b-txt-light">
                  {i18n.t('Configured for the following device platforms:')}
                </p>
                <ChipContainer data-testid="odt-chip-container">
                  {data?.configured_platforms?.length &&
                    fixedConfiguredPlatforms(data?.configured_platforms)?.map(
                      (name, i) => <Chip text={name} key={`${name + i}`} />,
                    )}
                </ChipContainer>

                <Setting.Helpers>
                  <p className="b-txt-light">
                    {i18n.t(
                      'The integration can be configured to manage macOS devices, iOS devices or both.',
                    )}{' '}
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      className="b-alink"
                      href="https://support.kandji.io/kb/okta-device-trust-configuring-the-okta-verify-library-item"
                    >
                      {i18n.t('Learn more')}
                    </a>
                  </p>
                  <DeviceBannerText
                    configuredPlatforms={data?.configured_platforms}
                  />
                </Setting.Helpers>
              </Setting.Row>
            </Setting.Rows>
            <SettingFooter>
              <StyledButton
                icon="arrow-right"
                kind="link"
                onClick={handleODTIntegrationClick}
              >
                {i18n.t('Go to Okta Device Trust integration')}
              </StyledButton>
            </SettingFooter>
          </>
        )}
      </Setting.Card>

      <OktaNavigateAwayModal
        isModalOpen={isOktaNavigateAwayModalOpen}
        onClose={handleCloseNavigateAwayModal}
        handleNext={handleNavigation}
        onSave={onSave}
      />

      <OktaDeviceTrustModal
        isModalOpen={showODTModal}
        setIsModalOpen={handleCloseODTModal}
        hasAppConfiguration={hasAppConfiguration}
        turnOnOktaToggle={handleConfirmTurnOnODT}
      />
    </>
  );
};

export default OktaDeviceTrust;
