import { Banner, updateSetting as update } from '@kandji-inc/bumblebee';
import React, { useCallback, useEffect, useMemo } from 'react';
import { i18n } from 'src/i18n';
import './wifi.scss';

import useUniqValue from 'components/common/hooks/use-uniq-value/use-uniq-value';
import { useLocation } from 'react-router-dom';
import { useLibraryItem } from '../../common/hooks/use-library-item';
import LibraryItemPage from '../../common/library-item-page';
import NewStatusTab from '../../common/new-status-tab/new-status-tab';
import { getNewStatusTabProps } from '../../common/new-status-tab/util';
import { populateSelectedDevices } from '../../data-service/library-item/devices';
import configs from '../../library/library-item-configurations/items/profile';
import ActivityTab from './activity-tab';
import FastLaneCard from './fast-lane-card';
import GeneralCard from './general-card';
import initialState from './initial-state';
import ProtocolsCard from './protocols-card';
import ProxyCard from './proxy-card';
import { transformFromApi, transformToApi } from './service/transformers';
import useWifiService from './service/use-wifi-service';
import WifiService, { wifiService } from './service/wifi-service';
import TrustCard from './trust-card';
import WifiV1Updated from './wifi-v1-updated';
import { showTrustCardKey, showTrustCardValues } from './wifi.model';

const WifiPage = (props) => {
  const { model, setModel, pageState } = props;
  const { state } = useLocation();
  const [validationDep, triggerValidation] = useUniqValue();
  const legacyConfigurationsUpdate = useCallback(
    update('legacyConfigurations', setModel),
    [],
  );
  const generalUpdate = useCallback(update('general', setModel), []);
  const authenticationUpdate = useCallback(
    update('authentication', setModel),
    [],
  );
  const trustUpdate = useCallback(update('trust', setModel), []);
  const proxyUpdate = useCallback(update('proxy', setModel), []);
  const fastlaneUpdate = useCallback(update('fastLaneMarking', setModel), []);
  const wifiConfig = configs['Wi-Fi'];

  useEffect(() => populateSelectedDevices(model, setModel, wifiConfig), []);

  useEffect(() => {
    /* istanbul ignore next */
    if (
      pageState.isAdding &&
      state?.certificate?.type &&
      Object.values(WifiService.identityCertificates).includes(
        state.certificate.type,
      ) &&
      state?.certificate?.server
    ) {
      authenticationUpdate(
        WifiService.keys.authentication.identityCertificate,
        state.certificate.type,
      );
      authenticationUpdate(
        WifiService.keys.authentication.adcs.server,
        state.certificate.server,
      );
      delete state.certificate.type;
      delete state.certificate.server;
    }
  }, []);

  const summaryInfoProps = useMemo(
    () => ({
      name: i18n.t(`{name} Profile`, {
        name: wifiConfig.getName(),
        _context:
          'type of an Apple configuration profile to install on a device',
      }),
      description: wifiConfig.getDescription(),
      publisher: wifiConfig.getPublisher(),
      devices: wifiConfig.devices,
      requirements: wifiConfig.getOsRequirements(),
    }),
    [
      wifiConfig.description,
      wifiConfig.devices,
      wifiConfig.osRequirements,
      wifiConfig.publisher,
    ],
  );

  const showTrustCard = showTrustCardValues.includes(
    model.authentication[showTrustCardKey],
  );

  const isDisplayLegacyWiFiV1 =
    !!model.legacyConfigurations?.configurations?.length;

  return (
    <LibraryItemPage
      {...props}
      crumb={model.name || wifiConfig.getName()}
      summaryInfoProps={summaryInfoProps}
      triggerValidation={triggerValidation}
      transformToApi={transformToApi}
      transformFromApi={transformFromApi}
      service={wifiService}
      ActivityTab={ActivityTab}
      StatusTab={NewStatusTab}
      getStatusTabProps={getNewStatusTabProps}
      defaultIcon={wifiConfig.icon}
      extraBanners={
        isDisplayLegacyWiFiV1 && (
          <Banner theme="info-strong" kind="block" className="b-mb">
            <span>
              You're viewing a legacy version of the Wi-Fi Library Item. The
              latest enhancements, including Enterprise Wi-Fi support, can be
              found in our updated Wi-Fi Library Item.
            </span>
          </Banner>
        )
      }
      supportsRules
      supportsInstallOn
      supportsDuplication
    >
      {isDisplayLegacyWiFiV1 ? (
        <WifiV1Updated
          setting={model.legacyConfigurations}
          update={legacyConfigurationsUpdate}
          validationDep={validationDep}
          isDisabled={pageState.isDisabled}
        />
      ) : (
        <>
          <GeneralCard
            setting={model.general}
            update={generalUpdate}
            isDisabled={pageState.isDisabled}
            isSubmitted={pageState.isSubmitted}
            validationDep={validationDep}
          />
          <ProtocolsCard
            setting={model.authentication}
            update={authenticationUpdate}
            isDisabled={pageState.isDisabled}
            isSubmitted={pageState.isSubmitted}
            validationDep={validationDep}
          />
          {showTrustCard && (
            <TrustCard
              setting={model.trust}
              update={trustUpdate}
              isDisabled={pageState.isDisabled}
              isSubmitted={pageState.isSubmitted}
              validationDep={validationDep}
            />
          )}
          <ProxyCard
            setting={model.proxy}
            update={proxyUpdate}
            isDisabled={pageState.isDisabled}
            isSubmitted={pageState.isSubmitted}
            validationDep={validationDep}
          />
          <FastLaneCard
            setting={model.fastLaneMarking}
            update={fastlaneUpdate}
            isDisabled={pageState.isDisabled}
            isSubmitted={pageState.isSubmitted}
            validationDep={validationDep}
          />
        </>
      )}
    </LibraryItemPage>
  );
};

const Wifi = () => {
  const { pageProps, PlaceHolder } = useLibraryItem({
    initialState,
    useService: useWifiService,
  });

  if (PlaceHolder) {
    return <PlaceHolder />;
  }

  return <WifiPage {...pageProps} testId="wifi-v2" />;
};

export default Wifi;
