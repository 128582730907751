import type { IconNames } from '@kandji-inc/nectar-ui';
import { Button } from '@kandji-inc/nectar-ui';
import type React from 'react';

type AllowBlockBlueButtonProps = {
  onClick: () => void;
  isDisabled?: boolean;
  iconName: IconNames;
  children: React.ReactNode;
  testId?: string;
  className?: string;
};

const AllowBlockBlueButton = (props: AllowBlockBlueButtonProps) => {
  const { onClick, isDisabled, iconName, children, testId, className } = props;

  return (
    <Button
      icon={{ name: iconName }}
      onClick={onClick}
      disabled={isDisabled}
      variant="subtle"
      css={{ color: '$blue50' }}
      data-testid={testId}
      className={className}
    >
      {children}
    </Button>
  );
};

export default AllowBlockBlueButton;
