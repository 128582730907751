import { Setting } from 'features/library-items/template';
import React from 'react';
import featureFlags from 'src/config/feature-flags';
import { i18n } from 'src/i18n';
import type { UnknownFileUploadProps } from '../../avert.types';

const UnknownFileUpload = (props: UnknownFileUploadProps) => {
  const { isDisabled, update, settings } = props;

  if (!featureFlags.getFlag('edr_020823_unknown-file-upload-display')) {
    return null;
  }

  return (
    <Setting.Card>
      <Setting.Header>
        <h3 className="b-h3">
          {i18n.t('File upload for additional threat analysis')}
        </h3>
        <Setting.HeaderToggle
          isEnabled={settings.file_upload_enabled}
          isDisabled={isDisabled}
          onChange={() => update('file_upload_enabled', (p) => !p)}
          chipText={{
            enabled: i18n.t('On'),
            disabled: i18n.t('Off'),
          }}
          className={`pendo-switch-file-upload pendo-value-${settings.file_upload_enabled}`}
        />
      </Setting.Header>
      <Setting.SubHeader>
        <p className="b-txt">
          {i18n.t(
            'Turn this setting on to automatically upload files with unknown classifications for additional threat analysis.',
          )}
        </p>
      </Setting.SubHeader>
    </Setting.Card>
  );
};

export default UnknownFileUpload;
