import {
  Flex,
  Tabs,
  Toaster_UNSTABLE as Toaster,
  styled,
} from '@kandji-inc/nectar-ui';
import * as React from 'react';
import { useHistory, useParams } from 'react-router';
import { Pagination, usePagination } from 'src/components/ui';
import useAdjustSidebarChatBubble from 'src/features/util/hooks/use-adjust-sidebar-chat-bubble';
import useWhiteBg from 'src/features/util/hooks/use-white-bg';
import type {
  PulseFileCheckDeviceSchema,
  PulseLoggedInUsersDeviceSchema,
  PulsePreferencesDeviceSchema,
  PulseProcessCheckDeviceSchema,
} from 'src/features/visibility/pulse/types/pulse.types';
import { PulseExport } from '../../components';
import { useCreatePulseJob } from '../../hooks/use-create-pulse-check-query';
import { ReportHeader } from './components';
import { PulseFileCheckResults } from './components/PulseFileCheckResults';
import { PulseLoggedInUsersResults } from './components/PulseLoggedInUsersResults';
import { PulsePreferencesResults } from './components/PulsePreferencesResults';
import { PulseProcessResults } from './components/PulseProcessResults';
import { usePulseCheckDetails } from './hooks';

const PulseCheckDetails = () => {
  useAdjustSidebarChatBubble();
  const {
    paginationState: { pageIndex, pageSize },
    setPagination,
  } = usePagination({ storageKey: 'pulse-results-page-limit' });
  const history = useHistory();
  const { pulseJobId } = useParams<{ pulseJobId: string }>();
  const pulseCheckDetails = usePulseCheckDetails(pulseJobId, {
    offset: pageIndex,
    limit: pageSize,
  });

  // istanbul ignore next
  const handlePageChange = React.useCallback(
    (page: number) => {
      setPagination((prev) => ({
        ...prev,
        pageIndex: page - 1,
      }));
    },
    [setPagination],
  );

  // istanbul ignore next
  const handlePageSizeChange = React.useCallback(
    (pageSize: number) => {
      setPagination({
        pageIndex: 0,
        pageSize: pageSize,
      });
    },
    [setPagination],
  );

  const { jobType, jobMetadata, jobDevicesData, showPending } =
    pulseCheckDetails;

  const rerunJobMutation = useCreatePulseJob();

  const handleRerunJob = async () => {
    await rerunJobMutation.mutateAsync(
      {
        pulseCheckId: jobMetadata.pulse_check_id,
        ...jobMetadata,
        id: jobMetadata.pulse_check_id,
        agent_filters: jobMetadata.agent_filters
          ? JSON.parse(jobMetadata.agent_filters)
          : {},
      },
      {
        onSuccess: (response) => {
          history.push(`/devices/pulse/${response.job_id}`);
        },
      },
    );
  };

  // istanbul ignore next
  const devicesWithResults = React.useMemo(() => {
    if (!jobDevicesData?.data) {
      return [];
    }

    if (jobType === 'file_check') {
      return jobDevicesData.data.filter((d) => {
        const result = d.results as { exists?: boolean };
        return result?.exists;
      });
    }

    if (jobType === 'processes') {
      return jobDevicesData.data.filter((d) => d.results != null);
    }

    if (jobType === 'logged_in_users') {
      return jobDevicesData.data.filter(
        (d) => d.results != null && !('code' in d.results),
      );
    }

    if (jobType === 'preferences') {
      return jobDevicesData.data.filter(
        (d) => d.results != null && !('code' in d.results),
      );
    }

    return [];
  }, [jobDevicesData?.data, jobType]);

  const getPulseDetailsComponent = () => {
    switch (jobType) {
      case 'file_check':
        return (
          <PulseFileCheckResults
            jobDevicesData={devicesWithResults as PulseFileCheckDeviceSchema[]}
            isLoading={showPending}
            onRerunJob={handleRerunJob}
          />
        );
      case 'processes':
        return (
          <PulseProcessResults
            jobDevicesData={
              devicesWithResults as PulseProcessCheckDeviceSchema[]
            }
            isLoading={showPending}
            onRerunJob={handleRerunJob}
          />
        );
      case 'logged_in_users':
        return (
          <PulseLoggedInUsersResults
            jobDevicesData={
              devicesWithResults as PulseLoggedInUsersDeviceSchema[]
            }
            isLoading={showPending}
            onRerunJob={handleRerunJob}
          />
        );
      case 'preferences':
        return (
          <PulsePreferencesResults
            jobDevicesData={
              devicesWithResults as PulsePreferencesDeviceSchema[]
            }
            isLoading={showPending}
            onRerunJob={handleRerunJob}
          />
        );
      default:
        return null;
    }
  };

  useWhiteBg();
  return (
    <Flex
      flow="column"
      gap="md"
      alignItems="center"
      justifyContent="center"
      css={{
        margin: '0 calc(var(--k-main-layout-side-padding) * -1) -48px',
        position: 'relative',
        // maxWidth: '2400px',
        // width: '100%',
        // margin: '0 auto',
        // TODO: keeping this around for when we go full-width and remove the gutters
        // this isn't exactly what we need but it's a start

        // margin: '0 calc(var(--k-main-layout-side-padding) * -1)',
        // '.full-width-sidebar-opened &': {
        // width: 'calc(100vw - 256px)',
        // paddingLeft: 0,
        // paddingRight: 0,
        // },
      }}
    >
      <ReportHeader
        pulseCheckDetails={pulseCheckDetails}
        onRerunJob={handleRerunJob}
      />

      <Tabs.Container
        css={{ width: '100%' }}
        defaultTabId="results"
        compact
        tabs={[
          {
            label: 'Results',
            tabId: 'results',
            css: {
              marginLeft: '24px',
            },
            // onClick: () => {},
          },
          // {
          //   label: 'Status',
          //   tabId: 'status',
          //   disabled: true,
          //   onClick: () => {},
          // },
          // {
          //   label: 'Activity',
          //   tabId: 'activity',
          //   disabled: true,
          //   onClick: () => {},
          // },
        ]}
      >
        <Tabs.Content
          tabId="results"
          css={{
            display: 'flex',
            gap: '$3',
            flexFlow: 'column',
            padding: '12px 0',
            height: '100%',
            paddingLeft: '16px',
            paddingRight: '24px',
          }}
        >
          {jobMetadata?.pulse_check_id && jobMetadata?.name && (
            <Flex justifyContent="end">
              <PulseExport
                reportName={jobMetadata?.name}
                jobId={jobMetadata?.job_id}
              />
            </Flex>
          )}
          {getPulseDetailsComponent()}
          <PaginationContainer>
            <Pagination
              currentPage={pageIndex + 1}
              onPageChange={handlePageChange}
              itemsPerPage={pageSize}
              onItemsPerPageChange={handlePageSizeChange}
              totalItems={jobMetadata?.total_number_of_results}
            />
          </PaginationContainer>
        </Tabs.Content>
        <Tabs.Content tabId="status">Status</Tabs.Content>
        <Tabs.Content tabId="activity">Activity</Tabs.Content>
      </Tabs.Container>
      <Toaster />
    </Flex>
  );
};

export default PulseCheckDetails;

const PaginationContainer = styled(Flex, {
  bottom: 0,
  justifyContent: 'space-between',
  padding: '12px 24px',
  background: '$neutral0',
  marginTop: '48px',
  width: '100vw',
  position: 'fixed',
  borderTop: '1px solid var(--Structure-Colors-separator-default, #D7E1ED)',

  // Page layout
  margin: '0 calc(var(--k-main-layout-side-padding) * -1)',
  paddingLeft: 'var(--k-main-layout-side-padding)',
  paddingRight: 'var(--k-main-layout-side-padding)',

  '.full-width-sidebar-opened &': {
    width: 'calc(100vw - 256px)',
  },
});
