import { Icon } from '@kandji-inc/bumblebee';
import { colors } from 'app/common/constants';
import { i18n } from 'i18n';
import React from 'react';
import styled from 'styled-components';
import uuidv1 from 'uuid/v1';

const getUniqueKey = () => uuidv1();

const borderWidth = 1;

const variants = {
  default: 'default',
  arrowLeft: 'arrowLeft',
  arrowRight: 'arrowRight',
  first: 'first',
  last: 'last',
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-family: var(--font-family-primary);
  color: ${colors['grey-500']};
`;

const Buttons = styled.div`
  display: flex;
  flex-shrink: 0;
  border: ${borderWidth}px solid ${colors['grey-130']};
  border-radius: 5px;
  height: 35px;
  font-size: 14px;
  font-weight: 400;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
`;

const PageDefault = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 35px;
  padding: 5px 10px;
  cursor: pointer;
  line-height: 14px;

  color: ${(props) => (props.isActive ? colors.white : null)};
  background-color: ${(props) =>
    props.isActive ? colors['marengo-700'] : colors.white};
  margin-top: ${(props) => (props.isActive ? `-${borderWidth}px` : null)};
  margin-bottom: ${(props) => (props.isActive ? `-${borderWidth}px` : null)};

  &:first-of-type {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  &:last-of-type {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`;

const ArrowButton = styled(PageDefault)`
  font-size: 18px;
  line-height: 18px;
  font-weight: 700;
`;

const ArrowButtonLeft = styled(ArrowButton)`
  border-right: ${borderWidth}px solid ${colors['grey-130']};
`;

const ArrowButtonRight = styled(ArrowButton)`
  border-left: ${borderWidth}px solid ${colors['grey-130']};
`;

const MarginalButton = styled(PageDefault)`
  text-transform: uppercase;
  letter-spacing: 0.2em;
  font-size: 10px;
  line-height: 10px;
  font-weight: 500;
  min-width: 60px;
`;

const FirstButton = styled(MarginalButton)`
  border-right: ${borderWidth}px solid ${colors['grey-130']};
`;

const LastButton = styled(MarginalButton)`
  border-left: ${borderWidth}px solid ${colors['grey-130']};
`;

const Label = styled.div`
  font-family: var(--font-family-primary);
  text-transform: uppercase;
  letter-spacing: 0.2em;
  font-weight: 700;
  font-size: 10px;
  margin-bottom: 10px;
`;

const buttonVariantMapping = {
  default: PageDefault,
  arrowLeft: ArrowButtonLeft,
  arrowRight: ArrowButtonRight,
  first: FirstButton,
  last: LastButton,
};

const Page = ({
  isActive,
  text,
  pageNumber,
  onClick,
  disabled,
  variant = 'default',
}) => {
  const StyledPage = buttonVariantMapping[variant];
  return (
    <StyledPage
      onClick={() => (disabled ? null : onClick(pageNumber))}
      disabled={!!disabled}
      isActive={isActive}
    >
      {text}
    </StyledPage>
  );
};

export const Pagination = ({
  currentPage,
  onChange,
  lastPage,
  count = 0,
  perPage = 0,
  labelName = 'Items',
}) => {
  const _lastPage = lastPage || 1;
  const _currentPage = currentPage || 1;

  const switchPageAndLoadMore = (pageNumber) => {
    if (onChange) {
      onChange(pageNumber);
    }
  };

  const maxVisiblePages = 5;

  let visiblePagesNums = [
    ...new Set([
      _currentPage,
      _currentPage + 1,
      _currentPage + 2,
      _lastPage - 2,
      _lastPage - 1,
      _lastPage,
    ]),
  ];

  visiblePagesNums = visiblePagesNums
    .sort((a, b) => (a > b ? 1 : -1))
    .filter((pageNum) => pageNum <= _lastPage && pageNum > 0);

  if (visiblePagesNums.length > maxVisiblePages) {
    visiblePagesNums.splice(
      visiblePagesNums.length - Math.floor((maxVisiblePages + 1) / 2),
      0,
      '...',
    );
  }

  const pages = visiblePagesNums.map((pageNum) => (
    <Page
      key={getUniqueKey()}
      pageNumber={pageNum}
      text={pageNum}
      onClick={() => switchPageAndLoadMore(pageNum)}
      disabled={pageNum === _currentPage || pageNum === '...'}
      isActive={pageNum === _currentPage}
    />
  ));

  pages.unshift(
    <Page
      key="fetch-prev"
      onClick={() => switchPageAndLoadMore(_currentPage - 1)}
      pageNumber={_currentPage - 1}
      text={<Icon size="sm" name="angle-left" />}
      disabled={_currentPage === 1}
      variant={variants.arrowLeft}
    />,
  );

  pages.push(
    <Page
      key="fetch-next"
      onClick={() => switchPageAndLoadMore(_currentPage + 1)}
      pageNumber={_currentPage + 1}
      text={<Icon size="sm" name="angle-right" />}
      disabled={_currentPage === _lastPage}
      variant={variants.arrowRight}
    />,
  );

  if (_currentPage !== 1) {
    pages.unshift(
      <Page
        key={getUniqueKey()}
        isActive={false}
        onClick={() => switchPageAndLoadMore(1)}
        pageNumber={1}
        text={i18n.t('first')}
        disabled={_currentPage === 1}
        variant={variants.first}
      />,
    );
  }

  if (_currentPage !== _lastPage) {
    pages.push(
      <Page
        key={getUniqueKey()}
        isActive={false}
        onClick={() => switchPageAndLoadMore(_lastPage)}
        pageNumber={_lastPage}
        text={i18n.t('last')}
        disabled={_currentPage === _lastPage}
        variant={variants.last}
      />,
    );
  }

  const pageRangeLow = Math.min(perPage * (_currentPage - 1) + 1, count);
  const pageRangeHigh = Math.min(perPage * _currentPage, count);

  return (
    <Wrapper>
      <Label>
        {labelName}{' '}
        {count ? (
          <>
            {i18n.t('{rangeLow}-{rangeHigh} of {count}', {
              rangeLow: i18n.format.number(pageRangeLow),
              rangeHigh: i18n.format.number(pageRangeHigh),
              count: count,
            })}
          </>
        ) : (
          <>{i18n.t('0 of 0')}</>
        )}
      </Label>
      <ButtonsWrapper>
        <Buttons>{pages}</Buttons>
      </ButtonsWrapper>
    </Wrapper>
  );
};
