import { formatNumber } from '@kandji-inc/nectar-i18n';
import {
  Badge,
  Flex,
  Heading,
  Icon,
  Loader,
  Text,
} from '@kandji-inc/nectar-ui';
import { useState } from 'react';
import type { DetectionDateFilterFields } from 'src/features/edr/common/common.types';
import EDRDetectionDateFilter from 'src/features/edr/common/components/Filters/EDRDetectionDateFilter';
import { i18n } from 'src/i18n';
import { DETECTION_DATE_SELECT_CUSTOM_RANGE_OPTIONS } from '../../../constants';
import { DEFAULT_DETECTION_DATE_FILTER } from '../../../constants';
import { useGetNewVulnerabilitiesDetected } from '../../../hooks/dashboard/use-get-new-vulnerabilities-detected';
import { Tile } from './tile';

const NewVulnerabilitiesDetectedTile = () => {
  const [timespan, setTimespan] = useState<Partial<DetectionDateFilterFields>>(
    DEFAULT_DETECTION_DATE_FILTER,
  );
  const { count, trend, isLoading } =
    useGetNewVulnerabilitiesDetected(timespan);
  const isTrendPositive = trend > 0;

  return (
    <Tile>
      <Flex flow="column" gap="xs" hFull>
        <Flex>
          <Flex alignItems="center" gap="xs" flex={1}>
            <Icon
              name="magnifying-glass-chart"
              color="var(--colors-blue50)"
              size="md"
            />
            <Heading size="4">{i18n.t('New vulnerabilities detected')}</Heading>
            {!isLoading && trend !== 0 && (
              <Badge
                color={isTrendPositive ? 'red' : 'green'}
                compact
                css={{
                  paddingTop: 0,
                  paddingBottom: 0,
                  height: 16,
                  alignSelf: 'center',
                }}
              >
                <Flex gap="sm" alignItems="center">
                  <Icon
                    name={
                      isTrendPositive ? 'arrow-up-right' : 'arrow-down-left'
                    }
                    style={{ width: 10 }}
                  />
                  <Text
                    css={{ fontSize: '12px' }}
                  >{`${formatNumber(trend * 100)}%`}</Text>
                </Flex>
              </Badge>
            )}
          </Flex>
          <EDRDetectionDateFilter
            filter={timespan}
            label=""
            options={DETECTION_DATE_SELECT_CUSTOM_RANGE_OPTIONS.filter(
              /* istanbul ignore next */ (option) =>
                !['all_time', 'custom_date_range'].includes(option.value),
            )}
            onChange={
              /* istanbul ignore next */ (
                _,
                fields: DetectionDateFilterFields,
              ) => setTimespan(fields)
            }
            showClear={false}
            dropdownProps={{ contentProps: { align: 'end' } }}
            buttonProps={{ disabled: isLoading }}
            buttonVariant="subtle"
          />
        </Flex>

        {isLoading && (
          <Flex hFull alignItems="center" justifyContent="center">
            <Loader data-testid="loading-new-vulnerabilities-detected" />
          </Flex>
        )}

        {!isLoading && (
          <Flex gap="sm" alignItems="center">
            <Heading size="2">{formatNumber(count)}</Heading>
            {count === 0 && (
              <Text variant="description" css={{ paddingTop: '6px' }}>
                {i18n.t(
                  'In the {timePeriod}, no new vulnerabilities have been detected in your Mac fleet.',
                  {
                    timePeriod: DETECTION_DATE_SELECT_CUSTOM_RANGE_OPTIONS.find(
                      ({ value }) => value === timespan.value,
                    ).getInlineLabel(),
                  },
                )}
              </Text>
            )}
          </Flex>
        )}
      </Flex>
    </Tile>
  );
};

export { NewVulnerabilitiesDetectedTile };
