import { i18n } from 'i18n';

const i18nTimezoneMap = i18n.createMap({
  'Pacific/Niue': () => i18n.t('(GMT-11:00) Niue'),
  'Pacific/Pago_Pago': () => i18n.t('(GMT-11:00) Pago Pago'),
  'Pacific/Honolulu': () => i18n.t('(GMT-10:00) Hawaii Time'),
  'Pacific/Rarotonga': () => i18n.t('(GMT-10:00) Rarotonga'),
  'Pacific/Tahiti': () => i18n.t('(GMT-10:00) Tahiti'),
  'Pacific/Marquesas': () => i18n.t('(GMT-09:30) Marquesas'),
  'America/Anchorage': () => i18n.t('(GMT-09:00) Alaska Time'),
  'Pacific/Gambier': () => i18n.t('(GMT-09:00) Gambier'),
  'America/Los_Angeles': () => i18n.t('(GMT-08:00) Pacific Time - Los Angeles'),
  'America/Tijuana': () => i18n.t('(GMT-08:00) Pacific Time - Tijuana'),
  'America/Vancouver': () => i18n.t('(GMT-08:00) Pacific Time - Vancouver'),
  'America/Whitehorse': () => i18n.t('(GMT-08:00) Pacific Time - Whitehorse'),
  'Pacific/Pitcairn': () => i18n.t('(GMT-08:00) Pitcairn'),
  'America/Denver': () => i18n.t('(GMT-07:00) Mountain Time'),
  'America/Phoenix': () => i18n.t('(GMT-07:00) Mountain Time - Arizona'),
  'America/Mazatlan': () =>
    i18n.t('(GMT-07:00) Mountain Time - Chihuahua, Mazatlan'),
  'America/Dawson_Creek': () =>
    i18n.t('(GMT-07:00) Mountain Time - Dawson Creek'),
  'America/Edmonton': () => i18n.t('(GMT-07:00) Mountain Time - Edmonton'),
  'America/Hermosillo': () => i18n.t('(GMT-07:00) Mountain Time - Hermosillo'),
  'America/Yellowknife': () =>
    i18n.t('(GMT-07:00) Mountain Time - Yellowknife'),
  'America/Belize': () => i18n.t('(GMT-06:00) Belize'),
  'America/Chicago': () => i18n.t('(GMT-06:00) Central Time'),
  'America/Mexico_City': () => i18n.t('(GMT-06:00) Central Time - Mexico City'),
  'America/Regina': () => i18n.t('(GMT-06:00) Central Time - Regina'),
  'America/Tegucigalpa': () => i18n.t('(GMT-06:00) Central Time - Tegucigalpa'),
  'America/Winnipeg': () => i18n.t('(GMT-06:00) Central Time - Winnipeg'),
  'America/Costa_Rica': () => i18n.t('(GMT-06:00) Costa Rica'),
  'America/El_Salvador': () => i18n.t('(GMT-06:00) El Salvador'),
  'Pacific/Galapagos': () => i18n.t('(GMT-06:00) Galapagos'),
  'America/Guatemala': () => i18n.t('(GMT-06:00) Guatemala'),
  'America/Managua': () => i18n.t('(GMT-06:00) Managua'),
  'America/Cancun': () => i18n.t('(GMT-05:00) America Cancun'),
  'America/Bogota': () => i18n.t('(GMT-05:00) Bogota'),
  'Pacific/Easter': () => i18n.t('(GMT-05:00) Easter Island'),
  'America/New_York': () => i18n.t('(GMT-05:00) Eastern Time'),
  'America/Iqaluit': () => i18n.t('(GMT-05:00) Eastern Time - Iqaluit'),
  'America/Toronto': () => i18n.t('(GMT-05:00) Eastern Time - Toronto'),
  'America/Guayaquil': () => i18n.t('(GMT-05:00) Guayaquil'),
  'America/Havana': () => i18n.t('(GMT-05:00) Havana'),
  'America/Jamaica': () => i18n.t('(GMT-05:00) Jamaica'),
  'America/Lima': () => i18n.t('(GMT-05:00) Lima'),
  'America/Nassau': () => i18n.t('(GMT-05:00) Nassau'),
  'America/Panama': () => i18n.t('(GMT-05:00) Panama'),
  'America/Port-au-Prince': () => i18n.t('(GMT-05:00) Port-au-Prince'),
  'America/Rio_Branco': () => i18n.t('(GMT-05:00) Rio Branco'),
  'America/Halifax': () => i18n.t('(GMT-04:00) Atlantic Time - Halifax'),
  'America/Barbados': () => i18n.t('(GMT-04:00) Barbados'),
  'Atlantic/Bermuda': () => i18n.t('(GMT-04:00) Bermuda'),
  'America/Boa_Vista': () => i18n.t('(GMT-04:00) Boa Vista'),
  'America/Caracas': () => i18n.t('(GMT-04:00) Caracas'),
  'America/Curacao': () => i18n.t('(GMT-04:00) Curacao'),
  'America/Grand_Turk': () => i18n.t('(GMT-04:00) Grand Turk'),
  'America/Guyana': () => i18n.t('(GMT-04:00) Guyana'),
  'America/La_Paz': () => i18n.t('(GMT-04:00) La Paz'),
  'America/Manaus': () => i18n.t('(GMT-04:00) Manaus'),
  'America/Martinique': () => i18n.t('(GMT-04:00) Martinique'),
  'America/Port_of_Spain': () => i18n.t('(GMT-04:00) Port of Spain'),
  'America/Porto_Velho': () => i18n.t('(GMT-04:00) Porto Velho'),
  'America/Puerto_Rico': () => i18n.t('(GMT-04:00) Puerto Rico'),
  'America/Santo_Domingo': () => i18n.t('(GMT-04:00) Santo Domingo'),
  'America/Thule': () => i18n.t('(GMT-04:00) Thule'),
  'America/St_Johns': () => i18n.t('(GMT-03:30) Newfoundland Time - St. Johns'),
  'America/Araguaina': () => i18n.t('(GMT-03:00) Araguaina'),
  'America/Asuncion': () => i18n.t('(GMT-03:00) Asuncion'),
  'America/Belem': () => i18n.t('(GMT-03:00) Belem'),
  'America/Argentina/Buenos_Aires': () => i18n.t('(GMT-03:00) Buenos Aires'),
  'America/Campo_Grande': () => i18n.t('(GMT-03:00) Campo Grande'),
  'America/Cayenne': () => i18n.t('(GMT-03:00) Cayenne'),
  'America/Cuiaba': () => i18n.t('(GMT-03:00) Cuiaba'),
  'America/Fortaleza': () => i18n.t('(GMT-03:00) Fortaleza'),
  'America/Godthab': () => i18n.t('(GMT-03:00) Godthab'),
  'America/Maceio': () => i18n.t('(GMT-03:00) Maceio'),
  'America/Miquelon': () => i18n.t('(GMT-03:00) Miquelon'),
  'America/Montevideo': () => i18n.t('(GMT-03:00) Montevideo'),
  'Antarctica/Palmer': () => i18n.t('(GMT-03:00) Palmer'),
  'America/Paramaribo': () => i18n.t('(GMT-03:00) Paramaribo'),
  'America/Punta_Arenas': () => i18n.t('(GMT-03:00) Punta Arenas'),
  'America/Recife': () => i18n.t('(GMT-03:00) Recife'),
  'Antarctica/Rothera': () => i18n.t('(GMT-03:00) Rothera'),
  'America/Bahia': () => i18n.t('(GMT-03:00) Salvador'),
  'America/Santiago': () => i18n.t('(GMT-03:00) Santiago'),
  'Atlantic/Stanley': () => i18n.t('(GMT-03:00) Stanley'),
  'America/Noronha': () => i18n.t('(GMT-02:00) Noronha'),
  'America/Sao_Paulo': () => i18n.t('(GMT-02:00) Sao Paulo'),
  'Atlantic/South_Georgia': () => i18n.t('(GMT-02:00) South Georgia'),
  'Atlantic/Azores': () => i18n.t('(GMT-01:00) Azores'),
  'Atlantic/Cape_Verde': () => i18n.t('(GMT-01:00) Cape Verde'),
  'America/Scoresbysund': () => i18n.t('(GMT-01:00) Scoresbysund'),
  'Africa/Abidjan': () => i18n.t('(GMT+00:00) Abidjan'),
  'Africa/Accra': () => i18n.t('(GMT+00:00) Accra'),
  'Africa/Bissau': () => i18n.t('(GMT+00:00) Bissau'),
  'Atlantic/Canary': () => i18n.t('(GMT+00:00) Canary Islands'),
  'Africa/Casablanca': () => i18n.t('(GMT+00:00) Casablanca'),
  'America/Danmarkshavn': () => i18n.t('(GMT+00:00) Danmarkshavn'),
  'Europe/Dublin': () => i18n.t('(GMT+00:00) Dublin'),
  'Africa/El_Aaiun': () => i18n.t('(GMT+00:00) El Aaiun'),
  'Atlantic/Faroe': () => i18n.t('(GMT+00:00) Faeroe'),
  'Etc/GMT': () => i18n.t('(GMT+00:00) GMT (no daylight saving)'),
  'Europe/Lisbon': () => i18n.t('(GMT+00:00) Lisbon'),
  'Europe/London': () => i18n.t('(GMT+00:00) London'),
  'Africa/Monrovia': () => i18n.t('(GMT+00:00) Monrovia'),
  'Atlantic/Reykjavik': () => i18n.t('(GMT+00:00) Reykjavik'),
  UTC: () => i18n.t('UTC'),
  'Africa/Algiers': () => i18n.t('(GMT+01:00) Algiers'),
  'Europe/Amsterdam': () => i18n.t('(GMT+01:00) Amsterdam'),
  'Europe/Andorra': () => i18n.t('(GMT+01:00) Andorra'),
  'Europe/Berlin': () => i18n.t('(GMT+01:00) Berlin'),
  'Europe/Brussels': () => i18n.t('(GMT+01:00) Brussels'),
  'Europe/Budapest': () => i18n.t('(GMT+01:00) Budapest'),
  'Europe/Belgrade': () =>
    i18n.t('(GMT+01:00) Central European Time - Belgrade'),
  'Europe/Prague': () => i18n.t('(GMT+01:00) Central European Time - Prague'),
  'Africa/Ceuta': () => i18n.t('(GMT+01:00) Ceuta'),
  'Europe/Copenhagen': () => i18n.t('(GMT+01:00) Copenhagen'),
  'Europe/Gibraltar': () => i18n.t('(GMT+01:00) Gibraltar'),
  'Africa/Lagos': () => i18n.t('(GMT+01:00) Lagos'),
  'Europe/Luxembourg': () => i18n.t('(GMT+01:00) Luxembourg'),
  'Europe/Madrid': () => i18n.t('(GMT+01:00) Madrid'),
  'Europe/Malta': () => i18n.t('(GMT+01:00) Malta'),
  'Europe/Monaco': () => i18n.t('(GMT+01:00) Monaco'),
  'Africa/Ndjamena': () => i18n.t('(GMT+01:00) Ndjamena'),
  'Europe/Oslo': () => i18n.t('(GMT+01:00) Oslo'),
  'Europe/Paris': () => i18n.t('(GMT+01:00) Paris'),
  'Europe/Rome': () => i18n.t('(GMT+01:00) Rome'),
  'Europe/Stockholm': () => i18n.t('(GMT+01:00) Stockholm'),
  'Europe/Tirane': () => i18n.t('(GMT+01:00) Tirane'),
  'Africa/Tunis': () => i18n.t('(GMT+01:00) Tunis'),
  'Europe/Vienna': () => i18n.t('(GMT+01:00) Vienna'),
  'Europe/Warsaw': () => i18n.t('(GMT+01:00) Warsaw'),
  'Europe/Zurich': () => i18n.t('(GMT+01:00) Zurich'),
  'Asia/Amman': () => i18n.t('(GMT+02:00) Amman'),
  'Europe/Athens': () => i18n.t('(GMT+02:00) Athens'),
  'Asia/Beirut': () => i18n.t('(GMT+02:00) Beirut'),
  'Europe/Bucharest': () => i18n.t('(GMT+02:00) Bucharest'),
  'Africa/Cairo': () => i18n.t('(GMT+02:00) Cairo'),
  'Europe/Chisinau': () => i18n.t('(GMT+02:00) Chisinau'),
  'Asia/Damascus': () => i18n.t('(GMT+02:00) Damascus'),
  'Asia/Gaza': () => i18n.t('(GMT+02:00) Gaza'),
  'Europe/Helsinki': () => i18n.t('(GMT+02:00) Helsinki'),
  'Asia/Jerusalem': () => i18n.t('(GMT+02:00) Jerusalem'),
  'Africa/Johannesburg': () => i18n.t('(GMT+02:00) Johannesburg'),
  'Africa/Khartoum': () => i18n.t('(GMT+02:00) Khartoum'),
  'Europe/Kiev': () => i18n.t('(GMT+02:00) Kyiv'),
  'Africa/Maputo': () => i18n.t('(GMT+02:00) Maputo'),
  'Europe/Kaliningrad': () => i18n.t('(GMT+02:00) Moscow-01 - Kaliningrad'),
  'Asia/Nicosia': () => i18n.t('(GMT+02:00) Nicosia'),
  'Europe/Riga': () => i18n.t('(GMT+02:00) Riga'),
  'Europe/Sofia': () => i18n.t('(GMT+02:00) Sofia'),
  'Europe/Tallinn': () => i18n.t('(GMT+02:00) Tallinn'),
  'Africa/Tripoli': () => i18n.t('(GMT+02:00) Tripoli'),
  'Europe/Vilnius': () => i18n.t('(GMT+02:00) Vilnius'),
  'Africa/Windhoek': () => i18n.t('(GMT+02:00) Windhoek'),
  'Asia/Baghdad': () => i18n.t('(GMT+03:00) Baghdad'),
  'Europe/Istanbul': () => i18n.t('(GMT+03:00) Istanbul'),
  'Europe/Minsk': () => i18n.t('(GMT+03:00) Minsk'),
  'Europe/Moscow': () => i18n.t('(GMT+03:00) Moscow+00 - Moscow'),
  'Africa/Nairobi': () => i18n.t('(GMT+03:00) Nairobi'),
  'Asia/Qatar': () => i18n.t('(GMT+03:00) Qatar'),
  'Asia/Riyadh': () => i18n.t('(GMT+03:00) Riyadh'),
  'Antarctica/Syowa': () => i18n.t('(GMT+03:00) Syowa'),
  'Asia/Tehran': () => i18n.t('(GMT+03:30) Tehran'),
  'Asia/Baku': () => i18n.t('(GMT+04:00) Baku'),
  'Asia/Dubai': () => i18n.t('(GMT+04:00) Dubai'),
  'Indian/Mahe': () => i18n.t('(GMT+04:00) Mahe'),
  'Indian/Mauritius': () => i18n.t('(GMT+04:00) Mauritius'),
  'Europe/Samara': () => i18n.t('(GMT+04:00) Moscow+01 - Samara'),
  'Indian/Reunion': () => i18n.t('(GMT+04:00) Reunion'),
  'Asia/Tbilisi': () => i18n.t('(GMT+04:00) Tbilisi'),
  'Asia/Yerevan': () => i18n.t('(GMT+04:00) Yerevan'),
  'Asia/Kabul': () => i18n.t('(GMT+04:30) Kabul'),
  'Asia/Aqtau': () => i18n.t('(GMT+05:00) Aqtau'),
  'Asia/Aqtobe': () => i18n.t('(GMT+05:00) Aqtobe'),
  'Asia/Ashgabat': () => i18n.t('(GMT+05:00) Ashgabat'),
  'Asia/Dushanbe': () => i18n.t('(GMT+05:00) Dushanbe'),
  'Asia/Karachi': () => i18n.t('(GMT+05:00) Karachi'),
  'Indian/Kerguelen': () => i18n.t('(GMT+05:00) Kerguelen'),
  'Indian/Maldives': () => i18n.t('(GMT+05:00) Maldives'),
  'Antarctica/Mawson': () => i18n.t('(GMT+05:00) Mawson'),
  'Asia/Yekaterinburg': () => i18n.t('(GMT+05:00) Moscow+02 - Yekaterinburg'),
  'Asia/Tashkent': () => i18n.t('(GMT+05:00) Tashkent'),
  'Asia/Colombo': () => i18n.t('(GMT+05:30) Colombo'),
  'Asia/Calcutta': () => i18n.t('(GMT+05:30) India Standard Time'),
  'Asia/Kathmandu': () => i18n.t('(GMT+05:45) Kathmandu'),
  'Asia/Almaty': () => i18n.t('(GMT+06:00) Almaty'),
  'Asia/Bishkek': () => i18n.t('(GMT+06:00) Bishkek'),
  'Indian/Chagos': () => i18n.t('(GMT+06:00) Chagos'),
  'Asia/Dhaka': () => i18n.t('(GMT+06:00) Dhaka'),
  'Asia/Omsk': () => i18n.t('(GMT+06:00) Moscow+03 - Omsk'),
  'Asia/Thimphu': () => i18n.t('(GMT+06:00) Thimphu'),
  'Antarctica/Vostok': () => i18n.t('(GMT+06:00) Vostok'),
  'Indian/Cocos': () => i18n.t('(GMT+06:30) Cocos'),
  'Asia/Yangon': () => i18n.t('(GMT+06:30) Rangoon'),
  'Asia/Bangkok': () => i18n.t('(GMT+07:00) Bangkok'),
  'Indian/Christmas': () => i18n.t('(GMT+07:00) Christmas'),
  'Antarctica/Davis': () => i18n.t('(GMT+07:00) Davis'),
  'Asia/Saigon': () => i18n.t('(GMT+07:00) Hanoi'),
  'Asia/Hovd': () => i18n.t('(GMT+07:00) Hovd'),
  'Asia/Jakarta': () => i18n.t('(GMT+07:00) Jakarta'),
  'Asia/Krasnoyarsk': () => i18n.t('(GMT+07:00) Moscow+04 - Krasnoyarsk'),
  'Asia/Brunei': () => i18n.t('(GMT+08:00) Brunei'),
  'Asia/Shanghai': () => i18n.t('(GMT+08:00) China Time - Beijing'),
  'Asia/Choibalsan': () => i18n.t('(GMT+08:00) Choibalsan'),
  'Asia/Hong_Kong': () => i18n.t('(GMT+08:00) Hong Kong'),
  'Asia/Kuala_Lumpur': () => i18n.t('(GMT+08:00) Kuala Lumpur'),
  'Asia/Macau': () => i18n.t('(GMT+08:00) Macau'),
  'Asia/Makassar': () => i18n.t('(GMT+08:00) Makassar'),
  'Asia/Manila': () => i18n.t('(GMT+08:00) Manila'),
  'Asia/Irkutsk': () => i18n.t('(GMT+08:00) Moscow+05 - Irkutsk'),
  'Asia/Singapore': () => i18n.t('(GMT+08:00) Singapore'),
  'Asia/Taipei': () => i18n.t('(GMT+08:00) Taipei'),
  'Asia/Ulaanbaatar': () => i18n.t('(GMT+08:00) Ulaanbaatar'),
  'Australia/Perth': () => i18n.t('(GMT+08:00) Western Time - Perth'),
  'Asia/Pyongyang': () => i18n.t('(GMT+08:30) Pyongyang'),
  'Asia/Dili': () => i18n.t('(GMT+09:00) Dili'),
  'Asia/Jayapura': () => i18n.t('(GMT+09:00) Jayapura'),
  'Asia/Yakutsk': () => i18n.t('(GMT+09:00) Moscow+06 - Yakutsk'),
  'Pacific/Palau': () => i18n.t('(GMT+09:00) Palau'),
  'Asia/Seoul': () => i18n.t('(GMT+09:00) Seoul'),
  'Asia/Tokyo': () => i18n.t('(GMT+09:00) Tokyo'),
  'Australia/Darwin': () => i18n.t('(GMT+09:30) Central Time - Darwin'),
  'Antarctica/DumontDUrville': () => i18n.t("(GMT+10:00) Dumont D'Urville"),
  'Australia/Brisbane': () => i18n.t('(GMT+10:00) Eastern Time - Brisbane'),
  'Pacific/Guam': () => i18n.t('(GMT+10:00) Guam'),
  'Asia/Vladivostok': () => i18n.t('(GMT+10:00) Moscow+07 - Vladivostok'),
  'Pacific/Port_Moresby': () => i18n.t('(GMT+10:00) Port Moresby'),
  'Pacific/Chuuk': () => i18n.t('(GMT+10:00) Truk'),
  'Australia/Adelaide': () => i18n.t('(GMT+10:30) Central Time - Adelaide'),
  'Antarctica/Casey': () => i18n.t('(GMT+11:00) Casey'),
  'Australia/Hobart': () => i18n.t('(GMT+11:00) Eastern Time - Hobart'),
  'Australia/Sydney': () =>
    i18n.t('(GMT+11:00) Eastern Time - Melbourne, Sydney'),
  'Pacific/Efate': () => i18n.t('(GMT+11:00) Efate'),
  'Pacific/Guadalcanal': () => i18n.t('(GMT+11:00) Guadalcanal'),
  'Pacific/Kosrae': () => i18n.t('(GMT+11:00) Kosrae'),
  'Asia/Magadan': () => i18n.t('(GMT+11:00) Moscow+08 - Magadan'),
  'Pacific/Norfolk': () => i18n.t('(GMT+11:00) Norfolk'),
  'Pacific/Noumea': () => i18n.t('(GMT+11:00) Noumea'),
  'Pacific/Pohnpei': () => i18n.t('(GMT+11:00) Ponape'),
  'Pacific/Funafuti': () => i18n.t('(GMT+12:00) Funafuti'),
  'Pacific/Kwajalein': () => i18n.t('(GMT+12:00) Kwajalein'),
  'Pacific/Majuro': () => i18n.t('(GMT+12:00) Majuro'),
  'Asia/Kamchatka': () =>
    i18n.t('(GMT+12:00) Moscow+09 - Petropavlovsk-Kamchatskiy'),
  'Pacific/Nauru': () => i18n.t('(GMT+12:00) Nauru'),
  'Pacific/Tarawa': () => i18n.t('(GMT+12:00) Tarawa'),
  'Pacific/Wake': () => i18n.t('(GMT+12:00) Wake'),
  'Pacific/Wallis': () => i18n.t('(GMT+12:00) Wallis'),
  'Pacific/Auckland': () => i18n.t('(GMT+13:00) Auckland'),
  'Pacific/Enderbury': () => i18n.t('(GMT+13:00) Enderbury'),
  'Pacific/Fakaofo': () => i18n.t('(GMT+13:00) Fakaofo'),
  'Pacific/Fiji': () => i18n.t('(GMT+13:00) Fiji'),
  'Pacific/Tongatapu': () => i18n.t('(GMT+13:00) Tongatapu'),
  'Pacific/Apia': () => i18n.t('(GMT+14:00) Apia'),
  'Pacific/Kiritimati': () => i18n.t('(GMT+14:00) Kiritimati'),
  'Device/Local': () => i18n.t("Device's Local Timezone"),
});

export const displayTimezone = (tz) => {
  if (tz === null) {
    return i18n.t('Infer time zone from web browser');
  }

  return i18nTimezoneMap(tz);
};
