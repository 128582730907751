/* istanbul ignore file */
import {
  Box,
  Button,
  DropdownMenuPrimitives as DropdownMenu,
  Flex,
  ScrollArea,
  Text,
  TextField,
} from '@kandji-inc/nectar-ui';
import React, { useRef, useState } from 'react';
import { i18n } from 'src/i18n';

const AssignMapDropdown = (props) => {
  const { assignments, onSelect } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [term, setTerm] = useState('');
  const searchRef = useRef<HTMLInputElement>(null);

  const handleDropdownOpenChange = (open: boolean) => {
    setIsOpen(open);
    if (open) {
      setTimeout(() => searchRef.current?.focus(), 0);
    } else {
      setTerm('');
    }
  };

  if (!assignments.length) {
    return null;
  }

  const filteredAssignments = assignments.filter(({ label }) =>
    label.toLowerCase().includes(term.toLowerCase()),
  );
  return (
    <DropdownMenu.Root
      open={isOpen}
      onOpenChange={handleDropdownOpenChange}
      modal={false}
    >
      <DropdownMenu.Trigger asChild>
        <Button
          variant="subtle"
          compact
          icon={{ name: 'plus' }}
          css={{
            backgroundColor: 'transparent',
            '&:hover': {
              backgroundColor: '#505e713d',
            },
            ...(isOpen && {
              backgroundColor: '#505e713d',
            }),
          }}
        >
          {i18n.t('Assign')}
        </Button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content
        css={{
          zIndex: 3,
          width: '376px',
        }}
        align="start"
        side="bottom"
      >
        <Box css={{ padding: '6px 12px' }}>
          <TextField
            ref={searchRef}
            css={{
              '& svg': {
                width: '20px',
                height: '20px',
              },
            }}
            iconLeft
            icon="magnifying-glass"
            placeholder={i18n.t('Search Assignment Maps')}
            value={term}
            onChange={(e) => setTerm(e.target.value)}
          />
        </Box>
        <ScrollArea
          css={{
            '& [data-radix-scroll-area-viewport]': { maxHeight: '200px' },
          }}
          scrollbarPosition="right"
          compact
        >
          {filteredAssignments.map(({ value, label, ...rest }) => (
            <DropdownMenu.Item
              key={value}
              onClick={() => {
                setIsOpen(false);
                onSelect({ label, value, ...rest });
              }}
              css={{
                padding: '6px 12px',
                '&:hover': {
                  background: '$blue10',
                },
              }}
              textValue={{
                /* istanbul ignore next */
                toLowerCase: () => ({
                  startsWith: () => false,
                }),
              }}
              onPointerLeave={
                /* istanbul ignore next */
                (e) => e.preventDefault()
              }
              onPointerMove={
                /* istanbul ignore next */
                (e) => e.preventDefault()
              }
              onSelect={(e) => e.preventDefault()}
            >
              <Box
                css={{
                  display: 'grid',
                  gridTemplateColumns: '1fr minmax(auto, 100px)',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  flex: '1',
                }}
              >
                <Text css={{ lineHeight: '20px' }}>{label}</Text>
              </Box>
            </DropdownMenu.Item>
          ))}
          {!filteredAssignments.length && (
            <DropdownMenu.Item css={{ cursor: 'auto', pointerEvents: 'none' }}>
              <Text variant="description">
                {i18n.t('No Blueprints found.')}
              </Text>
            </DropdownMenu.Item>
          )}
        </ScrollArea>
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};

export default AssignMapDropdown;
