/* istanbul ignore file */
import { useQuery } from '@tanstack/react-query';
import { vulnerabilityApi } from 'src/app/api/base';
import type { DetectionDateFilterFields } from 'src/features/edr/common/common.types';
import { transformDetectionDateFilterToApi } from '../../helpers';

const useGetVulnerabilitiesBySeverity = (
  timespan: Partial<DetectionDateFilterFields> = null,
  keys: Array<any> = [],
  params: any = {},
) => {
  const { data: apiRes, isLoading } = useQuery({
    queryKey: ['vulnerabilities-by-severity', timespan, ...(keys || [])],
    queryFn: () =>
      vulnerabilityApi(`v1/dashboards/vulns_by_severity`).get({
        ...params,
        filter: { timespan: transformDetectionDateFilterToApi(timespan) },
      }),
  });

  const data = apiRes?.data;
  const numCritical = data?.critical || 0;
  const numHigh = data?.high || 0;
  const numMedium = data?.medium || 0;
  const numLow = data?.low || 0;
  const total = data?.total || 0;

  return { numCritical, numHigh, numMedium, numLow, total, isLoading };
};

export { useGetVulnerabilitiesBySeverity };
