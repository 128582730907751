import React from 'react';

import { Button, modal as Modal } from '@kandji-inc/bumblebee';
import { i18n } from 'src/i18n';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

function DuplicateModal(props: Props) {
  const { isOpen, onClose, onConfirm } = props;

  const parts = {
    header: {
      text: i18n.t('Duplicate Library Item?'),
    },
    content: {
      children: (
        <p>
          {i18n.ut(
            "Another Library Item will be created with the same configurations, including assigned device families. Assignment Rules and configured Blueprints will <span class='b-txt-bold'>not</span> be duplicated.",
          )}
        </p>
      ),
    },
    footer: {
      children: (
        <>
          <Button kind="outline" onClick={onClose}>
            {i18n.t('Cancel')}
          </Button>

          <Button className="b-ml" iconPlacement="right" onClick={onConfirm}>
            {i18n.t('Yes, duplicate')}
          </Button>
        </>
      ),
    },
  };

  return (
    <Modal
      classes={{ root: 'k-duplicate-modal' }}
      isOpen={isOpen}
      onClose={onClose}
      parts={parts}
    />
  );
}

export default DuplicateModal;
