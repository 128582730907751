import { Banner } from '@kandji-inc/bumblebee';
import DOMPurify from 'dompurify';
import { bool, element, oneOfType, string } from 'prop-types';
import React from 'react';

import { i18n } from 'src/i18n';

const anchorRegex = /<a\s+([^>]+)>/g;
const styleWarningString = (warning) =>
  warning.replace(
    anchorRegex,
    `<a $1 rel="noopener noreferrer" target="_blank" class="b-alink">`,
  );

const InfoBanners = ({ warning = '', requiresRosetta = false }) => (
  <>
    {warning && (
      <Banner theme="info" kind="block" className="b-mt">
        {typeof warning === 'string' ? (
          <p
            style={{ whiteSpace: 'pre-line' }}
            // style anchor tags in warning html
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(styleWarningString(warning), {
                ADD_ATTR: ['target'],
              }),
            }}
          />
        ) : (
          <p>{warning}</p>
        )}
      </Banner>
    )}
    {requiresRosetta && (
      <Banner theme="info" kind="block" className="b-mt">
        <p>
          {i18n.t(`Mac computers with Apple silicon require Rosetta to run this app;
          Rosetta will be installed if it is missing.`)}{' '}
          <a
            href="https://support.kandji.io/kb/installing-rosetta-2-on-mac-computers-with-apple-silicon"
            rel="noopener noreferrer"
            target="_blank"
            className="b-alink"
          >
            {i18n.t(` Learn more...`)}
          </a>
        </p>
      </Banner>
    )}
  </>
);

InfoBanners.propTypes = {
  warning: oneOfType([string, element]),
  requiresRosetta: bool,
};

export default InfoBanners;
