import { Icon, setClass, useAfterMount } from '@kandji-inc/bumblebee';
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import { i18n } from 'src/i18n';
import FileUpload from './assets/file-upload.svg';
import { bytesToSize, validateExtension } from './common';
import './rtf-file-select.css';

const MB = 1e6;

const validateFile =
  ({ allowedFileTypes, hideFileTypesFromView, maxSize, validators }) =>
  (file) => {
    const isFileExtAllowed = validateExtension(allowedFileTypes, file);
    if (!isFileExtAllowed) {
      return {
        code: 'file-extension-error',
        message: i18n.t(
          `This doesn't appear to be a valid file type. Allowed extensions: {allowedFileTypes}`,
          {
            allowedFileTypes: allowedFileTypes
              .filter((allowed) => !hideFileTypesFromView.includes(allowed))
              .map((ext) => `.${ext}`)
              .join(', '),
          },
        ),
      };
    }
    if (file.size > maxSize) {
      return {
        code: 'file-too-large',
        message: i18n.t(
          `This file appears to be too large. Max size allowed: {maxSize}`,
          { maxSize: bytesToSize(maxSize) },
        ),
      };
    }

    if (validators) {
      const errors = validators.map((vdator) => vdator(file)).filter(Boolean);
      if (errors.length) {
        return {
          type: 'custom',
          message: errors[0],
        };
      }
    }

    return null;
  };

/**
 * used by:
 *  passport/kandji login RTF policy banners
 *  blueprint policy banner RTF files
 */
const RTFFileSelect = (props) => {
  const {
    disabled = false,
    maxSize = 100 * MB,
    allowedFileTypes = [
      'png',
      'svg',
      'jpg',
      'jpeg',
      'pkg',
      'zip',
      'dmg',
      'pdf',
    ],
    hideFileTypesFromView = [],
    /* istanbul ignore next */
    onFileSelect = () => {},
    validators,
    toggleValidationOn = [],
    children,
    testId,
    onlyShowAcceptedFileTypes = true,
  } = props;
  const isAfterMount = useAfterMount();
  const [error, setError] = useState([]);

  const dropzoneProps = {
    onDropAccepted: onFileSelect,
    validator: validateFile({
      allowedFileTypes,
      hideFileTypesFromView,
      maxSize,
      validators,
    }),
    disabled,
  };
  if (onlyShowAcceptedFileTypes) {
    dropzoneProps.accept = allowedFileTypes.map((ext) => `.${ext}`);
  }

  const { fileRejections, getRootProps, getInputProps, isDragActive } =
    useDropzone(dropzoneProps);

  useEffect(() => {
    setError(fileRejections);
  }, [fileRejections]);

  useEffect(() => {
    if (validators && isAfterMount) {
      const errors = validators.map((vdator) => vdator()).filter(Boolean);
      if (errors.length) {
        setError([
          {
            type: 'custom',
            errors: [{ message: errors[0] }],
          },
        ]);
      }
    }
  }, toggleValidationOn);

  return (
    <div
      className={setClass([
        'k-file-select__container',
        disabled && '--disabled',
      ])}
    >
      <div
        className={setClass([
          'k-file-select',
          isDragActive && '--is-hovered',
          error.length && '--has-error',
        ])}
        {...getRootProps()}
      >
        <input {...getInputProps()} data-testid={testId} />
        {(children &&
          children({
            disabled,
            isDragActive,
            error,
            allowedFileTypes,
            icon: FileUpload,
          })) || (
          <div className="k-file-select__detail">
            <div className="b-flex-vc b-flex-hc ">
              <img src={FileUpload} alt="upload-logo" />
            </div>
            <div className="b-flex-vgmicro b-flex-vc b-flex-hc">
              <p className="b-txt">
                {i18n.t(`Drag file here or`)}{' '}
                <a
                  href=""
                  onClick={(e) => e.preventDefault()}
                  className="b-alink"
                >
                  {i18n.t(`click to upload`)}
                </a>
              </p>
              <p className="b-txt-bold">
                {`${allowedFileTypes
                  .filter((allowed) => !hideFileTypesFromView.includes(allowed))
                  .map((ext) => `.${ext}`)
                  .join(', ')} file`}
              </p>
            </div>
          </div>
        )}
      </div>
      <div className="k-file-select__error-info b-txt b-txt--error">
        {!!error.length && (
          <>
            <Icon name="circle-info" />
            <div className="k-file-select__error-info__message">
              {error[0].errors[0].message}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default RTFFileSelect;
