// istanbul ignore file
import { visibilityApi } from 'app/api/base';
import { logError } from 'features/visibility/shared/utils';
import {
  customViewsOrderRequestSchema,
  customViewsOrderResponseSchema,
  exportPrismCategoryRequestSchema,
  exportPrismCategoryResponseSchema,
  exportPrismViewRequestSchema,
  exportPrismViewResponseSchema,
  prismCategoryDataSchema,
  prismCategoryParamsSchema,
  prismCountParamsSchema,
  prismCountResponseSchema,
  prismViewCountRequestSchema,
  prismViewResponseSchema,
  prismViewSchema,
} from '../types/prism.schema';
import type {
  CustomViewsOrderRequestSchema,
  CustomViewsOrderResponseSchema,
  ExportPrismCategoryRequestSchema,
  ExportPrismViewRequestSchema,
  PrismCategoryParamsSchema,
  PrismCategoryState,
  PrismCategoryUri,
  PrismCountParamsSchema,
  PrismViewCountRequestSchema,
  PrismViewSchema,
} from '../types/prism.types';

/*
 * Usage:
 *
 * import { prismService } from "src/features/visibility/data-service/visibility-service";
 * prismService.getQuery(schemas, 'device_information', { limit: 10, offset: 10 }).then(console.log)
 */

class PrismService {
  service: NonNullable<typeof visibilityApi>;

  public static resourceBase = {
    prism: 'prism',
    views: 'views',
  } as const;

  public static resourceNames = {
    device_information: 'device_information',
    filevault: 'filevault',
    startup_settings: 'startup_settings',
    launch_agents_and_daemons: 'launch_agents_and_daemons',
    activation_lock: 'activation_lock',
    system_extensions: 'system_extensions',
    gatekeeper_and_xprotect: 'gatekeeper_and_xprotect',
    transparency_database: 'transparency_database',
    kernel_extensions: 'kernel_extensions',
    local_users: 'local_users',
    installed_profiles: 'installed_profiles',
    application_firewall: 'application_firewall',
    apps: 'apps',
    desktop_and_screensaver: 'desktop_and_screensaver',
    schemas: 'schemas',
    export: 'export',
    certificates: 'certificates',
    views: 'views',
    count: 'count',
    universalSearch: 'universal-search',
  } as const;

  constructor() {
    if (!visibilityApi) {
      throw new Error('Visibility API not found');
    }
    this.service = visibilityApi;
  }

  getQuery(
    schemas: any,
    attribute: PrismCategoryUri,
    params: PrismCategoryParamsSchema = { limit: 10, offset: 0 },
    attributeState?: PrismCategoryState,
  ) {
    if (!schemas[attribute]) {
      throw new Error(`Attribute ${attribute} service not found`);
    }
    return this.getCategoryData(
      attribute,
      params,
      schemas[attribute],
      attributeState,
    );
  }

  async fetchSchemas(version = '') {
    if (version === 'v3') {
      return this.getSchemasV3();
    }
    return this.getSchemas();
  }

  public async getCategoryData(
    categoryUri: string,
    params: PrismCategoryParamsSchema,
    categorySchema: any,
    attributeState?: PrismCategoryState,
  ) {
    prismCategoryParamsSchema.parse(params);
    const client = this.service(
      `v1/${PrismService.resourceBase.prism}/${categoryUri}/`,
    );
    const filterEntry = attributeState?.attributeFilters?.get(
      PrismService.resourceNames[categoryUri],
    );
    const filter = filterEntry ? Object.fromEntries(filterEntry) : {};

    const res = await client.get({
      ...params,
      filter,
    });

    try {
      return categorySchema.parse(res.data);
    } catch (e) {
      logError(e, 'PrismService.getCategoryData');
      return res.data;
    }
  }

  public async getViewData(
    params: PrismCategoryParamsSchema,
    columns: Array<{ category: string; name: string }> | undefined,
    filters: Record<string, unknown> | undefined,
    search: string | undefined,
  ) {
    const client = this.service(
      `v1/${PrismService.resourceBase.views}/results`,
    );

    try {
      const res = await client.get({
        ...params,
        columns: JSON.stringify(columns),
        filter: filters,
        search,
      });
      // istanbul ignore next
      return res.data;
    } catch (e) {
      // istanbul ignore next
      throw new Error(
        e instanceof Error ? e.message : 'Unknown error occurred',
      );
    }
  }

  public async UNSAFE_getCategoryData(
    categoryUri: string,
    params: PrismCategoryParamsSchema,
    filters: Record<string, unknown> | undefined,
  ) {
    prismCategoryParamsSchema.parse(params);
    const client = this.service(
      `v1/${PrismService.resourceBase.prism}/${categoryUri}/`,
    );

    try {
      const res = await client.get({
        ...params,
        filter: filters,
      });

      return res.data;
    } catch (e) {
      throw new Error(
        e instanceof Error ? e.message : 'Unknown error occurred',
      );
    }
  }

  public async exportFleetData(body: ExportPrismCategoryRequestSchema) {
    const parsedRequest = exportPrismCategoryRequestSchema.parse(body);
    const client = visibilityApi?.(
      `v1/${PrismService.resourceBase.prism}/${PrismService.resourceNames.export}/`,
    );

    if (!client) {
      throw new Error('Visibility API not found');
    }

    const res = await client.post({
      category: parsedRequest.category,
      blueprint_ids: parsedRequest.blueprint_ids,
      device_families: parsedRequest.device_families,
      filter: parsedRequest.filter,
      columns: parsedRequest.columns,
      sort_by: parsedRequest.sort_by,
      view_name: parsedRequest.view_name,
    });
    try {
      return exportPrismCategoryResponseSchema.parse(res.data);
    } catch (e) {
      logError(e, 'PrismService.exportFleetData');
      return res.data;
    }
  }

  public async getExportStatus(id: string | undefined) {
    const client = visibilityApi?.(
      `v1/${PrismService.resourceBase.prism}/${PrismService.resourceNames.export}/${id}/`,
    );

    if (!client) {
      throw new Error('Visibility API not found');
    }

    const res = await client.get({});
    try {
      return exportPrismCategoryResponseSchema.parse(res.data);
    } catch (e) {
      logError(e, 'PrismService.getExportStatus');
      return res.data;
    }
  }

  public async exportView(body: ExportPrismViewRequestSchema) {
    const parsedRequest = exportPrismViewRequestSchema.parse(body);
    const client = visibilityApi?.(
      `v1/${PrismService.resourceNames.views}/${PrismService.resourceNames.export}/`,
    );

    /* istanbul ignore if */
    if (!client) {
      throw new Error('Visibility API not found');
    }

    const res = await client.post({
      blueprint_ids: parsedRequest.blueprint_ids,
      device_families: parsedRequest.device_families,
      filter: parsedRequest.filter,
      columns: parsedRequest.columns,
      view_name: parsedRequest.view_name,
    });

    try {
      // istanbul ignore next
      return exportPrismViewResponseSchema.parse(res.data);
    } catch (e) {
      // istanbul ignore next
      logError(e, 'PrismService.exportView');
      return res.data;
    }
  }

  public async getViewExportStatus(id: string | undefined) {
    const client = visibilityApi?.(
      `v1/${PrismService.resourceNames.views}/${PrismService.resourceNames.export}/${id}/`,
    );

    /* istanbul ignore if */
    if (!client) {
      throw new Error('Visibility API not found');
    }

    const res = await client.get({});
    try {
      // istanbul ignore next
      return exportPrismViewResponseSchema.parse(res.data);
    } catch (e) {
      // istanbul ignore next
      logError(e, 'PrismService.getViewExportStatus');
      return res.data;
    }
  }

  public async createDeviceView(view: PrismViewSchema) {
    const client = visibilityApi?.(`v1/${PrismService.resourceNames.views}`);

    if (!client) {
      throw new Error('Visibility API not found');
    }

    const res = await client.post(view);
    try {
      return prismViewSchema.parse(res.data);
    } catch (e) {
      logError(e, 'PrismService.createDeviceView');
      return res.data;
    }
  }

  public async createCategoryView(view: PrismViewSchema) {
    const client = visibilityApi?.(
      `v1/${PrismService.resourceBase.prism}/${PrismService.resourceNames.views}`,
    );

    if (!client) {
      throw new Error('Visibility API not found');
    }

    const res = await client.post(view);
    try {
      return prismViewSchema.parse(res.data);
    } catch (e) {
      logError(e, 'PrismService.createCategoryView');
      return res.data;
    }
  }

  public async updateDeviceView(view: PrismViewSchema) {
    if (!view.id) {
      throw new Error('View ID is required');
    }

    const client = visibilityApi?.(
      `v1/${PrismService.resourceNames.views}/${view.id}`,
    );

    if (!client) {
      throw new Error('Visibility API not found');
    }

    const res = await client.put(view);
    try {
      return prismViewSchema.parse(res.data);
    } catch (e) {
      logError(e, 'PrismService.updateDeviceView');
      return res.data;
    }
  }

  public async updateCategoryView(view: PrismViewSchema) {
    if (!view.id) {
      throw new Error('View ID is required');
    }

    const client = visibilityApi?.(
      `v1/${PrismService.resourceBase.prism}/${PrismService.resourceNames.views}/${view.id}`,
    );

    if (!client) {
      throw new Error('Visibility API not found');
    }

    const res = await client.put(view);
    try {
      return prismViewSchema.parse(res.data);
    } catch (e) {
      logError(e, 'PrismService.updateCategoryView');
      return res.data;
    }
  }

  public async getDeviceViews() {
    const client = visibilityApi?.(`v1/${PrismService.resourceNames.views}`);

    if (!client) {
      throw new Error('Visibility API not found');
    }

    const res = await client.get({});
    try {
      return prismViewResponseSchema.parse(res.data);
    } catch (e) {
      logError(e, 'PrismService.getDeviceViews');
      return res.data;
    }
  }

  public async getSavedViews() {
    const client = visibilityApi?.(
      `v1/${PrismService.resourceBase.prism}/${PrismService.resourceNames.views}`,
    );

    if (!client) {
      throw new Error('Visibility API not found');
    }

    const res = await client.get({});
    try {
      return prismViewResponseSchema.parse(res.data);
    } catch (e) {
      logError(e, 'PrismService.getSavedViews');
      return res.data;
    }
  }

  public async deleteDeviceView(appliedSavedViewId?: string) {
    if (!appliedSavedViewId) {
      throw new Error('View ID is required');
    }
    const client = visibilityApi?.(
      `v1/${PrismService.resourceNames.views}/${appliedSavedViewId}`,
    );

    if (!client) {
      throw new Error('Visibility API not found');
    }

    await client.delete(appliedSavedViewId);
  }

  public async deleteSavedView(appliedSavedViewId?: string) {
    if (!appliedSavedViewId) {
      throw new Error('View ID is required');
    }
    const client = visibilityApi?.(
      `v1/${PrismService.resourceBase.prism}/${PrismService.resourceNames.views}/${appliedSavedViewId}`,
    );

    if (!client) {
      throw new Error('Visibility API not found');
    }

    await client.delete(appliedSavedViewId);
  }

  public async getPrismViewCount(params: PrismViewCountRequestSchema) {
    prismViewCountRequestSchema.parse(params);
    const client = this.service(
      `v1/${PrismService.resourceBase.views}/${PrismService.resourceNames.count}`,
    );

    const filters = params.filter as Record<string, unknown>;

    const { columns, ...restParams } = params;
    const encodedColumns = JSON.stringify(columns);

    const res = await client.get({
      ...restParams,
      columns: encodedColumns,
      filter: filters,
    });
    try {
      return prismCountResponseSchema.parse(res.data);
    } catch (e) {
      logError(e, 'PrismService.getPrismViewCount');
      return res.data;
    }
  }

  public async getPrismCategoryCount(
    params: PrismCountParamsSchema,
    filters: Record<string, unknown> | undefined,
  ) {
    prismCountParamsSchema.parse(params);
    const client = this.service(
      `v1/${PrismService.resourceBase.prism}/${PrismService.resourceNames.count}/`,
    );

    const res = await client.get({
      ...params,
      filter: filters,
    });
    try {
      return prismCountResponseSchema.parse(res.data);
    } catch (e) {
      logError(e, 'PrismService.getPrismCategoryCount');
      return res.data;
    }
  }

  private async getSchemas() {
    const client = this.service(
      `v2/${PrismService.resourceBase.prism}/${PrismService.resourceNames.schemas}/`,
    );
    const res = await client.get({});
    try {
      return prismCategoryDataSchema.parse(res.data);
    } catch (e) {
      logError(e, 'PrismService.getSchemas');
      return res.data;
    }
  }

  private async getSchemasV3() {
    const client = this.service(
      `v3/${PrismService.resourceBase.prism}/${PrismService.resourceNames.schemas}/`,
    );
    const res = await client.get({});
    try {
      return prismCategoryDataSchema.parse(res.data);
    } catch (e) {
      logError(e, 'PrismService.getSchemas');
      return res.data;
    }
  }

  public async getCustomViewsOrder(): Promise<CustomViewsOrderResponseSchema> {
    const client = visibilityApi?.(
      `v1/${PrismService.resourceNames.views}/order`,
    );

    if (!client) {
      throw new Error('Visibility API not found');
    }

    const res = await client.get({});
    try {
      return customViewsOrderResponseSchema.parse(res.data);
    } catch (e) {
      logError(e, 'PrismService.getCustomViewsOrder');
      return res.data;
    }
  }

  public async updateCustomViewsOrder(
    order: CustomViewsOrderRequestSchema,
  ): Promise<void> {
    const client = visibilityApi?.(
      `v1/${PrismService.resourceNames.views}/order`,
    );

    if (!client) {
      throw new Error('Visibility API not found');
    }
    try {
      const parsedOrder = customViewsOrderRequestSchema.parse(order);
      await client.put({
        order: parsedOrder.order.join(','), // Transform to comma-separated string here
      });
    } catch (e) {
      logError(e, 'PrismService.updateCustomViewsOrder');
      throw e;
    }
  }

  public async getRecentSearches() {
    const client = visibilityApi?.(
      `v1/${PrismService.resourceNames.universalSearch}/recents`,
    );

    try {
      const res = await client.get({});
      return res.data;
    } catch (e) {
      throw new Error(
        e instanceof Error ? e.message : 'Unknown error occurred',
      );
    }
  }

  public async createRecentSearch(searchResult: {
    resource_id: string;
    type: string;
  }) {
    const client = visibilityApi?.(
      `v1/${PrismService.resourceNames.universalSearch}/recents`,
    );

    if (!client) {
      throw new Error('Visibility API not found');
    }

    const res = await client.post(searchResult);
    return res.data;
  }
}

const prismService = new PrismService();
export default PrismService;
export { prismService };
