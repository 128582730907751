import { Setting } from 'features/library-items/template';
import { i18n } from 'i18n';
import React from 'react';

const LicensesCard = (props) => {
  const { setting } = props;

  return (
    <Setting.Card>
      <Setting.Header>
        <h3 className="b-h3">{i18n.t('Licenses')}</h3>
      </Setting.Header>
      <Setting.Rows>
        <Setting.Row>
          <div className="b-txt">
            {i18n.t('{used} of {total} licenses used.', {
              used: setting.used,
              total: setting.total,
            })}
          </div>
        </Setting.Row>
      </Setting.Rows>
    </Setting.Card>
  );
};

export default LicensesCard;
