/* istanbul ignore file */
import { useQuery } from '@tanstack/react-query';
import { vulnerabilityApi } from 'src/app/api/base';

const useGetApplications = (keys: Array<any> = [], params: any = {}) => {
  const { data: apiRes, isLoading } = useQuery({
    queryKey: ['applications', ...(keys || [])],
    queryFn: () =>
      vulnerabilityApi(`v1/dashboards/vulns_by_application/applications`).get(
        params,
      ),
  });

  const data = apiRes?.data;
  const applications: string[] = data || [];

  return { applications, isLoading };
};

export { useGetApplications };
