/* istanbul ignore file */
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

const useRequestVulnerabilityTrial = (portalId: string, formId: string) =>
  useMutation({
    mutationKey: ['request-vulnerability-trial'],
    mutationFn: async ({
      tenantSubdomain,
      tenantName,
      userEmail,
      pageName,
    }: {
      tenantSubdomain: string;
      tenantName: string;
      userEmail: string;
      pageName: string;
    }) => {
      return axios.post(
        `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`,
        {
          submittedAt: Date.now(),
          context: {
            pageName,
          },
          fields: [
            {
              objectTypeId: '0-1',
              name: 'email',
              value: userEmail,
            },
            {
              objectTypeId: '0-1',
              name: 'vuln_in_app_requested',
              value: 'true',
            },
            {
              objectTypeId: '0-1',
              name: 'tenant_subdomain',
              value: tenantSubdomain,
            },
            {
              objectTypeId: '0-1',
              name: 'name',
              value: tenantName,
            },
          ],
        },
      );
    },
  });

export { useRequestVulnerabilityTrial };
