// useGlobalFilter.ts
import { useCallback, useState } from 'react';
import { useFlags } from 'src/config/feature-flags';
import { getMaxDeviceFamilies } from '../utils/constants';

export type DeviceFamily = 'iPhone' | 'iPad' | 'Mac' | 'AppleTV' | 'Vision';
export type Blueprint = {
  id: string;
  name: string;
};

export interface GlobalFilters {
  deviceFamilies: DeviceFamily[];
  blueprints: Blueprint[];
}

export const useGlobalFilters = (initialState?: GlobalFilters) => {
  const { 'ios-apple-vision-pro': iosAppleVisionPro } = useFlags();
  const [globalFilters, setGlobalFilters] = useState<GlobalFilters>(() => {
    // check if initial state exists and has the deviceFamilies and blueprints keys
    if (
      initialState &&
      'deviceFamilies' in initialState &&
      'blueprints' in initialState
    ) {
      return initialState;
    }
    return {
      deviceFamilies: [],
      blueprints: [],
    };
  });

  const updateDeviceFamilies = useCallback(
    (deviceFamilies: DeviceFamily[]) => {
      if (deviceFamilies.length === getMaxDeviceFamilies(iosAppleVisionPro)) {
        setGlobalFilters((prevFilter) => ({
          ...prevFilter,
          deviceFamilies: [],
        }));
      } else {
        setGlobalFilters((prevFilter) => ({
          ...prevFilter,
          deviceFamilies,
        }));
      }
    },
    [iosAppleVisionPro],
  );

  const updateBlueprints = useCallback((blueprints: Blueprint[]) => {
    setGlobalFilters((prevFilter) => ({
      ...prevFilter,
      blueprints,
    }));
  }, []);

  const resetAllFilters = useCallback(() => {
    setGlobalFilters({
      deviceFamilies: [],
      blueprints: [],
    });
  }, []);

  const generateQueryParams = useCallback((): {
    deviceFamilies: string;
    blueprintIds: string;
  } => {
    const deviceFamiliesParam = globalFilters.deviceFamilies?.join(',');
    const blueprintsParam = globalFilters.blueprints
      ?.map((b) => {
        // when a user selects a blueprint, the value is an object with an id and name
        // when the blueprint is read from the url, the value is a string
        if (typeof b === 'string') {
          return b;
        }
        return b.id;
      })
      .join(',');
    return {
      deviceFamilies: deviceFamiliesParam,
      blueprintIds: blueprintsParam,
    };
  }, [globalFilters]);

  return {
    globalFilters,
    updateDeviceFamilies,
    updateBlueprints,
    generateQueryParams,
    resetAllFilters,
  };
};
