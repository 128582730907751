import { Flex, Heading, Text } from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';
import NoResultsFoundImg from '../../assets/no-results-found.svg';

const NoResultsFound = () => (
  <Flex
    alignItems="center"
    justifyContent="center"
    flow="column"
    hFull
    css={{ overflow: 'hidden' }}
  >
    <img src={NoResultsFoundImg} alt="no-results-found" />
    <Heading size="4" css={{ fontWeight: '$medium', paddingTop: '$3' }}>
      {i18n.t('No results found')}
    </Heading>
    <Text>
      {i18n.t('Try changing the filter or search with different keywords.')}
    </Text>
  </Flex>
);

export { NoResultsFound };
