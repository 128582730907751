import { formatNumber } from '@kandji-inc/nectar-i18n';
import { Flex, Heading, Loader, Text } from '@kandji-inc/nectar-ui';
import { i18n } from 'src/i18n';
import { useGetTopApplicationsWithVulnerabilities } from '../../../hooks/dashboard/use-get-top-applications-with-vulnerabilities';
import { Tile } from './tile';

const TopApplicationsWithVulnerabilitiesTile = () => {
  const defaultNumApplications = 4;
  const { applications, isLoading } = useGetTopApplicationsWithVulnerabilities(
    defaultNumApplications,
  );
  const longestBar = applications[0]?.num_vulns;

  return (
    <Tile>
      <Flex flow="column" gap="sm" hFull>
        <Flex alignItems="center" gap="xs">
          <Heading size="4">
            {i18n.t('Top applications with vulnerabilities')}
          </Heading>
        </Flex>

        {isLoading && (
          <Flex hFull alignItems="center" justifyContent="center">
            <Loader data-testid="loading-top-applications-with-vulnerabilities" />
          </Flex>
        )}

        {!isLoading && applications.length > 0 && (
          <Flex flow="column" hFull wFull gap="sm" justifyContent="start">
            {applications?.map((item) => (
              <Flex
                justifyContent="space-between"
                alignItems="center"
                p2
                gap="sm"
                css={{
                  minWidth: 'min-content',
                  whiteSpace: 'nowrap',
                  width: `${(item.num_vulns / longestBar) * 100}%`,
                  background:
                    'linear-gradient(to right, var(--colors-yellow30), var(--colors-yellow10))',
                  borderRadius: '$rounded',
                }}
              >
                <Text css={{ fontWeight: '$medium' }}>{item.app_name}</Text>
                <Text css={{ fontSize: '10px' }}>
                  {formatNumber(item.num_vulns)}
                </Text>
              </Flex>
            ))}
          </Flex>
        )}

        {!isLoading && applications.length === 0 && (
          <Flex hFull wFull alignItems="center" justifyContent="center">
            <Text>
              {i18n.t(
                'No vulnerabilities have been detected in your Mac fleet.',
              )}
            </Text>
          </Flex>
        )}
      </Flex>
    </Tile>
  );
};

export { TopApplicationsWithVulnerabilitiesTile };
