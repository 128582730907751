import {
  Banner,
  PreLoadImages,
  updateSetting as update,
} from '@kandji-inc/bumblebee';
import React, { useMemo } from 'react';
import './kandji-login-previews.css';
import './kandji-login.css';

import useUniqValue from 'components/common/hooks/use-uniq-value/use-uniq-value';
import ViewActivity from 'components/library/ViewActivity';
import { useBlueprintConflicts } from 'features/library-items/common/blueprint-conflicts';
import { useLibraryItem } from 'features/library-items/common/hooks/use-library-item';
import { kandjiLoginService } from 'features/library-items/items/kandji-login/service/kandji-login-service';
import {
  transformFromApi,
  transformToApi,
} from 'features/library-items/items/kandji-login/service/transformers';
import useKandjiLoginService from 'features/library-items/items/kandji-login/service/use-kandji-login-service';
import { enrollmentApps } from 'features/library-items/library/library-item-configurations/items';
import featureFlags from 'src/config/feature-flags';
import { i18n } from 'src/i18n';
import LibraryItemPage from '../../common/library-item-page';
import NewStatusTab from '../../common/new-status-tab/new-status-tab';
import { getNewStatusTabProps } from '../../common/new-status-tab/util';
import initial from './initial-state';
import Access from './settings/access';
import Auth from './settings/auth';
import HelpScreen from './settings/help-screen';
import LoginScreen from './settings/login-screen';
import Provision from './settings/provision';

const KandjiLoginPage = (props) => {
  const { savedModel, model, setModel, pageState, handlers } = props;
  const [validationDep, triggerValidation] = useUniqValue();
  const [isNewOsTab, setIsNewOsTab] = React.useState(true);

  const AuthSection = useMemo(
    () => (
      <Auth
        isDisabled={pageState.isDisabled}
        isSubmitted={pageState.isSubmitted}
        setting={model.auth}
        update={update('auth', setModel)}
        onValidate={handlers.onValidate}
        validationDep={validationDep}
      />
    ),
    [pageState, model.auth, update, validationDep],
  );

  const ProvisionSection = useMemo(
    () => (
      <Provision
        isDisabled={pageState.isDisabled}
        setting={model.provision}
        update={update('provision', setModel)}
        validationDep={validationDep}
        isSubmitted={pageState.isSubmitted}
      />
    ),
    [pageState, model.provision, update, validationDep],
  );

  const AccessSection = useMemo(
    () => (
      <Access
        isDisabled={pageState.isDisabled}
        setting={model.access}
        authSetting={model.auth}
        update={update('access', setModel)}
        validationDep={validationDep}
      />
    ),
    [pageState, validationDep, update, model.access, model.auth],
  );

  const LoginSection = useMemo(
    () => (
      <LoginScreen
        isDisabled={pageState.isDisabled}
        setting={model.loginScreen}
        authSetting={model.auth}
        beforeEditModel={savedModel.loginScreen}
        update={update('loginScreen', setModel)}
        isNewOsTab={isNewOsTab}
        onOsTabChange={setIsNewOsTab}
      />
    ),
    [
      pageState,
      savedModel.loginScreen,
      update,
      model.loginScreen,
      model.auth,
      isNewOsTab,
      setIsNewOsTab,
    ],
  );

  const HelpSection = useMemo(
    () => (
      <HelpScreen
        isDisabled={pageState.isDisabled}
        setting={model.helpScreen}
        authSetting={model.auth}
        loginInfo={model.loginScreen}
        beforeEditModel={savedModel.helpScreen}
        update={update('helpScreen', setModel)}
        isNewOsTab={isNewOsTab}
      />
    ),
    [
      pageState,
      savedModel.helpScreen,
      model.auth,
      update,
      model.loginScreen,
      model.helpScreen,
      isNewOsTab,
    ],
  );

  const installPasscodeProfileEnabled = featureFlags.getFlag(
    'core-install-passcode-profile',
  );

  const itemConfig = enrollmentApps['Kandji Login'];

  return (
    <>
      <PreLoadImages
        paths={[model.loginScreen.logoUrl, model.loginScreen.desktopBgUrl]}
      />
      <LibraryItemPage
        {...props}
        type="kandji-login"
        passportFilesToUpload={{
          'loginScreen.logo.file': 'loginScreen.logoS3Key',
          'loginScreen.desktopBg.file': 'loginScreen.desktopBgS3Key',
          'loginScreen.policyBannerFile': 'loginScreen.policyBannerS3Key',
        }}
        crumb={model.name || itemConfig.getName()}
        defaultIcon={itemConfig.icon}
        summaryInfoProps={{
          name: itemConfig.getName(),
          description: (
            <>
              {i18n.t(`Allow users to authenticate on Mac computers using their
              organization's directory credentials. Configure settings and
              customize the experience below.`)}
              <a
                href="https://support.kandji.io/kb/configure-the-passport-library-item"
                rel="noopener noreferrer"
                target="_blank"
                className="b-alink"
              >
                {i18n.t(`Learn more...`)}
              </a>
              {installPasscodeProfileEnabled && (
                <Banner className="k-library-banner" theme="info" kind="block">
                  <p>
                    {i18n.t(`This library item will be installed prior to releasing the
                    user from the Automated Device Enrollment screen.`)}
                  </p>
                </Banner>
              )}
            </>
          ),
          publisher: itemConfig.getPublisher(),
          devices: ['Mac'],
          requirements: itemConfig.getOsRequirements(),
        }}
        service={kandjiLoginService}
        transformFromApi={transformFromApi}
        transformToApi={transformToApi}
        triggerValidation={triggerValidation}
        ActivityTab={ViewActivity}
        StatusTab={NewStatusTab}
        getStatusTabProps={getNewStatusTabProps}
        identifier=""
        supportsRules
        supportsDuplication
      >
        {AuthSection}
        {ProvisionSection}
        {AccessSection}
        {LoginSection}
        {HelpSection}
      </LibraryItemPage>
    </>
  );
};

const KandjiLogin = () => {
  const { pageProps, PlaceHolder } = useLibraryItem({
    initialState: initial(),
    useService: useKandjiLoginService,
  });
  const blueprintConflicts = useBlueprintConflicts();

  if (PlaceHolder) {
    return <PlaceHolder />;
  }

  return (
    <KandjiLoginPage {...pageProps} blueprintConflicts={blueprintConflicts} />
  );
};

export default KandjiLogin;
