import { Flex, Select } from '@kandji-inc/nectar-ui';
import { memo } from 'react';
import { Setting } from 'src/features/library-items/template';
import DiskManagementService from '../../service/disk-management-service';

const Management = (props: any) => {
  const { update, isDisabled, settings } = props;

  return (
    <Setting.Card>
      <Setting.Header>
        <h3 className="b-h3">Disk Management</h3>
      </Setting.Header>
      <Setting.SubHeader>
        <p className="b-txt">
          Manage mount control settings for external and network volumes in
          macOS 15 and later.{' '}
          <a
            href="https://support.kandji.io/kb/configuring-the-disk-management-library-item"
            rel="noopener noreferrer"
            target="_blank"
            className="b-alink"
          >
            Learn more
          </a>
        </p>
      </Setting.SubHeader>
      <Setting.Rows>
        <Setting.Row>
          <Setting.Controls>
            <Flex flow="column" gap="xl">
              <Select
                options={DiskManagementService.volumeTypeOptions}
                onChange={(v) => update('externalVolumes', v)}
                value={settings.externalVolumes}
                disabled={isDisabled}
                label="External volumes"
                data-testid="external-volumes"
              />
              <Select
                options={DiskManagementService.volumeTypeOptions}
                onChange={(v) => update('internalVolumes', v)}
                value={settings.internalVolumes}
                disabled={isDisabled}
                label="Network volumes"
                data-testid="internal-volumes"
              />
            </Flex>
          </Setting.Controls>
        </Setting.Row>
      </Setting.Rows>
    </Setting.Card>
  );
};

export default memo(Management);
