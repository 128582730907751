/* istanbul ignore file */
import type { UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { newLibraryAxios } from 'app/api/base';
import { apiTypes } from 'src/features/library-items/library/common';
import { newLibraryItemService } from '../library-item/new-library-item-service';

export const QUERY_KEY = 'use-get-library';

const routeMapping = {
  webList: () => newLibraryItemService.v2List(),
  list: ({ params }) => newLibraryAxios('').get(params),
  get: ({ id, params }) => newLibraryAxios(`${id}/`).get(params),
  listManagedItems: ({ params }) =>
    newLibraryAxios('').get({
      type__in: [
        apiTypes.AUTO_APP,
        apiTypes.MACOS_RELEASE,
        apiTypes.IOS_RELEASE,
        apiTypes.IPADOS_RELEASE,
        apiTypes.TVOS_RELEASE,
        apiTypes.MDM_MACOS_RELEASE,
      ].join(', '),
      ...params,
    }),
};

type UseGetLibraryParams = {
  loc: keyof typeof routeMapping;

  // Useful for setting custom key values
  keys?: Array<any>;
  id?: string;
  apiParams?: Record<string, any>;

  // An array of transformers that run after a request is made.
  // TODO: Any use of before or error props?
  // TODO: Define the Function for a transformer, likely based on a RHF type.
  after?: Array<(rollingData: any) => any>;

  options?: Partial<UseQueryOptions>;
};

/**
 * A useful hook for anything related to GETting for library items.
 */
export const useGetLibrary = (params: UseGetLibraryParams) => {
  const {
    loc,
    id,
    keys = [],
    after = [],
    options = {},
    apiParams = {},
  } = params;

  return useQuery<any, Error>({
    queryKey: [QUERY_KEY, ...keys],
    queryFn: async () => {
      const routeQueryFn = routeMapping[loc];
      return await routeQueryFn({ id, params: apiParams });
    },
    select: (data) => {
      let rollingTransformedData = data;
      if (after?.length) {
        for (let i = 0; i < after.length; i++) {
          const tFormer = after[i];
          rollingTransformedData = tFormer(rollingTransformedData);
        }
      }
      return rollingTransformedData;
    },
    ...options,
  });
};
