/* istanbul ignore file */
import {
  type Field,
  NFCheckbox,
  NFSelectV2,
  NFSubmit,
  NFTextField,
  NectarForm,
  Validation,
  registerField,
  useForm,
} from '@kandji-inc/nectar-form';
import {
  Box,
  Button,
  Chip,
  Dialog,
  Flex,
  Heading,
  MultiSelect,
  Separator,
  Text,
  useToast_UNSTABLE,
} from '@kandji-inc/nectar-ui';
import { keepPreviousData } from '@tanstack/react-query';
import React, { useContext, useEffect, useState } from 'react';
import { InterfaceContext } from 'src/contexts/interface';
import { useGetLibrary } from 'src/features/library-items/data-service/library/useGetLibraryItems';
import initialState from 'src/features/library-items/items/AppBlocking/initial-state';
import AppBlockingService, {
  appBlockingService,
} from 'src/features/library-items/items/AppBlocking/service/app-blocking-service';
import {
  transformFromApi,
  transformToApi,
} from 'src/features/library-items/items/AppBlocking/service/transformers';
import { useGetLibraryItems } from 'src/features/library-items/library/api/useGetLibraryItems';
import { apiTypes } from 'src/features/library-items/library/common';
import { useIntersectionObserver } from 'src/hooks/useIntersection';
import { i18n } from 'src/i18n';

const LIBRARY_FIELD = 'library_items';
const BY_PROCESS_FIELD = 'by_process';
const BY_PATH_FIELD = 'by_path';
const BY_BUNDLE_ID_FIELD = 'by_bundle_id';
const BY_DEVELOPER_ID_FIELD = 'by_developer_id';
const GET_ACTIVE_FIELD = (field: string) => `${field}_active`;
const GET_TYPE_FIELD = (field: string) => `${field}_type`;

const fields = () => [
  {
    field: BY_PROCESS_FIELD,
    label: i18n.t('Process identifier'),
    toggle: GET_ACTIVE_FIELD(BY_PROCESS_FIELD),
    typeField: GET_TYPE_FIELD(BY_PROCESS_FIELD),
    dataKey: 'process_name',
  },
  {
    field: BY_PATH_FIELD,
    label: i18n.t('Full path to application'),
    toggle: GET_ACTIVE_FIELD(BY_PATH_FIELD),
    typeField: GET_TYPE_FIELD(BY_PATH_FIELD),
    dataKey: 'full_path_to_app',
  },
  {
    field: BY_BUNDLE_ID_FIELD,
    label: i18n.t('By Bundle ID'),
    toggle: GET_ACTIVE_FIELD(BY_BUNDLE_ID_FIELD),
    typeField: GET_TYPE_FIELD(BY_BUNDLE_ID_FIELD),
    dataKey: 'bundle_identifier',
  },
  {
    field: BY_DEVELOPER_ID_FIELD,
    label: i18n.t('By Developer ID'),
    toggle: GET_ACTIVE_FIELD(BY_DEVELOPER_ID_FIELD),
    dataKey: 'developer_id',
  },
];

const LibraryItemSection = ({ blueprintId }) => {
  const libraryItemField = registerField(LIBRARY_FIELD, {
    validation: [
      (value) => {
        const items = JSON.parse(value);
        if (items.length === 0) {
          return i18n.t('This field is required');
        }
      },
    ],
    defaultValue: '[]',
  });
  const { isIntersecting, ref } = useIntersectionObserver({
    threshold: 0.5,
  });
  const [searchTerm, setSearchTerm] = React.useState('');
  const [options, setOptions] = React.useState(null);

  const { data, isFetching, fetchNextPage, hasNextPage } = useGetLibraryItems({
    queryParams: { type__in: apiTypes.APP_BLOCKING },
    queryKeys: [searchTerm],
    queryOptions: {
      placeholderData: keepPreviousData<{ pageParams: [undefined]; pages: [] }>,
    },
  });

  const {
    data: blueprintItemsData,
    isFetched: isFetchedBlueprintItems,
    isFetching: isFetchingBlueprintItems,
  } = useGetLibrary({
    loc: 'list',
    apiParams: {
      type__in: apiTypes.APP_BLOCKING,
      blueprint__in: blueprintId,
      include_data: true,
    },
  });
  const blueprintItems = blueprintItemsData?.data?.results || [];

  const pageData = React.useMemo(
    () => (data as any)?.pages.map((page) => page.results).flat() || [],
    [data],
  );

  const onCreateLibraryItem = (libraryItemName: string) => {
    libraryItemField.setValue(
      JSON.stringify([
        ...JSON.parse(libraryItemField.getValue()),
        { id: libraryItemName, isCreate: true },
      ]),
    );
  };

  const onSelectLibraryItem = (_, { action, data: selectedOption, option }) => {
    if (action === 'select-option') {
      const prev = JSON.parse(libraryItemField.getValue());
      // For some reason this function sometimes gets called twice when
      // selecting an option
      if (prev.some((item) => item.id === selectedOption)) {
        return;
      }
      libraryItemField.setValue(
        JSON.stringify([
          ...prev,
          // @ts-ignore - item exists
          { id: selectedOption, isCreate: false, item: option.item },
        ]),
      );
    } else if (action === 'deselect-option') {
      const prev = JSON.parse(libraryItemField.getValue());
      libraryItemField.setValue(
        JSON.stringify(prev.filter((item) => item.id !== selectedOption)),
      );
    }
  };

  React.useEffect(() => {
    if (pageData || blueprintItems) {
      const withoutDuplicates = [...pageData, ...(blueprintItems || [])].reduce(
        (acc, obj) => {
          if (!acc.some((item) => item.id === obj.id)) {
            acc.push(obj);
          }
          return acc;
        },
        [],
      );

      setOptions(
        withoutDuplicates?.map((item, idx) => ({
          label: item.name,
          value: item.id,
          item,
          richLabel: (
            <Chip
              ref={idx === withoutDuplicates.length - 1 ? ref : undefined}
              label={item.name}
            />
          ),
        })) || [],
      );
    }
  }, [pageData, blueprintItems]);

  React.useEffect(() => {
    if (isFetchedBlueprintItems && blueprintItems) {
      const prev = JSON.parse(libraryItemField.getValue());
      libraryItemField.setValue(
        JSON.stringify([
          ...new Map(
            [
              ...prev,
              ...blueprintItems.map((item) => ({
                id: item.id,
                isCreate: false,
                item,
              })),
            ].map((entry) => [entry.id, entry]),
          ).values(),
        ]),
      );
    }
  }, [isFetchedBlueprintItems, blueprintItems]);

  React.useEffect(() => {
    /* istanbul ignore next */
    if (hasNextPage && isIntersecting && !isFetching) {
      fetchNextPage();
    }
  }, [isIntersecting]);

  return (
    <MultiSelect
      label={i18n.t('Add rule to the following Library Item(s):')}
      value={JSON.parse(libraryItemField.getValue()).map(({ id }) => id)}
      onChange={onSelectLibraryItem}
      options={options}
      placeholder={i18n.t('Enter Library Items')}
      searchable
      searchFn={setSearchTerm}
      hideNoOptionsFoundMessage={true}
      creatable={{
        active: false,
        onCreate: onCreateLibraryItem,
        showMenuMessage: searchTerm?.trim() !== '',
        maxLength: 50,
        ignoredKeys: [','],
        customMenuMessage: (searchInput) => (
          <Flex alignItems="center" css={{ gap: '$1' }}>
            <Text>{i18n.t('Create')}</Text>
            <Chip label={searchInput} />
          </Flex>
        ),
      }}
      error={libraryItemField.getErrorOrNull()}
      customHeader={
        <Text variant="description" size="1" css={{ padding: '6px 12px' }}>
          {i18n.t('Select a Library Item or type to create new')}
        </Text>
      }
      componentCss={{
        label: { fontSize: '14px' },
        trigger: { width: '500px' },
        menu: { width: '500px' },
        valueContainer: { maxWidth: '500px' },
      }}
      disabled={isFetchingBlueprintItems}
    />
  );
};

const requiredByToggle = (toggleKey) => (value, fields) => {
  const isToggled = fields.getField(toggleKey).getValue();
  if (isToggled) {
    // @ts-ignore
    return Validation.required(value);
  }
  return null;
};

const RuleSection = () => {
  // @ts-ignore
  const { _fields } = useForm();

  const registeredFields: Array<[Field, string]> = fields().map((item) => {
    const toggleField = registerField(item.toggle);
    const field = registerField(item.field, {
      validation: requiredByToggle(item.toggle),
    });
    return [field, toggleField.getValue()];
  });

  useEffect(() => {
    registeredFields.forEach(([field, toggleField]) => {
      if (!toggleField) {
        field.clearError();
      }
    });
  }, [registeredFields]);

  return (
    <>
      {fields().map((row) => {
        const isToggled = Boolean(_fields[row.toggle].getValue());
        const isFieldError = _fields[row.field].hasError();

        return (
          <Flex alignItems="center" gap="sm">
            <NFCheckbox
              name={row.toggle}
              css={{ marginTop: '16px', height: '18px', width: '18px' }}
            />
            <Box css={{ flex: 1 }}>
              <NFTextField
                name={row.field}
                label={row.label}
                disabled={!isToggled}
              />
            </Box>
            {row.typeField && (
              <Box
                css={{
                  alignSelf: !isFieldError ? 'end' : 'unset',
                  flex: 0.5,
                }}
              >
                <NFSelectV2
                  options={AppBlockingService.getMatchKinds()}
                  triggerProps={{
                    // @ts-ignore - Typing
                    variant: 'input',
                    size: 'sm',
                  }}
                  name={row.typeField}
                  disabled={!isToggled}
                />
              </Box>
            )}
          </Flex>
        );
      })}
    </>
  );
};

const ApplicationBlocklistModal = (props) => {
  const { info, onHide } = props;
  const [isSaving, setIsSaving] = useState(false);
  const { toast } = useToast_UNSTABLE();
  const SIDEBAR_DOCKED_OFFSET = 256;
  const SIDEBAR_CLOSE_OFFSET = 78;
  const { sidebarDocked } = useContext(InterfaceContext);

  const onSave = async (form) => {
    const formLibraryItems = JSON.parse(form.library_items);
    const itemsToAdd = formLibraryItems.filter((item) => item.isCreate);
    const itemsToPatch = formLibraryItems.filter((item) => !item.isCreate);

    const toPatch = itemsToPatch.map(async (itemToPatch) => {
      const model = await transformFromApi(itemToPatch.item);
      const updatedModel = {
        ...model.data,
        general: {
          ...model.data.general,
          ...fields().reduce((a, c) => {
            const liFieldValues = model.data.general[c.field];

            if (!form[c.toggle]) {
              return {
                ...a,
                [c.field]: liFieldValues,
              };
            }

            const existingFieldIdx = liFieldValues.findIndex(
              (liField) => liField[c.dataKey] === form[c.field],
            );

            if (existingFieldIdx !== -1) {
              return {
                ...a,
                [c.field]: [
                  ...liFieldValues.slice(0, existingFieldIdx),
                  {
                    [c.dataKey]: form[c.field],
                    type: form[c.typeField],
                    id: '',
                  },
                  ...liFieldValues.slice(existingFieldIdx + 1),
                ],
              };
            }

            return {
              ...a,
              [c.field]: [
                ...liFieldValues,
                {
                  [c.dataKey]: form[c.field],
                  type: form[c.typeField],
                  id: '',
                },
              ],
            };
          }, {}),
        },
      };

      const toSend = await transformToApi(updatedModel as any);
      return appBlockingService.patch(itemToPatch.id, toSend);
    });

    const toAdd = itemsToAdd.map(async (itemToAdd) => {
      const model = {
        name: itemToAdd.id,
        active: true,
        selectedBlueprints: [],
        is_all_blueprints: false,
        general: {
          ...fields().reduce((a, c) => {
            return {
              ...a,
              [c.field]: [
                ...(form[c.toggle]
                  ? [
                      {
                        [c.dataKey]: form[c.field],
                        type: form[c.typeField],
                        id: 'just for now',
                      },
                    ]
                  : []),
              ],
            };
          }, {}),
          message_customization: initialState.general.message_customization,
        },
      };

      const toSend = await transformToApi(model as any);
      return appBlockingService.create(toSend);
    });

    setIsSaving(true);
    Promise.all([...toAdd, ...toPatch])
      .then((r) => {
        toast({
          title: i18n.t(
            `Successfully added app blocking rule to {count, plural, one {Library Item} other {Library Items}}. Don't forget to ensure they are assigned in your Blueprint(s).`,
            { count: r.length },
          ),
          duration: 3000,
          variant: 'success',
          style: {
            left: /* istanbul ignore next */ sidebarDocked
              ? `${SIDEBAR_DOCKED_OFFSET + 12}px`
              : `${SIDEBAR_CLOSE_OFFSET + 12}px`,
            bottom: '12px',
            position: 'absolute',
          },
        });
        onHide();
      })
      .catch(() => {
        toast({
          title: i18n.t('Something went wrong.'),
          variant: 'error',
          duration: 3000,
          style: {
            left: /* istanbul ignore next */ sidebarDocked
              ? `${SIDEBAR_DOCKED_OFFSET + 12}px`
              : `${SIDEBAR_CLOSE_OFFSET + 12}px`,
            bottom: '12px',
            position: 'absolute',
          },
        });
        setIsSaving(false);
      });
  };

  const title = <Heading size="3">{i18n.t('Add App Blocking rule')}</Heading>;

  const content = (
    <Flex flow="column" gap="sm" css={{ width: '512px' }}>
      <LibraryItemSection blueprintId={info?.blueprintId} />
      <Separator css={{ margin: '12px 0' }} />
      <RuleSection />
    </Flex>
  );
  const footer = (
    <Flex gap="md" justifyContent="end">
      <Button variant="subtle" onClick={onHide} disabled={isSaving}>
        {i18n.t('Cancel')}
      </Button>
      <NFSubmit label={i18n.t('Save')} disabled={isSaving} />
    </Flex>
  );

  const regexDevId = /\(([A-Z0-9]+)\)/g;
  const signedWith = info?.signed_with?.[0] || '';
  const match = regexDevId.exec(signedWith);
  const initialDeveloperId = match ? match[1] || '' : '';

  return (
    <NectarForm
      onSubmitValid={(v) => onSave(v)}
      initialValues={{
        by_process: info.process_name,
        by_path: info.path,
        by_bundle_id: info.bundle_identifier,
        by_developer_id: initialDeveloperId,
        by_process_active: info.process_name ? 'on' : '',
        by_path_active: info.path ? 'on' : '',
        by_bundle_id_active: info.bundle_identifier ? 'on' : '',
        by_developer_id_active: initialDeveloperId ? 'on' : '',
      }}
    >
      <Dialog
        isOpen
        closeOnEscape
        closeOnOutsideClick
        onOpenChange={onHide}
        title={title}
        content={content}
        footer={footer}
      />
    </NectarForm>
  );
};

export default ApplicationBlocklistModal;
