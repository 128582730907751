/* istanbul ignore file - legacy code, moved */
import NewStatusTab from 'features/library-items/common/new-status-tab';
import _, { get } from 'lodash';
import React, { useContext, useMemo } from 'react';
import './IPAPage.scss';

import { Banner } from '@kandji-inc/bumblebee';

import {
  Badge,
  Box,
  Chip,
  Flex,
  Heading,
  Icon,
  Text,
} from '@kandji-inc/nectar-ui';

import { blueprintTypes } from 'app/common/constants';
import { DataContext } from 'contexts/data';

import BannerTheme from 'theme/components/atoms/Banner';
import Loader from 'theme/components/atoms/Loader';
import Avatar from 'theme/components/molecules/Avatar';
import Basic from 'theme/components/molecules/Basic';
import { Form } from 'theme/components/molecules/Form';
import { IPAAssignmentBlueprint } from './IPAAssignmentBlueprint';
import { IPABannerStatus } from './IPABannerStatus';
import { IPABannerWarning } from './IPABannerWarning';
import { IPABreadcrumb } from './IPABreadcrumb';
import { IPADataContext } from './IPADataContext';
import { IPAFormSection } from './IPAFormSection';
import { IPAIconUpload } from './IPAIconUpload';
import { IPAMenu, IPAView } from './IPANavigation';
import { IPASettings } from './IPASettings';
import { IPATitle } from './IPATitle';
import { IPAToggle } from './IPAToggle';

const getDeviceFamily = (data, summary) => {
  const compatibilityToDeviceFamily = {
    runs_on_mac: 'Mac',
    runs_on_ipad: 'iPad',
    runs_on_iphone: 'iPhone',
  };

  // Map device compatibility specified in
  // the data to a list of device families
  let deviceFamilies = _.compact(
    _.map(
      _.keys(compatibilityToDeviceFamily),
      (key) => _.get(data, key) && compatibilityToDeviceFamily[key],
    ),
  );

  // No device info in data,
  // get device family from summary
  if (deviceFamilies.length === 0) {
    deviceFamilies = _.get(summary, 'device_family');
  }

  return deviceFamilies;
};

const getOSRequirement = (data, summary) => {
  // MacOS
  const macOSTemplate = `macOS ${
    _.get(data, 'data.minimum_os_version') || '10.11'
  }+`;
  if (_.get(data, 'runs_on_mac')) {
    return [macOSTemplate];
  }

  // iOS
  const iOSTemplate = `iOS ${_.get(data, 'data.minimum_os_version')}+`;
  const iOSCompatible = [
    'runs_on_ipad',
    'runs_on_iphone',
    'runs_on_ipod',
    'runs_on_tv',
    'runs_on_watch',
  ];
  if (_.some(iOSCompatible, (key) => _.get(data, key))) {
    return [iOSTemplate];
  }

  // No device info in data,
  // get OS requirement from summary
  return _.get(summary, 'os_requirement');
};

const menu = [
  {
    label: 'Settings',
    slug: 'root',
  },
  {
    label: 'Status',
    slug: 'status',
  },
  {
    label: 'Activity',
    slug: 'activity',
  },
];

const view = {
  root: () => (
    <IPASettings>
      <IPAFormSection
        title="Instructions"
        copy="Settings can not be configured for this Library Item."
      />
    </IPASettings>
  ),
  status: NewStatusTab,
};

export const IPAPage = () => {
  const {
    summary,
    type,
    item,
    data,
    fieldValidation,
    canEdit,
    isAutoApp,
    isOperatingSystem,
    isCustomScript,
    isLoading,
    isEditing,
    isExisting,
    isReadOnly,
    onSave,
    selectedBlueprints,
  } = useContext(IPADataContext);
  const {
    appData: { blueprints } = {},
  } = useContext(DataContext);
  const itemVersion = get(data, 'data.version');
  const image =
    get(data, 'app_icon') ||
    get(data, 'data.icon_url') ||
    get(data, 'data.icon');
  const title = get(data, 'name');
  const typeIcon = get(summary, 'icon');
  const typeName = get(summary, 'name');
  const typeHeadline = isAutoApp ? `${typeName} - ${title}` : typeName;
  const typeDescription =
    (isAutoApp && get(data, 'data.description')) || get(summary, 'description');
  const publisher =
    get(data, 'data.publisher') || get(data, 'data.author', summary.publisher);
  const deviceFamilies = getDeviceFamily(data, summary);
  const osRequirements = getOSRequirement(data, summary);
  const loopDeviceFamily = deviceFamilies.map((deviceFamily) => (
    <li key={`device-family-${deviceFamily}`} className="chip">
      <span>{deviceFamily}</span>
    </li>
  ));
  const loopOSRequirement = osRequirements.map((osRequirement) => (
    <li key={`os-requirement-${osRequirement}`}>
      <span>{osRequirement}</span>
    </li>
  ));

  const filteredMenu = useMemo(() => {
    if (isExisting) {
      return menu;
    }

    // for new lib item: display only Settings menu
    return menu.filter((menuItem) => menuItem.slug === 'root');
  }, [isExisting, menu]);

  const selectedAssignmentMaps = blueprints.filter(
    ({ id, type }) =>
      selectedBlueprints.includes(id) && type === blueprintTypes.flow,
  );

  return data && !isLoading ? (
    <Form
      initialValues={data}
      fieldValidation={fieldValidation}
      onSubmit={canEdit && isEditing ? onSave : () => {}}
    >
      <Basic element="aside" section="path" className="library-top-section">
        <IPABreadcrumb
          style={{
            margin: '24px 0',
          }}
          root="library"
          current={title || typeName}
        />
      </Basic>
      <Basic
        element="header"
        section="information"
        className="library library-below-breadcrumb"
      >
        <IPABannerStatus
          text={`${typeHeadline} is inactive. The applied settings will not affect the devices.`}
          type={BannerTheme.types.NARROW_STATUS}
        />
        <div className="paper">
          <header className="division">
            <div className="node">
              <div className="row gutter-40 gutter-60-horizontal">
                <div className="col">
                  <div className="field">
                    {summary.iconEditable && (
                      <IPAIconUpload
                        name="settings_app_icon"
                        name2="settings_self_service_data_iconUrl"
                        style={{
                          marginRight: 'var(--b-gap1)',
                        }}
                        isDisabled={!isEditing || isLoading || isReadOnly}
                        defaultIcon={summary.getDefaultIcon(data)}
                      />
                    )}
                    {!summary.iconEditable && (
                      <Avatar
                        type={type}
                        title={title}
                        image={image}
                        icon={typeIcon}
                      />
                    )}
                    <IPATitle name="name" />
                  </div>
                </div>
                <div className="col-auto">
                  <div className="field">
                    <IPAToggle name="active" disabled={isReadOnly} status />
                  </div>
                </div>
              </div>
            </div>
          </header>
          <section className="division divide">
            <div className="node">
              <div className="row gutter-40 gutter-60-horizontal">
                <div className="col-12 col-xl">
                  <div className="detail">
                    <h2 className="headline">{typeHeadline}</h2>
                    <p className="description regular-text">
                      {typeDescription}
                    </p>
                    <IPABannerWarning />
                  </div>
                </div>
                <div className="col-12 col-xl">
                  <div className="detail library-details">
                    <div className="detail-column">
                      <div className="detail-name">Publisher:</div>
                      {(isAutoApp || isOperatingSystem) && (
                        <div className="detail-name">Current Version:</div>
                      )}
                      <div className="detail-name">Device family:</div>
                      <div className="detail-name">OS requirement:</div>
                    </div>
                    <div className="detail-column">
                      <div className="detail-value">{publisher}</div>
                      {(isAutoApp || isOperatingSystem) && (
                        <div className="detail-value">{itemVersion}</div>
                      )}
                      <ul className="detail-list chip-list">
                        {loopDeviceFamily}
                      </ul>
                      <ul className="detail-list list-reset">
                        {loopOSRequirement}
                      </ul>
                    </div>
                  </div>
                </div>
                {isCustomScript && (
                  <div className="col-12">
                    <Banner
                      theme="warning"
                      kind="block"
                      className="custom-script--banner"
                    >
                      <div className="b-flex b-flex-align-center b-flex-justify-start">
                        <span>
                          WARNING: This is a very powerful feature. All scripts
                          run as root. Test your scripts extensively before
                          deploying to production machines.
                        </span>
                      </div>
                    </Banner>
                  </div>
                )}
              </div>
            </div>
          </section>
          <footer className="division">
            <div className="node">
              <IPAFormSection title="Assignment" sticky={false}>
                <Flex
                  flow="column"
                  gap="lg"
                  css={{
                    marginBottom: '$2',
                  }}
                >
                  <Flex flow="row" alignItems="center">
                    <Icon
                      name="sitemap"
                      size="sm"
                      color="var(--colors-neutral80)"
                    />
                    <Heading
                      size="5"
                      css={{
                        marginLeft: '$1',
                        marginRight: '$3',
                        fontWeight: '$medium',
                      }}
                    >
                      Assignment Maps
                    </Heading>

                    <Badge color="blue" icon="sparkles">
                      New
                    </Badge>
                  </Flex>

                  {!selectedAssignmentMaps.length && (
                    <Flex flow="row" alignItems="end" gap="sm">
                      <Text
                        css={{
                          color: '$neutral70',
                        }}
                      >
                        Not used in any Assignment Maps.
                      </Text>

                      <a
                        href="https://support.kandji.io/kb/assignment-maps-overview"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        <Text
                          variant="primary"
                          size="1"
                          css={{
                            fontWeight: '$medium',
                            textDecoration: 'none',
                            '&:hover': {
                              textDecoration: 'underline',
                            },
                          }}
                        >
                          Learn more
                        </Text>
                      </a>
                    </Flex>
                  )}

                  {selectedAssignmentMaps.length > 0 && (
                    <div
                      className="field"
                      style={{
                        paddingBottom: '10px',
                      }}
                    >
                      <div className="row gutter-60-horizontal">
                        <div className="col-2">
                          <span
                            className="field-label"
                            style={{
                              lineHeight: '20px',
                            }}
                          >
                            Blueprint
                          </span>
                        </div>
                        <div className="col">
                          <Flex gap="sm" wrap="wrap">
                            {selectedAssignmentMaps.map(({ name }) => (
                              <Chip
                                label={name}
                                key={name}
                                css={{
                                  minWidth: 0,
                                }}
                              />
                            ))}
                          </Flex>
                        </div>
                      </div>
                    </div>
                  )}

                  <Box
                    wFull
                    css={{
                      backgroundColor: '$neutral30',
                      height: '1px',
                    }}
                  />

                  <Flex flow="row" alignItems="center">
                    <Icon
                      name="memo-pad"
                      size="sm"
                      color="var(--colors-neutral80)"
                    />
                    <Heading
                      size="5"
                      css={{
                        marginLeft: '$1',
                        marginRight: '$3',
                        fontWeight: '$medium',
                      }}
                    >
                      Classic Blueprints
                    </Heading>
                  </Flex>
                </Flex>

                <div className="field">
                  <div className="row gutter-60-horizontal">
                    <div className="col-2">
                      <span className="field-label">Blueprint</span>
                    </div>
                    <div className="col">
                      <IPAAssignmentBlueprint />
                    </div>
                  </div>
                </div>
              </IPAFormSection>
            </div>
          </footer>
        </div>
      </Basic>
      <Basic element="aside" section="navigation">
        <nav className={`remote remote-${isExisting ? item : 'add'}`}>
          <IPAMenu menu={filteredMenu} view={view} />
        </nav>
      </Basic>
      <IPAView menu={filteredMenu} view={view} />
    </Form>
  ) : (
    <Loader type="line" />
  );
};
