import { Flex, Loader, styled } from '@kandji-inc/nectar-ui';
import { useEffect, useState } from 'react';
import { Pagination, usePagination } from 'src/components/ui';
import type { DetectionDateFilterFields } from 'src/features/edr/common/common.types';
import { NoResultsFound } from 'src/features/edr/vulnerability/common/table/no-results-found';
import { DEFAULT_DETECTION_DATE_FILTER } from 'src/features/edr/vulnerability/constants';
import { useGetVulnerabilityDevices } from 'src/features/edr/vulnerability/hooks/use-get-vulnerability-devices';
import type {
  SidePanelDeviceTabSortTypes,
  Vulnerability,
} from 'src/features/edr/vulnerability/vulnerability.types';
import { transformVulnerableDevicesFilterToApi } from '../../../transformers/transformToApi';
import { DeviceCard } from './device-card';
import { FilterBar } from './filter-bar';

const StickySeparator = styled(Flex, {
  height: 1,
  bg: '$neutral20',
  position: 'sticky',
  top: 109,
  zIndex: 1,
});

const DEFAULT_SORT = '-last_detection_date';

const DEFAULT_FILTER = {
  term: '',
  detectionDate: DEFAULT_DETECTION_DATE_FILTER,
  blueprints: [],
};

const DeviceList = (props: { selectedVulnerability: Vulnerability }) => {
  const { selectedVulnerability } = props;

  const itemsPerPageOptions = [10, 25, 50, 75, 100];

  const [expandedDeviceCards, setExpandedDeviceCards] = useState<string[]>([]);
  const { paginationState: pagination, setPagination } = usePagination({
    defaultPageSize: itemsPerPageOptions[0],
  });

  const [sort, setSort] = useState<SidePanelDeviceTabSortTypes>(DEFAULT_SORT);

  const [filter, setFilter] = useState<{
    term: string;
    detectionDate: DetectionDateFilterFields;
    blueprints: string[];
  }>(DEFAULT_FILTER);

  useEffect(
    /* istanbul ignore next */ () => {
      setPagination({ ...pagination, pageIndex: 0 });
    },
    [filter],
  );

  const { devices, count, isLoading } = useGetVulnerabilityDevices(
    selectedVulnerability.cve_id,
    [pagination, sort, filter],
    {
      size: pagination.pageSize,
      page: pagination.pageIndex + 1,
      sort_by: sort,
      search: filter.term,
      filter: transformVulnerableDevicesFilterToApi(filter),
    },
  );

  /* istanbul ignore next */
  const hasFilterApplied =
    filter.term || filter.detectionDate?.value || filter.blueprints.length > 0;
  const hasDevices = devices?.length > 0;

  return (
    <Flex hFull flow="column" pb3 css={{ gap: '10px' }}>
      <StickySeparator />
      <Flex
        flow="column"
        css={{
          mt: -11,
          pt: 10,
          pb: 10,
          bg: '$neutral0',
          position: 'sticky',
          top: 27,
          zIndex: 2,
          marginInline: -20,
          paddingInline: 20,
        }}
      >
        <FilterBar
          cveId={selectedVulnerability.cve_id}
          appName={selectedVulnerability.app_name}
          filter={filter}
          setFilter={setFilter}
          defaultFilter={DEFAULT_FILTER}
          sort={sort}
          setSort={setSort}
        />
      </Flex>

      {isLoading && (
        <Flex
          wFull
          hFull
          flow="column"
          alignItems="center"
          justifyContent="center"
          gap="sm"
          data-testid="loading-devices"
        >
          <Loader size="lg" />
        </Flex>
      )}

      {!isLoading && hasDevices && (
        <Flex flow="column" css={{ gap: '10px', mt: -10 }}>
          {devices?.map((device) => (
            <DeviceCard
              key={device.device_id}
              device={device}
              searchTerm={filter.term}
              isExpanded={expandedDeviceCards.includes(device.device_id)}
              setIsExpanded={(value) =>
                setExpandedDeviceCards(
                  value
                    ? [...expandedDeviceCards, device.device_id]
                    : expandedDeviceCards.filter(
                        (id) => id !== device.device_id,
                      ),
                )
              }
            />
          ))}
        </Flex>
      )}

      {!isLoading && !hasDevices && hasFilterApplied && <NoResultsFound />}

      <Flex
        wFull
        css={{
          position: 'absolute',
          bottom: 0,
          marginInline: '-20px',
          paddingInline: '20px',
          padding: '$3 $4',
          borderTop: '1px solid $neutral20',
          backgroundColor: '$neutral0',
        }}
      >
        <Pagination
          currentPage={pagination.pageIndex + 1}
          totalItems={count}
          itemsPerPageOptions={itemsPerPageOptions}
          itemsPerPage={pagination.pageSize}
          onPageChange={
            /* istanbul ignore next */ (page) =>
              setPagination({ ...pagination, pageIndex: page - 1 })
          }
          onItemsPerPageChange={
            /*  istanbul ignore next */ (itemsPerPage) => {
              setPagination({
                ...pagination,
                pageIndex: 0,
                pageSize: itemsPerPage,
              });
            }
          }
        />
      </Flex>
    </Flex>
  );
};

export { DeviceList };
