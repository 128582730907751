import React from 'react';

import { Setting } from 'features/library-items/template';
import { i18n } from 'src/i18n';

import ServerCertificateNames from './components/server-certificate-names';
import TrustedCertificates from './components/trusted-certificates';

const TrustCard = ({ setting, update, isDisabled, validationDep }) => (
  <Setting.Card>
    <Setting.Header>
      <h3 className="b-h3">{i18n.t(`Certificate trust`)}</h3>
    </Setting.Header>
    <Setting.SubHeader>
      <p className="b-txt">
        {i18n.t(`Configure certificate trust settings.`)}{' '}
        <a
          href="https://support.kandji.io/kb/configure-the-wi-fi-library-item"
          rel="noopener noreferrer"
          target="_blank"
          className="b-alink"
        >
          {i18n.t(`Learn more`)}
        </a>
      </p>
    </Setting.SubHeader>
    <Setting.Rows>
      <TrustedCertificates
        setting={setting}
        update={update}
        isDisabled={isDisabled}
        validationDep={validationDep}
      />
      <ServerCertificateNames
        setting={setting}
        update={update}
        isDisabled={isDisabled}
        validationDep={validationDep}
      />
    </Setting.Rows>
  </Setting.Card>
);

export default React.memo(TrustCard);
