import {
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { api } from 'app/api/base';
import { URL_BLUEPRINTS } from 'app/api/urls';
import { AccountContext } from 'contexts/account';
import { EnvironmentContext } from 'contexts/environment';
import { integrationsBackendService } from 'features/integrations/data-service/integrations-backend-service';
import { newIntegrationsService } from 'features/integrations/data-service/new-integrations-service';
import { useFeature as useIntegrationsFeature } from 'features/integrations/hooks';
import { applyFiltersAndOrdering as filterIntegrations } from 'features/integrations/overview/calc';
import { newLibraryItemService } from 'features/library-items/data-service/library-item/new-library-item-service';
import { transformLibraryItems } from 'features/library-items/library/api/transformer';
import { getFilteredItems } from 'features/library-items/library/common';
import { libraryItemsOrder } from 'features/library-items/library/library';
import { prismService } from 'features/visibility/prism/data-service/prism-service';
import * as React from 'react';

const SEARCH_LIMIT = 100;

const getBlueprints = () => api(URL_BLUEPRINTS).get({});

const DEVICE_FAMILIES = ['Mac', 'iPhone', 'iPad', 'Apple TV'];

const deviceColumns = [
  { name: 'device__name', category: '' },
  { name: 'device_id', category: 'device_information' },
  { name: 'asset_tag', category: '' },
  { name: 'device__family', category: '' },
  { name: 'serial_number', category: '' },
  { name: 'device__user_name', category: '' },
  { name: 'device__user_email', category: '' },
  { name: 'model_id', category: '' },
];

export const useUniversalSearchQueries = ({
  search,
  deviceIds,
  limit = true,
}: { search: string; deviceIds?: string[]; limit?: boolean }) => {
  const environment = React.useContext(EnvironmentContext);
  const account = React.useContext(AccountContext);
  const features = account?.currentCompany?.feature_configuration as any;
  const isThreatEnabled = features.edr?.enabled;
  const searchTerm = search.trim().toLowerCase();
  const { mapCategoryType } = useIntegrationsFeature();

  const queries = [
    {
      queryKey: [
        'universal-search-devices',
        searchTerm,
        (deviceIds || []).join(','),
      ],
      queryFn: () =>
        prismService.getViewData(
          { limit: SEARCH_LIMIT, offset: 0 },
          deviceColumns,
          deviceIds
            ? { 'device_information.device_id': { like: deviceIds } }
            : {},
          searchTerm,
        ),
      enabled: searchTerm.length > 0 || (deviceIds && deviceIds.length > 0),
    },
    {
      queryKey: ['universal-search-blueprints'],
      queryFn: () => getBlueprints(),
    },
    {
      queryKey: ['universal-search-library-items'],
      queryFn: () => newLibraryItemService.v2List(),
    },
    {
      queryKey: ['universal-search-integrations-main'],
      queryFn: () => newIntegrationsService.listIntegrations(''),
    },
    {
      queryKey: ['universal-search-integrations-backend'],
      queryFn: () =>
        integrationsBackendService.getIntegrations({ page: '0', size: '100' }),
    },
  ];

  const results = useQueries({ queries });

  const isPending = results.some((result) => result.isPending);

  const devices = results[0].data?.data ?? [];

  const blueprints = (results[1].data?.data ?? []).filter(({ name }) =>
    name.toLowerCase().includes(searchTerm),
  );

  const libItemsOrder = React.useMemo(() => {
    return libraryItemsOrder(isThreatEnabled);
  }, [isThreatEnabled]);

  const transformedLibraryItems = transformLibraryItems(
    results[2].data?.data ?? [],
    { environment, account },
    true,
  );
  const filteredLibraryItems = getFilteredItems(
    transformedLibraryItems,
    { searchTerm, devices: DEVICE_FAMILIES, category: '' },
    libItemsOrder,
    (i) => i.defaultConfiguration.isHidden({ account, environment }),
  );
  const libraryItems = filteredLibraryItems.reduce((acc, { data }) => {
    return [...acc, ...data];
  }, []);

  const integrationsFetching = results[3].isFetching || results[4].isFetching;
  const integrationsMain = results[3].data?.data?.results ?? [];
  const integrationsBackend = results[4].data?.data?.items ?? [];
  const integrations = integrationsFetching
    ? []
    : filterIntegrations(
        [...integrationsMain, ...integrationsBackend].map((integration) => {
          const category = Object.entries(mapCategoryType).find(([, types]) =>
            (types as string[]).includes(integration?.type),
          )?.[0];
          return category ? { ...integration, category } : null;
        }),
        { search },
      );

  return {
    data: {
      devices,
      blueprints: limit ? blueprints.slice(0, SEARCH_LIMIT) : blueprints,
      libraryItems: limit ? libraryItems.slice(0, SEARCH_LIMIT) : libraryItems,
      integrations: limit ? integrations.slice(0, SEARCH_LIMIT) : integrations,
    },
    isPending,
  };
};

export const useUniversalSearchRecents = () => {
  const { data: recentData, isPending: recentsPending } = useQuery({
    queryKey: ['universal-search-recents'],
    queryFn: () => prismService.getRecentSearches(),
  });
  const deviceIds = recentData
    ? recentData.filter((r) => r.type === 'devices').map((r) => r.resource_id)
    : [];
  const { isPending, data } = useUniversalSearchQueries({
    search: '',
    deviceIds,
    limit: false,
  });
  if (isPending || recentsPending) {
    return { isPending, data: [] };
  }
  return {
    isPending,
    data: recentData
      ? recentData
          .map((recent) => {
            const record = data[recent.type].find((searchResult) => {
              const idPath = getIdPath(recent);
              return searchResult[idPath] === recent.resource_id;
            });
            return { record, type: recent.type };
          })
          .filter((r) => r.record != null)
      : [],
  };
};

export const useCreateRecentSearch = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: prismService.createRecentSearch,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['universal-search-recents'],
      });
    },
  });
};

const getIdPath = (recent) => {
  if (recent.type === 'devices') {
    return 'device_information.device_id';
  }
  if (recent.type === 'integrations') {
    return 'uuid';
  }
  return 'id';
};
