import {
  Banner,
  Button,
  Checkbox,
  Select,
  TextInput,
  mapInvalidIndexToField,
  setClass,
  useInvalidations,
  useRefs,
} from '@kandji-inc/bumblebee';
import React, { useCallback, useRef, useEffect } from 'react';
import uuidv4 from 'uuid/v4';
import './provision.css';

import { Setting } from 'features/library-items/template';
import featureFlags from 'src/config/feature-flags';
import { i18n } from 'src/i18n';
import getOptionFor from '../../get-option';
import usePreviousValue from '../../use-previous-value';

const MAX_GROUP_COUNT = 20;
const MAX_LOCAL_USER_COUNT = 20;
const MAX_INPUT_COUNT_UNTIL_SCROLL = 5;

const getFieldsToValidate = () => {
  const fields = [];

  for (let i = 0; i < 20; i++) {
    fields.push(`group-${i}`);
    fields.push(`permission-${i}`);
  }
  for (let i = 0; i < 20; i++) {
    fields.push(`exclude-user-${i}`);
  }

  return fields;
};

const Provision = (props) => {
  const { setting, update, isDisabled, validationDep, isSubmitted } = props;
  const permissionsBasedOnGroupLastInputRef = useRef(null);
  const permissionsBasedOnGroupScrollContainerRef = useRef(null);
  const localUsersExcludedLastInputRef = useRef(null);
  const localUsersExcludedScrollContainerRef = useRef(null);
  const prevSetting = usePreviousValue(setting);
  const fieldsToValidate = getFieldsToValidate();
  const refs = useRefs(fieldsToValidate.length);
  const { invalidations, onInvalidate } = useInvalidations({
    inputs: fieldsToValidate.length,
  });
  const invalidationsMap = mapInvalidIndexToField(
    invalidations,
    fieldsToValidate,
    refs,
  );
  useEffect(() => {
    update('invalidationsMap', invalidationsMap);
  }, [...invalidations]);

  useEffect(() => {
    for (let i = 0; i < 40; i++) {
      onInvalidate(i)(false);
    }
    const isRequired = setting.userPermissionType === 'based_on_user_group';
    setting.permissionsBasedOnGroup?.forEach((v, i) => {
      onInvalidate(2 * i)(isRequired && !v.group && 'Required');
      onInvalidate(2 * i + 1)(isRequired && !v.permission && 'Required');
    });
  }, [setting.permissionsBasedOnGroup, setting.userPermissionType]);
  useEffect(() => {
    const isRequired =
      setting.mergeOption === 'always' && setting.isExcludeLocalUsers;
    for (let i = 40; i < 60; i++) {
      onInvalidate(i)(false);
    }

    setting.localUsersExcluded?.forEach((v, i) => {
      onInvalidate(40 + i)(isRequired && !v.value && 'Required');
    });
  }, [
    setting.mergeOption,
    setting.isExcludeLocalUsers,
    setting.localUsersExcluded,
  ]);
  const trigger = ['onBlur', validationDep];

  const getLastInputRefProps = (
    ref,
    index,
    pos,
    offset = MAX_INPUT_COUNT_UNTIL_SCROLL,
  ) => {
    if (index === pos && index > offset - 1) {
      return {
        ref,
      };
    }
    return {};
  };

  useEffect(() => {
    const handleScrollToLastPermissionsSelect = () => {
      const selectNode =
        permissionsBasedOnGroupScrollContainerRef.current?.querySelector(
          '.b-select__menu',
        );

      if (selectNode && permissionsBasedOnGroupScrollContainerRef.current) {
        const { bottom: selectBottom, height: selectHeight } =
          selectNode.getBoundingClientRect();
        const inputGroup = selectNode.closest(
          '.k-klogin-permission-based-group-inputs',
        );
        const inputGroupTop = inputGroup.offsetTop;
        const { bottom: scrollBottom } =
          permissionsBasedOnGroupScrollContainerRef.current.getBoundingClientRect();

        const containerHeight =
          permissionsBasedOnGroupScrollContainerRef.current.offsetHeight;
        if (selectBottom >= scrollBottom) {
          const EXTRA_PADDING = 8;
          const top =
            inputGroupTop + (selectHeight - containerHeight + EXTRA_PADDING);

          permissionsBasedOnGroupScrollContainerRef.current.scrollTo({
            top,
            behavior: 'smooth',
          });
        }
      }
    };

    if (permissionsBasedOnGroupScrollContainerRef.current) {
      permissionsBasedOnGroupScrollContainerRef.current.addEventListener(
        'mouseup',
        handleScrollToLastPermissionsSelect,
      );
    }

    return () => {
      permissionsBasedOnGroupScrollContainerRef.current?.removeEventListener(
        'mouseup',
        handleScrollToLastPermissionsSelect,
      );
    };
  }, [setting.userPermissionType]);

  useEffect(() => {
    if (
      permissionsBasedOnGroupLastInputRef?.current &&
      permissionsBasedOnGroupScrollContainerRef?.current &&
      prevSetting?.permissionsBasedOnGroup?.length &&
      setting.permissionsBasedOnGroup?.length &&
      setting.permissionsBasedOnGroup.length > MAX_INPUT_COUNT_UNTIL_SCROLL
    ) {
      const isAddGroup =
        setting.permissionsBasedOnGroup.length >
        prevSetting.permissionsBasedOnGroup.length;

      if (isAddGroup) {
        const top = permissionsBasedOnGroupLastInputRef.current.offsetTop;
        permissionsBasedOnGroupScrollContainerRef.current.scrollTo({
          top,
          behavior: 'smooth',
        });
      }
    }
  }, [setting.permissionsBasedOnGroup.length]);

  useEffect(() => {
    if (
      localUsersExcludedLastInputRef?.current &&
      localUsersExcludedScrollContainerRef?.current &&
      prevSetting?.localUsersExcluded?.length &&
      setting.localUsersExcluded?.length &&
      setting.localUsersExcluded.length > MAX_INPUT_COUNT_UNTIL_SCROLL
    ) {
      const isAddUser =
        setting.localUsersExcluded.length >
        prevSetting.localUsersExcluded.length;

      if (isAddUser) {
        const top = localUsersExcludedLastInputRef.current.offsetTop;
        localUsersExcludedScrollContainerRef.current.scrollTo({
          top,
          behavior: 'smooth',
        });
      }
    }
  }, [setting.localUsersExcluded.length]);

  const addUser = useCallback(() => {
    const newUser = {
      value: '',
      uuid: uuidv4(),
    };
    update('localUsersExcluded', (p) => {
      const copy = [...p];
      copy.push(newUser);
      return copy;
    });
  }, []);

  const permissionOptions = [
    {
      value: 'admin',
      label: i18n.t('Administrator'),
    },
    {
      value: 'standard',
      label: i18n.t('Standard user'),
    },
    {
      value: 'based_on_user_group',
      label: i18n.t('Specify per identity provider group'),
    },
  ];

  const localUserTypesOptions = [
    {
      value: 'local_admin',
      label: i18n.t('Administrator'),
    },
    {
      value: 'standard',
      label: i18n.t('Standard user'),
    },
  ];

  const mergeOptions = [
    {
      value: 'never',
      label: i18n.t('Never'),
    },
    {
      value: 'local_user_match',
      label: i18n.t('If a local username matches'),
    },
    {
      value: 'always',
      label: i18n.t('Always'),
    },
  ];

  const removeUser = (v) => {
    update('localUsersExcluded', (p) => p.filter((pg) => pg.uuid !== v.uuid));
  };

  const addGroup = useCallback(() => {
    const newGroup = {
      group: '',
      permission: '',
      uuid: uuidv4(),
    };
    update('permissionsBasedOnGroup', (p) => {
      const copy = [...p];
      copy.push(newGroup);
      return copy;
    });
  }, []);

  const removeGroup = (v) => {
    update('permissionsBasedOnGroup', (p) =>
      p.filter((pg) => pg.uuid !== v.uuid),
    );
  };

  return (
    <Setting.Card>
      <Setting.Header>
        <h3 className="b-h3">{i18n.t(`User provisioning`)}</h3>
      </Setting.Header>
      <Setting.SubHeader>
        <p className="b-txt">
          {i18n.t(`Configure user provisioning settings to be applied upon initial
          login.`)}
          &nbsp;
          <a
            href="https://support.kandji.io/kb/configure-the-passport-library-item"
            rel="noopener noreferrer"
            target="_blank"
            className="b-alink"
          >
            {i18n.t(`Learn more...`)}
          </a>
        </p>
      </Setting.SubHeader>
      <Setting.Rows>
        <Setting.Row>
          <Setting.Title>
            <p className="b-txt">{i18n.t(`User account type`)}</p>
          </Setting.Title>
          <Setting.Helpers className="b-txt-light">
            {i18n.t(`Select the user account type or specify that permissions should be
            based on membership in an IdP group.`)}
          </Setting.Helpers>
          <Setting.Controls>
            <Select
              disabled={isDisabled}
              options={permissionOptions}
              compact
              onChange={(v) => {
                if (setting.userPermissionType === 'based_on_user_group') {
                  update('permissionsBasedOnGroup', [
                    {
                      uuid: uuidv4(),
                      group: '',
                      permission: '',
                    },
                  ]);
                }
                update('userPermissionType', v.value);
              }}
              value={getOptionFor(
                setting.userPermissionType,
                permissionOptions,
              )}
            />
          </Setting.Controls>
          {setting.userPermissionType === 'based_on_user_group' && (
            <Setting.SecondaryControls>
              <Setting.SecondaryControlsRow>
                <Setting.Title>
                  <p className="b-txt">{i18n.t(`Default account type`)}</p>
                </Setting.Title>
                <Setting.Helpers>
                  <p className="b-txt-light b-mb1">
                    {i18n.t(`Select the type of account a user will be assigned unless
                    specified otherwise below.`)}
                  </p>
                  <Banner theme="info" kind="block">
                    <p className="mb-2">
                      {i18n.t(`If a user's IdP group membership returns both
                      administrator and standard account types, the user will be
                      designated an administrator.`)}
                    </p>
                  </Banner>
                </Setting.Helpers>
                <Setting.Controls>
                  <Select
                    compact
                    inputId="default_account_type_dropdown"
                    options={localUserTypesOptions}
                    onChange={(v) => update('excludedUserGroupType', v.value)}
                    value={getOptionFor(
                      setting.excludedUserGroupType,
                      localUserTypesOptions,
                    )}
                    disabled={isDisabled}
                  />
                </Setting.Controls>
              </Setting.SecondaryControlsRow>
              <Setting.SecondaryControlsRow>
                <div>
                  <div className="k-klogin-group-grid b-mb1">
                    <p className="b-txt">{i18n.t(`Identity provider group`)}</p>
                    <p className="b-txt">{i18n.t(`Account type`)}</p>
                  </div>
                  <div
                    className={setClass(
                      't',
                      setting.permissionsBasedOnGroup?.length >
                        MAX_INPUT_COUNT_UNTIL_SCROLL
                        ? 'k-klogin-accounts-scrollable --wide --permissions-based-on-group'
                        : '',
                    )}
                    ref={permissionsBasedOnGroupScrollContainerRef}
                  >
                    {setting.permissionsBasedOnGroup.map((u, i, l) => {
                      const isScrollAndLastTwoInputs =
                        i >= MAX_INPUT_COUNT_UNTIL_SCROLL - 1 &&
                        i >= l.length - 2 &&
                        l.length > MAX_INPUT_COUNT_UNTIL_SCROLL;

                      return (
                        <div
                          key={u.uuid}
                          className={setClass(
                            'k-klogin-group-grid',
                            'k-klogin-permission-based-group-inputs',
                            isScrollAndLastTwoInputs
                              ? 'k-klogin-accounts-last-select-scroll-spacing'
                              : '',
                            i !== setting.permissionsBasedOnGroup.length - 1
                              ? 'b-mb2'
                              : '',
                          )}
                          {...getLastInputRefProps(
                            permissionsBasedOnGroupLastInputRef,
                            i,
                            l.length - 1,
                          )}
                          ref={(v) => {
                            if (refs[2 * i]) {
                              refs[2 * i].current = v;
                              refs[2 * i + 1].current = v;
                            }
                          }}
                        >
                          <TextInput
                            value={u.group}
                            onChange={(e) => {
                              e.persist();
                              update('permissionsBasedOnGroup', (p) => {
                                const copy = [...p];
                                const idx = copy.findIndex(
                                  (c) => c.uuid === u.uuid,
                                );
                                copy[idx].group = e.target.value;
                                return copy;
                              });
                            }}
                            disabled={isDisabled}
                            placeholder={i18n.t('Enter the group name')}
                            validator={(v) => [
                              {
                                message: i18n.t('Required'),
                                invalid: () => !v,
                                trigger,
                              },
                            ]}
                          />
                          <Select
                            placeholder={i18n.t('Select account type')}
                            options={localUserTypesOptions}
                            value={getOptionFor(
                              u.permission,
                              localUserTypesOptions,
                            )}
                            className="k-klogin-permission"
                            defaultValue=""
                            onChange={(v) => {
                              update('permissionsBasedOnGroup', (p) => {
                                const copy = [...p];
                                const idx = copy.findIndex(
                                  (c) => c.uuid === u.uuid,
                                );
                                copy[idx].permission = v.value;
                                return copy;
                              });
                            }}
                            disabled={isDisabled}
                            errorText={
                              isSubmitted && !u.permission && i18n.t('Required')
                            }
                          />
                          {setting.permissionsBasedOnGroup.length > 1 ? (
                            <Button
                              theme="error"
                              kind="link"
                              icon="trash-can"
                              onClick={() => removeGroup(u)}
                              disabled={isDisabled}
                            />
                          ) : null}
                        </div>
                      );
                    })}
                  </div>
                  <Button
                    disabled={
                      (setting.permissionsBasedOnGroup.length === 1 &&
                        !setting.permissionsBasedOnGroup[0].group) ||
                      !setting.permissionsBasedOnGroup[0].permission ||
                      setting.permissionsBasedOnGroup.length ===
                        MAX_GROUP_COUNT ||
                      isDisabled
                    }
                    className="b-mt2"
                    kind="link"
                    icon="circle-plus"
                    onClick={addGroup}
                  >
                    {i18n.t(`Add group`)}
                  </Button>
                </div>
              </Setting.SecondaryControlsRow>
            </Setting.SecondaryControls>
          )}
        </Setting.Row>

        <Setting.Row>
          <Setting.Title>
            <p className="b-txt">{i18n.t(`Ask to merge with a local user`)}</p>
          </Setting.Title>
          <Setting.Helpers className="b-txt-light">
            {i18n.t(`Select whether the end user will be offered the option to merge with
            an existing local user. This option will be shown just once.`)}
          </Setting.Helpers>
          <Setting.Controls>
            <Select
              inputId="provision-merge-options"
              disabled={isDisabled}
              options={mergeOptions}
              compact
              onChange={(v) => {
                if (v.value !== 'always') {
                  update('isExcludeLocalUsers', false);
                  update('disableLocalUserCreation', false);
                  update('showHiddenUsers', false);
                  update('localUsersExcluded', [{ uuid: uuidv4(), value: '' }]);
                }
                update('mergeOption', v.value);
              }}
              value={getOptionFor(setting.mergeOption, mergeOptions)}
            />
          </Setting.Controls>
          {setting.mergeOption === 'always' && (
            <Setting.SecondaryControls>
              {featureFlags.getFlag(
                'ag_05092023_Passport-showHiddenAccounts',
              ) && (
                <Setting.SecondaryControlsRow>
                  <div className="b-grid-controls" style={{ margin: 0 }}>
                    <Checkbox
                      testId="hidden-users-merge-option"
                      disabled={isDisabled}
                      label={i18n.t('Show hidden users')}
                      checked={setting.showHiddenUsers}
                      onChange={() => {
                        update('showHiddenUsers', (p) => !p);
                      }}
                    />
                  </div>
                  <div className="--last">
                    <p className="b-txt-light">
                      {i18n.t(
                        `Hidden accounts will be shown in the list of local users.`,
                      )}
                    </p>
                  </div>
                </Setting.SecondaryControlsRow>
              )}
              <Setting.SecondaryControlsRow>
                <div className="b-grid-controls" style={{ margin: 0 }}>
                  <Checkbox
                    disabled={isDisabled}
                    label={i18n.t('Migrate existing account only')}
                    checked={setting.disableLocalUserCreation}
                    onChange={() => {
                      update('disableLocalUserCreation', (p) => !p);
                    }}
                  />
                </div>
                <div className="--last">
                  <p className="b-txt-light">
                    {i18n.t(`The user will be presented with the option to migrate an
                    existing local user account only.`)}
                  </p>
                </div>
              </Setting.SecondaryControlsRow>
              <Setting.SecondaryControlsRow>
                <div className="b-grid-controls" style={{ margin: 0 }}>
                  <Checkbox
                    disabled={isDisabled}
                    label={i18n.t('Exclude local users')}
                    checked={setting.isExcludeLocalUsers}
                    onChange={() => {
                      update('isExcludeLocalUsers', (p) => !p);
                    }}
                  />
                </div>
                <div className="--last">
                  <p className="b-txt-light">
                    {i18n.t(`Exclude specific accounts from the list of local users to
                    merge with during the user’s initial login.`)}
                  </p>
                </div>
                <div>
                  {setting.isExcludeLocalUsers && (
                    <Setting.SecondaryControls>
                      <div>
                        <div
                          className="k-klogin-accounts-scrollable"
                          ref={localUsersExcludedScrollContainerRef}
                        >
                          {setting.localUsersExcluded.map((u, i, l) => (
                            <div
                              key={u.uuid}
                              className={setClass(
                                'k-klogin-exclude-user-grid',
                                i !== setting.localUsersExcluded.length - 1
                                  ? 'b-mb1'
                                  : '',
                              )}
                              {...getLastInputRefProps(
                                localUsersExcludedLastInputRef,
                                i,
                                l.length - 1,
                              )}
                              ref={refs[40 + i]}
                            >
                              <TextInput
                                compact
                                value={u.value}
                                onChange={(e) => {
                                  e.persist();
                                  update('localUsersExcluded', (p) => {
                                    const copy = [...p];
                                    const idx = copy.findIndex(
                                      (c) => c.uuid === u.uuid,
                                    );
                                    copy[idx].value = e.target.value;
                                    return copy;
                                  });
                                }}
                                placeholder={i18n.t('Local user short name')}
                                disabled={isDisabled}
                                validator={(v) => [
                                  {
                                    message: i18n.t('Required'),
                                    invalid: () => !v,
                                    trigger,
                                  },
                                ]}
                              />
                              {setting.localUsersExcluded.length > 1 ? (
                                <Button
                                  theme="error"
                                  kind="link"
                                  icon="trash-can"
                                  onClick={() => removeUser(u)}
                                  disabled={isDisabled}
                                />
                              ) : null}
                            </div>
                          ))}
                        </div>
                        <Button
                          disabled={
                            setting.localUsersExcluded.length ===
                              MAX_LOCAL_USER_COUNT || isDisabled
                          }
                          kind="link"
                          icon="circle-plus"
                          className="b-mt1"
                          onClick={addUser}
                        >
                          {i18n.t(`Add user`)}
                        </Button>
                      </div>
                    </Setting.SecondaryControls>
                  )}
                </div>
              </Setting.SecondaryControlsRow>
            </Setting.SecondaryControls>
          )}
        </Setting.Row>
      </Setting.Rows>
    </Setting.Card>
  );
};

export default Provision;
