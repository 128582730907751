import { Banner, Checkbox, Chip } from '@kandji-inc/bumblebee';
import {
  i18nOsRequirements,
  osRequirements,
} from 'features/library-items/library/common';
import { Setting } from 'features/library-items/template';
/* istanbul ignore file */
import React from 'react';
import FeatureFlags from 'src/config/feature-flags';
import { i18n } from 'src/i18n';

const Devicename = (props: any) => {
  const { update, isDisabled, setting } = props;

  return (
    <Setting.Card>
      <Setting.Header>
        <h3 className="b-h3">{i18n.t('Device name modification')}</h3>
      </Setting.Header>
      <Setting.SubHeader>
        <p className="b-txt">
          {i18n.t(
            'A restrictions profile will be installed on the device to restrict the user from changing the device name.',
          )}
        </p>
      </Setting.SubHeader>
      <Setting.Rows>
        <Setting.Row>
          <Setting.Title>
            <Checkbox
              isDisabled={isDisabled}
              label={i18n.t(
                'Prevent users from modifying device name and hostname',
              )}
              checked={setting.prevent}
              onChange={() => update('prevent', (p) => !p)}
            />
          </Setting.Title>
          <Setting.Helpers>
            <p className="b-txt-light">
              {FeatureFlags.getFlag('ios-apple-vision-pro')
                ? i18n.t(
                    'Prevents the device name from being changed on iOS, iPadOS, tvOS, and macOS Sonoma, visionOS and later. This setting requires supervision.',
                  )
                : i18n.t(
                    'Prevents the device name from being changed on iOS, iPadOS, tvOS, and macOS Sonoma and later. This setting requires supervision.',
                  )}
            </p>
            <div className="b-flex-vc b-flex-g1">
              <Chip
                kind="tertiary-light"
                text={i18nOsRequirements(osRequirements.MAC_14_0)}
              />
              <Chip
                kind="tertiary-light"
                text={i18nOsRequirements(osRequirements.IOS_14_0)}
              />
              <Chip
                kind="tertiary-light"
                text={i18nOsRequirements(osRequirements.IPAD_14_0)}
              />
              <Chip
                kind="tertiary-light"
                text={i18nOsRequirements(osRequirements.TV_14_0)}
              />
              {FeatureFlags.getFlag('ios-apple-vision-pro') && (
                <Chip
                  kind="tertiary-light"
                  text={i18nOsRequirements(osRequirements.VISION_2_0)}
                />
              )}
              <Chip kind="tertiary-light" text={i18n.t('Supervised')} />
            </div>
            <Banner
              kind="block"
              theme="info"
              icon="circle-info"
              style={{ padding: '8px' }}
            >
              <span>
                {i18n.t(
                  'If users change the device name or hostname on macOS, it will automatically be remediated upon next check-in.',
                )}
              </span>
            </Banner>
          </Setting.Helpers>
        </Setting.Row>
      </Setting.Rows>
    </Setting.Card>
  );
};

export default Devicename;
