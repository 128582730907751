import {
  Box,
  Button,
  Flex,
  Heading,
  Loader,
  Text,
  Toast,
  animations,
} from '@kandji-inc/nectar-ui';
import { useRef } from 'react';
import { useHistory } from 'react-router';
import { EDRSidePanel } from 'src/features/edr/common/components/EDRSidePanel';
import { i18n } from 'src/i18n';
import { useShallow } from 'zustand/react/shallow';
import { useGetVulnerability } from '../../hooks/use-get-vulnerability';
import { useVulnerabilityToast } from '../../hooks/use-vulnerability-toast';
import useVulnerability from '../../store';
import { Body } from './body';
import { Header } from './header';

const VulnerabilitySidePanel = () => {
  const [selectedVulnerabilityId, setSelectedVulnerabilityId] =
    useVulnerability(
      useShallow((state) => [
        state.selectedVulnerabilityId,
        state.setSelectedVulnerabilityId,
      ]),
    );
  const toastRef = useRef(null);
  const history = useHistory();
  const { showToast } = useVulnerabilityToast(toastRef);

  const onClearSelectedVulnerability = /* istanbul ignore next */ () => {
    setSelectedVulnerabilityId(null);

    history.push({
      pathname: history.location.pathname,
      search: '',
    });
  };
  const { data, isLoading, isError, refetch } = useGetVulnerability(
    selectedVulnerabilityId,
  );

  const shouldShowVulnerability = !isLoading && !isError && data;

  const loader = (
    <Flex
      hFull
      wFull
      flow="column"
      alignItems="center"
      justifyContent="center"
      gap="sm"
      data-testid="loading-vulnerability"
    >
      <Loader size="lg" />
    </Flex>
  );

  const header = shouldShowVulnerability ? (
    <Header selectedVulnerability={data} showToast={showToast} />
  ) : (
    <Box />
  );

  let body;
  if (isError) {
    body = (
      <Flex
        flow="column"
        gap="sm"
        flex={1}
        justifyContent="center"
        alignItems="center"
      >
        <Heading size="3">{i18n.common.error()}.</Heading>
        <Text variant="description" css={{ paddingBottom: '$2' }}>
          {i18n.t('Please try again. If the issue persists, contact support.')}
        </Text>
        <Button variant="primary" onClick={refetch}>
          {i18n.t('Try again')}
        </Button>
      </Flex>
    );
  } else if (data && !isLoading) {
    body = <Body selectedVulnerability={data} />;
  } else {
    body = loader;
  }

  const outsideContent = (
    <Toast
      managerRef={toastRef}
      content={null}
      title={null}
      children={null}
      css={{
        '&[data-state=open]': {
          animation: `${animations.fadeIn.default}`,
        },
        width: '248px',
      }}
    />
  );

  return (
    <EDRSidePanel
      onClose={onClearSelectedVulnerability}
      header={header}
      body={body}
      outsideContent={outsideContent}
      css={{ containerCss: { zIndex: 2 }, contentCss: { overflow: 'hidden' } }}
    />
  );
};

export { VulnerabilitySidePanel };
