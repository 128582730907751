/* istanbul ignore file */
import './styles.css';

import { useInvalidations } from '@kandji-inc/bumblebee';
import { i18n } from 'i18n';
import isEqual from 'lodash/isEqual';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import {
  Flex,
  Heading,
  Label,
  Radio,
  RadioGroup,
  SelectContents,
  SelectGroupedItems,
  SelectV2,
  TextField,
} from '@kandji-inc/nectar-ui';
import { updateUser } from 'app/_actions/users';
import { timezones, userTypes } from 'app/common/constants';
import { AccountContext } from 'contexts/account';
import {
  localizedUserProfileUpdateMessage,
  optionsLocaleLanguages,
} from 'features/company-settings/options';
import { i18nDateFormatOptionMap } from 'features/user-profile/select-options';
import { displayTimezone } from 'src/app/common/helpers';
import { useFocusInput } from 'src/hooks/useFocusInput';
import Actions from './actions';
import {
  initialPasswords,
  useInitialForm,
  useInitialPageState,
} from './initial-state';
import { dateFormatOptions as _dateFormatOptions } from './select-options';
import {
  newPassword,
  passwordsMustMatch,
  requiredAndWithinCharLimit,
} from './validation';

interface UserSelectorState {
  account: {
    user: {
      locale?: string | undefined;
    };
  };
}

const UserProfile = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector<
    UserSelectorState,
    UserSelectorState['account']['user']
  >((state) => state.account.user);

  const { isEmailPasswordAuth0, userType } = useContext(AccountContext);

  const [initialForm, setInitialForm] = useState(useInitialForm());
  const [form, setForm] = useState(initialForm);
  const [page, setPage] = useState(useInitialPageState());

  // Note that password state is stored separately from the general form state because unlike
  // the general form, password field storage structure and payload structure differ.
  const [passwords, setPasswords] = useState(initialPasswords);
  const { invalidations, onInvalidate } = useInvalidations({ inputs: 4 });

  const showUserLocale = i18n.isEnabled();

  const timezoneOptions = timezones.map((option) => ({
    ...option,
    label: displayTimezone(option.value),
  }));

  const [isTimezoneSearchOpen, setIsTimezoneSearchOpen] = useState(false);
  const [timezoneSearch, setTimezoneSearch] = useState('');
  const [filteredTimezones, setFilteredTimezones] = useState(timezoneOptions);

  const handleTimezoneSearchChange = (search: string) => {
    setTimezoneSearch(search);
    setFilteredTimezones(
      timezoneOptions.filter((option) =>
        option.label.toLowerCase().includes(search.toLowerCase()),
      ),
    );
  };

  const handleOpenTimezoneSearch = (open: boolean) => {
    setIsTimezoneSearchOpen(open);
    setTimezoneSearch('');
    setFilteredTimezones(timezoneOptions);
  };

  const searchInputRef = useFocusInput([timezoneOptions, isTimezoneSearchOpen]);

  const dateFormatOptions = _dateFormatOptions.map((option) => ({
    ...option,
    label: i18nDateFormatOptionMap(option.label),
  }));

  const isDisabled =
    !page.isEditing || page.isSaving || userType === userTypes.super;

  const updatePageState = useCallback(
    (k: string, v: boolean) =>
      setPage((prevState) => ({ ...prevState, [k]: v })),
    [],
  );

  const updateForm = (k: string, v: unknown, isDateTimePref = false) => {
    if (isDateTimePref) {
      setForm((prevState) => ({
        ...prevState,
        settings: { ...prevState.settings, [k]: v },
      }));
    } else {
      setForm((prevState) => ({ ...prevState, [k]: v }));
    }
  };

  const updatePassword = (k: string, v: unknown) =>
    setPasswords((p) => ({ ...p, [k]: v }));

  const onBack = () => {
    history.push('/devices');
  };

  const onCancel = () => {
    updatePageState('isEditing', false);
    setPasswords(initialPasswords);
    setForm(initialForm);
  };

  const onSave = () => {
    // Force validate that passwords match
    if (passwords.new !== passwords.confirmNew) {
      document.getElementById('new-password-confirm').focus();
    } else {
      // Add passwords to payload if they have been edited
      const payload = form;
      if (!isEqual(passwords, initialPasswords)) {
        payload.old_password = passwords.current;
        payload.password = passwords.new;
      }

      updatePageState('isSaving', true);
      updatePageState('isEditing', false);

      const hasUpdatedLocaleLanguage = form.locale !== user.locale;
      const isSaveLocaleLanguage = showUserLocale && hasUpdatedLocaleLanguage;
      /* istanbul ignore next */
      const userLocaleSaveOptions = isSaveLocaleLanguage
        ? {
            successMessage:
              localizedUserProfileUpdateMessage[form.locale] || null,
            onSuccess: () => {
              const LOCALE_UPDATE_PAGE_RELOAD_DELAY_MS = 3500;
              setTimeout(() => {
                window.location.reload();
              }, LOCALE_UPDATE_PAGE_RELOAD_DELAY_MS);
            },
          }
        : null;

      dispatch(updateUser(payload, true, true, userLocaleSaveOptions));

      // Update/reset form
      setInitialForm(form);
      setPasswords(initialPasswords);
      updatePageState('isSaving', false);
    }
  };

  // Enable/Disable the `Save` button
  useEffect(() => {
    // Either no passwords have been entered or all have been edited
    const passwordsAreValid =
      isEqual(initialPasswords, passwords) ||
      Object.values(passwords).every((v) => v.length);
    const formHasChanged =
      !isEqual(initialForm, form) || !isEqual(initialPasswords, passwords);

    updatePageState(
      'isValid',
      formHasChanged && passwordsAreValid && !invalidations.some(Boolean),
    );
  }, [initialForm, form, invalidations, passwords, updatePageState]);

  const radioCss = {
    '&': {
      background: '#D7E1EDA3 !important',
    },
    '& ~ span': {
      color: '$neutral90 !important',
    },
    '& > span::after': {
      backgroundColor: '$neutral90 !important',
    },
  };

  const textCss = {
    '& span': {
      color: '$neutral70',
    },
    '& input:read-only': {
      backgroundColor: '#D7E1ED7A',
      color: '$neutral90',
    },
  };

  const selectCss = {
    color: '$input_default_text_enabled !important',
  };
  return (
    <Flex
      flow="column"
      css={{
        width: '100%',
        gap: '36px',
        height: '100%',
        position: 'relative',
        overflow: 'auto',
        paddingBottom: '64px',
      }}
    >
      <Flex
        flow="column"
        gap="xl"
        css={{
          maxWidth: '400px',
          width: '100%',
          padding: '36px 0',
        }}
      >
        <Heading
          size="4"
          css={{
            fontWeight: 500,
            lineHeight: 'var(--font-line-height-large-24, 24px) /* 150% */',
            letterSpacing: '-0.4px',
          }}
        >
          {i18n.t('User information')}
        </Heading>
        <Flex flow="column" gap="md">
          <TextField
            disabled={isDisabled}
            value={form.first_name}
            onChange={(e) => updateForm('first_name', e.target.value)}
            validator={requiredAndWithinCharLimit(25)}
            onInvalidate={onInvalidate(0)}
            label={i18n.t('First name')}
            css={textCss}
          />
          <TextField
            disabled={isDisabled}
            value={form.last_name}
            onChange={(e) => updateForm('last_name', e.target.value)}
            validator={requiredAndWithinCharLimit(25)}
            onInvalidate={onInvalidate(1)}
            label={i18n.t('Last name')}
            css={textCss}
          />
          <TextField
            disabled
            value={form.email}
            onChange={(e) => updateForm('email', e.target.value)}
            label={i18n.t('Email')}
            css={textCss}
          />
          {isEmailPasswordAuth0 && (
            <Flex flow="column" gap="sm">
              {/* <Callout
                title={i18n.t('Password must be at least 8 characters.')}
                showCloseButton={false}
              /> */}
              {page.isEditing ? (
                <>
                  <TextField
                    data-testid="current-password"
                    // @ts-ignore
                    id="current-password"
                    name="current-password"
                    autoComplete="current-password"
                    type="password"
                    placeholder={i18n.t('Enter your existing password')}
                    value={passwords.current}
                    onChange={(e) => updatePassword('current', e.target.value)}
                    label={i18n.t('Current password')}
                    css={textCss}
                  />
                  <TextField
                    data-testid="new-password"
                    // @ts-ignore
                    id="new-password"
                    name="new-password"
                    autoComplete="new-password"
                    type="password"
                    placeholder={i18n.t('Password')}
                    value={passwords.new}
                    onChange={(e) => updatePassword('new', e.target.value)}
                    validator={newPassword(
                      8,
                      i18n.t('Your password'),
                      passwords.current,
                    )}
                    onInvalidate={onInvalidate(2)}
                    label={i18n.t('New password')}
                    css={textCss}
                  />
                  <TextField
                    data-testid="new-password-confirm"
                    // @ts-ignore
                    id="new-password-confirm"
                    name="new-password-confirm"
                    autoComplete="new-password"
                    type="password"
                    placeholder={i18n.t('Password')}
                    value={passwords.confirmNew}
                    onChange={(e) =>
                      updatePassword('confirmNew', e.target.value)
                    }
                    validator={passwordsMustMatch(passwords.new)}
                    onInvalidate={onInvalidate(3)}
                    label={i18n.t('Confirm new password')}
                    css={textCss}
                  />
                </>
              ) : (
                <>
                  <TextField
                    type="password"
                    value="*********"
                    disabled
                    label={i18n.t('Current password')}
                    css={textCss}
                  />
                </>
              )}
            </Flex>
          )}
        </Flex>
      </Flex>

      <Flex
        flow="column"
        gap="xl"
        css={{ maxWidth: '400px', width: '100%', flex: 1 }}
      >
        <Heading
          size="4"
          css={{
            fontWeight: 500,
            lineHeight: 'var(--font-line-height-large-24, 24px) /* 150% */',
            letterSpacing: '-0.4px',
          }}
        >
          {i18n.t('Preferences')}
        </Heading>
        <Flex flow="column" gap="lg" css={{ width: '100%' }}>
          <Flex flow="column" gap="sm">
            <Label above>{i18n.t('Time zone')}</Label>
            <SelectV2.Default
              data-testid="timezone_pref"
              disabled={isDisabled}
              value={form.settings.timezone}
              triggerProps={{
                // @ts-expect-error -- TODO: update type
                variant: 'input',
                placeholder: i18n.t('Infer time zone from web browser'),
                css: selectCss,
              }}
              onValueChange={(selected) =>
                updateForm('timezone', selected, true)
              }
              onOpenChange={handleOpenTimezoneSearch}
              options={timezoneOptions}
              content={() => (
                <SelectContents.Searchable
                  ref={searchInputRef}
                  search={timezoneSearch}
                  onSearchChange={handleTimezoneSearchChange}
                >
                  <SelectGroupedItems options={filteredTimezones} />
                </SelectContents.Searchable>
              )}
            />
          </Flex>

          {showUserLocale && (
            <Flex flow="column" gap="sm">
              <Label above>{i18n.t('Language')}</Label>
              <SelectV2.Default
                data-testid="language_pref"
                disabled={isDisabled}
                value={String(form.locale)}
                onValueChange={(selected) => updateForm('locale', selected)}
                options={optionsLocaleLanguages}
                triggerProps={{
                  // @ts-expect-error -- TODO: update type
                  variant: 'input',
                  css: selectCss,
                }}
              />
            </Flex>
          )}

          {!showUserLocale && (
            <Flex flow="column" gap="sm">
              <Label above>{i18n.t('Date format')}</Label>
              <SelectV2.Default
                data-testid="date_format_pref"
                disabled={isDisabled}
                value={String(form.settings.preferred_date_format)}
                onValueChange={(selected) =>
                  updateForm('preferred_date_format', selected, true)
                }
                options={dateFormatOptions}
                triggerProps={{
                  // @ts-expect-error -- TODO: update type
                  variant: 'input',
                  css: selectCss,
                }}
              />
            </Flex>
          )}

          <Flex flow="column" gap="md">
            <Label above>{i18n.t('Display relative dates')}</Label>
            <Flex css={{ paddingLeft: '12px' }}>
              <RadioGroup
                value={String(form.settings.disable_relative_dates)}
                onValueChange={(selected) =>
                  updateForm('disable_relative_dates', selected, true)
                }
                orientation="vertical"
              >
                <Radio
                  value="enable"
                  label={i18n.t('Yes')}
                  disabled={isDisabled}
                  css={isDisabled ? radioCss : {}}
                />
                <Radio
                  value="disable"
                  label={i18n.t('No')}
                  disabled={isDisabled}
                  css={isDisabled ? radioCss : {}}
                />
              </RadioGroup>
            </Flex>
          </Flex>
          <Flex flow="column" gap="md">
            <Label above>{i18n.t('Receive weekly status emails')}</Label>
            <Flex css={{ paddingLeft: '12px' }}>
              <RadioGroup
                // @ts-expect-error -- this form expects a boolean
                value={Boolean(form.settings.send_weekly_emails)}
                onValueChange={(selected) =>
                  updateForm('send_weekly_emails', selected, true)
                }
                orientation="vertical"
              >
                <Radio
                  value={true}
                  label={i18n.t('Yes')}
                  disabled={isDisabled}
                  css={isDisabled ? radioCss : {}}
                />
                <Radio
                  value={false}
                  label={i18n.t('No')}
                  disabled={isDisabled}
                  css={isDisabled ? radioCss : {}}
                />
              </RadioGroup>
            </Flex>
          </Flex>
        </Flex>
      </Flex>

      <Flex
        css={{
          width: '100%',
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: '$neutral0',
          borderTop: '1px solid $neutral20',
          zIndex: 10,
        }}
      >
        <Actions
          pageState={page}
          onBack={onBack}
          onCancel={onCancel}
          onEdit={() => updatePageState('isEditing', true)}
          onSave={onSave}
        />
      </Flex>
    </Flex>
  );
};

export default UserProfile;
