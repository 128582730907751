import { Button, Flex } from '@kandji-inc/nectar-ui';
import HubSpotHandler from 'components/common/hubspot-handler';
import { i18n } from 'i18n';

interface PageState {
  isEditing: boolean;
  isSaving: boolean;
  isValid: boolean;
}

interface ActionsProps {
  className?: string;
  isEditable?: boolean;
  onBack: () => void;
  onCancel: () => void;
  onEdit: () => void;
  onSave: () => void;
  pageState: PageState;
  style?: React.CSSProperties;
}

const Actions = ({
  isEditable = true,
  onBack,
  onCancel,
  onEdit,
  onSave,
  pageState,
}: ActionsProps) => {
  return (
    <>
      <HubSpotHandler />
      <Flex
        css={{
          padding: '16px 24px 16px 0px',
          justifyContent: 'flex-end',
          alignItems: 'center',
          position: 'sticky',
          bottom: 0,
          left: 0,
          right: 0,
          width: '100%',
        }}
      >
        <Flex justifyContent="flex-end" gap="md">
          {pageState.isEditing && (
            <Button
              isDisabled={pageState.isSaving}
              onClick={onCancel}
              variant="outline"
            >
              {i18n.t('Cancel')}
            </Button>
          )}
          {isEditable &&
            (pageState.isEditing ? (
              <Button
                onClick={onSave}
                disabled={!pageState.isValid}
                variant="primary"
              >
                {i18n.t('Save')}
              </Button>
            ) : (
              <Button onClick={onEdit} variant="primary">
                {i18n.t('Edit')}
              </Button>
            ))}
        </Flex>
      </Flex>
    </>
  );
};

export default Actions;
