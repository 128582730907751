/* istanbul ignore file */
import { useQuery } from '@tanstack/react-query';
import { vulnerabilityApi } from 'src/app/api/base';

const useGetTopApplicationsWithVulnerabilities = (
  num_applications: number,
  keys: Array<any> = [],
  params: any = {},
) => {
  const { data: apiRes, isLoading } = useQuery({
    queryKey: ['top-applications-with-vulnerabilities', ...(keys || [])],
    queryFn: () =>
      vulnerabilityApi(`v1/dashboards/top_apps_with_vulns`).get({
        ...params,
        num_applications,
      }),
  });

  const applications: { app_name: string; num_vulns: number }[] =
    apiRes?.data || [];

  return { applications, isLoading };
};

export { useGetTopApplicationsWithVulnerabilities };
