import { Banner, Flex, Paragraph, Text } from '@kandji-inc/nectar-ui';
import React from 'react';
import { i18n } from 'src/i18n/index';

type Props = {
  isModalForBannerNavigation?: boolean;
};

const ModalContent = (props: Props) => {
  const { isModalForBannerNavigation } = props;
  const landingLocation = isModalForBannerNavigation
    ? i18n.t('Integrations Marketplace')
    : i18n.t('Okta Device Trust integration');
  return (
    <Flex flow="column" gap="md">
      <Banner
        text={i18n.t('You have unsaved changes on this Library item.')}
        theme="warning"
      />
      <Paragraph>
        {i18n.ut(
          'You are about to navigate away from the&nbsp;<span style="font-weight: 500">Okta Verify Library item</span>&nbsp;and have unsaved changes. If you are done modifying this Library item, we will save your work and navigate to the {landingLocation}.',
          { landingLocation },
        )}
      </Paragraph>
      <Paragraph>
        {i18n.t(
          'If you need to continue modifying the Library item, click on Cancel to return to the current page.',
        )}
      </Paragraph>
    </Flex>
  );
};

export default ModalContent;
