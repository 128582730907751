/* istanbul ignore file */
import { create } from 'zustand';
import {
  defaultAllVulnerabilitiesFilter,
  defaultDeviceVulnerabilitiesFilter,
} from './constants';
import type {
  AllVulnerabilitiesFilter,
  DeviceVulnerabilitiesFilter,
  Vulnerability,
} from './vulnerability.types';

type State = {
  selectedVulnerabilityId: Vulnerability['cve_id'] | null;
  allVulnerabilitiesFilter: AllVulnerabilitiesFilter;
  deviceVulnerabilitiesFilter: DeviceVulnerabilitiesFilter;
};

type Action = {
  setSelectedVulnerabilityId: (id: Vulnerability['cve_id'] | null) => void;
  setAllVulnerabilitiesFilter: (
    filter: Partial<State['allVulnerabilitiesFilter']>,
  ) => void;
  setDeviceVulnerabilitiesFilter: (
    filter: Partial<State['deviceVulnerabilitiesFilter']>,
  ) => void;
};

const useVulnerability = create<Partial<State> & Partial<Action>>((set) => ({
  selectedVulnerabilityId: null,
  allVulnerabilitiesFilter: defaultAllVulnerabilitiesFilter,
  deviceVulnerabilitiesFilter: defaultDeviceVulnerabilitiesFilter,

  setSelectedVulnerabilityId: (id) => set({ selectedVulnerabilityId: id }),
  setAllVulnerabilitiesFilter: (filter) =>
    set((state) => ({
      allVulnerabilitiesFilter: {
        ...state.allVulnerabilitiesFilter,
        ...filter,
      },
    })),
  setDeviceVulnerabilitiesFilter: (filter) =>
    set((state) => ({
      deviceVulnerabilitiesFilter: {
        ...state.deviceVulnerabilitiesFilter,
        ...filter,
      },
    })),
}));

export type { State as VulnerabilityState };
export default useVulnerability;
