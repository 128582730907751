import type { DetectionDateFilterFields } from 'src/features/edr/common/common.types';
import { transformDetectionDateFilterToApi } from '../../helpers';
import type { VulnerabilityState } from '../../store';
import type {
  AllVulnerabilitiesRequestSchema,
  DeviceVulnerabilitiesRequestSchema,
  VulnerableApplicationsRequestSchema,
  VulnerableDevicesRequestSchema,
} from '../../vulnerability.types';

export const transformAllVulnerabilitiesFilterToApi = (filter: {
  severity: VulnerabilityState['allVulnerabilitiesFilter']['severity'];
  latestDetected: VulnerabilityState['allVulnerabilitiesFilter']['latestDetected'];
}): AllVulnerabilitiesRequestSchema['filter'] => {
  const { severity, latestDetected } = filter;

  return {
    severity: { in: severity?.length ? severity : null },
    latest_detection_date: transformDetectionDateFilterToApi(latestDetected),
  };
};

export const transformDeviceVulnerabilitiesFilterToApi = (
  filter: VulnerabilityState['deviceVulnerabilitiesFilter'],
): DeviceVulnerabilitiesRequestSchema['filter'] => {
  const { severity, detectionDate } = filter;

  return {
    severity: { in: severity.length ? severity : null },
    detection_datetime: transformDetectionDateFilterToApi(detectionDate),
  };
};

export const transformVulnerableDevicesFilterToApi =
  /* istanbul ignore next */ (filter: {
    detectionDate: DetectionDateFilterFields;
    blueprints: string[];
  }): VulnerableDevicesRequestSchema['filter'] => {
    const { detectionDate, blueprints } = filter;

    return {
      detection_datetime: transformDetectionDateFilterToApi(detectionDate),
      blueprint_id: { in: blueprints.length ? blueprints : null },
    };
  };

export const transformVulnerableApplicationsFilterToApi =
  /* istanbul ignore next */ (filter: {
    latestDetectionDate: DetectionDateFilterFields;
    blueprints: string[];
  }): VulnerableApplicationsRequestSchema['filter'] => {
    const { latestDetectionDate, blueprints } = filter;

    return {
      latest_detection_date:
        transformDetectionDateFilterToApi(latestDetectionDate),
      blueprint_id: { in: blueprints.length ? blueprints : null },
    };
  };
