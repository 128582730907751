import { Button } from '@kandji-inc/bumblebee';
import React from 'react';
import { i18n } from 'src/i18n';
import EmptyAppsImg from '../../../assets/empty-apps-img.png';

const EmptyApps = ({ setIsVisible, isDisabled, isLoadingApps }) => (
  <div className="k-wifiv2-fastlane-specific-apps-empty">
    <div className="k-wifiv2-fastlane-specific-apps-empty__img">
      <img src={EmptyAppsImg} alt="Empty apps" />
    </div>
    <div className="k-wifiv2-fastlane-specific-apps-empty__title">
      <p className="b-txt-bold">{i18n.t('Apps are not set up')}</p>
    </div>
    <div className="k-wifiv2-fastlane-specific-apps-empty__text">
      <p className="b-txt">
        {i18n.t('Choose apps that will be allowed to use Fast Lane marking.')}{' '}
        <a
          href="https://support.kandji.io/kb/configure-the-wi-fi-library-item"
          rel="noopener noreferrer"
          target="_blank"
          className="b-alink"
        >
          {i18n.t('Learn more')}
        </a>
      </p>
    </div>
    <div className="k-wifiv2-fastlane-specific-apps-empty__btn">
      <Button
        isDisabled={isDisabled}
        isProgress={isLoadingApps}
        icon={isLoadingApps ? 'arrows-rotate' : 'circle-plus'}
        kind="link"
        onClick={() => setIsVisible(true)}
      >
        {i18n.t('Add application')}
      </Button>
    </div>
  </div>
);

export default EmptyApps;
