import { formatNumber } from '@kandji-inc/nectar-i18n';

const getFormatCount = (count: number, skipRounding: boolean = false) => {
  if (count < 1000 || skipRounding) {
    return formatNumber(count);
  }
  return `${(count / 1000).toFixed(1)}k`;
};

export default getFormatCount;
