import { Select } from '@kandji-inc/nectar-ui';
import { Setting } from 'features/library-items/template';
import { i18n } from 'i18n';
import featureFlags from 'src/config/feature-flags';
import type { BehavioralDetectionsProps } from '../../avert.types';
import AvertService from '../../service/avert.service';

const BehavioralDetections = (props: BehavioralDetectionsProps) => {
  const {
    isDisabled,
    settings,
    update,
    processMonitoringEnabledSetting,
    updateProcessMonitoringEnabled,
  } = props;

  if (!featureFlags.getFlag('edr_083024_behavioral-detections-avert-li')) {
    return null;
  }

  return (
    <Setting.Card>
      <Setting.Header>
        <h3 className="b-h3">{i18n.t('Behavioral detections')}</h3>
        <Setting.HeaderToggle
          isEnabled={processMonitoringEnabledSetting}
          isDisabled={isDisabled}
          onChange={updateProcessMonitoringEnabled}
          chipText={{
            enabled: i18n.t('On'),
            disabled: i18n.t('Off'),
          }}
          className={`pendo-switch-behavioral-detection pendo-value-${processMonitoringEnabledSetting}`}
        />
      </Setting.Header>
      <Setting.SubHeader>
        <p className="b-txt">
          {i18n.t(
            'Configure the response posture for malicious behavioral detection events.',
          )}
        </p>
      </Setting.SubHeader>
      <Setting.Rows>
        <Setting.Row>
          <Setting.Helpers className="b-txt-light">
            {i18n.ut(
              'Detect mode identifies and reports malicious behavioral detections. Protect mode identifies, reports, and blocks malicious behavioral detections.<br><br>Note: Suspicious behavioral detections are automatically listed in the Threats table with an informational status to highlight unusual activity that may warrant attention. As these detections are designed to provide visibility into such events, their posture mode cannot be configured.',
            )}
          </Setting.Helpers>
          <Select
            label={i18n.t('Malicious behavior response posture')}
            options={AvertService.postureOptions()}
            value={settings.process_mal_posture}
            onChange={(v) => update('process_mal_posture', v)}
            disabled={isDisabled || !processMonitoringEnabledSetting}
            testId="process_mal_posture"
            className={`pendo-dropdown-malicious-posture pendo-value-${settings.process_mal_posture}`}
          />
        </Setting.Row>
      </Setting.Rows>
    </Setting.Card>
  );
};

export default BehavioralDetections;
