import { i18n } from 'i18n';
import history from '../../../router/history';
import UniversalAlert from './UniversalAlert';

const IntegrationIsBrokenBanner = ({ service }) => (
  <UniversalAlert
    type="warning"
    text={i18n.t('Unable to sync users from {service}.', { service })}
    button={{
      text: i18n.t(`Reconnect {service}`, { service }),
      onClick: () =>
        history.push({
          pathname: '/my-company/integrations',
          state: {
            anchor: 'integration',
          },
        }),
    }}
  />
);

export default IntegrationIsBrokenBanner;
