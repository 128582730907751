// istanbul ignore file
import type {
  OnChangeFn,
  PaginationState,
  SortingState,
} from '@tanstack/react-table';
import type { Column } from '@tanstack/react-table';
import * as React from 'react';

import { usePagination } from 'src/components/ui';
import { useFlags } from 'src/config/feature-flags';
import { usePrismSchemas } from 'src/features/visibility/prism/hooks';
import { getDeviceInfoColumns } from 'src/features/visibility/prism/utils/views-column-defs';
import type {
  CategorySchema,
  PrismCategorySchema,
} from '../../prism/types/prism.types';
import { getColumnsForPrismCategory } from '../../prism/utils/views-column-defs/getColumnsForPrismCategory';

export type Option = {
  id: string;
  name: string;
};

interface Columns {
  pinnedColumns?: string[];
  columnDefs?: Column<CategorySchema>[];
  alwaysHiddenColumns?: string[];
}

export type PrismViewsContextType = {
  columns: Columns;
  columnCategories: Array<{
    uri: string;
    display_name: string;
    is_many: boolean;
    columns: Columns;
  }>;
  prismCategories: PrismCategorySchema[] | undefined;
  isLoading: boolean;
  pagination: PaginationState;
  setPagination: OnChangeFn<PaginationState>;
  sorting: SortingState;
  setSorting: OnChangeFn<SortingState>;
};

const PrismViewsContext = React.createContext<PrismViewsContextType>({
  columns: {},
  columnCategories: [],
  prismCategories: [],
  isLoading: false,
  pagination: {
    pageIndex: 0,
    pageSize: 10,
  },
  setPagination: {} as OnChangeFn<PaginationState>,
  sorting: [] as SortingState,
  setSorting: {} as OnChangeFn<SortingState>,
});

const PrismViewsProvider = ({
  children,
}: {
  children: React.ReactNode | React.ReactNode[];
}) => {
  const prismSchemas = usePrismSchemas('v3');
  const { 'visibility-cross-category-views': ffCrossCategoryViews } = useFlags([
    'visibility-cross-category-views',
  ]);

  const [sorting, setSorting] = React.useState<SortingState>([]);

  const {
    paginationState: { pageIndex, pageSize },
    setPagination,
  } = usePagination();

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  const columnCategories = React.useMemo(() => {
    if (!prismSchemas.data) {
      return [];
    }

    const categories = [
      {
        uri: 'device_information',
        display_name: 'Device Information',
        is_many: false,
        columns: getDeviceInfoColumns({
          globalSchema: prismSchemas.data.find((s) => s.uri === 'globals'),
          deviceInfoSchema: prismSchemas.data.find(
            (s) => s.uri === 'device_information',
          ),
          ffCrossCategoryViews,
        }),
      },
    ];

    prismSchemas.data
      .filter((s) => !['globals', 'device_information'].includes(s.uri))
      .forEach((schema) => {
        categories.push({
          uri: schema.uri,
          display_name: schema.display_name,
          is_many: schema.is_many,
          columns: getColumnsForPrismCategory(schema),
        });
      });

    return categories;
  }, [ffCrossCategoryViews, prismSchemas.data]);

  const columns = React.useMemo(
    () =>
      columnCategories
        .filter(
          ffCrossCategoryViews
            ? () => true
            : (c) => ['globals', 'device_information'].includes(c.uri),
        )
        .reduce(
          (columns, category) => ({
            columnDefs: [
              ...(category.columns.columnDefs ?? []),
              ...(columns.columnDefs ?? []),
            ],
            pinnedColumns: [
              ...(category.columns.pinnedColumns ?? []),
              ...(columns.pinnedColumns ?? []),
            ],
            alwaysHiddenColumns: [
              ...(category.columns.alwaysHiddenColumns ?? []),
              ...(columns.alwaysHiddenColumns ?? []),
            ],
          }),
          {
            columnDefs: [],
            pinnedColumns: [],
            alwaysHiddenColumns: [],
          } as Columns,
        ),
    [columnCategories, ffCrossCategoryViews],
  );

  return (
    <PrismViewsContext.Provider
      value={{
        columns,
        columnCategories,
        prismCategories: prismSchemas.data,
        isLoading: prismSchemas.isLoading,
        pagination,
        setPagination,
        sorting,
        setSorting,
      }}
    >
      {children}
    </PrismViewsContext.Provider>
  );
};

const usePrismViewsContext = () => {
  return React.useContext(PrismViewsContext);
};

export { PrismViewsProvider, usePrismViewsContext, PrismViewsContext };
