import featureFlags from 'src/config/feature-flags';
/* istanbul ignore file */
import { formatDeviceOptions } from '../../data-service/library-item/devices';
import { ipaSupportedDevices } from './in-house-apps.types';

const ffFormatDeviceOptions = () => {
  const isVisionEnabled = featureFlags.getFlag('ios-apple-vision-pro');
  // Filter out 'Vision' if the feature flag is not enabled
  const filteredDevices = isVisionEnabled
    ? ipaSupportedDevices
    : ipaSupportedDevices.filter((device) => device !== 'Vision');
  return formatDeviceOptions(filteredDevices);
};

const createInitialState = () => {
  return {
    id: '',
    name: '',
    isActive: true,
    selectedBlueprints: [],
    excludedBlueprints: [],
    isAllBlueprints: false,
    devices: ffFormatDeviceOptions(),
    rules: [],
    icon: undefined,
    skip_blueprint_conflict: undefined,
    installDetails: {
      ipaFile: {
        file: undefined,
        fileName: undefined,
        fileSize: undefined,
        fileKey: undefined,
        fileUpdated: undefined,
        fileUrl: undefined,
        appIcon: undefined,
        ipadIcon: undefined,
        appId: undefined,
        appName: undefined,
        appVersion: undefined,
        sha256: undefined,
      },
    },
  };
};

export default createInitialState;
