import { formatNumber } from '@kandji-inc/nectar-i18n';
import { Flex, Heading, Icon, Loader, Text } from '@kandji-inc/nectar-ui';
import { useHistory } from 'react-router';
import { i18n } from 'src/i18n';
import { useGetCVEImpactingTheMostDevices } from '../../../hooks/dashboard/use-get-cve-impacting-the-most-devices';
import useVulnerability from '../../../store';
import { Tile } from './tile';

const CVEImpactingTheMostDevicesTile = () => {
  const { cveId, count, isLoading } = useGetCVEImpactingTheMostDevices();

  const setSelectedVulnerabilityId = useVulnerability(
    (state) => state.setSelectedVulnerabilityId,
  );
  const history = useHistory();

  const hasData = !isLoading && cveId;

  const onTileClick = /* istanbul ignore next */ (cveId) => {
    setSelectedVulnerabilityId(cveId);

    history.push({
      pathname: history.location.pathname,
      search: `?cve_id=${cveId}`,
    });
  };

  const interactiveProps = {
    interactive: true,
    hoverVariant: 'elevate-1',
    onClick: () => onTileClick(cveId),
    css: { '&:hover': { cursor: 'pointer' } },
  };

  return (
    <Tile {...(hasData ? interactiveProps : {})}>
      <Flex hFull alignItems="center">
        <Flex flow="column" gap="xs" flex={1}>
          <Flex alignItems="center" gap="xs">
            <Icon
              name="bug"
              size="md"
              style={{ transform: 'rotate(180deg)' }}
            />
            <Heading size="4">
              {`${i18n.t('CVE impacting the most devices')}${hasData ? `: ${cveId}` : ''}`}
            </Heading>
          </Flex>
          {isLoading && (
            <Flex hFull alignItems="center" justifyContent="center">
              <Loader data-testid="loading-cve-impacting-the-most-devices" />
            </Flex>
          )}

          {hasData && <Heading size="2">{formatNumber(count)}</Heading>}

          {!isLoading && !cveId && (
            <Flex hFull wFull alignItems="center">
              <Text>
                {i18n.t(
                  'No vulnerabilities have been detected in your Mac fleet.',
                )}
              </Text>
            </Flex>
          )}
        </Flex>
        {hasData && <Icon name="fa-angle-right-small" size="md" />}
      </Flex>
    </Tile>
  );
};

export { CVEImpactingTheMostDevicesTile };
