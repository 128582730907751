import {
  Button,
  Flex,
  Heading,
  Icon,
  Text,
  useToast_UNSTABLE as useToast,
} from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';
import { AccountContext } from 'src/contexts/account';
import { InterfaceContext } from 'src/contexts/interface';

import { useContext, useState } from 'react';
import { useRequestVulnerabilityTrial } from '../../hooks/use-request-vulnerability-trial';
import HeroImage from '../assets/hero.png';

const SIDEBAR_DOCKED_OFFSET = 256;
const SIDEBAR_CLOSE_OFFSET = 78;
const KANDJI_VULN_URL = 'https://www.kandji.io/vulnerability-management';

const HeroSection = () => {
  const { currentCompany, userEmail } = useContext(AccountContext);

  const isVulnTrialRequested = localStorage.getItem('vulnTrialRequested');
  const [isRequested, setIsRequested] = useState<boolean>(
    Boolean(isVulnTrialRequested) || false,
  );

  const { sidebarDocked } = useContext(InterfaceContext);
  const { toast } = useToast();

  const tenantSubdomain: string = (currentCompany?.subdomain as string) || '';
  const tenantName: string = (currentCompany?.name as string) || '';
  const email: string = userEmail || '';
  const pageName = 'vuln-trial';
  const portalId = '5058330';
  const formId = 'd4add897-b32d-4b6c-9829-133ecce23c61';

  const { mutateAsync: requestTrial, isPending } = useRequestVulnerabilityTrial(
    portalId,
    formId,
  );

  const handleRequest = () => {
    setIsRequested(true);

    const toastMessage = {
      duration: 10000,
      style: {
        left: /* istanbul ignore next */ sidebarDocked
          ? `${SIDEBAR_DOCKED_OFFSET + 12}px`
          : `${SIDEBAR_CLOSE_OFFSET + 12}px`,
        bottom: '12px',
        position: 'absolute' as any,
      },
    };

    requestTrial({
      tenantSubdomain,
      tenantName,
      userEmail: email,
      pageName,
    })
      .then(
        /* istanbul ignore next */ () => {
          toast({
            ...toastMessage,
            title: 'Request Sent',
            content:
              'Thank you for requesting a trial of our Vulnerability Management solution!\nOur Customer Success team will be reaching out shortly to help you get started with your trial.',
            variant: 'success',
          });

          localStorage.setItem('vulnTrialRequested', 'true');
        },
      )
      .catch(
        /* istanbul ignore next */ () => {
          toast({
            ...toastMessage,
            title: 'Failed to request a trial. Please try again.',
            variant: 'error',
          });

          setIsRequested(false);
        },
      );
  };

  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      css={{ alignSelf: 'stretch' }}
    >
      <Flex
        flow="column"
        alignItems="start"
        flex={1}
        gap="md"
        css={{ color: '$neutral70' }}
      >
        <Flex alignItems="center" css={{ gap: 2 }}>
          <Icon name="shield-virus" style={{ width: 14, height: 14 }} />
          <Text
            css={{
              fontWeight: '$medium',
              letterSpacing: '0.4px',
              textTransform: 'uppercase',
            }}
          >
            {i18n.t('Vulnerability Management')}
          </Text>
        </Flex>
        <Flex
          gap="lg"
          flow="column"
          alignItems="start"
          css={{
            alignSelf: 'stretch',
          }}
        >
          <Flex
            gap="md"
            flow="column"
            alignItems="start"
            css={{ alignSelf: 'stretch' }}
          >
            <Heading
              size="1"
              css={{
                fontSize: 32,
                fontWeight: '$medium',
                lineHeight: '$5',
                letterSpacing: '-1.2px',
              }}
            >
              {i18n.t('Detect and mitigate vulnerabilities')}
            </Heading>
            <Text
              css={{
                color: '$neutral90',
                fontSize: 14,
                lineHeight: '20px',
              }}
            >
              {i18n.t(
                'Get the insights you need to mitigate risk from application and macOS vulnerabilities on your computers.',
              )}
            </Text>
          </Flex>
          <Flex gap="md" alignItems="center" css={{ mb: 20 }}>
            <Button
              variant="primary"
              disabled={isRequested && !isPending}
              onClick={handleRequest}
              loading={isPending}
            >
              {isRequested
                ? isPending
                  ? i18n.t('Requesting Trial...')
                  : i18n.t('Trial Requested')
                : i18n.t('Request Trial')}
            </Button>
            <Button
              variant="link"
              style={{ backgroundColor: 'transparent' }}
              onClick={
                /* istanbul ignore next */ () =>
                  window.open(KANDJI_VULN_URL, '_blank')
              }
            >
              {i18n.t('Learn more')}
            </Button>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        css={{
          width: 706,
          position: 'relative',
          '&:hover .side-panel': {
            transform: 'translateX(0%)',
          },
        }}
      >
        <img
          src={HeroImage}
          style={{
            width: 738,
            marginLeft: -16,
            marginTop: -12,
            marginBottom: -20,
          }}
          alt="detect-and-mitigate-vulnerabilities"
        />
      </Flex>
    </Flex>
  );
};

export { HeroSection };
