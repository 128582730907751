export default {
  id: null,
  name: '',
  selectedBlueprints: [],
  isAllBlueprints: false,
  isActive: true,
  general: {
    by_process: [],
    by_path: [],
    by_developer_id: [],
    by_bundle_id: [],
    message_customization: {
      message:
        'The following application has been blocked by your organization. For more information, please contact your IT administrator.',
      button_title: 'Learn More',
      url: 'https://support.kandji.io/kb/application-blocking-notification',
    },
  },
};
