import { Banner, Checkbox, Chip } from '@kandji-inc/bumblebee';
import React, { useEffect } from 'react';
import './general.css';
import featureFlags from 'src/config/feature-flags';

import { Setting } from 'features/library-items/template';
import { i18n } from 'src/i18n';
import {
  complianceTags,
  i18nOsRequirements,
  osRequirements,
} from '../../../../library/common';

const GeneralSection = (props) => {
  const { setting, update, isDisabled } = props;

  const isVisionEnabled = featureFlags.getFlag('ios-apple-vision-pro');

  const generalItems = [
    {
      label: i18n.t('Prevent open in unmanaged destination'),
      helper: i18n.t(
        'Prevent users from opening documents and data from managed sources in unmanaged destinations. ',
      ),
      chips: [
        i18nOsRequirements(osRequirements.IOS_7_0),
        i18nOsRequirements(osRequirements.IPAD_13_0),
        ...(isVisionEnabled
          ? [i18nOsRequirements(osRequirements.VISION_2_0)]
          : []),
        complianceTags.CIS,
        complianceTags.NCSC,
      ],
      inverted: true,
      key: 'allowOpenFromManagedToUnmanaged',
      dependents: [
        {
          key: 'requireManagedPasteboard',
          shouldBeFalse: (setting) =>
            setting['allowOpenFromManagedToUnmanaged'] &&
            setting['allowOpenFromUnmanagedToManaged'],
        },
      ],
      nested: [
        {
          label: i18n.t('Managed apps can create unmanaged contacts'),
          helper: i18n.t(
            'Allow managed apps to create unmanaged contacts in unmanaged accounts.',
          ),
          chips: [
            i18nOsRequirements(osRequirements.IOS_12_0),
            i18nOsRequirements(osRequirements.IPAD_13_0),
            ...(isVisionEnabled
              ? [i18nOsRequirements(osRequirements.VISION_2_0)]
              : []),
          ],
          key: 'allowManagedToWriteUnmanagedContacts',
        },
        {
          label: i18n.t('Unmanaged apps can read managed contacts'),
          helper: i18n.t('Allow unmanaged apps to read managed contacts.'),
          chips: [
            i18nOsRequirements(osRequirements.IOS_12_0),
            i18nOsRequirements(osRequirements.IPAD_13_0),
            ...(isVisionEnabled
              ? [i18nOsRequirements(osRequirements.VISION_2_0)]
              : []),
          ],
          key: 'allowUnmanagedToReadManagedContacts',
        },
        {
          label: i18n.t('Treat AirDrop as an unmanaged destination'),
          helper: i18n.t(
            'Users will see AirDrop as an option when sharing from a managed app.',
          ),
          chips: [
            i18nOsRequirements(osRequirements.IOS_9_0),
            i18nOsRequirements(osRequirements.IPAD_13_0),
            ...(isVisionEnabled
              ? [i18nOsRequirements(osRequirements.VISION_2_0)]
              : []),
            complianceTags.CIS,
          ],
          key: 'forceAirDropUnmanaged',
        },
      ],
    },
    {
      label: i18n.t('Prevent open in managed destination'),
      helper: i18n.t(
        'Prevent users from opening documents and data from unmanaged sources in managed destinations. ',
      ),
      chips: [
        i18nOsRequirements(osRequirements.IOS_7_0),
        i18nOsRequirements(osRequirements.IPAD_13_0),
        ...(isVisionEnabled
          ? [i18nOsRequirements(osRequirements.VISION_2_0)]
          : []),
        complianceTags.CIS,
        complianceTags.NCSC,
      ],
      inverted: true,
      key: 'allowOpenFromUnmanagedToManaged',
      dependents: [
        {
          key: 'requireManagedPasteboard',
          shouldBeFalse: (setting) =>
            setting['allowOpenFromManagedToUnmanaged'] &&
            setting['allowOpenFromUnmanagedToManaged'],
        },
      ],
    },
    {
      label: i18n.t('Restrict copy and paste'),
      helper: i18n.t(
        'Additonally restrict copy and paste functionality between the unmanaged/managed sources and destinations as configured above.',
      ),
      chips: [
        i18nOsRequirements(osRequirements.IOS_15_0),
        i18nOsRequirements(osRequirements.IPAD_15_0),
        ...(isVisionEnabled
          ? [i18nOsRequirements(osRequirements.VISION_2_0)]
          : []),
      ],
      key: 'requireManagedPasteboard',
      disabled:
        setting.allowOpenFromManagedToUnmanaged &&
        setting.allowOpenFromUnmanagedToManaged,
      disabledBannerText: (
        <p>
          {i18n.ut(
            `This configuration cannot be managed when the options to <b class="b-txt-bold" style="color: var(--color-purple-70)"> Prevent open from managed </b> or <b class="b-txt-bold" style="color: var(--color-purple-70)"> unmanaged destination </b> is unselected.`,
          )}
        </p>
      ),
    },
    {
      label: i18n.t('Prevent iCloud app sync'),
      helper: i18n.t(
        'Prevent managed applications from syncing data to iCloud.',
      ),
      chips: [
        i18nOsRequirements(osRequirements.IOS_8_0),
        i18nOsRequirements(osRequirements.IPAD_13_0),
        ...(isVisionEnabled
          ? [i18nOsRequirements(osRequirements.VISION_2_0)]
          : []),
        complianceTags.CMMC,
        complianceTags.NIST,
        complianceTags.NCSC,
      ],
      inverted: true,
      key: 'allowManagedAppsCloudSync',
    },
    {
      label: i18n.t('Prevent Files app from accessing network drives'),
      helper: i18n.t('Users cannot access network drives using the Files app.'),
      chips: [
        complianceTags.SUPERVISED,
        i18nOsRequirements(osRequirements.IOS_13_1),
        i18nOsRequirements(osRequirements.IPAD_13_1),
        ...(isVisionEnabled
          ? [i18nOsRequirements(osRequirements.VISION_2_0)]
          : []),
        complianceTags.CIS,
        complianceTags.NCSC,
      ],
      inverted: true,
      key: 'allowFilesNetworkDriveAccess',
    },
    {
      label: i18n.t('Prevent Files app from accessing USB devices'),
      helper: i18n.t(
        'Users cannot access USB storage devices using the Files app.',
      ),
      chips: [
        complianceTags.SUPERVISED,
        i18nOsRequirements(osRequirements.IOS_13_1),
        i18nOsRequirements(osRequirements.IPAD_13_1),
        complianceTags.CIS,
      ],
      inverted: true,
      key: 'allowFilesUSBDriveAccess',
    },
  ];

  useEffect(() => {
    generalItems.forEach((row) => {
      const isChecked = row.inverted ? !setting[row.key] : setting[row.key];
      if (row.dependents?.length) {
        row.dependents.forEach(({ key, shouldBeFalse }) => {
          if (setting[key] && shouldBeFalse(setting)) {
            update(key, () => false);
          }
        });
      }
      if (
        !isChecked &&
        row.nested?.some((nestedRow) => setting[nestedRow.key])
      ) {
        row.nested.forEach((nestedRow) => {
          if (setting[nestedRow.key]) {
            update(nestedRow.key, () => false);
          }
        });
      }
    });
  }, [setting]);

  return (
    <Setting.Card>
      <Setting.Header>
        <h3 className="b-h3">{i18n.t('General')}</h3>
      </Setting.Header>
      <Setting.Rows>
        {generalItems.map((row) => {
          const isChecked = row.inverted ? !setting[row.key] : setting[row.key];
          return (
            <Setting.Row key={row.key} className="k-mdf-config-row">
              <Setting.Controls className="k-mdf-config-row__controls">
                <Checkbox
                  isDisabled={isDisabled || row.disabled}
                  checked={isChecked}
                  label={row.label}
                  onChange={() => {
                    update(row.key, (p) => !p);
                    if (row.dependents?.length) {
                      row.dependents.forEach(({ key, shouldBeFalse }) => {
                        if (setting[key] && shouldBeFalse(setting)) {
                          update(key, () => false);
                        }
                      });
                    }
                  }}
                />
              </Setting.Controls>
              <Setting.Helpers className="k-mdf-config-row__helper">
                <div className="b-flex-vg1">
                  <p className="b-txt-light">{row.helper}</p>
                  <div className="b-flex-wrap-gtiny">
                    {row.chips.map((chip) => (
                      <Chip
                        key={chip}
                        disabled={isDisabled}
                        kind="tertiary-light"
                        text={chip}
                        className="k-ade-chip--m0"
                      />
                    ))}
                  </div>
                  {row.disabled && (
                    <Banner theme="info">{row.disabledBannerText}</Banner>
                  )}
                </div>
              </Setting.Helpers>

              {isChecked && row.nested && (
                <Setting.SecondaryControls>
                  {row.nested.map((nestedRow) => {
                    return (
                      <Setting.SecondaryControlsRow
                        key={nestedRow.key}
                        className="k-mdf-config-row"
                      >
                        <Setting.Controls className="k-mdf-config-row__controls">
                          <Checkbox
                            isDisabled={isDisabled}
                            checked={setting[nestedRow.key]}
                            label={nestedRow.label}
                            onChange={() => {
                              update(nestedRow.key, (p) => !p);
                            }}
                          />
                        </Setting.Controls>
                        <Setting.Helpers className="k-mdf-config-row__helper">
                          <div className="b-flex-vg1">
                            <p className="b-txt-light">{nestedRow.helper}</p>
                            <div className="b-flex-wrap-gtiny">
                              {nestedRow.chips.map((chip) => (
                                <Chip
                                  key={chip}
                                  disabled={isDisabled}
                                  kind="tertiary-light"
                                  text={chip}
                                  className="k-ade-chip--m0"
                                />
                              ))}
                            </div>
                          </div>
                        </Setting.Helpers>
                      </Setting.SecondaryControlsRow>
                    );
                  })}
                </Setting.SecondaryControls>
              )}
            </Setting.Row>
          );
        })}
      </Setting.Rows>
    </Setting.Card>
  );
};

export default GeneralSection;
