import { Chip, setClass } from '@kandji-inc/bumblebee';
import { Flex, styled } from '@kandji-inc/nectar-ui';
import {
  array,
  bool,
  element,
  node,
  object,
  oneOfType,
  string,
} from 'prop-types';
import React from 'react';
import { i18n } from 'src/i18n';

// istanbul ignore next
const osReq = (reqs: any) => {
  if (typeof reqs === 'string') {
    return <span>{reqs}</span>;
  }
  return (
    <>
      {reqs.map((r: any, i: any) => (
        <span key={`os_req_${i}`}>{r}</span>
      ))}
    </>
  );
};

const PublisherGrid = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'min-content auto',
  gridAutoRows: 'auto',
  alignItems: 'baseline',
  whiteSpace: 'nowrap',
  gridColumnGap: '24px',
  gridRowGap: '8px',
});

// istanbul ignore next
const Info = (props: any) => {
  const {
    style,
    className,
    name,
    instanceName,
    description,
    publisher,
    currentVersion,
    extra,
    devices = [],
    requirements,
    isDisabled,
    component: Component,
  } = props;

  const buildDeviceFamilies = () => {
    return devices.map((v: any) => (
      <Chip key={`devices_${v}`} kind="secondary" text={v} />
    ));
  };

  if (Component) {
    return <Component {...props} />;
  }

  return (
    <div
      style={style}
      className={setClass('b-library-summary__info', className)}
    >
      <div>
        <p className="b-txt-bold b-mb">{name}</p>
        {instanceName && isDisabled && (
          <p className="b-txt-light b-mb">
            {i18n.t('Label')}:<span className="b-txt ml-3">{instanceName}</span>
          </p>
        )}
        <p className="b-txt">{description}</p>
        {extra}
      </div>

      <PublisherGrid>
        <p className="b-txt-light">{i18n.t('Publisher')}:</p>
        <p className="b-txt">{publisher}</p>
        {currentVersion && (
          <p className="b-txt-light">{i18n.t('Current Version')}:</p>
        )}
        {currentVersion && <p className="b-txt">{currentVersion}</p>}
        <p className="b-txt-light">{i18n.t('Device families')}:</p>
        <Flex gap="xs">{buildDeviceFamilies()}</Flex>
        <p className="b-txt-light">{i18n.t('OS requirement')}:</p>
        <div className="b-txt b-flex-col">{osReq(requirements)}</div>
      </PublisherGrid>
    </div>
  );
};

Info.propTypes = {
  style: object,
  className: string,
  name: oneOfType([node, string, element]),
  instanceName: oneOfType([node, string]),
  description: oneOfType([node, string]),
  publisher: oneOfType([node, string]),
  extra: oneOfType([node, string]),
  currentVersion: oneOfType([node, string]),
  devices: oneOfType([array, string]),
  base_device_families: oneOfType([array, string]),
  requirements: oneOfType([array, string]),
  isDisabled: bool.isRequired,
  component: oneOfType([node, element]),
};

Info.defaultProps = {
  style: {},
  className: null,
  name: '',
  instanceName: null,
  description: '',
  publisher: '',
  extra: null,
  currentVersion: null,
  devices: [],
  base_device_families: [],
  requirements: '',
  component: null,
};

export default Info;
