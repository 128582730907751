import { Banner } from '@kandji-inc/bumblebee';
import React from 'react';
import featureFlags from 'src/config/feature-flags';

import { i18n } from 'src/i18n';
import NewLibraryItemService from '../../../data-service/library-item/new-library-item-service';

const DEVICE_FAMILIES = {
  MAC: 'Mac',
  IPHONE: 'iPhone',
  IPAD: 'iPad',
  VISION: 'Vision',
};

export const INPUT_TYPES = {
  SELECT: 'select',
  SLIDER: 'slider',
};

const HelpText = ({ children }) => <p className="b-txt-light">{children}</p>;

const helpTextWithVision = () => {
  return featureFlags.getFlag('ios-apple-vision-pro')
    ? i18n.t(
        "Don't allow passcodes with more than two sequential characters (such as 123 or CBA). On macOS, don't allow passcodes with repeating characters (such as 11 or AA). On iOS, iPadOS, visionOS, and tvOS don't allow passcodes with more than three repeating characters (such as 111 or AAA).",
      )
    : i18n.t(
        "Don't allow passcodes with more than two sequential characters (such as 123 or CBA). On macOS, don't allow passcodes with repeating characters (such as 11 or AA). On iOS, iPadOS, and tvOS don't allow passcodes with more than three repeating characters (such as 111 or AAA).",
      );
};

class PasscodeService extends NewLibraryItemService {
  static CHECKBOX_FIELDS = () => [
    {
      apiProperty: 'forcePIN',
      label: i18n.t('Require Passcode'),
      deviceFamilies: [
        DEVICE_FAMILIES.MAC,
        DEVICE_FAMILIES.IPHONE,
        DEVICE_FAMILIES.IPAD,
        DEVICE_FAMILIES.VISION,
      ],
      helpText: (
        <HelpText>
          {i18n.t(
            'Require a passcode on the device. Setting this value (and not others) forces the user to enter a passcode, without imposing a length or quality.',
          )}
        </HelpText>
      ),
      defaults: {
        checked: false,
      },
    },
    {
      apiProperty: 'allowSimple',
      label: i18n.t('Disallow Simple Passcode'),
      deviceFamilies: [
        DEVICE_FAMILIES.MAC,
        DEVICE_FAMILIES.IPHONE,
        DEVICE_FAMILIES.IPAD,
        DEVICE_FAMILIES.VISION,
      ],
      helpText: <HelpText>{helpTextWithVision()}</HelpText>,
      defaults: {
        checked: false,
      },
    },
    {
      apiProperty: 'requireAlphanumeric',
      label: i18n.t('Require Alphanumeric Passcode'),
      deviceFamilies: [DEVICE_FAMILIES.MAC],
      helpText: (
        <HelpText>
          {i18n.t('Require the passcode to contain letters and numbers.')}
        </HelpText>
      ),
      defaults: {
        checked: false,
      },
    },
    {
      apiProperty: 'requireAlphanumeric',
      label: i18n.t('Require Alphanumeric Passcode'),
      deviceFamilies: [
        DEVICE_FAMILIES.IPHONE,
        DEVICE_FAMILIES.IPAD,
        DEVICE_FAMILIES.VISION,
      ],
      helpText: (
        <HelpText>
          {i18n.t(
            'Require the passcode to contain letters, and not just numbers.',
          )}
        </HelpText>
      ),
      defaults: {
        checked: false,
      },
    },
    {
      apiProperty: 'minLength',
      label: i18n.t('Minimum Passcode Length'),
      deviceFamilies: [
        DEVICE_FAMILIES.MAC,
        DEVICE_FAMILIES.IPHONE,
        DEVICE_FAMILIES.IPAD,
        DEVICE_FAMILIES.VISION,
      ],
      helpText: (
        <HelpText>
          {i18n.t('The minimum overall length of the passcode.')}
        </HelpText>
      ),
      defaults: {
        checked: false,
        value: 8,
      },
      subField: {
        type: INPUT_TYPES.SLIDER,
        label: i18n.t('characters'),
        min: 1,
        max: 16,
      },
    },
    {
      apiProperty: 'minComplexChars',
      label: i18n.t('Minimum Complex Characters'),
      deviceFamilies: [
        DEVICE_FAMILIES.MAC,
        DEVICE_FAMILIES.IPHONE,
        DEVICE_FAMILIES.IPAD,
        DEVICE_FAMILIES.VISION,
      ],
      helpText: (
        <HelpText>
          {i18n.t(
            'The minimum number of complex characters that a passcode must contain. A complex character is a character other than a number or a letter, such as & % $ #.',
          )}
        </HelpText>
      ),
      defaults: {
        checked: false,
        value: 1,
      },
      subField: {
        type: INPUT_TYPES.SELECT,
        label: i18n.t('complex characters'),
        options: [
          {
            label: '1',
            value: 1,
          },
          {
            label: '2',
            value: 2,
          },
          {
            label: '3',
            value: 3,
          },
          {
            label: '4',
            value: 4,
          },
        ],
      },
    },
    {
      apiProperty: 'maxPINAgeInDays',
      label: i18n.t('Maximum Passcode Age'),
      deviceFamilies: [
        DEVICE_FAMILIES.MAC,
        DEVICE_FAMILIES.IPHONE,
        DEVICE_FAMILIES.IPAD,
        DEVICE_FAMILIES.VISION,
      ],
      helpText: (
        <HelpText>
          {i18n.t(
            'The number of days for which the passcode can remain unchanged. After this number of days, the user is forced to change the passcode before the device is unlocked.',
          )}
        </HelpText>
      ),
      defaults: {
        checked: false,
        value: 365,
      },
      subField: {
        type: INPUT_TYPES.SLIDER,
        label: i18n.t('days'),
        min: 1,
        max: 730,
      },
    },
    {
      apiProperty: 'pinHistory',
      label: i18n.t('Passcode History'),
      deviceFamilies: [
        DEVICE_FAMILIES.MAC,
        DEVICE_FAMILIES.IPHONE,
        DEVICE_FAMILIES.IPAD,
        DEVICE_FAMILIES.VISION,
      ],
      helpText: (
        <HelpText>
          {i18n.t(
            'New passcodes must be different than previously used passcodes.',
          )}
        </HelpText>
      ),
      defaults: {
        checked: false,
        value: 15,
      },
      subField: {
        type: INPUT_TYPES.SLIDER,
        label: i18n.t('previous passcodes not allowed'),
        min: 1,
        max: 50,
      },
    },
    {
      apiProperty: 'maxGracePeriod',
      label: i18n.t('Require Passcode After Sleep or Screen Saver Begins'),
      deviceFamilies: [DEVICE_FAMILIES.MAC],
      helpText: (
        <HelpText>
          {i18n.t(
            'The amount of time a device can sleep or have the screen saver on before a password is required.',
          )}
        </HelpText>
      ),
      defaults: {
        checked: false,
        value: 0,
      },
      subField: {
        type: INPUT_TYPES.SELECT,
        label: '',
        options: [
          {
            label: i18n.t('Immediately (No Grace Period)'),
            value: 0,
          },
          {
            label: i18n.common.durationMinutes(1),
            value: 1,
          },
          {
            label: i18n.common.durationMinutes(5),
            value: 5,
          },
          {
            label: i18n.common.durationMinutes(15),
            value: 15,
          },
          {
            label: i18n.common.durationHours(1),
            value: 60,
          },
          {
            label: i18n.common.durationHours(4),
            value: 240,
          },
          {
            label: i18n.common.durationHours(8),
            value: 480,
          },
        ],
      },
    },
    {
      apiProperty: 'maxGracePeriod',
      label: i18n.t('Require Passcode After Screen Lock'),
      deviceFamilies: [
        DEVICE_FAMILIES.IPHONE,
        DEVICE_FAMILIES.IPAD,
        DEVICE_FAMILIES.VISION,
      ],
      helpText: (
        <>
          <HelpText>
            {i18n.t(
              'The amount of time to unlock a device without entering a passcode.',
            )}
          </HelpText>
          <Banner theme="info">
            <div>
              {i18n.t(
                'NOTE: If Touch ID or Face ID is configured to unlock the device, this setting will automatically default to Immediately on the device, regardless of what is specified in the configuration.',
              )}
            </div>
          </Banner>
        </>
      ),
      defaults: {
        checked: false,
        value: 0,
      },
      subField: {
        type: INPUT_TYPES.SELECT,
        label: '',
        options: [
          {
            label: i18n.t('Immediately (No Grace Period)'),
            value: 0,
          },
          {
            label: i18n.common.durationMinutes(1),
            value: 1,
          },
          {
            label: i18n.common.durationMinutes(5),
            value: 5,
          },
          {
            label: i18n.common.durationMinutes(15),
            value: 15,
          },
          {
            label: i18n.common.durationHours(1),
            value: 60,
          },
        ],
      },
    },
    {
      apiProperty: 'maxInactivity',
      label: i18n.t('Maximum Available Auto-Lock Delay'),
      deviceFamilies: [DEVICE_FAMILIES.IPHONE],
      helpText: (
        <HelpText>
          {i18n.t(
            'The maximum period of time available in the Auto-Lock setting.',
          )}
        </HelpText>
      ),
      defaults: {
        checked: false,
        value: 5,
      },
      subField: {
        type: INPUT_TYPES.SELECT,
        label: '',
        options: [
          {
            label: i18n.common.durationMinutes(1),
            value: 1,
          },
          {
            label: i18n.common.durationMinutes(2),
            value: 2,
          },
          {
            label: i18n.common.durationMinutes(3),
            value: 3,
          },
          {
            label: i18n.common.durationMinutes(4),
            value: 4,
          },
          {
            label: i18n.common.durationMinutes(5),
            value: 5,
          },
        ],
      },
    },
    {
      apiProperty: 'maxInactivity',
      label: i18n.t('Maximum Available Auto-Lock Delay'),
      deviceFamilies: [DEVICE_FAMILIES.IPAD, DEVICE_FAMILIES.VISION],
      helpText: (
        <HelpText>
          {i18n.t(
            'The maximum period of time available in the Auto-Lock setting.',
          )}
        </HelpText>
      ),
      defaults: {
        checked: false,
        value: 5,
      },
      subField: {
        type: INPUT_TYPES.SELECT,
        label: '',
        options: [
          {
            label: i18n.common.durationMinutes(2),
            value: 2,
          },
          {
            label: i18n.common.durationMinutes(5),
            value: 5,
          },
          {
            label: i18n.common.durationMinutes(10),
            value: 10,
          },
          {
            label: i18n.common.durationMinutes(15),
            value: 15,
          },
        ],
      },
    },
    {
      apiProperty: 'maxInactivity',
      label: i18n.t('Start Screen Saver After'),
      deviceFamilies: [DEVICE_FAMILIES.MAC],
      helpText: (
        <HelpText>
          {i18n.t(
            'The amount of time a device can be idle before the screen saver turns on.',
          )}
        </HelpText>
      ),
      defaults: {
        checked: false,
        value: 5,
      },
      subField: {
        type: INPUT_TYPES.SELECT,
        label: '',
        options: [
          {
            label: i18n.common.durationMinutes(1),
            value: 1,
          },
          {
            label: i18n.common.durationMinutes(2),
            value: 2,
          },
          {
            label: i18n.common.durationMinutes(5),
            value: 5,
          },
          {
            label: i18n.common.durationMinutes(10),
            value: 10,
          },
          {
            label: i18n.common.durationMinutes(20),
            value: 20,
          },
          {
            label: i18n.common.durationMinutes(30),
            value: 30,
          },
          {
            label: i18n.common.durationHours(1),
            value: 60,
          },
        ],
      },
    },
    {
      apiProperty: 'maxFailedAttempts',
      label: i18n.t('Maximum Failed Attempts Before Erasing Device'),
      deviceFamilies: [
        DEVICE_FAMILIES.IPHONE,
        DEVICE_FAMILIES.IPAD,
        DEVICE_FAMILIES.VISION,
      ],
      helpText: (
        <>
          <HelpText>
            {i18n.t(
              'The allowed number of failed passcode attempts before all data on the device will be erased.',
            )}
          </HelpText>
          <Banner theme="info">
            <div>
              {i18n.t(
                'Devices will immediately be erased after the number of failed passcode attempts is reached.',
              )}
            </div>
          </Banner>
        </>
      ),
      defaults: {
        checked: false,
        value: 10,
      },
      subField: {
        type: INPUT_TYPES.SLIDER,
        label: i18n.t('failed attempts before erasing device'),
        min: 2,
        max: 11,
      },
    },
    {
      apiProperty: 'maxFailedAttempts',
      label: i18n.t('Maximum Failed Attempts Before Account Lockout'),
      deviceFamilies: [DEVICE_FAMILIES.MAC],
      helpText: (
        <>
          <HelpText>
            {i18n.ut(
              'The allowed number of failed passcode attempts before the user account is locked. Note that unless the Account Lockout Duration control below is enabled, <span class="b-txt-light b-txt-bold">the user account will remain permanently locked</span>.',
            )}
          </HelpText>
          <Banner theme="info">
            <div>
              {i18n.ut(
                'User accounts will remain locked unless the <span class="b-kandji-banner__txt b-txt-bold">Account Lockout Duration</span> control below is turned on.',
              )}
            </div>
          </Banner>
        </>
      ),
      defaults: {
        checked: false,
        value: 10,
      },
      subField: {
        type: INPUT_TYPES.SLIDER,
        label: i18n.t('failed attempts before account lockout'),
        min: 2,
        max: 11,
      },
    },
    {
      apiProperty: 'minutesUntilFailedLoginReset',
      label: i18n.t('Account Lockout Duration'),
      deviceFamilies: [DEVICE_FAMILIES.MAC],
      helpText: (
        <HelpText>
          {i18n.t(
            'The amount of time a user account remains locked after too many failed passcode attempts.',
          )}
        </HelpText>
      ),
      dependence: 'maxFailedAttempts',
      defaults: {
        checked: false,
        value: 5,
      },
      subField: {
        type: INPUT_TYPES.SLIDER,
        label: i18n.t('minutes'),
        min: 1,
        max: 60,
      },
    },
    {
      apiProperty: 'changeAtNextAuth',
      label: i18n.t('Force Password Reset'),
      deviceFamilies: [DEVICE_FAMILIES.MAC],
      helpText: (
        <>
          <HelpText>
            {i18n.ut(
              'Users will be prompted to change their passcode the next time they authenticate at the lock screen or login window, regardless of whether their existing passcode is compliant. Note that <span class="b-txt-light b-txt-bold"> users will be prompted to reset their password every time a change is made to this passcode configuration.</span>',
            )}
          </HelpText>
          <Banner theme="info">
            <div>
              {i18n.t(
                'Users will be prompted to reset their passcode every time this configuration is modified.',
              )}
            </div>
          </Banner>
        </>
      ),
      defaults: {
        checked: false,
      },
    },
  ];

  static MAC_CHECKBOX_FIELDS = () =>
    this.CHECKBOX_FIELDS().filter((field) =>
      field.deviceFamilies.includes(DEVICE_FAMILIES.MAC),
    );

  static IPHONE_CHECKBOX_FIELDS = () =>
    this.CHECKBOX_FIELDS().filter((field) =>
      field.deviceFamilies.includes(DEVICE_FAMILIES.IPHONE),
    );

  static IPAD_CHECKBOX_FIELDS = () =>
    this.CHECKBOX_FIELDS().filter((field) =>
      field.deviceFamilies.includes(DEVICE_FAMILIES.IPAD),
    );

  static VISION_CHECKBOX_FIELDS = () =>
    this.CHECKBOX_FIELDS().filter((field) =>
      field.deviceFamilies.includes(DEVICE_FAMILIES.VISION),
    );
}

export const passcodeService = new PasscodeService();

export default PasscodeService;
