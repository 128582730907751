import { Box, Button, Flex } from '@kandji-inc/nectar-ui';
import { Setting } from 'features/library-items/template';
import React, { useCallback, useState } from 'react';

import featureFlags from 'src/config/feature-flags';
import { i18n } from 'src/i18n';
import type { EndUserNotificationsProps } from '../../avert.types';
import NotificationImage from './assets/notification.jpg';

const Notifications = (props: EndUserNotificationsProps) => {
  const { isDisabled, update, settings } = props;

  const [isExpanded, setIsExpanded] = useState<boolean>(
    localStorage.getItem('avertNotificationsExpandPreview') === 'true',
  );

  const handleExpandCollapse = useCallback(() => {
    localStorage.setItem(
      'avertNotificationsExpandPreview',
      String(!isExpanded),
    );
    setIsExpanded(!isExpanded);
  }, [isExpanded]);

  if (!featureFlags.getFlag('edr_120123_end-user-notification-avert-li')) {
    return null;
  }

  return (
    <Setting.Card>
      <Setting.Header className="k-ss2-header">
        <h3 className="b-h3">{i18n.t(`User alerts`)}</h3>
        <Setting.HeaderToggle
          isEnabled={settings.end_user_notifications_enabled}
          isDisabled={isDisabled}
          onChange={() => update('end_user_notifications_enabled', (p) => !p)}
          chipText={{
            enabled: i18n.t('Notify users'),
            disabled: i18n.t('Do not notify users'),
          }}
          className={`pendo-switch-user-alerts pendo-value-${settings.end_user_notifications_enabled}`}
        />
      </Setting.Header>
      <Setting.SubHeader>
        <p className="b-txt">
          {i18n.t(
            `Specify if users should receive notifications when malware or Potentially Unwanted Program (PUP) events occur on their device. Notifications are supported only when the malware or PUP response postures are set to protect mode.`,
          )}
        </p>
      </Setting.SubHeader>
      {settings.end_user_notifications_enabled && (
        <Setting.Rows className="pb-4">
          <Setting.Row singleCol className="pt-0 pb-0">
            <Box className="mt-0 mb-0">
              <Flex flow="row" gap="lg" alignItems="center">
                <Button
                  aria-label="Collapse preview"
                  variant="subtle"
                  onClick={handleExpandCollapse}
                  icon={{
                    name: isExpanded ? 'angle-up' : 'angle-down',
                  }}
                  css={{
                    '& > svg': { height: 'var(--sizes-4)' },
                    color: '$blue50',
                  }}
                  className={`pendo-button-user-alerts-expand pendo-value-${isExpanded}`}
                >
                  {isExpanded
                    ? i18n.t('Collapse preview')
                    : i18n.t('Expand preview')}
                </Button>
              </Flex>
              {isExpanded && (
                <Box>
                  <img
                    className="b-w100 mt-4"
                    style={{ borderRadius: '5px' }}
                    draggable="false"
                    src={NotificationImage}
                    alt={i18n.t('Notification event')}
                  />
                </Box>
              )}
            </Box>
          </Setting.Row>
        </Setting.Rows>
      )}
    </Setting.Card>
  );
};

export default Notifications;
