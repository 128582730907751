import { Button, Dialog, Flex } from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';
import { type Dispatch, type SetStateAction, useState } from 'react';

export type DialogStatus =
  | 'turnOffMsdcOpen'
  | 'navToMsdcPageOpen'
  | 'allClosed';

interface Props {
  dialogStatus: DialogStatus;
  setDialogStatus: Dispatch<SetStateAction<DialogStatus>>;
  handleToggleChange: (nextToggleState: boolean) => void;
  saveAndNavToMsdcPage: () => Promise<() => void>;
}

const dialogCss = {
  maxWidth: 600,
} as const;

export function MsAuthenticatorDialog({
  dialogStatus,
  setDialogStatus,
  handleToggleChange,
  saveAndNavToMsdcPage,
}: Props) {
  const [isAllClosed, isTurnOffMsdcOpen, isNavToMsdcPageOpen] = [
    dialogStatus === 'allClosed',
    dialogStatus === 'turnOffMsdcOpen',
    dialogStatus === 'navToMsdcPageOpen',
  ] as const;

  const onOpenChange = (nextOpenState: boolean) =>
    setDialogStatus((prevOpenState) =>
      nextOpenState ? prevOpenState : 'allClosed',
    );

  const closeDialog = () => setDialogStatus('allClosed');

  const dialogPropsMap = {
    turnOffMsdcOpen: {
      title: i18n.t('Turn off Microsoft Device Compliance?'),
      content: i18n.t(
        'By turning off this integration, Microsoft Device Compliance cannot be deployed to iOS/iPadOS devices.',
      ),
      footer: (
        <TurnOffMsdcDialogFooter
          closeDialog={closeDialog}
          handleToggleChange={handleToggleChange}
        />
      ),
      isOpen: isTurnOffMsdcOpen,
      onOpenChange,
    },
    navToMsdcPageOpen: {
      title: i18n.t('Leave this page?'),
      content: i18n.t(
        'Are you sure you want to cancel? If you are done modifying this Library item, we will save your work and navigate to the Microsoft Device Trust integration.',
      ),
      footer: (
        <NavToMsdcPageDialogFooter
          closeDialog={closeDialog}
          saveAndNavToMsdcPage={saveAndNavToMsdcPage}
        />
      ),
      isOpen: isNavToMsdcPageOpen,
      onOpenChange,
    },
  };

  return (
    !isAllClosed && (
      <Dialog
        animateOnOpen
        autoFocusOnOpen
        closeOnEscape
        closeOnOutsideClick
        css={dialogCss}
        {...dialogPropsMap[dialogStatus]}
      />
    )
  );
}

function TurnOffMsdcDialogFooter({
  closeDialog,
  handleToggleChange,
}: {
  closeDialog: () => void;
  handleToggleChange: (nextToggleState: boolean) => void;
}) {
  function turnOffToggle() {
    handleToggleChange(false);
    closeDialog();
  }

  return (
    <Flex justifyContent="end" gap="sm">
      <Button variant="subtle" onClick={closeDialog}>
        {i18n.t('Cancel')}
      </Button>
      <Button variant="danger" onClick={turnOffToggle}>
        {i18n.t('Turn off')}
      </Button>
    </Flex>
  );
}

function NavToMsdcPageDialogFooter({
  closeDialog,
  saveAndNavToMsdcPage,
}: {
  closeDialog: () => void;
  saveAndNavToMsdcPage: () => Promise<() => void>;
}) {
  const [isSaving, setIsSaving] = useState(false);

  function sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  function handleSaveAndNav() {
    setIsSaving(true);
    saveAndNavToMsdcPage()
      .then(async (nextAction) => {
        setIsSaving(false);
        await sleep(1500);
        nextAction();
      })
      .catch(() => {
        setIsSaving(false);
        closeDialog();
      });
  }

  return (
    <Flex justifyContent="end" gap="sm">
      <Button variant="subtle" onClick={closeDialog}>
        {i18n.t('Stay on page')}
      </Button>
      <Button variant="primary" onClick={handleSaveAndNav} disabled={isSaving}>
        {i18n.t('Leave')}
      </Button>
    </Flex>
  );
}
