/* istanbul ignore file */
const transformToSelectOptions = (data) =>
  data?.map(({ name, id, type, ...rest }) => ({
    label: name,
    value: id,
    type,
    ...rest,
  }));

export { transformToSelectOptions };
