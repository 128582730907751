import { Flex } from '@kandji-inc/nectar-ui';
import useAddBodyClass from 'src/features/util/hooks/use-add-body-class';
import useWhiteBg from 'src/features/util/hooks/use-white-bg';

import BackgroundImage from './assets/background.jpg';
import { HeroSection } from './sections/hero-section';
import { SeeAssessTakeActionSecction } from './sections/see-assess-take-action-section';
import { WhyKandjiSection } from './sections/why-kandji-section';

const TrialPage = () => {
  useAddBodyClass('vuln-trial');
  useWhiteBg();

  return (
    <Flex
      justifyContent="center"
      css={{
        backgroundImage: `url(${BackgroundImage})`,
        backgroundPositionX: 'calc(50% + 146px)',
        backgroundPositionY: 'top',
        backgroundSize: '1548px',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Flex
        flow="column"
        alignItems="start"
        css={{
          width: 1256,
          padding: '100px 60px 280px',
          gap: 100,
        }}
      >
        <HeroSection />

        <SeeAssessTakeActionSecction />

        <WhyKandjiSection />
      </Flex>
    </Flex>
  );
};

export { TrialPage };
