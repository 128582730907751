/* istanbul ignore file */
import { Toaster as toaster, validateModel } from '@kandji-inc/bumblebee';
import deepcopy from 'deepcopy';
import get from 'lodash/get';
import set from 'lodash/set';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { useLocation } from 'react-router-dom';

import { filterApplicableRules } from 'src/features/rules/transformers';

import {
  PassportUploader,
  PublicSelfServiceIconUploader,
} from 'components/common/s3-upload';
import { i18n } from 'i18n';
import { enterpriseExternalApi } from 'src/app/api/base';
import { useFlags } from 'src/config/feature-flags';
import type { ConflictResolutions } from '../assignment-map-conflicts/useAssignmentMaps';

export const useHandlers = ({
  handlers,
  model,
  blueprintConflicts,
  type,
  identifier,
  setModel,
  triggerValidation,
  passportFilesToUpload,
  publicSelfServiceIconFilesToUpload,
  transformToApi,
  pageState,
  service,
  history,
  setSavedModel,
  transformFromApi,
  askFlush,
  setIsFlushModalOpen,
  restoreModel,
  facetMap,
  supportsInstallOn,
  supportsRules,
  resolutions,
  onFailedAssignmentMapPlacement,
  blueprintOptions,
}) => {
  const {
    'dc-assign-to-assignment-map-from-library-item-page': LDFF_assignAMFromLi,
  } = useFlags(['dc-assign-to-assignment-map-from-library-item-page']);
  const { state } = useLocation<{
    isDuplicate: boolean;
    failedAssignmentMapPlacements: Array<{ label: string; value: string }>;
  }>();
  const [previousPage, setPreviousPage] = useState();
  const params = useParams();

  useEffect(() => {
    if (state?.previousPage) {
      setPreviousPage(state.previousPage);
    }
  }, []);

  const goBack = () => {
    // Route to another section if the user came from somewhere like /blueprints, or
    // just go back to the library items list:

    // Note: state.previousPage is set from a react-router-dom Link component's `to` prop
    // by passing it an object
    history.push(previousPage || '/library');
  };

  const saveAssignmentMaps = (postSaveLibraryItemId?: string) => {
    if (!LDFF_assignAMFromLi) {
      return Promise.resolve();
    }

    const toResolve = Object.values(resolutions as ConflictResolutions);
    return Promise.allSettled(
      toResolve.map(({ id: blueprintId, itemsToAdd, itemsToRemove }) => {
        const map = ({
          assignmentId: assignment_node_id,
          libraryItemId: library_item_id,
        }) => ({
          assignment_node_id,
          library_item_id: library_item_id || postSaveLibraryItemId,
        });

        return enterpriseExternalApi(
          `blueprints/${blueprintId}/node-assignments`,
        ).post({
          assign: itemsToAdd.map(map),
          unassign: itemsToRemove.map(map),
        });
      }),
    ).then((r) => {
      const { assigned, unassigned } = r.reduce(
        (a, c, idx) => {
          const isSuccess = c.status === 'fulfilled';
          const blueprintId = toResolve[idx].id;
          const blueprint = blueprintOptions.find(
            ({ value }) => value === blueprintId,
          );
          return {
            ...a,
            assigned: [...a.assigned, ...(isSuccess ? [blueprint] : [])],
            unassigned: [...a.unassigned, ...(!isSuccess ? [blueprint] : [])],
          };
        },
        {
          assigned: [],
          unassigned: [],
        },
      );

      if (postSaveLibraryItemId) {
        history.push(`/library/${params.type}/${postSaveLibraryItemId}`, {
          failedAssignmentMapPlacements: unassigned,
        });
      } else {
        setSavedModel((p) => ({
          ...p,
          selectedBlueprints: [...p.selectedBlueprints, ...assigned],
        }));
        setModel((p) => ({
          ...p,
          selectedBlueprints: [...p.selectedBlueprints, ...assigned],
        }));

        onFailedAssignmentMapPlacement(unassigned);
      }
    });
  };

  const onSave = handlers.onSave(async () => {
    triggerValidation();
    const isValid = validateModel(model);
    if (!isValid) {
      return handlers.onSave.stop;
    }

    const modelCopy = deepcopy(model);

    if (blueprintConflicts) {
      const [canContinue, resolvedBps] =
        await blueprintConflicts.checkConflicts({
          type,
          identifier,
          managedLibraryItemId: model.managedLibraryItemId,
          blueprints: model.selectedBlueprints,
          isAllBlueprints: model.isAllBlueprints,
          // Only send the model id if we are editing an existing library item,
          // otherwise the managedLibraryItemId as the identifier will suffice
          itemId: pageState.isAdding ? '' : model.id,
          setBlueprints: (selectedBlueprints) =>
            setModel((p) => ({ ...p, selectedBlueprints })),
          onError: () =>
            toaster(i18n.t('There was an issue adding your Blueprints')),
        });

      if (!canContinue) {
        return handlers.onSave.stop;
      }

      modelCopy.selectedBlueprints = resolvedBps;
      modelCopy.skip_blueprint_conflict = true;
    }

    // If the Library Item supports customizing which devices to install on and
    // assignment rules, filter out rules that do not apply to selected devices
    if (supportsInstallOn && supportsRules) {
      modelCopy.rules = filterApplicableRules(
        modelCopy.rules,
        modelCopy.devices,
        facetMap,
      );
    }

    const uploadFiles = async (uploader, fileMap) => {
      await Promise.all(
        Object.keys(fileMap).map((filePath) => {
          const file = get(modelCopy, filePath);
          return (
            file &&
            uploader
              .getS3UploadData([{ name: file.name }])
              .then((s3Data) =>
                uploader
                  .upload({
                    file,
                    s3Data: s3Data.data.files[0].s3_post_data,
                  })
                  .then(() => s3Data),
              )
              .then((s3Data) =>
                set(
                  modelCopy,
                  fileMap[filePath],
                  s3Data.data.files[0].s3_post_data.fields.key,
                ),
              )
          );
        }),
      );
    };

    if (passportFilesToUpload) {
      await uploadFiles(PassportUploader, passportFilesToUpload);
    }

    if (publicSelfServiceIconFilesToUpload) {
      await uploadFiles(
        PublicSelfServiceIconUploader,
        publicSelfServiceIconFilesToUpload,
      );
    }

    const data = await transformToApi(modelCopy);
    if (pageState.isAdding) {
      return service
        .create(data)
        .then(async (r) => {
          const newItemID = r.data.id;

          history.push(`/library/${params.type}/${newItemID}`);

          const transformed = await transformFromApi(r.data);

          setSavedModel((p) => ({ ...p, ...transformed.data }));
          setModel((p) => ({ ...p, ...transformed.data }));

          return saveAssignmentMaps(newItemID);
        })
        .catch((errorResponse) => {
          let errorMessage = `Failed to create ${model.name}`;

          /*
          Attempt to get a more helpful error message. If it exists, display it.
          We are expecting an error message to be in the following format:
          errorResponse: {
            response: {
              data: {
                field_name: ["Error message 1", "Error message 2"]
              }
            }
          }
          */
          const allErrorMessages = errorResponse?.response?.data;
          if (
            typeof allErrorMessages === 'object' &&
            Object.values(allErrorMessages)[0].length
          ) {
            const firstErrorMessage = Object.values(allErrorMessages)[0][0];
            if (firstErrorMessage) {
              errorMessage = firstErrorMessage;
            }

            // Handle Bookmark LI conflicts:
            if (Object.keys(allErrorMessages).includes('bookmark_conflicts')) {
              setModel((p) => ({
                ...p,
                manageBookmarks: {
                  ...p.manageBookmarks,
                  bookmark_conflicts: allErrorMessages.bookmark_conflicts,
                },
              }));

              return handlers.onSave.stop;
            }
          }

          toaster(errorMessage);
          return handlers.onSave.stop;
        });
    }

    return service
      .patch(model.id, data)
      .then(async (r) => {
        const transformed = await transformFromApi(r.data);

        setSavedModel((p) => ({ ...p, ...transformed.data }));
        setModel((p) => ({ ...p, ...transformed.data }));

        toaster(i18n.t('Updated Library Item successfully!'));

        if (askFlush?.()) {
          setIsFlushModalOpen(true);
        }

        return saveAssignmentMaps();
      })
      .catch((errorResponse) => {
        let errorMessage = `Failed to save ${model.name}`;

        /*
          Attempt to get a more helpful error message. If it exists, display it.
          We are expecting an error message to be in the following format:
          errorResponse: {
            response: {
              data: {
                field_name: ["Error message 1", "Error message 2"]
              }
            }
          }
          */
        const allErrorMessages = errorResponse?.response?.data;
        if (
          typeof allErrorMessages === 'object' &&
          Object.values(allErrorMessages)[0].length
        ) {
          const firstErrorMessage = Object.values(allErrorMessages)[0][0];
          if (firstErrorMessage) {
            errorMessage = firstErrorMessage;
          }

          // Handle Bookmark LI conflicts:
          if (Object.keys(allErrorMessages).includes('bookmark_conflicts')) {
            setModel((p) => ({
              ...p,
              manageBookmarks: {
                ...p.manageBookmarks,
                bookmark_conflicts: allErrorMessages.bookmark_conflicts,
              },
            }));

            return handlers.onSave.stop;
          }
        }

        toaster(errorMessage);
        return handlers.onSave.stop;
      });
  });

  const onRemove = useMemo(
    () =>
      handlers.onRemove(async () => {
        try {
          await service.remove(model.id);
          goBack();
        } catch (e) {
          // todo ??
        }
      }),
    [handlers, history, model.id, service],
  );
  const onEdit = useMemo(() => handlers.onEdit(() => {}), [handlers]);
  const onDelete = useMemo(() => handlers.onDelete(() => {}), [handlers]);
  const onCancel = useMemo(
    () => handlers.onCancel(() => restoreModel()),
    [handlers, history, restoreModel],
  );
  const onClose = useMemo(
    () =>
      handlers.onClose(() => {
        goBack();
      }),
    [handlers, history],
  );

  const onCloseModal = useMemo(
    () => handlers.onCloseModal(() => {}),
    [handlers],
  );

  /**
   * Calls the duplication service method and ultimately redirects to the route of
   * the newly created library item clone.
   * @returns nothing; redirects to clone while passing some state via `history.push`
   */
  const onDuplicate = async () => {
    try {
      const { data } = await service.duplicate(model.id);

      const { new_library_item_id: cloneId } = data;

      history.push(`/library/${params.type}/${cloneId}`, {
        isDuplicate: true,
        originId: model.id,
        originRoute: `/library/${params.type}/${model.id}`,
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const newState = state;

    if (state?.failedAssignmentMapPlacements) {
      onFailedAssignmentMapPlacement(state.failedAssignmentMapPlacements);
      delete newState.failedAssignmentMapPlacements;
    }

    if (state?.isDuplicate) {
      toaster(i18n.t('Duplicate Library Item created.'), {
        type: 'success',
      });

      onEdit();

      // Remove `isDuplicate` from location state so that the initial
      // editing state for duplicate items does not persist on refresh
      delete newState.isDuplicate;
    }

    window.history.replaceState(newState, document.title);
  }, []);

  return {
    onSave,
    onRemove,
    onEdit,
    onDelete,
    onCancel,
    onClose,
    onCloseModal,
    onDuplicate,
  };
};
