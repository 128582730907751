import styled from 'styled-components';

export const Status = styled('div')`
  font-size: 10px;
  line-height: 7px;
  display: flex;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  align-items: center;
  gap: 2px;

  color: #1a1d25;
  i {
    font-size: 6px;
    color: ${(props) => props.iColor};
    margin-right: 5px;
  }
`;

export const StatusLabel = styled('div')`
  line-height: 26px;
  width: 120px;
  min-width: 120px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const StatusLabelText = styled('div')`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
