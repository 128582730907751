import {
  Flex,
  Icon,
  Paragraph,
  Tooltip,
  TooltipProvider,
  styled,
} from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';
import type { ReactNode } from 'react';
import { useFlags } from 'src/config/feature-flags';
import type { PrismCategoryUri } from '../../types/prism.types';
import {
  getIconByDeviceFamily,
  getSupportedDeviceFamiliesByPrismCategory,
} from '../PrismTable/utils/tooltipUtils';

export interface PrismNavItemProps {
  children: ReactNode;
  onSelect: () => void;
  selected: boolean;
  disabled?: boolean;
  uri: PrismCategoryUri;
}

const StyledPrismNavItem = styled('button', {
  display: 'flex',
  gap: '$2',
  p: '$2 $4',
  fontSize: '$2',
  textAlign: 'left',
  borderRadius: '4px',
  minWidth: 40,
  width: '100%',
  margin: '0',
  border: 'none',
  cursor: 'pointer',
  alignItems: 'center',

  '&:hover': {
    background: 'rgba(170, 202, 255, 0.24)',
  },

  '&:disabled': {
    color: '$neutral50',
    '&:hover': {
      color: '$neutral50',
      background: 'none',
    },
  },

  '& svg': {
    minWidth: '24px',
  },

  variants: {
    selected: {
      true: {
        background: 'rgba(170, 202, 255, 0.36)',
        color: '$blue50',
        fontWeight: '$medium',
        '&:hover': {
          background: 'rgba(170, 202, 255, 0.36)',
        },
        '&:disabled': {
          background: 'none',
        },
      },
      false: {
        background: 'transparent',
        color: '$neutral90',
        fontWeight: '$regular',
      },
    },
  },
});

const PrismNavItem = ({
  children,
  onSelect,
  selected,
  disabled,
  uri,
}: PrismNavItemProps) => {
  const { 'ios-apple-vision-pro': iosAppleVisionPro } = useFlags();
  const supportedDevices = getSupportedDeviceFamiliesByPrismCategory(
    uri,
    iosAppleVisionPro,
  );
  return (
    <TooltipProvider>
      {!disabled ? (
        <StyledPrismNavItem
          selected={selected}
          disabled={Boolean(disabled)}
          onClick={onSelect}
        >
          {children}
        </StyledPrismNavItem>
      ) : (
        <Tooltip
          content={
            <Flex flow="column" gap="sm">
              <Paragraph size="2">
                {i18n.t(
                  'Current device filter excludes this category. Modify filters to view.',
                )}
              </Paragraph>
              <Flex gap="xs">
                {supportedDevices.map((device) => (
                  <Icon
                    key={device}
                    name={getIconByDeviceFamily(device)}
                    size="sm"
                  />
                ))}
              </Flex>
            </Flex>
          }
          side="right"
          css={{
            zIndex: 5,
          }}
        >
          <StyledPrismNavItem
            selected={selected}
            disabled={Boolean(disabled)}
            onClick={onSelect}
          >
            {children}
          </StyledPrismNavItem>
        </Tooltip>
      )}
    </TooltipProvider>
  );
};

export default PrismNavItem;
