import { i18n } from 'i18n';

/* istanbul ignore file */
export const footerLinks = [
  {
    getLabel: () => 'Privacy Policy',
    link: 'https://www.kandji.io/legal/privacy/',
  },
  {
    getLabel: () => 'Cookie Policy',
    link: 'https://www.kandji.io/legal/privacy/',
  },
  {
    getLabel: () => 'California Privacy Notice',
    link: 'https://www.kandji.io/legal/privacy/',
  },
  {
    getLabel: () => 'Accessibility',
    link: 'https://www.kandji.io/legal/privacy/',
  },
  {
    getLabel: () => 'Legal',
    link: 'https://www.kandji.io/legal/privacy/',
  },
  {
    getLabel: () => 'Patent',
    link: 'https://www.kandji.io/legal/privacy/',
  },
];
