/* istanbul ignore file */
import {
  Chip as BumblebeeChip,
  MultiSelectSearch,
  Toggle,
  setClass,
  useOnChange,
} from '@kandji-inc/bumblebee';
import {
  array,
  bool,
  element,
  func,
  node,
  object,
  oneOfType,
  string,
} from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import './scss/assign.scss';

import {
  Badge,
  Banner,
  Box,
  Button,
  Chip,
  Flex,
  Heading,
  Icon,
  Text,
} from '@kandji-inc/nectar-ui';

import { blueprintTypes } from 'src/app/common/constants';
import HoverTippy from 'src/features/util/components/hover-tippy/hover-tippy';

import { useFlags } from 'src/config/feature-flags';
import { paths } from 'src/features/blueprints/common';
import { i18n } from 'src/i18n';
import { filterArrayFromArray } from '../../common/library-item-page/helpers';
import { LibraryItemContext } from '../../common/library-item-page/library-item-page';
import CircleQuestion from './assets/circle-question.svg';
import AssignMapDropdown from './assign-assignment-map';

const Assign = (props: any) => {
  const { style, className, children, component: Component } = props;

  if (Component) {
    return <Component {...props} />;
  }

  return (
    <div
      style={style}
      className={setClass(className, 'b-library-summary__assign')}
    >
      <h3 className="b-h3 b-library-summary__assign-title">
        {i18n.t('Assignment')}
      </h3>

      <div className="b-library-summary__assign-body">{children}</div>
    </div>
  );
};

Assign.propTypes = {
  style: object,
  className: string,
  children: oneOfType([node, element]),
  component: oneOfType([node, element]),
};

Assign.defaultProps = {
  style: {},
  className: null,
  children: null,
  component: null,
};

const AssignBlueprints = (props: any) => {
  const {
    style,
    className,
    blueprints,
    selectedBlueprints,
    excludedBlueprints,
    isAllBlueprints,
    onChange,
    isDisabled,
    isEditing,
    onClose,
    onAddNew,
  } = props;

  const {
    'dc-assign-to-assignment-map-from-library-item-page': LDFF_assignAMFromLi,
  } = useFlags(['dc-assign-to-assignment-map-from-library-item-page']);
  const {
    selectAssignmentMap,
    selectedAssignmentMaps,
    failedAssignmentMapPlacements,
    clearFailedAssignmentMapPlacements,
  } = useContext(LibraryItemContext);

  const [localModel, setLocalModel] = useState({
    selectedBlueprints,
    excludedBlueprints,
    isAllBlueprints,
  });

  const filterOutAssignmentMaps = (bpList) =>
    bpList.filter(({ type }) => type === blueprintTypes.form);
  const filterOutClassicBlueprints = (bpList, onlySimpleMaps = undefined) =>
    bpList.filter(
      ({ type, is_simple_map }) =>
        type === blueprintTypes.flow && (!onlySimpleMaps || is_simple_map),
    );

  const sortedSelectedAssignmentMaps = selectedAssignmentMaps.sort(
    /* istanbul ignore next */ (a, b) =>
      a.label.toUpperCase() > b.label.toUpperCase() ? 1 : -1,
  );
  const selectedClassicBlueprints = filterOutAssignmentMaps(
    localModel.selectedBlueprints,
  );
  const classicBlueprints = filterOutAssignmentMaps(blueprints);

  const [editedModelWithAllBlueprintsOn, setEditedModelWithAllBlueprintsOn] =
    useState(null);
  const [editedModelWithAllBlueprintsOff, setEditedModelWithAllBlueprintsOff] =
    useState(null);

  // Subscribe to new data passed from the parent component to update the `localModel`.
  // This updates the component upon save or cancel.
  useEffect(() => {
    setLocalModel({
      selectedBlueprints,
      excludedBlueprints,
      isAllBlueprints,
    });
  }, [selectedBlueprints, excludedBlueprints, isAllBlueprints]);

  // Reset the edited models when the user leaves edit state
  useEffect(() => {
    if (!isEditing) {
      setEditedModelWithAllBlueprintsOn(null);
      setEditedModelWithAllBlueprintsOff(null);
    }
  }, [isEditing]);

  // If the "All Blueprints" toggle is ON, always show the "Exclude Blueprints" dropdown while
  // in EDIT mode, but only show it while in VIEW mode if there is at least one Blueprint selected
  // to be excluded.
  const showExcludeBlueprints =
    localModel.isAllBlueprints && (isEditing || excludedBlueprints.length > 0);

  const handleAllBlueprintsToggle = (toggleVal: boolean) => {
    if (toggleVal) {
      // Save the edited "off" state before switching the toggle
      setEditedModelWithAllBlueprintsOff(localModel);

      // If there was a previously edited "on" state, populate the dropdowns
      // with that data. Otherwise, manually select all blueprints.
      if (editedModelWithAllBlueprintsOn) {
        setLocalModel(editedModelWithAllBlueprintsOn);
      } else {
        setLocalModel({
          isAllBlueprints: true,
          selectedBlueprints: [...classicBlueprints, ...selectedAssignmentMaps], // Manually select all Classic Blueprints
          excludedBlueprints: [], // Reset excluded blueprints
        });
      }
    } else {
      // Save the edited "on" state before switching the toggle
      setEditedModelWithAllBlueprintsOn(localModel);

      // If there was a previously edited "off" state, populate the dropdowns
      // with that data. Otherwise, manually select all blueprints that were not excluded.
      if (editedModelWithAllBlueprintsOff) {
        setLocalModel(editedModelWithAllBlueprintsOff);
      } else {
        setLocalModel((prev) => ({
          ...prev,
          isAllBlueprints: false,
          selectedBlueprints: [
            ...filterArrayFromArray(classicBlueprints, excludedBlueprints),
            ...selectedAssignmentMaps,
          ],
          excludedBlueprints: [],
        }));
      }
    }
  };

  const onBlueprintSelect = (
    selectedFormBlueprints: Array<any>,
    selectedFlowBlueprints: Array<any>,
  ) => {
    const selectedBlueprints = [
      ...selectedFormBlueprints,
      ...selectedFlowBlueprints,
    ];
    setLocalModel((prev) => ({
      ...prev,
      selectedBlueprints,
      excludedBlueprints: filterArrayFromArray(blueprints, selectedBlueprints),
    }));
  };

  const handleExcludedBlueprintChange = (newExcludedBlueprints: any) =>
    setLocalModel((prev) => ({
      ...prev,
      // Update selectedBlueprints to include everything except what is now excluded
      selectedBlueprints: filterArrayFromArray(
        [...classicBlueprints, ...selectedAssignmentMaps],
        newExcludedBlueprints,
      ),
      excludedBlueprints: newExcludedBlueprints,
    }));

  const allBlueprintsChip = () => (
    <span className="b-library-summary__all-blueprints">
      <BumblebeeChip
        kind="info"
        text={i18n.t('All Classic Blueprints')}
        iconLeft="kandji-blueprint"
      />
    </span>
  );

  const allBlueprintsToggle = (
    <div className="b-flex">
      <Toggle
        checked={localModel.isAllBlueprints}
        onToggle={handleAllBlueprintsToggle}
      />
      <p className="b-txt b-ml-tiny b-mr-tiny">
        {i18n.t('All Classic Blueprints')}
      </p>
      <HoverTippy
        text={i18n.t(
          'When a new Classic Blueprint is created, this Library Item will automatically be added.',
        )}
        icon={CircleQuestion}
        maxWidth="none"
      />
    </div>
  );

  useOnChange(onChange, localModel);

  return (
    <Flex flow="column">
      <Flex flow="column" gap="lg" css={{ marginBottom: '$2' }}>
        <Flex flow="row" alignItems="center">
          <Icon name="sitemap" size="sm" />
          <Heading
            size="5"
            css={{
              marginLeft: '$1',
              marginRight: '$3',
              fontWeight: '$medium',
            }}
          >
            {i18n.t('Assignment Maps')}
          </Heading>

          {/* @ts-ignore - typing */}
          <Badge color="blue" icon="sparkles">
            {i18n.t('New')}
          </Badge>
        </Flex>

        {failedAssignmentMapPlacements.length > 0 && (
          <Box>
            <Banner
              isDismissable
              onDismiss={clearFailedAssignmentMapPlacements}
              css={{ alignItems: 'center' }}
              theme="danger"
              text={
                <Text>
                  {i18n.t(
                    'This Library Item was not able to be placed on the following maps:',
                  )}{' '}
                  {failedAssignmentMapPlacements.map((am, idx) => (
                    <b style={{ fontWeight: 500 }} key={am.value}>
                      {am.label}
                      {idx < failedAssignmentMapPlacements.length - 1
                        ? ', '
                        : ''}
                    </b>
                  ))}
                  {i18n.t('. Please try again.')}
                </Text>
              }
            />
          </Box>
        )}

        {!selectedAssignmentMaps.length &&
          (!LDFF_assignAMFromLi || !isEditing) && (
            <Flex flow="row" alignItems="end" gap="sm">
              <Text
                css={{
                  color: '$neutral70',
                }}
              >
                {i18n.t('Not used in any Assignment Maps.')}
              </Text>

              <a
                href="https://support.kandji.io/kb/assignment-maps-overview"
                target="_blank"
                rel="noreferrer noopener"
              >
                <Text
                  variant="primary"
                  size="1"
                  css={{
                    fontWeight: '$medium',
                    textDecoration: 'none',
                    '&:hover': {
                      textDecoration: 'underline',
                    },
                  }}
                >
                  {i18n.t('Learn more')}
                </Text>
              </a>
            </Flex>
          )}

        <Flex flow="row" alignItems="center">
          {(selectedAssignmentMaps.length > 0 ||
            (LDFF_assignAMFromLi && isEditing)) && (
            <Text
              css={{
                minWidth: '105px',
                marginRight: '$4',
                paddingTop: '2px',
              }}
            >
              {i18n.t('Blueprint')}
            </Text>
          )}
          <Flex gap="sm" alignItems="center" wrap="wrap">
            {selectedAssignmentMaps.map(({ label, value }) => (
              <Chip
                label={label}
                key={label}
                css={{
                  minWidth: 0,
                }}
                iconRight={
                  LDFF_assignAMFromLi && {
                    icon: 'eye',
                    css: {
                      width: '16px',
                      height: '16px',
                      cursor: 'pointer',
                      color: '$blue60',
                    },
                    onIconClick: () => {
                      const url = new URL(window.location.href);
                      window.open(
                        `${url.origin}${paths.flowBlueprint(value)}`,
                        '_blank',
                      );
                    },
                  }
                }
              />
            ))}
            {LDFF_assignAMFromLi && isEditing && (
              <AssignMapDropdown
                assignments={filterOutClassicBlueprints(
                  blueprints,
                  true,
                ).filter(
                  (blueprint) =>
                    !selectedAssignmentMaps.some(
                      (selectedBlueprint) =>
                        selectedBlueprint.value === blueprint.value,
                    ),
                )}
                onSelect={(selected) => selectAssignmentMap(selected)}
              />
            )}
          </Flex>
        </Flex>

        <Box
          wFull
          css={{
            backgroundColor: '$neutral30',
            height: '1px',
          }}
        />

        <Flex flow="row" alignItems="center">
          <Icon name="memo-pad" size="sm" />
          <Heading
            size="5"
            css={{
              marginLeft: '$1',
              marginRight: '$3',
              fontWeight: '$medium',
            }}
          >
            {i18n.t('Classic Blueprints')}
          </Heading>
        </Flex>
      </Flex>

      <div
        style={style}
        className={setClass('b-library-summary__assign-row', className)}
      >
        <p className="b-txt">{i18n.t('Blueprint')}</p>

        <MultiSelectSearch
          placeholder={i18n.t('Select Blueprint')}
          addNewEnabled
          auto
          options={classicBlueprints}
          onChange={(selected) =>
            onBlueprintSelect(selected, selectedAssignmentMaps)
          }
          onClose={onClose}
          values={selectedClassicBlueprints}
          disabled={isDisabled}
          onAddNew={onAddNew}
          hasSelectAll
          displayCustomValue={
            localModel.isAllBlueprints ? allBlueprintsChip : null
          }
          topControl={allBlueprintsToggle}
          isMenuListHidden={localModel.isAllBlueprints}
        />
      </div>

      {showExcludeBlueprints && (
        <div
          style={style}
          className={setClass(
            'b-library-summary__assign-row k-exclude-blueprints',
            className,
          )}
        >
          <div className="b-library-summary__assign-row-spacer" />

          <div className="b-library-summary__assign-row-wrapper b-mt">
            <p className="b-txt">{i18n.t('Excluding (optional)')}</p>

            <MultiSelectSearch
              placeholder={i18n.t('Select Blueprints to exclude')}
              auto
              options={classicBlueprints}
              hasSelectAll
              /* istanbul ignore next */
              onChange={handleExcludedBlueprintChange}
              onClose={onClose}
              values={localModel.excludedBlueprints}
              disabled={isDisabled}
            />
          </div>
        </div>
      )}
    </Flex>
  );
};

AssignBlueprints.propTypes = {
  style: object,
  className: string,
  selectedBlueprints: array,
  excludedBlueprints: array,
  isAllBlueprints: bool,
  blueprints: array,
  onChange: func,
  isDisabled: bool,
  isEditing: bool,
  onAddNew: func,
  onClose: func,
};

AssignBlueprints.defaultProps = {
  style: {},
  className: null,
  selectedBlueprints: [],
  excludedBlueprints: [],
  isAllBlueprints: false,
  blueprints: null,
  onChange: () => {},
  isDisabled: false,
  isEditing: true,
  onAddNew: () => {},
  onClose: () => {},
};

const AssignDevices = (props: any) => {
  const {
    style,
    className,
    devices,
    selectedDevices,
    /* istanbul ignore next */ compatibleDeviceFamilies = [],
    onChange,
    isDisabled,
    onClose,
    // component: Component,
  } = props;

  // if (Component) {
  //   return <Component {...props} />;
  // }

  /* istanbul ignore next */
  const updateSelectedModelsForCompatibleDeviceFamilies = (
    selectedValues: any[],
  ) =>
    selectedValues.map((device: any) => ({
      value: device.value,
      label: /* istanbul ignore next */ !compatibleDeviceFamilies.includes(
        device.value,
      )
        ? device.value
        : device.label,
    }));

  const [localModel, setLocalModel] = useState({
    selectedDevices,
  });

  // Subscribe to any new `selectedDevices` passed from the parent component to update the `localModel` with latest selected devices
  useEffect(() => {
    setLocalModel({
      selectedDevices,
    });
  }, [selectedDevices]);

  useOnChange(onChange, localModel);

  return (
    <div
      id="install_on_selector"
      style={style}
      className={setClass('b-library-summary__assign-row', className)}
    >
      <p className="b-txt">{i18n.t('Install on')}</p>

      <MultiSelectSearch
        placeholder={i18n.t('Select device families')}
        searchPlaceholder={i18n.t('Filter device families')}
        auto
        options={devices}
        onChange={(v: any) =>
          setLocalModel({
            selectedDevices: updateSelectedModelsForCompatibleDeviceFamilies(v),
          })
        }
        onClose={onClose}
        values={localModel.selectedDevices}
        disabled={isDisabled}
      />
    </div>
  );
};

AssignDevices.propTypes = {
  style: object,
  className: string,
  devices: array,
  selectedDevices: array,
  baseDeviceFamilies: array,
  onChange: func,
  isDisabled: bool,
  onClose: func,
};

AssignDevices.defaultProps = {
  style: {},
  className: null,
  devices: null,
  selectedDevices: [],
  baseDeviceFamilies: [],
  onChange: () => {},
  isDisabled: false,
  onClose: () => {},
};

export { Assign, AssignBlueprints, AssignDevices };
