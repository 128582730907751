import featureFlags from 'src/config/feature-flags';
/* istanbul ignore file */
import { getSelectedDevicesFromRunsOn } from '../../data-service/library-item/devices';

const createInitialState = () => {
  return {
    id: null,
    name: '',
    selectedBlueprints: [],
    excludedBlueprints: [],
    isAllBlueprints: false,
    isActive: true,
    devices: getSelectedDevicesFromRunsOn({
      runs_on_mac: true,
      runs_on_iphone: true,
      runs_on_tv: true,
      runs_on_ipad: true,
      runs_on_watch: true,
      runs_on_vision: featureFlags.getFlag('ios-apple-vision-pro'),
    }),

    general: {
      URL: '',
      Name: '',
      Challenge: '',
      CAFingerprint: '',
    },

    subject: {
      Subject: '',
      SubjectAlternativeNameType: 'None',
      SubjectAltName: '',
      ntPrincipalName: '',
    },

    key: {
      Keysize: 1024,
      'Key Usage': 0,
    },

    options: {
      Retries: {
        checked: false,
        value: 3,
      },
      RetryDelay: {
        checked: false,
        value: 10,
      },
      KeyIsExtractable: false,
      AllowAllAppsAccess: false,
      CertificateRenewalTimeInterval: {
        checked: false,
        value: 14,
      },
      Redistribution: {
        checked: false,
        value: 30,
      },
    },
  };
};

export default createInitialState;
