import { intersection } from 'lodash';

import { i18n } from 'src/i18n';
import InstallContinuous from './library-list/assets/install_continuous.svg';
import InstallOnce from './library-list/assets/install_once.svg';

const libraryRoute = {
  library: '/library',
  addLibrary: '/library/add',
};

/* A list of device types */
const deviceTypes = {
  IPAD: 'iPad',
  IPHONE: 'iPhone',
  MAC: 'Mac',
  TV: 'Apple TV',
  VISION: 'Vision',
} as const;

/* istanbul ignore next */
export const displayDeviceType = i18n.createMap({
  [deviceTypes.IPAD]: () => i18n.t('iPad'),
  [deviceTypes.IPHONE]: () => i18n.t('iPhone'),
  [deviceTypes.MAC]: () => i18n.t('Mac'),
  [deviceTypes.TV]: () => i18n.t('Apple TV'),
  [deviceTypes.VISION]: () => i18n.t('Vision'),
});

/* Maps the api response for a device to a string. */
const apiToDevice = {
  runs_on_ipad: deviceTypes.IPAD,
  runs_on_iphone: deviceTypes.IPHONE,
  runs_on_mac: deviceTypes.MAC,
  runs_on_tv: deviceTypes.TV,
  runs_on_vision: deviceTypes.VISION,
};

/* A list of OS Prefixes for support device types. */
const osPrefixes = {
  [deviceTypes.MAC]: 'macOS',
  [deviceTypes.IPHONE]: 'iOS',
  [deviceTypes.IPAD]: 'iPadOS',
  [deviceTypes.TV]: 'tvOS',
  [deviceTypes.VISION]: 'visionOS',
};

/* OS requirements for when defining library items that do not come from the
API. */
const osRequirements = {
  MAC_10_7: 'macOS 10.7+',
  MAC_10_9: 'macOS 10.9+',
  MAC_10_10: 'macOS 10.10+',
  MAC_10_11: 'macOS 10.11+',
  MAC_10_12: 'macOS 10.12+',
  MAC_10_12_4: 'macOS 10.12.4+',
  MAC_10_13: 'macOS 10.13+',
  MAC_10_13_2: 'macOS 10.13.2+',
  MAC_10_13_4: 'macOS 10.13.4+',
  MAC_10_13_6: 'macOS 10.13.6+',
  MAC_10_14: 'macOS 10.14+',
  MAC_10_14_4: 'macOS 10.14.4+',
  MAC_10_15: 'macOS 10.15+',
  MAC_11_0: 'macOS 11.0+',
  MAC_11_1: 'macOS 11.1+',
  MAC_13_0: 'macOS 13+',
  MAC_14_0: 'macOS 14+',
  MAC_14_1: 'macOS 14.1+',
  MAC_15_0: 'macOS 15+',
  IOS_4_0: 'iOS 4.0+',
  IOS_6_0: 'iOS 6.0+',
  IOS_7_0: 'iOS 7.0+',
  IOS_8_0: 'iOS 8.0+',
  IOS_8_1: 'iOS 8.1+',
  IOS_8_3: 'iOS 8.3+',
  IOS_9_0: 'iOS 9.0+',
  IOS_9_3: 'iOS 9.3+',
  IOS_9_3_2: 'iOS 9.3.2+',
  IOS_10_0: 'iOS 10.0+',
  IOS_11_0: 'iOS 11.0+',
  IOS_11_13: 'iOS 11.13+',
  IOS_12_0: 'iOS 12.0+',
  IOS_13_0: 'iOS 13.0+',
  IOS_13_1: 'iOS 13.1+',
  IOS_14_0: 'iOS 14.0+',
  IOS_14_3: 'iOS 14.3+',
  IOS_15_0: 'iOS 15.0+',
  IOS_16_0: 'iOS 16.0+',
  IOS_17_0: 'iOS 17.0+',
  IOS_18_0: 'iOS 18.0+',
  IPAD_9_0: 'iPadOS 9.0+',
  IPAD_11_0: 'iPadOS 11.0+',
  IPAD_12_0: 'iPadOS 12.0+',
  IPAD_13_0: 'iPadOS 13.0+',
  IPAD_13_1: 'iPadOS 13.1+',
  IPAD_13_6: 'iPadOS 13.6+',
  IPAD_14_0: 'iPadOS 14.0+',
  IPAD_14_3: 'iPadOS 14.3+',
  IPAD_15_0: 'iPadOS 15.0+',
  IPAD_16_0: 'iPadOS 16.0+',
  IPAD_17_0: 'iPadOS 17.0+',
  IPAD_18_0: 'iPadOS 18.0+',
  TV_9_0: 'tvOS 9.0+',
  TV_10_0: 'tvOS 10.0+',
  TV_10_2: 'tvOS 10.2+',
  TV_11_0: 'tvOS 11.0+',
  TV_11_4: 'tvOS 11.4+',
  TV_11_13: 'tvOS 11.13+',
  TV_14_0: 'tvOS 14.0+',
  TV_17_0: 'tvOS 17.0+',
  VISION_1_0: 'visionOS 1.0+',
  VISION_1_1: 'visionOS 1.1+',
  VISION_2_0: 'visionOS 2.0+',
} as const;

/* istanbul ignore next */
export const i18nOsRequirements = i18n.createMap({
  [osRequirements.MAC_10_7]: () => i18n.t('macOS 10.7+'),
  [osRequirements.MAC_10_9]: () => i18n.t('macOS 10.9+'),
  [osRequirements.MAC_10_10]: () => i18n.t('macOS 10.10+'),
  [osRequirements.MAC_10_11]: () => i18n.t('macOS 10.11+'),
  [osRequirements.MAC_10_12]: () => i18n.t('macOS 10.12+'),
  [osRequirements.MAC_10_12_4]: () => i18n.t('macOS 10.12.4+'),
  [osRequirements.MAC_10_13]: () => i18n.t('macOS 10.13+'),
  [osRequirements.MAC_10_13_2]: () => i18n.t('macOS 10.13.2+'),
  [osRequirements.MAC_10_13_4]: () => i18n.t('macOS 10.13.4+'),
  [osRequirements.MAC_10_13_6]: () => i18n.t('macOS 10.13.6+'),
  [osRequirements.MAC_10_14]: () => i18n.t('macOS 10.14+'),
  [osRequirements.MAC_10_14_4]: () => i18n.t('macOS 10.14.4+'),
  [osRequirements.MAC_10_15]: () => i18n.t('macOS 10.15+'),
  [osRequirements.MAC_11_0]: () => i18n.t('macOS 11.0+'),
  [osRequirements.MAC_11_1]: () => i18n.t('macOS 11.1+'),
  [osRequirements.MAC_13_0]: () => i18n.t('macOS 13+'),
  [osRequirements.MAC_14_0]: () => i18n.t('macOS 14.0+'),
  [osRequirements.MAC_14_1]: () => i18n.t('macOS 14.1+'),
  [osRequirements.MAC_15_0]: () => i18n.t('macOS 15+'),
  [osRequirements.IOS_4_0]: () => i18n.t('iOS 4.0+'),
  [osRequirements.IOS_6_0]: () => i18n.t('iOS 6.0+'),
  [osRequirements.IOS_7_0]: () => i18n.t('iOS 7.0+'),
  [osRequirements.IOS_8_0]: () => i18n.t('iOS 8.0+'),
  [osRequirements.IOS_8_1]: () => i18n.t('iOS 8.1+'),
  [osRequirements.IOS_8_3]: () => i18n.t('iOS 8.3+'),
  [osRequirements.IOS_9_0]: () => i18n.t('iOS 9.0+'),
  [osRequirements.IOS_9_3]: () => i18n.t('iOS 9.3+'),
  [osRequirements.IOS_9_3_2]: () => i18n.t('iOS 9.3.2+'),
  [osRequirements.IOS_10_0]: () => i18n.t('iOS 10.0+'),
  [osRequirements.IOS_11_0]: () => i18n.t('iOS 11.0+'),
  [osRequirements.IOS_11_13]: () => i18n.t('iOS 11.13+'),
  [osRequirements.IOS_12_0]: () => i18n.t('iOS 12.0+'),
  [osRequirements.IOS_13_0]: () => i18n.t('iOS 13.0+'),
  [osRequirements.IOS_13_1]: () => i18n.t('iOS 13.1+'),
  [osRequirements.IOS_14_0]: () => i18n.t('iOS 14.0+'),
  [osRequirements.IOS_14_3]: () => i18n.t('iOS 14.3+'),
  [osRequirements.IOS_15_0]: () => i18n.t('iOS 15.0+'),
  [osRequirements.IOS_16_0]: () => i18n.t('iOS 16.0+'),
  [osRequirements.IOS_17_0]: () => i18n.t('iOS 17.0+'),
  [osRequirements.IOS_18_0]: () => i18n.t('iOS 18.0+'),
  [osRequirements.IPAD_9_0]: () => i18n.t('iPadOS 9.0+'),
  [osRequirements.IPAD_11_0]: () => i18n.t('iPadOS 11.0+'),
  [osRequirements.IPAD_12_0]: () => i18n.t('iPadOS 12.0+'),
  [osRequirements.IPAD_13_0]: () => i18n.t('iPadOS 13.0+'),
  [osRequirements.IPAD_13_1]: () => i18n.t('iPadOS 13.1+'),
  [osRequirements.IPAD_13_6]: () => i18n.t('iPadOS 13.6+'),
  [osRequirements.IPAD_14_0]: () => i18n.t('iPadOS 14.0+'),
  [osRequirements.IPAD_14_3]: () => i18n.t('iPadOS 14.3+'),
  [osRequirements.IPAD_15_0]: () => i18n.t('iPadOS 15.0+'),
  [osRequirements.IPAD_16_0]: () => i18n.t('iPadOS 16.0+'),
  [osRequirements.IPAD_17_0]: () => i18n.t('iPadOS 17.0+'),
  [osRequirements.IPAD_18_0]: () => i18n.t('iPadOS 18.0+'),
  [osRequirements.TV_9_0]: () => i18n.t('tvOS 9.0+'),
  [osRequirements.TV_10_0]: () => i18n.t('tvOS 10.0+'),
  [osRequirements.TV_10_2]: () => i18n.t('tvOS 10.2+'),
  [osRequirements.TV_11_0]: () => i18n.t('tvOS 11.0+'),
  [osRequirements.TV_11_4]: () => i18n.t('tvOS 11.4+'),
  [osRequirements.TV_11_13]: () => i18n.t('tvOS 11.13+'),
  [osRequirements.TV_14_0]: () => i18n.t('tvOS 14.0+'),
  [osRequirements.TV_17_0]: () => i18n.t('tvOS 17.0+'),
  [osRequirements.VISION_1_0]: () => i18n.t('visionOS 1.0+'),
  [osRequirements.VISION_1_1]: () => i18n.t('visionOS 1.1+'),
  [osRequirements.VISION_2_0]: () => i18n.t('visionOS 2.0+'),
});

/* A list of publishers for when defining configurations for library items that
do not come from the API. */
const publishers = {
  KANDJI: 'Kandji, Inc.',
  APPLE: 'Apple, Inc.',
} as const;

/* istanbul ignore next */
export const publishersTranslations = i18n.createMap({
  [publishers.KANDJI]: () => i18n.t('Kandji, Inc.'),
  [publishers.APPLE]: () => i18n.t('Apple, Inc.'),
});

const installTypes = {
  CONTINUOUS: 'continuously_enforce',
  ONCE: 'install_once',
  NONE: 'no_enforcement',
};

const installTypeIcons = {
  [installTypes.ONCE]: InstallOnce,
  [installTypes.CONTINUOUS]: InstallContinuous,
};

const updateOnlyIconName = 'calendar-arrow-down';

const complianceTags = {
  CIS: 'CIS',
  NCSC: 'NCSC',
  SUPERVISED: 'Supervised',
  CMMC: 'CMMC',
  NIST: 'NIST',
};

const categories = {
  APP_STORE: 'App Store Apps',
  AUTO_APPS: 'Auto Apps',
  BOOKMARKS: 'Bookmarks',
  CUSTOM_APPS: 'Custom Apps',
  CUSTOM_PRINTERS: 'Custom Printers',
  CUSTOM_SCRIPTS: 'Custom Scripts',
  ENROLLMENT: 'Enrollment Configurations',
  GENERAL: 'General',
  IN_HOUSE_APPS: 'In-House Apps',
  IOS_RELEASES: 'iOS Releases',
  IPADOS_RELEASES: 'iPadOS Releases',
  MDM_MACOS_RELEASES: 'MDM Managed OS Releases',
  OPERATING_SYSTEMS: 'Operating Systems',
  PROFILES: 'Profiles',
  SECURITY: 'Endpoint Security',
  TVOS_RELEASES: 'tvOS Releases',
} as const;

export const displayLibraryCategory = i18n.createMap({
  [categories.APP_STORE]: () => i18n.t('App Store Apps'),
  [categories.AUTO_APPS]: () => i18n.t('Auto Apps'),
  [categories.BOOKMARKS]: () => i18n.t('Bookmarks'),
  [categories.CUSTOM_APPS]: () => i18n.t('Custom Apps'),
  [categories.CUSTOM_PRINTERS]: () => i18n.t('Custom Printers'),
  [categories.CUSTOM_SCRIPTS]: () => i18n.t('Custom Scripts'),
  [categories.ENROLLMENT]: () => i18n.t('Enrollment Configurations'),
  [categories.GENERAL]: () => i18n.t('General'),
  [categories.IN_HOUSE_APPS]: () => i18n.t('In-House Apps'),
  [categories.IOS_RELEASES]: () => i18n.t('iOS Releases'),
  [categories.IPADOS_RELEASES]: () => i18n.t('iPadOS Releases'),
  [categories.MDM_MACOS_RELEASES]: () => i18n.t('MDM Managed OS Releases'),
  [categories.OPERATING_SYSTEMS]: () => i18n.t('Operating Systems'),
  [categories.PROFILES]: () => i18n.t('Profiles'),
  [categories.SECURITY]: () => i18n.t('Endpoint Security'),
  [categories.TVOS_RELEASES]: () => i18n.t('tvOS Releases'),
  Parameters: () => i18n.t('Parameters'),
});

const apiTypes = /** @type {const} */ {
  BOOKMARK: 'bookmark',
  CUSTOM_APP: 'custom-app',
  CUSTOM_PRINTER: 'custom-printer',
  CUSTOM_SCRIPT: 'custom-script',
  VPP_APP: 'vpp-app',
  PROFILE: 'profile',
  AUTO_APP: 'automatic-app',
  IPA_APP: 'ipa-app',
  IOS_RELEASE: 'ios-release',
  IPADOS_RELEASE: 'ipados-release',
  MACOS_RELEASE: 'macos-release',
  MDM_MACOS_RELEASE: 'mdm-macos-release',
  TVOS_RELEASE: 'tvos-release',
  DEP_PROFILE: 'dep-profile',
  KANDJI_SETUP: 'kandji-setup',
  KANDJI_LOGIN: 'kandji-login',
  SSH: 'ssh',
  RECOVERY_PASSWORD: 'recovery-password',
  THREAT_SECURITY_POLICY: 'threat-security-policy',
  DEVICE_NAME: 'device-name',
  WALLPAPER: 'wallpaper',
  WEBCLIP: 'webclip',
  DEVICE_CONTROL: 'device-control',
  IPA_APP_V2: 'ipa-app-v2',
  APP_BLOCKING: 'app-blocking',
} as const;

const humanReadableTypes = {
  [apiTypes.AUTO_APP]: 'Auto App',
  [apiTypes.BOOKMARK]: 'Bookmarks',
  [apiTypes.CUSTOM_APP]: 'Custom Apps',
  [apiTypes.CUSTOM_PRINTER]: 'Custom Printers',
  [apiTypes.CUSTOM_SCRIPT]: 'Custom Scripts',
  [apiTypes.DEP_PROFILE]: 'Automated Device Enrollment',
  [apiTypes.DEVICE_CONTROL]: 'Accessory & Storage Access',
  [apiTypes.DEVICE_NAME]: 'Device Name',
  [apiTypes.IOS_RELEASE]: 'Operating System',
  [apiTypes.IPA_APP]: 'In-House App',
  [apiTypes.IPADOS_RELEASE]: 'Operating System',
  [apiTypes.KANDJI_LOGIN]: 'Passport',
  [apiTypes.KANDJI_SETUP]: 'Liftoff',
  [apiTypes.MACOS_RELEASE]: 'Operating System',
  [apiTypes.MDM_MACOS_RELEASE]: 'Operating System',
  [apiTypes.PROFILE]: 'Profile',
  [apiTypes.RECOVERY_PASSWORD]: 'Recovery Password',
  [apiTypes.SSH]: 'SSH',
  [apiTypes.THREAT_SECURITY_POLICY]: 'Avert',
  [apiTypes.TVOS_RELEASE]: 'Operating System',
  [apiTypes.VPP_APP]: 'App Store App',
  [apiTypes.WALLPAPER]: 'Wallpaper',
  [apiTypes.WEBCLIP]: 'Web Clip',
  [apiTypes.APP_BLOCKING]: 'App Blocking',
} as const;

const visionRequirements = ['visionOS 1.0+', 'visionOS 1.1+', 'visionOS 2.0+'];

/**
 * This list is used in the Classic Blueprint library item list
 * (it seems to be a unique list of categories and types, only used on the Classic Blueprints page)
 */
/* istanbul ignore next */
export const displayHumanReadableTypes = i18n.createMap({
  'Accessory & Storage Access': () => i18n.t('Accessory & Storage Access'),
  'AirPlay Security': () => i18n.t('AirPlay Security'),
  AirPrint: () => i18n.t('AirPrint'),
  'App Blocking': () => i18n.t('App Blocking'),
  'App Lock': () => i18n.t('App Lock'),
  'App Store': () => i18n.t('App Store'),
  'App Store App': () => i18n.t('App Store App'),
  'Auto Apps': () => i18n.t('Auto Apps'),
  'Automated Device Enrollment': () => i18n.t('Automated Device Enrollment'),
  Avert: () => i18n.t('Avert'),
  Bookmarks: () => i18n.t('Bookmarks'),
  Certificate: () => i18n.t('Certificate'),
  'Conference Room Display': () => i18n.t('Conference Room Display'),
  'Custom Apps': () => i18n.t('Custom Apps'),
  'Custom Printers': () => i18n.t('Custom Printers'),
  'Custom Profile': () => i18n.t('Custom Profile'),
  'Custom Scripts': () => i18n.t('Custom Scripts'),
  'Device Name': () => i18n.t('Device Name'),
  'Disk Management': () => i18n.t('Disk Management'),
  'Energy Saver': () => i18n.t('Energy Saver'),
  Ethernet: () => i18n.t('Ethernet'),
  FileVault: () => i18n.t('FileVault'),
  Firewall: () => i18n.t('Firewall'),
  Gatekeeper: () => i18n.t('Gatekeeper'),
  'Home Screen Layout': () => i18n.t('Home Screen Layout'),
  'In-House Apps': () => i18n.t('In-House Apps'),
  'Kernel Extension': () => i18n.t('Kernel Extension'),
  Liftoff: () => i18n.t('Liftoff'),
  'Lock Screen Message': () => i18n.t('Lock Screen Message'),
  'Login & Background Items': () => i18n.t('Login & Background Items'),
  'Login Window': () => i18n.t('Login Window'),
  'Managed Data Flow': () => i18n.t('Managed Data Flow'),
  'Media Access': () => i18n.t('Media Access'),
  'Operating Systems': () => i18n.t('Operating Systems'),
  Passcode: () => i18n.t('Passcode'),
  Passport: () => i18n.t('Passport'),
  Privacy: () => i18n.t('Privacy'),
  'Recovery Password': () => i18n.t('Recovery Password'),
  Restrictions: () => i18n.t('Restrictions'),
  'Safari Extensions': () => i18n.t('Safari Extensions'),
  SCEP: () => i18n.t('SCEP'),
  'Screen Saver': () => i18n.t('Screen Saver'),
  'Setup Assistant': () => i18n.t('Setup Assistant'),
  'Single Sign-On Extension': () => i18n.t('Single Sign-On Extension'),
  'Software Update': () => i18n.t('Software Update'),
  SSH: () => i18n.t('SSH'),
  'System Extension': () => i18n.t('System Extension'),
  'System Preferences Panes': () => i18n.t('System Preferences Panes'),
  VPN: () => i18n.t('VPN'),
  Wallpaper: () => i18n.t('Wallpaper'),
  'Web Clip': () => i18n.t('Web Clip'),
  'Wi-Fi': () => i18n.t('Wi-Fi'),
});

/* Maps types from the API to the categories displayed on the library pages. */
export const typeToCategory = {
  [apiTypes.CUSTOM_SCRIPT]: categories.CUSTOM_SCRIPTS,
  [apiTypes.VPP_APP]: categories.APP_STORE,
  [apiTypes.PROFILE]: categories.PROFILES,
  [apiTypes.SSH]: categories.PROFILES,
  [apiTypes.RECOVERY_PASSWORD]: categories.PROFILES,
  [apiTypes.AUTO_APP]: categories.AUTO_APPS,
  [apiTypes.CUSTOM_APP]: categories.CUSTOM_APPS,
  [apiTypes.CUSTOM_PRINTER]: categories.CUSTOM_PRINTERS,
  [apiTypes.IPA_APP]: categories.IN_HOUSE_APPS,
  [apiTypes.IPA_APP_V2]: categories.IN_HOUSE_APPS,
  [apiTypes.IOS_RELEASE]: categories.OPERATING_SYSTEMS,
  [apiTypes.IPADOS_RELEASE]: categories.OPERATING_SYSTEMS,
  [apiTypes.MACOS_RELEASE]: categories.OPERATING_SYSTEMS,
  [apiTypes.MDM_MACOS_RELEASE]: categories.MDM_MACOS_RELEASES,
  [apiTypes.TVOS_RELEASE]: categories.OPERATING_SYSTEMS,
  [apiTypes.KANDJI_SETUP]: categories.ENROLLMENT,
  [apiTypes.DEP_PROFILE]: categories.ENROLLMENT,
  [apiTypes.KANDJI_LOGIN]: categories.ENROLLMENT,
  [apiTypes.THREAT_SECURITY_POLICY]: categories.SECURITY,
  [apiTypes.BOOKMARK]: categories.BOOKMARKS,
  [apiTypes.DEVICE_NAME]: categories.PROFILES,
  [apiTypes.WALLPAPER]: categories.PROFILES,
  [apiTypes.WEBCLIP]: categories.PROFILES,
  [apiTypes.DEVICE_CONTROL]: categories.SECURITY,
  [apiTypes.APP_BLOCKING]: categories.PROFILES,
};

const getItemCategory = (type) => typeToCategory[type];

const getItemTypeDisplayName = (type) => humanReadableTypes[type];

const canAddLibraryTypes = {
  PREDEFINED_BY_BACKEND: 'predefined_by_backend',
  PREDEFINED_BY_FRONTEND: 'predefined_by_frontend',
  CANNOT_ADD: 'cannot_add',
} as const;

// This one can be used for both main Library page and Add Library Item page
/**
 * @typedef ItemData
 * @type {Object}
 * @property {string} name
 * @property {string} instance_name
 * @property {Array<string>} devices
 */
/**
 * @typedef CategoryData
 * @type {Object}
 * @property {string} category
 * @property {Array<ItemData>} data
 */
/**
 * @typedef Filters
 * @type {Object}
 * @property {string} category
 * @property {string} searchTerm
 * @property {Array<string>} devices
 */

/**
 * @param {Array<CategoryData>} allItemsByCategories
 * @param {Filters} filters
 * @param {Array<string>} order
 * @param {function(Object): boolean} isItemHidden
 * @returns {null|Array<CategoryData>}
 */
function getFilteredItems(allItemsByCategories, filters, order, isItemHidden) {
  if (!allItemsByCategories) {
    return null;
  }

  return allItemsByCategories
    .map((catData) => ({
      ...catData,
      data: catData.data.filter((item) => {
        const fitsByDevices = !!intersection(item.devices, filters.devices)
          .length;
        const fitsByNameSearch = item.name
          .toLowerCase()
          .includes(filters.searchTerm.toLowerCase());
        const fitsByInstanceNameSearch = item.instance_name
          ? item.instance_name
              .toLowerCase()
              .includes(filters.searchTerm.toLowerCase())
          : false;
        const notHidden = !isItemHidden(item);

        return (
          fitsByDevices &&
          (fitsByNameSearch || fitsByInstanceNameSearch) &&
          notHidden
        );
      }),
    }))
    .filter((catData) => {
      const fitsByCategory =
        !filters.category ||
        catData.category.toLowerCase() === filters.category.toLowerCase();
      const hasFittingItems = !!catData.data.length;

      return fitsByCategory && hasFittingItems;
    })
    .sort(
      (a, b) =>
        order.findIndex((orderedCat) => orderedCat === a.category) -
        order.findIndex((orderedCat) => orderedCat === b.category),
    );
}

const singularizeName = (name) => {
  const endings = {
    ves: 'fe',
    ies: 'y',
    i: 'us',
    zes: 'ze',
    ses: 's',
    es: 'e',
    s: '',
  };
  return name.replace(
    new RegExp(`(${Object.keys(endings).join('|')})$`),
    (r) => endings[r],
  );
};

export {
  apiToDevice,
  apiTypes,
  canAddLibraryTypes,
  categories,
  complianceTags,
  deviceTypes,
  getFilteredItems,
  getItemCategory,
  getItemTypeDisplayName,
  installTypeIcons,
  installTypes,
  libraryRoute,
  osPrefixes,
  osRequirements,
  publishers,
  singularizeName,
  updateOnlyIconName,
  visionRequirements,
};
