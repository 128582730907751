import { Chip, Select } from '@kandji-inc/bumblebee';
import React from 'react';

import { Setting } from 'features/library-items/template';
import {
  i18nOsRequirements,
  osRequirements,
} from 'src/features/library-items/library/common';
import { i18n } from 'src/i18n';

import {
  ACTIVATION_LOCK_OPTIONS,
  ACTIVATION_LOCK_OPTION_ITEM,
} from '../activation-lock';

const ActivationLockRow = ({ setting, update, isDisabled }) => (
  <Setting.Row>
    <div>
      <p className="b-txt">{i18n.t('Activation Lock')}</p>
    </div>
    <Setting.Helpers>
      <p className="b-txt-light">
        {i18n.t(`Specify if users should be allowed to enable Activation Lock using Find
        My and a personal Apple Account.`)}{' '}
        &nbsp;
        <a
          href="https://support.kandji.io/kb/activation-lock"
          rel="noopener noreferrer"
          target="_blank"
          className="b-alink"
        >
          {i18n.t('Learn More...')}
        </a>
      </p>
      <div className="b-flex-wrap-gtiny">
        <Chip
          kind="tertiary-light"
          text={i18nOsRequirements(osRequirements.MAC_10_15)}
          className="k-ade-chip--m0"
        />
      </div>
    </Setting.Helpers>
    <div>
      <Select
        isDisabled={isDisabled}
        options={ACTIVATION_LOCK_OPTIONS()}
        compact
        onChange={(v) =>
          update(
            'userActivationLockAllowed',
            v.value === ACTIVATION_LOCK_OPTION_ITEM.ALLOW().value,
          )
        }
        value={
          setting.userActivationLockAllowed
            ? ACTIVATION_LOCK_OPTION_ITEM.ALLOW()
            : ACTIVATION_LOCK_OPTION_ITEM.DISALLOW()
        }
      />
    </div>
  </Setting.Row>
);

export default ActivationLockRow;
