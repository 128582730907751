/* istanbul ignore file */
import type {
  BaseLibraryItemModel,
  LibraryItemApiData,
} from '../../library-items.types';
import { deviceTypes } from '../../library/common';

export interface IpaAppApiData extends LibraryItemApiData {
  skip_blueprint_conflict?: boolean;
  file: string;
  sha256: string;
  app_icon: string | null | undefined;
  icon: string;
  ipad_icon: string | null | undefined;
  app_identifier: string;
  app_name: string;
  app_version: string;
  file_name: string;
  file_size: number;
  file_updated: string;
  file_url: string;
  file_key: string | null | undefined;
  data: {
    file_url: '';
    app_icon: '';
  };
}

export interface IpaFile {
  id?: string;
  file: string;
  fileName: string;
  fileSize: number;
  fileKey: string | null | undefined;
  fileUpdated: string;
  fileUrl: string;
  appId: string | null | undefined;
  appVersion: string;
  sha256: string;
  appIcon: string | null | undefined;
  ipadIcon: string | null | undefined;
  appName: string;
}

export interface IpaAppModel extends BaseLibraryItemModel {
  icon: string;
  installDetails: {
    ipaFile: IpaFile;
  };
}

export type IpaAppFromApi =
  | IpaAppApiData
  | {
      data: IpaAppModel;
    };

export const ipaSupportedDevices = [
  deviceTypes.IPHONE,
  deviceTypes.IPAD,
  deviceTypes.TV,
  deviceTypes.VISION,
];
