import { LibraryItemStatusLog } from '../../lib/LibraryItemStatusLog';
import {
  type AppAppleStatusDetails,
  ManagedAppStatusDescriptions,
} from '../IPA_VPP.types';

function serializerV1(details: AppAppleStatusDetails) {
  const { app_status } = details;

  return new LibraryItemStatusLog()
    .addRow(ManagedAppStatusDescriptions(app_status))
    .toString();
}

export const InstallFailedAuditSerializers = {
  Default: serializerV1,
  1: serializerV1,
};
