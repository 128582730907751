import { i18n } from 'src/i18n';
/* istanbul ignore file */
import { v4 } from 'uuid';

/** Currently used feature flags for restrictions. */
export const flags = {
  lit08292023restrictionsupervisionreqdep:
    'lit_08292023_restriction_supervision_req_dep',
};

export type TagKind =
  | 'iPad'
  | 'iPhone'
  | 'Mac'
  | 'Apple TV'
  | 'Vision'
  | ReturnType<typeof i18n.t>;

export const tagKinds: () => Record<string, TagKind> = () => ({
  ipad: 'iPad',
  iphone: 'iPhone',
  mac: 'Mac',
  tv: 'Apple TV',
  vision: 'Vision',
  supervised: i18n.t('Supervised'),
});

export type UpdateFn = (key: string, v: any | ((prev: any) => void)) => void;
export type TransformerFn = (...args: any[]) => void;
export type FieldType = 'checkbox' | 'select' | 'custom' | 'number' | 'radio';
export type RestrictionConfig = {
  key: string;
  /**
    *
   Useful when there are multiple keys associated with a single field in the
   UI. Currently, will only specify keys. So transformers from the
   configuration are re-used. This is specifically used when a key needs to
   find a configuration that is used by another key, or set of keys. ex.
   blacklistedAppBundleIDs
    */
  secondaryKeys?: Array<string>;
  name: string;
  description?: string;
  tags?: Array<TagKind>;
  isInverted?: boolean;

  // This field holds the inversion value from Restrictions V1.
  legacyInversion?: boolean;
  descriptionLink?: {
    link: string;
    text: string;
  };
  isHidden?: boolean;
  dependencies?: Array<{ key: string; mustBe: boolean }>;
  hideDependencyText?: boolean;
  supervisionWarning?: boolean;
  customWarning?: string;
  type?: FieldType;
  component?: React.FC;
  componentProps?: React.ComponentPropsWithRef<any>;
  selectOptions?: Array<{ label: string; value: any }>;
  radioOptions?: Array<{ label: string; value: string }>;
  helper?: string;
  minimum?: number;
  maximum?: number;
  transformersToAPI?: Array<TransformerFn>;
  transformersFromAPI?: Array<TransformerFn>;

  onAfterChange?: (
    newValue: any,
    setting: Record<string, any>,
    update: (key: string, v: any | ((prev: any) => void)) => void,
  ) => void;
};

export const fieldTypes: Record<FieldType, FieldType> = {
  checkbox: 'checkbox',
  select: 'select',
  custom: 'custom',
  number: 'number',
  radio: 'radio',
};

export const getIsChecked = (setting, key, invert = false) => {
  let check = false;

  if (!setting[key]) {
    return check;
  }

  if ('checked' in setting[key]) {
    check = setting[key].checked;
  } else {
    check = setting[key].value;
  }

  return invert ? !check : check;
};

export const transformToAppRestriction = (v, { model, key }) => {
  const { _helpers } = model;
  const apps = _helpers.appRestrictionsValues;
  const restrictionType = _helpers.appRestrictionsOption;
  const isManagingAppRestrictions = _helpers.isManageAppRestrictions;

  if (!isManagingAppRestrictions) {
    return [];
  }

  if (
    (restrictionType === 'disallow' && key === 'blacklistedAppBundleIDs') ||
    (restrictionType === 'allow' && key === 'whitelistedAppBundleIDs')
  ) {
    return removeIdFieldFromValue(apps);
  }

  return [];
};

export const transformFromAppRestriction = (v, { model, key }) => {
  const { checked, value } = v;
  const model_helpers = model._helpers;

  if (checked) {
    model_helpers.isManageAppRestrictions = checked;
    model_helpers.appRestrictionsValues = value;
    model_helpers.appRestrictionsOption =
      key === 'blacklistedAppBundleIDs' ? 'disallow' : 'allow';
  }

  return v;
};

export const extractValueArrayFromObject = (v: {
  [k: string]: any;
  checked: boolean;
  value: Array<any>;
}) => {
  const { checked, value } = v;
  if (checked) {
    return value;
  }

  return [];
};

export const interpolateArrayValueIntoCheckedObject = (v: Array<any>) => ({
  checked: !!v.length,
  value: v,
});

export const transformValueStringToNumber = (v: {
  [k: string]: any;
  value: string;
}) => {
  const { value, ...rest } = v;
  return { ...rest, value: parseInt(value, 10) };
};

export const transformValueNumberToString = (v: {
  [k: string]: any;
  value: number;
}) => {
  const { value, ...rest } = v;
  return { ...rest, value: value.toString() };
};

export const removeIdFieldFromValue = (
  value: Array<{ _id: string; [k: string]: any }>,
) => value.map(({ _id, ...rest }) => ({ ...rest }));

export const addIdFieldToValue = (v: {
  [k: string]: any;
  value: Array<{ [k: string]: any }>;
}) => {
  const { value, ...rest } = v;

  return {
    ...rest,
    value: value.map((d) => ({ ...d, _id: v4() })),
  };
};
