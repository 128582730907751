import { Flex, Heading, Text } from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';
import type { DetectionDateFilterFields } from 'src/features/edr/common/common.types';
import VulnerabilityIcon from '../../assets/vulnerability.png';
import { DETECTION_DATE_SELECT_CUSTOM_RANGE_OPTIONS } from '../../constants';

const NoVulnerabilities = (props: {
  lastDetectedFilter: DetectionDateFilterFields;
  onDeviceRecord?: boolean;
}) => {
  const { lastDetectedFilter, onDeviceRecord = false } = props;
  let copy = '';
  const target = onDeviceRecord
    ? i18n.t('on this device')
    : i18n.t('in your Mac fleet');

  // The Last Detected filter is not set
  if (!lastDetectedFilter?.value) {
    copy = i18n.t('No vulnerabilities have been detected {target}.', {
      target,
    });
  } else if (lastDetectedFilter.value === 'custom_date_range') {
    copy = i18n.t(
      'No vulnerabilities have been detected {target} within the specified detection period.',
      {
        target,
      },
    );
  } else if (lastDetectedFilter.value === 'all_time') {
    copy = i18n.t(
      'Across all time, no vulnerabilities have been detected {target}.',
      { target },
    );
  } else {
    const timePeriod = DETECTION_DATE_SELECT_CUSTOM_RANGE_OPTIONS.find(
      ({ value }) => value === lastDetectedFilter.value,
    ).getInlineLabel();

    copy = i18n.t(
      'In the {timePeriod}, no vulnerabilities have been detected {target}.',
      {
        timePeriod,
        target,
      },
    );
  }

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      flow="column"
      hFull
      css={{ overflow: 'hidden' }}
    >
      <img
        src={VulnerabilityIcon}
        alt="Vulnerability"
        style={{ width: '50px' }}
      />
      <Heading size="4" css={{ fontWeight: '$medium', paddingTop: '$3' }}>
        {i18n.t('No vulnerabilities detected')}
      </Heading>
      <Text>{copy}</Text>
    </Flex>
  );
};

export { NoVulnerabilities };
