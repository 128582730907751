import { updateSetting as update } from '@kandji-inc/bumblebee';
import { i18n } from 'i18n';
import React, { useContext, useCallback } from 'react';

import useUniqValue from 'components/common/hooks/use-uniq-value/use-uniq-value';
import ActivityTab from 'features/library-items/common/activity-tab';
import NewStatusTab from 'features/library-items/common/new-status-tab/new-status-tab';
import { getNewStatusTabProps } from 'features/library-items/common/new-status-tab/util';
import LibraryContext from 'features/library-items/routes/library.context';
import { useBlueprintConflicts } from '../../common/blueprint-conflicts';
import { useLibraryItem } from '../../common/hooks/use-library-item';
import LibraryItemPage from '../../common/library-item-page';
import type { AvertModel, AvertPageProps } from './avert.types';
import initial from './initial-state';
import AllowBlockList from './sections/AllowBlockList/AllowBlockList';
import BehavioralDetections from './sections/BehavioralDetections';
import General from './sections/General';
import Notifications from './sections/Notifications';
import UnknownFileUpload from './sections/UnknownFileUpload';
import { avertService } from './service/avert.service';
import { transformFromApi, transformToApi } from './service/transformers';
import useAvertService from './service/use-avert.service';

const AvertPage = (props: AvertPageProps) => {
  const { model, setModel, pageState } = props;
  const { itemConfig } = useContext(LibraryContext);
  const [validationDep, triggerValidation] = useUniqValue();
  const updateGeneral = useCallback(update('general', setModel), []);
  const updateAllowBlock = useCallback(update('allowBlock', setModel), []);
  const updateUnknownFileUpload = useCallback(
    update('unknownFileUpload', setModel),
    [],
  );
  const updateEndUserNotifications = useCallback(
    update('endUserNotifications', setModel),
    [],
  );
  const updateProcessMonitoringEnabled = useCallback(
    // istanbul ignore next
    () =>
      // istanbul ignore next
      setModel((p: Readonly<AvertModel>) => ({
        ...p,
        processMonitoringEnabled: !p.processMonitoringEnabled,
      })),
    [setModel],
  );

  return (
    <LibraryItemPage
      {...props}
      type={itemConfig.type}
      identifier={itemConfig.identifier}
      crumb={model.name || itemConfig.getName()}
      summaryInfoProps={{
        name: itemConfig.getName(),
        description: (
          <>
            {itemConfig.getDescription()}{' '}
            <a
              href="https://support.kandji.io/kb/configuring-the-avert-library-item"
              rel="noopener noreferrer"
              target="_blank"
              className="b-alink"
            >
              {i18n.t('Learn More...')}
            </a>
          </>
        ),
        publisher: itemConfig.getPublisher(),
        devices: itemConfig.devices,
        requirements: itemConfig.getOsRequirements(),
      }}
      triggerValidation={triggerValidation}
      transformToApi={transformToApi}
      transformFromApi={transformFromApi}
      service={avertService}
      defaultIcon={itemConfig.icon}
      ActivityTab={ActivityTab}
      StatusTab={NewStatusTab}
      getStatusTabProps={getNewStatusTabProps}
      supportsDuplication
    >
      <General
        isDisabled={pageState.isDisabled}
        validationDep={validationDep}
        settings={model.general}
        update={updateGeneral}
      />
      <BehavioralDetections
        isDisabled={pageState.isDisabled}
        validationDep={validationDep}
        settings={model.general}
        update={updateGeneral}
        processMonitoringEnabledSetting={model.processMonitoringEnabled}
        updateProcessMonitoringEnabled={updateProcessMonitoringEnabled}
      />
      <Notifications
        isDisabled={pageState.isDisabled}
        settings={model.endUserNotifications}
        update={updateEndUserNotifications}
      />
      <AllowBlockList
        isDisabled={pageState.isDisabled}
        validationDep={validationDep}
        settings={model.allowBlock}
        generalSettings={model.general}
        update={updateAllowBlock}
      />
      <UnknownFileUpload
        isDisabled={pageState.isDisabled}
        settings={model.unknownFileUpload}
        update={updateUnknownFileUpload}
      />
    </LibraryItemPage>
  );
};

const Avert = () => {
  const { pageProps, PlaceHolder } = useLibraryItem({
    initialState: initial,
    useService: useAvertService,
  });

  const blueprintConflicts = useBlueprintConflicts();

  /* istanbul ignore next */
  if (PlaceHolder) {
    return <PlaceHolder />;
  }

  return (
    <AvertPage
      {...pageProps}
      blueprintConflicts={blueprintConflicts}
      testId="avert"
    />
  );
};

export default Avert;
