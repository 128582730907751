import {
  Flex,
  Heading,
  Icon,
  type IconNames,
  Text,
} from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';

const WhyKandjiSection = () => {
  const whyKandjiOptions: {
    icon: IconNames;
    title: string;
    description: string;
    iconHeight?: number;
  }[] = [
    {
      icon: 'kandji-logo',
      title: i18n.t('Deploy with the Kandji Agent'),
      description: i18n.t(
        'Instantaneously deploy Vulnerability Management to your entire fleet without installing or maintaining an additional agent.',
      ),
      iconHeight: 30,
    },
    {
      icon: 'crosshairs',
      title: i18n.t('Detect with zero delay'),
      description: i18n.t(
        "Kandji's deep integration with Apple's Endpoint Security framework results in real-time detection without scanning. Shorter vulnerability dwell times lower your risk.",
      ),
    },
    {
      icon: 'shield-check',
      title: 'Remediate faster than ever',
      description: i18n.t(
        'Find vulnerabilities and patch them in record time with a unified platform. Intelligence means that workflows change from reactive to proactive.',
      ),
    },
  ];

  return (
    <Flex
      flow="column"
      alignItems="center"
      css={{
        width: 1136,
        gap: 45,
      }}
    >
      <Heading
        as="h2"
        size="2"
        css={{ lineHeight: '36px', letterSpacing: '-0.8px' }}
      >
        {i18n.t('Why Kandji for Vulnerability Management')}
      </Heading>
      <Flex
        justifyContent="space-between"
        alignItems="start"
        css={{
          alignSelf: 'stretch',
        }}
      >
        {whyKandjiOptions.map((item) => (
          <Flex
            gap="md"
            flow="column"
            alignItems="center"
            justifyContent="center"
            css={{ alignSelf: 'stretch' }}
          >
            <Flex
              gap="md"
              flow="column"
              alignItems="center"
              css={{
                width: 301,
              }}
            >
              <Flex
                justifyContent="center"
                alignItems="center"
                css={{
                  borderRadius: 6,
                  background: '$blue50',
                  width: 44,
                  height: 44,
                }}
              >
                <Icon
                  name={item.icon}
                  color="var(--colors-neutral0)"
                  style={{
                    width: item.iconHeight || 24,
                    height: item.iconHeight || 24,
                  }}
                />
              </Flex>
              <Flex
                gap="md"
                flow="column"
                alignItems="center"
                css={{
                  alignSelf: 'stretch',
                  textAlign: 'center',
                }}
              >
                <Text
                  size="4"
                  css={{
                    fontWeight: '$medium',
                    lineHeight: '32px',
                    letterSpacing: '-0.8px',
                  }}
                >
                  {i18n.t(item.title)}
                </Text>
                <Text
                  size="2"
                  css={{
                    lineHeight: '$2',
                    color: '$neutral90',
                  }}
                >
                  {i18n.t(item.description)}
                </Text>
              </Flex>
            </Flex>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};

export { WhyKandjiSection };
