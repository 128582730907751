/* istanbul ignore file */
import { i18n } from 'src/i18n';

// Do not remove! this is a list of all the icon name segments that need to be translated.
// They are defined here so the strings can be extracted for translation in `getIcons()`.
const translateIconNames = () => {
  i18n.t('0');
  i18n.t('00');
  i18n.t('01');
  i18n.t('02');
  i18n.t('03');
  i18n.t('04');
  i18n.t('05');
  i18n.t('06');
  i18n.t('07');
  i18n.t('08');
  i18n.t('09');
  i18n.t('1');
  i18n.t('10');
  i18n.t('100');
  i18n.t('11');
  i18n.t('12');
  i18n.t('123');
  i18n.t('13');
  i18n.t('14');
  i18n.t('15');
  i18n.t('16');
  i18n.t('17');
  i18n.t('18');
  i18n.t('19');
  i18n.t('1x2');
  i18n.t('2');
  i18n.t('20');
  i18n.t('21');
  i18n.t('22');
  i18n.t('23');
  i18n.t('24');
  i18n.t('25');
  i18n.t('26');
  i18n.t('27');
  i18n.t('28');
  i18n.t('29');
  i18n.t('2d');
  i18n.t('2x1');
  i18n.t('2x2');
  i18n.t('3');
  i18n.t('30');
  i18n.t('31');
  i18n.t('32');
  i18n.t('33');
  i18n.t('34');
  i18n.t('35');
  i18n.t('36');
  i18n.t('37');
  i18n.t('38');
  i18n.t('39');
  i18n.t('3d');
  i18n.t('3x1');
  i18n.t('3x2');
  i18n.t('3x3');
  i18n.t('4');
  i18n.t('40');
  i18n.t('41');
  i18n.t('42');
  i18n.t('43');
  i18n.t('44');
  i18n.t('45');
  i18n.t('46');
  i18n.t('47');
  i18n.t('48');
  i18n.t('49');
  i18n.t('4k');
  i18n.t('4x3');
  i18n.t('5');
  i18n.t('50');
  i18n.t('6');
  i18n.t('60');
  i18n.t('7');
  i18n.t('75');
  i18n.t('8');
  i18n.t('9');
  i18n.t('90');
  i18n.t('a');
  i18n.t('abc');
  i18n.t('airplane');
  i18n.t('alarm');
  i18n.t('aligncenter');
  i18n.t('alignleft');
  i18n.t('alignright');
  i18n.t('alt');
  i18n.t('amplifier');
  i18n.t('and');
  i18n.t('angled');
  i18n.t('ant');
  i18n.t('antenna');
  i18n.t('aperture');
  i18n.t('app');
  i18n.t('append');
  i18n.t('apps');
  i18n.t('aqi');
  i18n.t('archivebox');
  i18n.t('arrow');
  i18n.t('arrowshape');
  i18n.t('arrowtriangle');
  i18n.t('arrowtriangles');
  i18n.t('aspectratio');
  i18n.t('asterisk');
  i18n.t('at');
  i18n.t('atom');
  i18n.t('australsign');
  i18n.t('average');
  i18n.t('b');
  i18n.t('backward');
  i18n.t('badge');
  i18n.t('bag');
  i18n.t('bahtsign');
  i18n.t('bandage');
  i18n.t('banknote');
  i18n.t('bar');
  i18n.t('barcode');
  i18n.t('barometer');
  i18n.t('battery');
  i18n.t('batteryblock');
  i18n.t('bed');
  i18n.t('bell');
  i18n.t('below');
  i18n.t('bicycle');
  i18n.t('bin');
  i18n.t('binoculars');
  i18n.t('bitcoinsign');
  i18n.t('bold');
  i18n.t('bolt');
  i18n.t('book');
  i18n.t('bookmark');
  i18n.t('books');
  i18n.t('bottom');
  i18n.t('bottomhalf');
  i18n.t('bottomleft');
  i18n.t('bottommiddle');
  i18n.t('bottomright');
  i18n.t('bottomrightquarter');
  i18n.t('bottomthird');
  i18n.t('bounce');
  i18n.t('braille');
  i18n.t('branch');
  i18n.t('brazilianrealsign');
  i18n.t('briefcase');
  i18n.t('bubble');
  i18n.t('building');
  i18n.t('bullet');
  i18n.t('burn');
  i18n.t('burst');
  i18n.t('bus');
  i18n.t('c');
  i18n.t('calendar');
  i18n.t('camera');
  i18n.t('candybarphone');
  i18n.t('capslock');
  i18n.t('capsule');
  i18n.t('capsulepath');
  i18n.t('captions');
  i18n.t('car');
  i18n.t('cart');
  i18n.t('case');
  i18n.t('cedisign');
  i18n.t('center');
  i18n.t('centsign');
  i18n.t('character');
  i18n.t('chart');
  i18n.t('checkerboard');
  i18n.t('checkmark');
  i18n.t('chevron');
  i18n.t('circle');
  i18n.t('circlebadge');
  i18n.t('circlepath');
  i18n.t('circles');
  i18n.t('clap');
  i18n.t('clear');
  i18n.t('click');
  i18n.t('clipboard');
  i18n.t('clock');
  i18n.t('clockwise');
  i18n.t('closed');
  i18n.t('cloud');
  i18n.t('club');
  i18n.t('coloncurrencysign');
  i18n.t('columns');
  i18n.t('comb');
  i18n.t('command');
  i18n.t('compact');
  i18n.t('compress');
  i18n.t('cone');
  i18n.t('connected');
  i18n.t('connection');
  i18n.t('contextualmenu');
  i18n.t('control');
  i18n.t('controlhandles');
  i18n.t('counterclockwise');
  i18n.t('cpu');
  i18n.t('creditcard');
  i18n.t('crop');
  i18n.t('cross');
  i18n.t('crossed');
  i18n.t('crown');
  i18n.t('cruzeirosign');
  i18n.t('cube');
  i18n.t('curlybraces');
  i18n.t('cursive');
  i18n.t('cursor');
  i18n.t('cursorarrow');
  i18n.t('curvedto');
  i18n.t('cylinder');
  i18n.t('d');
  i18n.t('dash');
  i18n.t('dashed');
  i18n.t('decrease');
  i18n.t('delete');
  i18n.t('deskclock');
  i18n.t('desktopcomputer');
  i18n.t('diagonal');
  i18n.t('dial');
  i18n.t('diamond');
  i18n.t('die');
  i18n.t('directcurrent');
  i18n.t('display');
  i18n.t('divide');
  i18n.t('doc');
  i18n.t('dock');
  i18n.t('dollarsign');
  i18n.t('dongsign');
  i18n.t('dot');
  i18n.t('dotmatrix');
  i18n.t('dotted');
  i18n.t('dottedline');
  i18n.t('dottedunderline');
  i18n.t('double');
  i18n.t('doubledecker');
  i18n.t('down');
  i18n.t('dpad');
  i18n.t('draw');
  i18n.t('drizzle');
  i18n.t('drop');
  i18n.t('dust');
  i18n.t('e');
  i18n.t('ear');
  i18n.t('ecg');
  i18n.t('eject');
  i18n.t('ellipse');
  i18n.t('ellipsis');
  i18n.t('end');
  i18n.t('enter');
  i18n.t('envelope');
  i18n.t('equal');
  i18n.t('escape');
  i18n.t('esim');
  i18n.t('eurosign');
  i18n.t('exclamationmark');
  i18n.t('exit');
  i18n.t('expand');
  i18n.t('externaldrive');
  i18n.t('eye');
  i18n.t('eyebrow');
  i18n.t('eyedropper');
  i18n.t('eyeglasses');
  i18n.t('eyes');
  i18n.t('f');
  i18n.t('face');
  i18n.t('faxmachine');
  i18n.t('fiberchannel');
  i18n.t('figure');
  i18n.t('filemenu');
  i18n.t('fill');
  i18n.t('film');
  i18n.t('filters');
  i18n.t('flag');
  i18n.t('flame');
  i18n.t('flashlight');
  i18n.t('flipphone');
  i18n.t('florinsign');
  i18n.t('flowchart');
  i18n.t('fn');
  i18n.t('fog');
  i18n.t('folder');
  i18n.t('forward');
  i18n.t('frame');
  i18n.t('francsign');
  i18n.t('full');
  i18n.t('function');
  i18n.t('fx');
  i18n.t('g');
  i18n.t('gamecontroller');
  i18n.t('gauge');
  i18n.t('gear');
  i18n.t('gearshape');
  i18n.t('gift');
  i18n.t('giftcard');
  i18n.t('globe');
  i18n.t('gobackward');
  i18n.t('goforward');
  i18n.t('graduationcap');
  i18n.t('greaterthan');
  i18n.t('greetingcard');
  i18n.t('grid');
  i18n.t('guaranisign');
  i18n.t('guitars');
  i18n.t('gyroscope');
  i18n.t('h');
  i18n.t('hail');
  i18n.t('halffull');
  i18n.t('hammer');
  i18n.t('hand');
  i18n.t('hands');
  i18n.t('hare');
  i18n.t('haze');
  i18n.t('headphones');
  i18n.t('hearingaid');
  i18n.t('heart');
  i18n.t('heavyrain');
  i18n.t('helm');
  i18n.t('hexagon');
  i18n.t('hexagongrid');
  i18n.t('hexagonpath');
  i18n.t('hifispeaker');
  i18n.t('high');
  i18n.t('highlighter');
  i18n.t('horizontal');
  i18n.t('hourglass');
  i18n.t('house');
  i18n.t('hryvniasign');
  i18n.t('hurricane');
  i18n.t('i');
  i18n.t('icloud');
  i18n.t('in');
  i18n.t('increase');
  i18n.t('indent');
  i18n.t('indianrupeesign');
  i18n.t('infinity');
  i18n.t('info');
  i18n.t('insert');
  i18n.t('inset');
  i18n.t('internaldrive');
  i18n.t('inverse');
  i18n.t('inward');
  i18n.t('ipad');
  i18n.t('iphone');
  i18n.t('italic');
  i18n.t('j');
  i18n.t('joystick');
  i18n.t('justify');
  i18n.t('justifyleft');
  i18n.t('justifyright');
  i18n.t('k');
  i18n.t('key');
  i18n.t('keyboard');
  i18n.t('kipsign');
  i18n.t('l');
  i18n.t('l1');
  i18n.t('l2');
  i18n.t('ladybug');
  i18n.t('landscape');
  i18n.t('laptopcomputer');
  i18n.t('largecircle');
  i18n.t('larger');
  i18n.t('larisign');
  i18n.t('lasso');
  i18n.t('latch');
  i18n.t('lb');
  i18n.t('leading');
  i18n.t('leadinghalf');
  i18n.t('leaf');
  i18n.t('left');
  i18n.t('lefthalf');
  i18n.t('leftthird');
  i18n.t('lessthan');
  i18n.t('level');
  i18n.t('lifepreserver');
  i18n.t('light');
  i18n.t('lightbulb');
  i18n.t('line');
  i18n.t('lineweight');
  i18n.t('link');
  i18n.t('lirasign');
  i18n.t('list');
  i18n.t('location');
  i18n.t('lock');
  i18n.t('loupe');
  i18n.t('low');
  i18n.t('lt');
  i18n.t('lungs');
  i18n.t('m');
  i18n.t('macwindow');
  i18n.t('magnify');
  i18n.t('magnifyingglass');
  i18n.t('mail');
  i18n.t('manatsign');
  i18n.t('map');
  i18n.t('mappin');
  i18n.t('matrix');
  i18n.t('max');
  i18n.t('mediabox');
  i18n.t('medium');
  i18n.t('megaphone');
  i18n.t('memories');
  i18n.t('memorychip');
  i18n.t('menubar');
  i18n.t('merge');
  i18n.t('metering');
  i18n.t('metronome');
  i18n.t('mic');
  i18n.t('middle');
  i18n.t('middleleft');
  i18n.t('middleright');
  i18n.t('millsign');
  i18n.t('min');
  i18n.t('minus');
  i18n.t('moon');
  i18n.t('mosaic');
  i18n.t('motionlines');
  i18n.t('mount');
  i18n.t('mouth');
  i18n.t('move');
  i18n.t('multiply');
  i18n.t('multispot');
  i18n.t('music');
  i18n.t('mustache');
  i18n.t('n');
  i18n.t('nairasign');
  i18n.t('network');
  i18n.t('newspaper');
  i18n.t('none');
  i18n.t('north');
  i18n.t('nose');
  i18n.t('nosign');
  i18n.t('note');
  i18n.t('number');
  i18n.t('o');
  i18n.t('octagon');
  i18n.t('off');
  i18n.t('offgrid');
  i18n.t('on');
  i18n.t('onehanded');
  i18n.t('open');
  i18n.t('opticaldisc');
  i18n.t('opticaldiscdrive');
  i18n.t('option');
  i18n.t('out');
  i18n.t('outline');
  i18n.t('outward');
  i18n.t('oval');
  i18n.t('p');
  i18n.t('paintbrush');
  i18n.t('paintpalette');
  i18n.t('pano');
  i18n.t('paperclip');
  i18n.t('paperplane');
  i18n.t('paragraphsign');
  i18n.t('partial');
  i18n.t('pass');
  i18n.t('path');
  i18n.t('pause');
  i18n.t('pc');
  i18n.t('pencil');
  i18n.t('percent');
  i18n.t('person');
  i18n.t('personalhotspot');
  i18n.t('perspective');
  i18n.t('pesetasign');
  i18n.t('pesosign');
  i18n.t('phone');
  i18n.t('photo');
  i18n.t('pianokeys');
  i18n.t('pie');
  i18n.t('pills');
  i18n.t('pin');
  i18n.t('pip');
  i18n.t('plaintext');
  i18n.t('play');
  i18n.t('playpause');
  i18n.t('plus');
  i18n.t('plusminus');
  i18n.t('point');
  i18n.t('pointed');
  i18n.t('portrait');
  i18n.t('power');
  i18n.t('poweroff');
  i18n.t('poweron');
  i18n.t('powersleep');
  i18n.t('printer');
  i18n.t('projective');
  i18n.t('pull');
  i18n.t('purchased');
  i18n.t('puzzlepiece');
  i18n.t('pyramid');
  i18n.t('q');
  i18n.t('qrcode');
  i18n.t('quarternote');
  i18n.t('questionmark');
  i18n.t('quote');
  i18n.t('quotelevel');
  i18n.t('r');
  i18n.t('r1');
  i18n.t('r2');
  i18n.t('rack');
  i18n.t('radio');
  i18n.t('radiowaves');
  i18n.t('rain');
  i18n.t('raised');
  i18n.t('rays');
  i18n.t('rb');
  i18n.t('record');
  i18n.t('recordingtape');
  i18n.t('rectangle');
  i18n.t('rectanglepath');
  i18n.t('redaction');
  i18n.t('reecord');
  i18n.t('reectangle');
  i18n.t('remove');
  i18n.t('repeat');
  i18n.t('restart');
  i18n.t('return');
  i18n.t('rhombus');
  i18n.t('richtext');
  i18n.t('right');
  i18n.t('righthalf');
  i18n.t('rightthird');
  i18n.t('righttriangle');
  i18n.t('rosette');
  i18n.t('rotate');
  i18n.t('rotation');
  i18n.t('roundedbottom');
  i18n.t('roundedtop');
  i18n.t('rt');
  i18n.t('rublesign');
  i18n.t('ruler');
  i18n.t('rupeesign');
  i18n.t('s');
  i18n.t('scale');
  i18n.t('scalemass');
  i18n.t('scanner');
  i18n.t('scissors');
  i18n.t('scope');
  i18n.t('screwdriver');
  i18n.t('scribble');
  i18n.t('scroll');
  i18n.t('sdcard');
  i18n.t('seal');
  i18n.t('selection');
  i18n.t('server');
  i18n.t('shadow');
  i18n.t('shekelsign');
  i18n.t('shield');
  i18n.t('shift');
  i18n.t('shippingbox');
  i18n.t('shuffle');
  i18n.t('sidebar');
  i18n.t('signature');
  i18n.t('signpost');
  i18n.t('simcard');
  i18n.t('size');
  i18n.t('skew');
  i18n.t('slash');
  i18n.t('sleep');
  i18n.t('sleet');
  i18n.t('slider');
  i18n.t('slowmo');
  i18n.t('smallcircle');
  i18n.t('smaller');
  i18n.t('smiling');
  i18n.t('smoke');
  i18n.t('snow');
  i18n.t('snowflake');
  i18n.t('spade');
  i18n.t('sparkle');
  i18n.t('sparkles');
  i18n.t('speaker');
  i18n.t('speedometer');
  i18n.t('split');
  i18n.t('sportscourt');
  i18n.t('spot');
  i18n.t('square');
  i18n.t('squarepath');
  i18n.t('squareroot');
  i18n.t('squares');
  i18n.t('squareshape');
  i18n.t('stack');
  i18n.t('stand');
  i18n.t('star');
  i18n.t('staroflife');
  i18n.t('stars');
  i18n.t('sterlingsign');
  i18n.t('stethoscope');
  i18n.t('stop');
  i18n.t('stopwatch');
  i18n.t('strikethrough');
  i18n.t('studentdesk');
  i18n.t('subscript');
  i18n.t('suit');
  i18n.t('sum');
  i18n.t('sun');
  i18n.t('sunrise');
  i18n.t('sunset');
  i18n.t('superscript');
  i18n.t('swap');
  i18n.t('swirl');
  i18n.t('switch');
  i18n.t('t');
  i18n.t('tablecells');
  i18n.t('tag');
  i18n.t('tap');
  i18n.t('target');
  i18n.t('tengesign');
  i18n.t('terminal');
  i18n.t('text');
  i18n.t('textbox');
  i18n.t('textformat');
  i18n.t('thermometer');
  i18n.t('thumbsdown');
  i18n.t('thumbsup');
  i18n.t('ticket');
  i18n.t('timelapse');
  i18n.t('timeline');
  i18n.t('timemachine');
  i18n.t('timer');
  i18n.t('tip');
  i18n.t('to');
  i18n.t('togglepower');
  i18n.t('top');
  i18n.t('tophalf');
  i18n.t('topleft');
  i18n.t('topmiddle');
  i18n.t('topright');
  i18n.t('topthird');
  i18n.t('tornado');
  i18n.t('tortoise');
  i18n.t('torus');
  i18n.t('trailing');
  i18n.t('tram');
  i18n.t('transparent');
  i18n.t('trash');
  i18n.t('tray');
  i18n.t('triangle');
  i18n.t('trianglebadge');
  i18n.t('trianglepath');
  i18n.t('tropicalstorm');
  i18n.t('tugriksign');
  i18n.t('tuningfork');
  i18n.t('tunnel');
  i18n.t('turkishlirasign');
  i18n.t('turn');
  i18n.t('tv');
  i18n.t('u');
  i18n.t('uiwindow');
  i18n.t('umbrella');
  i18n.t('underline');
  i18n.t('unknown');
  i18n.t('up');
  i18n.t('uturn');
  i18n.t('v');
  i18n.t('variable');
  i18n.t('vertical');
  i18n.t('view');
  i18n.t('viewfinder');
  i18n.t('w');
  i18n.t('wake');
  i18n.t('walk');
  i18n.t('wallet');
  i18n.t('wand');
  i18n.t('wave');
  i18n.t('waveform');
  i18n.t('weighted');
  i18n.t('wifi');
  i18n.t('wind');
  i18n.t('wonsign');
  i18n.t('wrench');
  i18n.t('x');
  i18n.t('xaxis');
  i18n.t('xmark');
  i18n.t('y');
  i18n.t('yensign');
  i18n.t('z');
  i18n.t('zigzag');
  i18n.t('zipper');
  i18n.t('zl');
  i18n.t('zr');
  i18n.t('zzz');
};

const sfSymbolsRegularFiles = require.context(
  './assets/sf-symbols/regular',
  true,
  /\.svg$|.png/,
);

const getIcons = () => {
  const sfSymbolsRegularIcons = [];

  for (const file of sfSymbolsRegularFiles.keys()) {
    const icon = file.replace(/^.\/|[.](svg$|png$)/gi, '');
    const iconTerms = icon.split('.');
    const translatedSearchFields = iconTerms.map((term) => i18n.t(term));
    const src = sfSymbolsRegularFiles(file);

    sfSymbolsRegularIcons.push({
      translatedSearchFields,
      icon,
      src,
    });
  }

  return sfSymbolsRegularIcons;
};

export { getIcons };
