import { Checkbox, Toaster as toaster } from '@kandji-inc/bumblebee';
import { Button, Dialog, Flex, Text, TextField } from '@kandji-inc/nectar-ui';
import React, { useState } from 'react';
import { enableLostMode } from '../../computer/api';

import { i18n } from 'i18n';
import { queryClient } from 'src/app/router';
import CustomCheckbox from './shared/CustomCheckbox';

const EnableLostMode = (props) => {
  const { computer, info, onHide, updateComputer } = props;
  const { computerId } = info;

  const [isLoading, setIsLoading] = useState(false);

  // Checkboxes
  const [hasMessage, setHasMessage] = useState(true);
  const [hasPhoneNumber, setHasPhoneNumber] = useState(true);
  const [hasFootnote, setHasFootnote] = useState(true);

  // Text fields
  const [message, setMessage] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [footnote, setFootnote] = useState('');

  const handleOnHasMessageChange = () => {
    setHasMessage((prev) => !prev);
    setMessage('');
  };

  const handleOnHasPhoneNumberChange = () => {
    setHasPhoneNumber((prev) => !prev);
    setPhoneNumber('');
  };

  const handleOnHasFootnoteChange = () => {
    setHasFootnote((prev) => !prev);
    setFootnote('');
  };

  const isConfirmDisabled =
    (!message && !phoneNumber) ||
    message.length > 250 ||
    phoneNumber.length > 15 ||
    footnote.length > 50;

  const onEnable = () => {
    setIsLoading(true);
    enableLostMode(computerId, {
      Message: message,
      PhoneNumber: phoneNumber,
      Footnote: footnote,
    })
      .then(() => {
        /* istanbul ignore next */
        queryClient.setQueryData(
          ['single-computer', computer.id],
          (oldData) => {
            return {
              ...oldData,
              data: {
                ...oldData.data,
                lost_mode: {
                  ...(oldData.data?.lost_mode || {}),
                  lost_mode_status: 'PENDING',
                },
              },
            };
          },
        );
        updateComputer({
          ...computer,
          lost_mode: { ...computer?.lost_mode, lost_mode_status: 'PENDING' },
        });
        onHide();
        setIsLoading(false);
        toaster(i18n.t('Command sent to enable Lost Mode'));
      })
      .catch(() => {
        onHide();
        setIsLoading(false);
        toaster(i18n.common.error());
      });
  };

  const content = (
    <Flex flow="column" gap="lg">
      <Text pb2>
        {`Lost Mode allows you to lock a mobile device and track the device's
        location. `}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://support.kandji.io/kb/enable-lost-mode"
        >
          <Text
            variant="primary"
            css={{
              display: 'inline-block',
              '&:hover': { textDecoration: 'underline' },
            }}
          >
            Learn more...
          </Text>
        </a>
      </Text>

      <Flex flow="column" alignItems="start" gap="md">
        <CustomCheckbox
          label="Display message on Lock Screen"
          onChange={handleOnHasMessageChange}
          checked={hasMessage}
          isDisabled={!hasPhoneNumber}
          hoverText="You must display a message if you don't provide a phone number."
          id="message"
        />
        {hasMessage && (
          <TextField
            wFull
            pl6
            aria-label="message"
            placeholder="Enter message"
            onChange={(e) => setMessage(e.target.value)}
            hint={{ charCounter: { max: 250, count: message.length } }}
          />
        )}
      </Flex>

      <Flex flow="column" alignItems="start" gap="md">
        <CustomCheckbox
          label="Display phone number on Lock Screen"
          onChange={handleOnHasPhoneNumberChange}
          checked={hasPhoneNumber}
          isDisabled={!hasMessage}
          hoverText="You must display a phone number if you don't provide a message."
          id="phone"
        />
        {hasPhoneNumber && (
          <TextField
            wFull
            pl6
            aria-label="phonenumber"
            placeholder="Enter phone"
            onChange={(e) => setPhoneNumber(e.target.value)}
            hint={{ charCounter: { max: 15, count: phoneNumber.length } }}
          />
        )}
      </Flex>

      <Flex flow="column" alignItems="start" gap="md">
        <Checkbox
          checked={hasFootnote}
          onChange={handleOnHasFootnoteChange}
          label="Display footnote on Lock Screen in place of Slide to Unlock"
        />
        {hasFootnote && (
          <TextField
            wFull
            pl6
            aria-label="footnote"
            placeholder="Enter footnote"
            onChange={(e) => setFootnote(e.target.value)}
            hint={{ charCounter: { max: 50, count: footnote.length } }}
          />
        )}
      </Flex>
    </Flex>
  );

  const footer = (
    <Flex gap="md" justifyContent="end" wrap="wrap">
      <Button variant="subtle" onClick={onHide}>
        Cancel
      </Button>
      <Button
        variant="primary"
        loading={isLoading}
        disabled={isConfirmDisabled}
        onClick={onEnable}
      >
        Enable Lost Mode
      </Button>
    </Flex>
  );

  return (
    <Dialog
      isOpen
      closeOnEscape
      closeOnOutsideClick
      onOpenChange={onHide}
      title="Enable Lost Mode"
      content={content}
      footer={footer}
      css={{ zIndex: 2000 }}
    />
  );
};

export default EnableLostMode;
