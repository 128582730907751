import { formatNumber } from '@kandji-inc/nectar-i18n';
import {
  Flex,
  Heading,
  Loader,
  SelectContents,
  SelectGroupedItems,
  SelectV2,
  Text,
} from '@kandji-inc/nectar-ui';
import { useState } from 'react';
import { useFocusInput } from 'src/hooks/useFocusInput';
import { i18n } from 'src/i18n';
import { useGetApplications } from '../../../hooks/dashboard/use-get-applications';
import { useGetVulnerabilitiesByApplication } from '../../../hooks/dashboard/use-get-vulnerabilities-by-application';
import { Tile } from './tile';

const VulnerabilitiesByApplicationTile = () => {
  const { applications, isLoading: isLoadingApplications } =
    useGetApplications();
  const applicationOptions = applications.map((app) => ({
    label: app,
    value: app,
    css: { width: '100%' },
  }));

  const [appNameFilter, setAppNameFilter] = useState<string>();
  const {
    numVulnerabilities,
    appName,
    isLoading: isLoadingVulnerabilitiesByApplcation,
  } = useGetVulnerabilitiesByApplication(appNameFilter);

  const isLoading =
    isLoadingApplications || isLoadingVulnerabilitiesByApplcation;

  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [applicationSearch, setApplicationSearch] = useState('');
  const [filteredApplications, setFilteredApplications] = useState([]);
  const searchInputRef = useFocusInput([applicationOptions, isSearchOpen]);

  const handleOpenApplicationSearch = (open: boolean) => {
    setIsSearchOpen(open);
    setApplicationSearch('');
    setFilteredApplications(applicationOptions);
  };

  const handleApplicationSearchChange = (search: string) => {
    setApplicationSearch(search);
    setFilteredApplications(
      applicationOptions.filter((option) =>
        option.label.toLowerCase().includes(search.toLowerCase()),
      ),
    );
  };

  return (
    <Tile>
      <Flex flow="column" gap="xs" hFull>
        <Flex
          alignItems="center"
          gap="xs"
          justifyContent="space-between"
          css={{ minHeight: '28px' }}
        >
          <Heading size="4">{i18n.t('Vulnerabilities by application')}</Heading>
          {applicationOptions.length > 0 && (
            <SelectV2.Default
              value={appName || appNameFilter}
              disabled={isLoading}
              triggerProps={{
                variant: 'subtle',
                compact: true,
              }}
              contentProps={{ align: 'end' }}
              onValueChange={(selected) => setAppNameFilter(selected)}
              onOpenChange={handleOpenApplicationSearch}
              options={applicationOptions}
              content={() => (
                <SelectContents.Searchable
                  ref={searchInputRef}
                  search={applicationSearch}
                  placeholder={i18n.t('Search applications')}
                  onSearchChange={handleApplicationSearchChange}
                  css={{ width: '100%' }}
                  align="end"
                >
                  <SelectGroupedItems options={filteredApplications} />
                  {filteredApplications.length === 0 && (
                    <Flex wFull justifyContent="center" pt2>
                      <Text variant="description">
                        {i18n.t('No results found')}
                      </Text>
                    </Flex>
                  )}
                </SelectContents.Searchable>
              )}
            />
          )}
        </Flex>

        {isLoading && (
          <Flex hFull alignItems="center" justifyContent="center">
            <Loader data-testid="loading-vulnerabilities-by-application" />
          </Flex>
        )}

        {!isLoading && applicationOptions.length > 0 && (
          <Flex
            hFull
            alignItems="center"
            css={{ justifyContent: 'space-around' }}
          >
            <Flex flow="column" alignItems="center" gap="xs">
              <Heading size="1">{formatNumber(numVulnerabilities)}</Heading>
              <Text css={{ color: 'var(--colors-neutral70)' }}>
                {i18n.t('CVEs associated')}
              </Text>
            </Flex>
          </Flex>
        )}

        {!isLoading && applicationOptions.length === 0 && (
          <Flex hFull wFull alignItems="center" justifyContent="center">
            <Text>
              {i18n.t(
                'No vulnerabilities have been detected in your Mac fleet.',
              )}
            </Text>
          </Flex>
        )}
      </Flex>
    </Tile>
  );
};

export { VulnerabilitiesByApplicationTile };
