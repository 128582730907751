import { useToast_UNSTABLE as useToast } from '@kandji-inc/nectar-ui';
/* istanbul ignore file */
import deepcopy from 'deepcopy';
import { useContext, useEffect, useState } from 'react';
import type { Node } from 'reactflow';
import { InterfaceContext } from 'src/contexts/interface';
import type { Blueprint } from 'src/features/blueprint-flow/blueprint-flow.types';
import { NODE_TYPES } from 'src/features/blueprint-flow/constants';
import { canDropIntoAssignmentNode } from 'src/features/blueprint-flow/nodes.common';
import { i18n } from 'src/i18n';
import { blueprintService } from '../../data-service/blueprint/blueprint-service';
import { newLibraryItemService } from '../../data-service/library-item/new-library-item-service';
import LibraryContext from '../../routes/library.context';

export type ConflictResolutions = {
  [k: string]: {
    id: string;
    itemsToRemove: Array<{
      assignmentId: string;
      libraryItemId: string;
    }>;
    itemsToAdd: Array<{
      assignmentId: string;
      libraryItemId: string;
    }>;
  };
};

type BasicAssignmentMap = {
  label: string;
  value: string;
  is_simple_map: boolean;
  type: 'flow';
};

type AssignmentMap = {
  graph: { nodes: Blueprint['nodes']; edges: Blueprint['edges'] };
} & BasicAssignmentMap;

const defaultSimpleMapConflictDetail = {
  hasConflict: false,
  blueprint: null,
  libraryItem: null,
  conflictingLibraryItem: null,
  startNodeId: null,
};

const SIDEBAR_DOCKED_OFFSET = 256;
const SIDEBAR_CLOSE_OFFSET = 78;

export const useAssignmentMaps = (
  initialBasicAssignmentMaps: Array<BasicAssignmentMap>,
  libraryItemModel: any,
  resetWatch = [],
) => {
  const { getItemConfig } = useContext(LibraryContext);
  const { sidebarDocked } = useContext(InterfaceContext);
  const { toast } = useToast();

  const [selectedAssignmentMaps, setSelectedAssignmentMaps] = useState(
    initialBasicAssignmentMaps,
  );

  // Simple maps are handled slightly differently than complex maps. When
  // selecting a map that is simple, we can automatically determine conflicts.
  // If conflicts exist, we set this field and allow the caller to do what they
  // wish with the information. At any given moment there is only a single
  // simple map conflict.
  const [simpleMapConflictDetails, setSingleMapConflictDetails] = useState(
    deepcopy(defaultSimpleMapConflictDetail),
  );

  // Currently this holds the data on what to do for each newly selected
  // assignment map.
  const [resolutions, setResolutions] = useState<ConflictResolutions>({});

  useEffect(() => {
    setResolutions({});
    setSelectedAssignmentMaps(initialBasicAssignmentMaps);
    setSingleMapConflictDetails(deepcopy(defaultSimpleMapConflictDetail));
  }, resetWatch);

  const updateResolution = (
    blueprintId: string,
    assignmentId: string,
    libraryItemId: string,
    action: 'remove' | 'add',
  ) =>
    setResolutions((prev) => {
      const prevItemsToAdd = prev[blueprintId]?.itemsToAdd || [];
      const prevItemsToRemove = prev[blueprintId]?.itemsToRemove || [];
      const resolution = {
        assignmentId,
        libraryItemId,
      };
      return {
        ...prev,
        [blueprintId]: {
          id: blueprintId,
          ...(prev[blueprintId] || {}),
          itemsToAdd: [
            ...prevItemsToAdd,
            ...(action === 'add' ? [resolution] : []),
          ],
          itemsToRemove:
            action === 'remove'
              ? prevItemsToRemove.filter(
                  (r) =>
                    r.assignmentId === assignmentId &&
                    r.libraryItemId === libraryItemId,
                )
              : prevItemsToRemove,
        },
      };
    });

  const selectAssignmentMap = async (blueprint: BasicAssignmentMap) => {
    const blueprintData = await blueprintService.get(blueprint.value, {});

    if (blueprint.is_simple_map) {
      const { nodes } = blueprintData.graph;
      const startNode = nodes.find((node) => node.type === NODE_TYPES.start);

      if (!startNode) {
        toast({
          title: i18n.t(
            'Something went wrong. We were unable to select the assignment map: {name}',
            { name: blueprint.label },
          ),
          variant: 'error',
          style: {
            left: /* istanbul ignore next */ sidebarDocked
              ? `${SIDEBAR_DOCKED_OFFSET + 12}px`
              : `${SIDEBAR_CLOSE_OFFSET + 12}px`,
            bottom: '12px',
            position: 'absolute',
          },
        });
        return;
      }

      checkBlueprintCanBeAssigned(blueprintData, startNode)
        .then((canAssign) => {
          if (!canAssign.isSCLIConflict) {
            updateResolution(
              blueprint.value,
              startNode.id,
              libraryItemModel.id || '',
              'add',
            );
            setSelectedAssignmentMaps((prev) => [...prev, blueprint]);
          } else {
            setSingleMapConflictDetails((prev) => ({
              ...prev,
              hasConflict: true,
              blueprint,
              libraryItem: libraryItemModel,
              conflictingLibraryItem: canAssign.conflictItem,
              startNodeId: startNode.id,
            }));
          }
        })
        .catch((e) => {
          console.error('Unexpected error checking assignment ability', e);
        });
    }
  };

  const resolveSimpleConflict = () => {
    if (simpleMapConflictDetails.hasConflict) {
      const { blueprint, libraryItem, conflictingLibraryItem } =
        simpleMapConflictDetails;

      setResolutions((prev) => {
        const prevItemsToRemove = prev[blueprint.value]?.itemsToRemove || [];
        const prevItemsToAdd = prev[blueprint.value]?.itemsToAdd || [];

        return {
          ...prev,
          [blueprint.value]: {
            id: blueprint.value,
            ...(prev[blueprint.value] || {}),
            itemsToRemove: [
              ...prevItemsToRemove,
              {
                assignmentId: simpleMapConflictDetails.startNodeId,
                libraryItemId: conflictingLibraryItem.id,
              },
            ],
            itemsToAdd: [
              ...prevItemsToAdd,
              {
                assignmentId: simpleMapConflictDetails.startNodeId,
                libraryItemId: libraryItem.id,
              },
            ],
          },
        };
      });

      setSelectedAssignmentMaps((prev) => [...prev, blueprint]);
    }
  };

  const checkBlueprintCanBeAssigned = async (
    blueprint: {
      id: string;
      graph: any;
      is_simple_map: boolean;
    },
    node: Node,
  ): Promise<ReturnType<typeof canDropIntoAssignmentNode>> => {
    const blueprintId = blueprint.id;
    const libraryItemsDataFromGraph =
      (
        await newLibraryItemService.list({
          blueprint__in: blueprintId,
        })
      )?.data?.results?.map((item) => ({
        ...item,
        defaultConfiguration: getItemConfig(item),
      })) || [];

    // TODO: Should maybe set up transformer somewhere here for to make the data
    // matchable to blueprint flow
    return canDropIntoAssignmentNode({
      droppedItem: {
        id: '',
        data: {
          id: libraryItemModel.id,
          flowId: '',
          origin: 'bank',
        },
      },
      droppedNode: {
        ...node,
        data: {
          ...node.data,
          items: node.data.libraryItems.map((id) => ({
            data: { id },
          })),
        },
      },
      getLibraryItem: (id) => {
        if (id === libraryItemModel.id) {
          return {
            ...libraryItemModel,
            defaultConfiguration: getItemConfig(libraryItemModel),
          };
        }
        return libraryItemsDataFromGraph.find((li) => li.id === id);
      },
    });
  };

  return {
    selectAssignmentMap,
    simpleMapConflictDetails,
    clearSimpleMapConflict: () =>
      setSingleMapConflictDetails(deepcopy(defaultSimpleMapConflictDetail)),
    resolveSimpleConflict,
    resolutions,
    selectedAssignmentMaps,
  };
};
