import {
  Select,
  useInputsValidators,
  useIsChanged,
  useRemoveValidationOnUnmount,
} from '@kandji-inc/bumblebee';
import React, { useEffect } from 'react';

import { Setting } from 'features/library-items/template';
import { i18n } from 'src/i18n';

import CustomScriptService from '../service/custom-script-service';

const ExecutionFrequency = (props) => {
  const { setting, update, isDisabled, validationDep } = props;

  const options = [
    {
      label: i18n.t('Run once per device'),
      value: CustomScriptService.executionFrequencyTypes.ONCE,
    },
    {
      label: i18n.t('Run every 15 minutes'),
      value: CustomScriptService.executionFrequencyTypes.EVERY_15_MIN,
    },
    {
      label: i18n.t('Run daily'),
      value: CustomScriptService.executionFrequencyTypes.EVERY_DAY,
    },
    {
      label: i18n.t('Run on-demand from Self Service'),
      value: CustomScriptService.executionFrequencyTypes.NO_ENFORCEMENT,
    },
  ];

  const isSubmitted = useIsChanged(validationDep);
  const fieldsToValidate = ['type'];
  const { refs, onInvalidate, invalidations } = useInputsValidators(
    fieldsToValidate,
    update,
  );
  useRemoveValidationOnUnmount(fieldsToValidate, update);

  useEffect(() => {
    onInvalidate('type')(
      !setting.type ||
        !Object.values(CustomScriptService.executionFrequencyTypes).includes(
          setting.type,
        ),
    );
  }, [setting]);

  return (
    <Setting.Card>
      <Setting.Header>
        <h3 className="b-h3">Execution Frequency</h3>
      </Setting.Header>
      <Setting.Rows>
        <Setting.Row>
          <Setting.Helpers className="b-txt-light">
            <p ref={refs[0]}>
              {i18n.t(`Determine how often this Custom Script should be executed. Choose
              to execute the script once per device, at every check-in, once
              daily, or install on-demand through Self Service.`)}{' '}
              <a
                href="https://support.kandji.io/kb/custom-scripts-overview"
                className="b-alink"
                target="_blank"
                rel="noreferrer"
              >
                {i18n.t('Learn more')}
              </a>
            </p>
          </Setting.Helpers>
          <Setting.Controls>
            <Select
              placeholder={i18n.t('Select frequency')}
              compact
              disabled={isDisabled}
              options={options}
              value={options.find(({ value }) => value === setting.type)}
              onChange={({ value }) => update('type', value)}
              errorText={isSubmitted && invalidations.type && 'Required.'}
              testId="frequency"
            />
          </Setting.Controls>
        </Setting.Row>
      </Setting.Rows>
    </Setting.Card>
  );
};

export default ExecutionFrequency;
