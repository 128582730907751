/* istanbul ignore file -- coverage diff picked up prettier changes */

import {
  Banner,
  Radio,
  Select,
  TextInput,
  urlValidator,
  useInputsValidators,
  useRemoveValidationOnUnmount,
} from '@kandji-inc/bumblebee';
import React, { useEffect } from 'react';
import './auth.css';

import { Setting } from 'features/library-items/template';

import { i18n } from 'src/i18n';
import MacLogin from '../../assets/waves-mac-login.png';
import KandjiLoginService from '../../service/kandji-login-service';
import { providerCopy, providerOptions } from './common';
import GoogleIdp from './google-idp';

import AzureLogin from '../../assets/waves-azure.png';
import GoogleWorkspace from '../../assets/waves-google.png';
import OktaLogin from '../../assets/waves-okta.png';
import OneLogin from '../../assets/waves-one-login.png';
import IdpLogin from '../../assets/waves-other.png';

import { Link } from 'react-router-dom';
import { useFlags } from 'src/config/feature-flags';
import { authStrategyMap } from 'src/features/library-items/data-service/sso/use-sso-service';
import { ManualEnrollmentAuthSelect } from 'src/pages/ManualEnrollment/ManualEnrollmentAuthSelect';
import { getConnectionIcon } from 'src/pages/ManualEnrollment/ManualEnrollmentBlueprintCard';
import { useGetAuthConnectors } from 'src/pages/ManualEnrollment/hooks/useGetAuthConnectors';

const Auth = (props) => {
  const {
    setting,
    update,
    isDisabled,
    isSubmitted,
    validationDep,
    onValidate,
  } = props;
  const { 'dc-1146-passport-google-workspace': LDFF_googleWorkspace } =
    useFlags(['dc-1146-passport-google-workspace']);

  const getProviderImage = (provider) => {
    if (provider === KandjiLoginService.identityProviders.GOOGLE_WORKSPACE) {
      return GoogleWorkspace;
    }

    if (provider === KandjiLoginService.identityProviders.MICROSOFT_AZURE) {
      return AzureLogin;
    }

    if (provider === KandjiLoginService.identityProviders.OKTA) {
      return OktaLogin;
    }

    if (provider === KandjiLoginService.identityProviders.ONE_LOGIN) {
      return OneLogin;
    }

    if (provider === KandjiLoginService.identityProviders.OTHER) {
      return IdpLogin;
    }

    return null;
  };

  const fieldsToValidate = [
    'idp',
    'googleIdp',
    'oidcBaseUrl',
    'publicAppId',
    'redirectUri',
    'webLoginClientId',
    'clientSecret',
    'googleConnector',
  ];
  const { refs, onInvalidate, invalidations } = useInputsValidators(
    fieldsToValidate,
    update,
  );
  useRemoveValidationOnUnmount(fieldsToValidate, update);

  const { data: authConnectorData } = useGetAuthConnectors(true);

  const authConnections =
    authConnectorData?.filter(
      ({ strategy }) => strategy === authStrategyMap.GOOGLE_APPS,
    ) || [];

  const trigger = ['onBlur', validationDep];
  const copy = providerCopy()[setting.idp] || {};

  const showWebClientId =
    setting.mode === KandjiLoginService.authenticationMode.WEB_LOGIN &&
    [
      KandjiLoginService.identityProviders.ONE_LOGIN,
      KandjiLoginService.identityProviders.OTHER,
    ].some((provider) => setting.idp === provider);

  const clientSecretRequired =
    setting.mode === KandjiLoginService.authenticationMode.WEB_LOGIN &&
    setting.idp === KandjiLoginService.identityProviders.ONE_LOGIN;

  const isNotGoogleWorkspace =
    setting.idp !== KandjiLoginService.identityProviders.GOOGLE_WORKSPACE;

  useEffect(() => {
    onInvalidate(0)(!setting.idp);
  }, [setting.idp]);

  useEffect(() => {
    onInvalidate('googleConnector')(
      !isNotGoogleWorkspace &&
        setting.mode === KandjiLoginService.authenticationMode.WEB_LOGIN &&
        !setting.googleConnector,
    );
  }, [setting.idp, setting.mode]);

  useEffect(() => {
    if (authConnections.length === 1) {
      update('googleConnector', authConnections[0].id);
    }
  }, [authConnections]);

  const reset = () => {
    update('mode', KandjiLoginService.authenticationMode.MAC_LOGIN);
    update('oidcBaseUrl', '');
    update('redirectUri', '');
    update('publicAppId', '');
    update('clientSecret', '');
    update('webLoginClientId', '');
    update('googleConnector', '');
  };

  return (
    <Setting.Card>
      <Setting.Header>
        <h3 className="b-h3">{i18n.t(`Authentication configuration`)}</h3>
      </Setting.Header>
      <Setting.SubHeader>
        <p className="b-txt">
          {i18n.t(`Configure the connection between Passport and your identity provider
          (IdP).`)}
          <a
            href="https://support.kandji.io/kb/configure-the-passport-library-item"
            rel="noopener noreferrer"
            target="_blank"
            className="b-alink"
          >
            {i18n.t(`Learn more...`)}
          </a>
        </p>
      </Setting.SubHeader>
      <Setting.Rows>
        <>
          <Setting.Row>
            <Setting.Title>
              <p className="b-txt">{i18n.t(`Identity provider`)}</p>
            </Setting.Title>
            <Setting.Helpers className="b-txt-light">
              {i18n.t(`Select the IdP to configure with Passport.`)}
            </Setting.Helpers>
            <Setting.Controls>
              <Select
                testId="idp"
                compact
                placeholder={i18n.t('Select IdP')}
                disabled={isDisabled}
                options={providerOptions()}
                onChange={({ value }) => {
                  update('idp', value);
                  reset();
                }}
                value={providerOptions().find(
                  ({ value }) => value === setting.idp,
                )}
                errorText={
                  (isSubmitted && !setting.idp && i18n.t('Required')) || ''
                }
              />

              {copy.idpLearnMoreHelper && (
                <p className="b-txt-light b-mt">
                  {copy.idpLearnMoreHelper}{' '}
                  <a
                    href={copy.idpLearnMoreUrl}
                    rel="noopener noreferrer"
                    target="_blank"
                    className="b-alink"
                  >
                    {i18n.t(`Learn more...`)}
                  </a>
                </p>
              )}
            </Setting.Controls>
          </Setting.Row>

          <GoogleIdp
            ref={refs.googleIdp}
            setting={setting}
            update={update}
            onValidate={onValidate}
            onInvalidate={onInvalidate('googleIdp')}
            invalidations={invalidations}
            isSubmitted={isSubmitted}
            isDisabled={isDisabled}
            isVisible={!isNotGoogleWorkspace}
          />

          {setting.idp && (
            <>
              {isNotGoogleWorkspace && (
                <Setting.Row>
                  <Setting.Title>
                    <p className="b-txt">{i18n.t(`Identity provider URL`)}</p>
                  </Setting.Title>
                  <Setting.Helpers className="b-txt-light">
                    {copy.urlHelper}
                  </Setting.Helpers>
                  <Setting.Controls>
                    <div ref={refs[0]}>
                      <TextInput
                        key={setting.idp}
                        data-testid="oidcBaseUrl"
                        compact
                        disabled={isDisabled}
                        value={setting.oidcBaseUrl}
                        onChange={(e) => update('oidcBaseUrl', e.target.value)}
                        placeholder={copy.urlPlaceholder}
                        validator={(v) =>
                          [
                            {
                              message: i18n.t('Required'),
                              invalid: () => !v,
                              trigger,
                            },
                            setting.idp !==
                              KandjiLoginService.identityProviders.OTHER &&
                              urlValidator(v, { trigger }),
                          ].filter(Boolean)
                        }
                        onInvalidate={onInvalidate(0)}
                      />
                    </div>
                  </Setting.Controls>
                </Setting.Row>
              )}

              {isNotGoogleWorkspace && (
                <Setting.Row>
                  <Setting.Title>
                    <p className="b-txt">
                      {i18n.t(`Client ID (Password Sync)`)}
                    </p>
                  </Setting.Title>
                  <Setting.Helpers className="b-txt-light">
                    {copy.clientHelper}
                  </Setting.Helpers>
                  <Setting.Controls>
                    <div ref={refs[2]}>
                      <TextInput
                        key={setting.idp}
                        data-testid="publicAppId"
                        compact
                        disabled={isDisabled}
                        value={setting.publicAppId}
                        onChange={(e) => update('publicAppId', e.target.value)}
                        placeholder="Ap9aKX3"
                        validator={(v) => [
                          {
                            message: i18n.t('Required'),
                            invalid: () => !v,
                            trigger: ['onBlur', validationDep],
                          },
                        ]}
                        onInvalidate={onInvalidate(2)}
                        removeValidationOnUnmount
                      />
                    </div>
                  </Setting.Controls>
                </Setting.Row>
              )}

              {(isNotGoogleWorkspace || LDFF_googleWorkspace) && (
                <Setting.Row className="k-passport-config-row">
                  <Setting.Title className="k-passport-config-row__title">
                    <p className="b-txt">{i18n.t('Authentication mode')}</p>
                  </Setting.Title>
                  <Setting.Helpers className="b-txt-light k-passport-config-row__helper">
                    {copy.modeHelper}{' '}
                    {setting.mode ===
                      KandjiLoginService.authenticationMode.WEB_LOGIN &&
                      copy.webLoginBanner && <>{copy.webLoginBanner}</>}
                    {!isNotGoogleWorkspace && authConnections.length === 0 && (
                      <Banner className="b-mt-tiny" theme="warning">
                        <span style={{ fontSize: '14px' }}>
                          {i18n.t('In order to use Web Login, create a')}{' '}
                          <Link
                            to="/my-company/access"
                            rel="noopener noreferrer"
                            target="_blank"
                            className="b-alink"
                          >
                            {i18n.t('Google Workspace SSO Connection.')}
                          </Link>
                        </span>
                      </Banner>
                    )}
                  </Setting.Helpers>
                  <Setting.Controls className="k-passport-config-row__controls">
                    <div className="b-flex-vg">
                      <div
                        data-testid="select-mac-login"
                        className="b-flex-g b-flex-vc"
                        onClick={() => {
                          if (!isDisabled) {
                            update(
                              'mode',
                              KandjiLoginService.authenticationMode.MAC_LOGIN,
                            );
                          }
                          if (
                            setting.idp ===
                              KandjiLoginService.identityProviders.ONE_LOGIN ||
                            setting.idp ===
                              KandjiLoginService.identityProviders.OTHER
                          ) {
                            update('clientSecret', '');
                          }
                        }}
                      >
                        <Radio
                          id="mac-login"
                          isDisabled={isDisabled}
                          checked={
                            setting.mode ===
                            KandjiLoginService.authenticationMode.MAC_LOGIN
                          }
                          onChange={() => {
                            update(
                              'mode',
                              KandjiLoginService.authenticationMode.MAC_LOGIN,
                            );
                            if (
                              setting.idp ===
                                KandjiLoginService.identityProviders
                                  .ONE_LOGIN ||
                              setting.idp ===
                                KandjiLoginService.identityProviders.OTHER
                            ) {
                              update('clientSecret', '');
                            }
                          }}
                        />
                        <img
                          src={MacLogin}
                          alt=""
                          className="li-passport_auth-login-img"
                        />
                        <div className="b-flex-vgmicro">
                          <p className="b-txt">{i18n.t('Mac Login')}</p>
                          <p className="b-txt-ctrl7 b-txt-light">
                            {i18n.t('Username and password')}
                          </p>
                        </div>
                      </div>
                      <div
                        data-testid="select-web-login"
                        className="b-flex-g b-flex-vc"
                        onClick={() => {
                          if (
                            ((!isNotGoogleWorkspace &&
                              authConnections.length > 0) ||
                              isNotGoogleWorkspace) &&
                            !isDisabled
                          ) {
                            update(
                              'mode',
                              KandjiLoginService.authenticationMode.WEB_LOGIN,
                            );
                          }
                          if (
                            setting.idp ===
                              KandjiLoginService.identityProviders.ONE_LOGIN ||
                            setting.idp ===
                              KandjiLoginService.identityProviders.OTHER
                          ) {
                            update('clientSecret', '');
                          }
                        }}
                      >
                        <Radio
                          id="web-login"
                          isDisabled={
                            isDisabled ||
                            (!isNotGoogleWorkspace &&
                              authConnections.length === 0)
                          }
                          checked={
                            setting.mode ===
                            KandjiLoginService.authenticationMode.WEB_LOGIN
                          }
                          onChange={() => {
                            update(
                              'mode',
                              KandjiLoginService.authenticationMode.WEB_LOGIN,
                            );
                            if (
                              setting.idp ===
                                KandjiLoginService.identityProviders
                                  .ONE_LOGIN ||
                              setting.idp ===
                                KandjiLoginService.identityProviders.OTHER
                            ) {
                              update('clientSecret', '');
                            }
                          }}
                        />
                        <img
                          // src={providerImages[setting.idp]}
                          src={getProviderImage(setting.idp)}
                          alt=""
                          className="li-passport_auth-login-img"
                        />
                        <div className="b-flex-vgmicro">
                          <p className="b-txt">{i18n.t('Web Login')}</p>
                          <p className="b-txt-ctrl7 b-txt-light">
                            {i18n.t(
                              'Supports multi-factor authentication (MFA)',
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Setting.Controls>

                  <Setting.SecondaryControls>
                    {showWebClientId && (
                      <Setting.SecondaryControlsRow className="web-login-clientid">
                        <Banner kind="block" theme="info">
                          <span>{copy.webClientBanner}</span>
                        </Banner>
                      </Setting.SecondaryControlsRow>
                    )}

                    {showWebClientId && (
                      <Setting.SecondaryControlsRow>
                        <Setting.Title>
                          <p className="b-txt" ref={refs.webLoginClientId}>
                            Client ID (Web Authentication)
                          </p>
                        </Setting.Title>
                        <Setting.Helpers className="b-txt-light">
                          {copy.webClientIdHelper}
                        </Setting.Helpers>
                        <Setting.Controls>
                          <TextInput
                            key={setting.idp}
                            data-testid="webClientId"
                            compact
                            disabled={isDisabled}
                            value={setting.webLoginClientId}
                            onChange={(e) =>
                              update('webLoginClientId', e.target.value)
                            }
                            placeholder="Ap9aKX3"
                            validator={(v) => [
                              {
                                message: 'Required',
                                invalid: () => !v,
                                trigger,
                              },
                            ]}
                            onInvalidate={onInvalidate('webLoginClientId')}
                            removeValidationOnUnmount
                          />
                        </Setting.Controls>
                      </Setting.SecondaryControlsRow>
                    )}

                    {setting.mode ===
                      KandjiLoginService.authenticationMode.WEB_LOGIN &&
                      !isNotGoogleWorkspace && (
                        <Setting.SecondaryControlsRow>
                          <Setting.Title>{i18n.t('Connection')}</Setting.Title>
                          <Setting.Helpers className="b-txt-light">
                            {i18n.t(
                              'The single sign-on connector to be used for authentication.',
                            )}
                          </Setting.Helpers>
                          <div ref={refs.googleConnector}>
                            <ManualEnrollmentAuthSelect
                              disabled={isDisabled}
                              value={setting.googleConnector}
                              options={authConnections.map((connection) => ({
                                label: connection.display_name,
                                icon: getConnectionIcon(connection.strategy),
                                value: connection.id,
                              }))}
                              onValueChange={(connector) => {
                                update('googleConnector', connector);
                                onInvalidate('googleConnector')(false);
                              }}
                              placeholder="Select a connection"
                            />
                          </div>
                        </Setting.SecondaryControlsRow>
                      )}

                    {setting.mode ===
                      KandjiLoginService.authenticationMode.WEB_LOGIN &&
                      isNotGoogleWorkspace && (
                        <Setting.SecondaryControlsRow>
                          <Setting.Title>
                            <p ref={refs.redirectUri} className="b-txt">
                              {i18n.t('Redirect URI')}
                            </p>
                          </Setting.Title>
                          <Setting.Helpers className="b-txt-light">
                            {copy.redirectUriHelper}
                          </Setting.Helpers>
                          <Setting.Controls>
                            <TextInput
                              key={setting.idp}
                              data-testid="redirectUri"
                              compact
                              disabled={isDisabled}
                              value={setting.redirectUri}
                              onChange={(e) =>
                                update('redirectUri', e.target.value)
                              }
                              placeholder={copy.redirectUriPlaceholder}
                              validator={(v) => [
                                {
                                  message: i18n.t('Required'),
                                  invalid: () => !v,
                                  trigger,
                                },
                              ]}
                              onInvalidate={onInvalidate('redirectUri')}
                              removeValidationOnUnmount
                            />
                          </Setting.Controls>
                        </Setting.SecondaryControlsRow>
                      )}

                    {isNotGoogleWorkspace && (
                      <Setting.SecondaryControlsRow>
                        <Setting.Title>
                          <p
                            className="b-txt"
                            ref={
                              clientSecretRequired ? refs.clientSecret : null
                            }
                          >
                            {i18n.t(`Client secret`)}{' '}
                            {!clientSecretRequired && (
                              <span className="b-txt-light">
                                {i18n.t(`(optional)`)}
                              </span>
                            )}
                          </p>
                        </Setting.Title>
                        <Setting.Helpers className="b-txt-light">
                          {setting.mode ===
                            KandjiLoginService.authenticationMode.WEB_LOGIN &&
                          copy.clientSecretWebHelper
                            ? copy.clientSecretWebHelper
                            : copy.clientSecretHelper}
                        </Setting.Helpers>
                        <Setting.Controls>
                          <TextInput
                            key={setting.idp}
                            data-testid="clientSecret"
                            compact
                            disabled={isDisabled}
                            value={setting.clientSecret}
                            onChange={(e) =>
                              update('clientSecret', e.target.value)
                            }
                            placeholder={i18n.t('client_secret')}
                            validator={(v) => [
                              {
                                message: i18n.t('Required'),
                                invalid: () => clientSecretRequired && !v,
                                trigger,
                              },
                            ]}
                            onInvalidate={onInvalidate('clientSecret')}
                            removeValidationOnUnmount
                          />
                        </Setting.Controls>
                      </Setting.SecondaryControlsRow>
                    )}
                  </Setting.SecondaryControls>
                </Setting.Row>
              )}
            </>
          )}
        </>
      </Setting.Rows>
    </Setting.Card>
  );
};

export default Auth;
