/* istanbul ignore file */
import { Button, Icon, Menu } from '@kandji-inc/bumblebee';
import { ColorNeutral0 } from '@kandji-inc/bumblebee/lib/tokens/colors/colors';
import { i18n } from 'i18n';
import { array, bool, func, object, string } from 'prop-types';
import React, { useRef } from 'react';

/**
 * Doesn't currently handle uploading, essentially an icon file selecter.
 * However, when necesary, can add progress and loading.
 *
 * Moved to kandji-web for localization
 */
const IconUpload = ({
  className,
  style,
  allowedTypes,
  isDisabled,
  onFileSelect,
  onDelete,
  currentIcon,
  defaultIcon,
  disabledIcon,
  disabledMenu,
}: any) => {
  const inputRef = useRef(null);

  return (
    <>
      <input
        className="b-icon-selector-xs__input"
        type="file"
        accept={allowedTypes.join(',')}
        disabled={isDisabled}
        ref={inputRef}
        onChange={(e) => {
          if (onFileSelect) {
            onFileSelect(e.target.files[0]);
          }
        }}
      />

      <Menu isDisabled={disabledMenu || isDisabled}>
        <div>
          <Button
            onClick={(e: any) => {
              e.preventDefault();
              if (inputRef && inputRef.current) {
                inputRef.current.click();
              }
            }}
            kind="link"
            icon="circle-plus"
          >
            {i18n.t('Add new icon')}
          </Button>
          {currentIcon && (
            <Button
              onClick={(e: any) => {
                e.preventDefault();
                if (onDelete) {
                  onDelete(currentIcon);
                }
              }}
              kind="link"
              icon="arrow-rotate-right"
            >
              {i18n.t('Restore default')}
            </Button>
          )}
        </div>
        <div
          className={`b-icon-selector-xs ${
            isDisabled
              ? `b-icon-selector-xs-container__disabled${
                  currentIcon ? '__with-icon' : ''
                }`
              : 'b-icon-selector-xs-container'
          } ${className || ''}`}
          style={style}
        >
          {!isDisabled && (
            <div className="b-icon-selector-xs__hover">
              <Icon
                name={currentIcon ? 'ellipsis' : 'plus'}
                color={ColorNeutral0}
              />
            </div>
          )}
          {currentIcon || defaultIcon ? (
            <img
              className="b-icon-selector-xs-img b-icon-selector-xs__loaded"
              src={currentIcon || defaultIcon}
              alt=""
            />
          ) : (
            <a
              href=""
              className={`decorate-off b-icon-selector-xs__icon ${
                isDisabled ? 'b-icon-selector-xs-icon__disabled' : ''
              }`}
              onClick={(e) => e.preventDefault()}
            >
              {isDisabled ? (
                <Icon name={disabledIcon} color={ColorNeutral0} />
              ) : (
                <Icon name="circle-plus" color={ColorNeutral0} />
              )}
            </a>
          )}
        </div>
      </Menu>
    </>
  );
};

IconUpload.propTypes = {
  className: string,
  style: object,
  allowedTypes: array,
  isDisabled: bool,
  onFileSelect: func,
  onDelete: func,
  disabledIcon: string,
  currentIcon: string,
  defaultIcon: string,
  disabledMenu: bool,
};

IconUpload.defaultProps = {
  className: '',
  style: {},
  allowedTypes: ['.png', '.jpg', '.jpeg'],
  isDisabled: false,
  onFileSelect: () => {},
  onDelete: () => {},
  disabledIcon: 'file',
  currentIcon: '',
  defaultIcon: '',
  disabledMenu: false,
};

export default IconUpload;
