import { Button } from '@kandji-inc/bumblebee';
import cn from 'classnames';
import React from 'react';
import { MapkitProvider } from 'react-mapkit';
import { useDispatch } from 'react-redux';
import { setModal } from 'src/app/_actions/ui';
import { colors } from 'src/app/common/constants';
import { REACT_APP_APPLE_MAPS_JWT } from 'src/constants';
import useLostMode from 'src/hooks/useLostMode';
import styled from 'styled-components';
import ErrorStatusBanner from './ErrorStatusBanner';
import LostModeDetails from './LostModeDetails';

import LostModeMap from './LostModeMap';

const grayText = colors['marengo-300'];
const borderColor = '#dedede';

const EnabledTab = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  font-weight: 500;

  p {
    margin: 0;
    font-family:
      Atlas Grotesk Web,
      sans-serif;
    font-weight: 400;
  }

  .enabled-lost-mode__header {
    width: 100%;
    align-self: flex-start;
    padding: 24px;
    margin: 0;
    a {
      color: #0057da;
    }
    border-bottom: 1px solid ${borderColor};
  }
  .enabled-lost-mode__main {
    width: 100%;
    align-self: flex-start;
    padding: 24px 24px 0 24px;
    .enabled-lost-mode__main-header {
      padding-bottom: 16px;
    }
    .lost-mode__sound-status-banner {
      margin-bottom: 16px;
    }
    .enabled-lost-mode__map-container {
      width: 100%;
      display: flex;
    }
    .enabled-lost-mode__address {
      display: flex;
      align-items: center;
    }
  }
  .enabled-lost-mode__footer {
    margin-top: 16px;
    padding: 24px 0 0 0;
    border-top: 1px solid ${borderColor};
    .enabled-lost-mode__footer-info {
      display: flex;
      padding-top: 16px;

      .enabled-lost-mode__message {
        width: 50%;
        padding-right: 16px;
      }

      .enabled-lost-mode__phone,
      .enabled-lost-mode__footnote {
        width: 25%;
      }
      & > div {
        dt {
          font-family:
            Atlas Grotesk Web,
            sans-serif;
          font-weight: 400;
          color: ${grayText};
          padding-bottom: 8px;
        }

        dd {
          font-family:
            Atlas Grotesk Web,
            sans-serif;
          font-weight: 400;
        }

        dd.gray {
          color: ${grayText};
        }
      }
    }
  }
  .enabled-lost-mode__disable-btn {
    border-top: 1px solid ${borderColor};
    padding: 12px 24px 12px 24px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
`;

function EnabledLostModeTab(props) {
  const { computer } = props;
  const { message, phone, footnote, isLostModeDisablePending } =
    useLostMode(computer);
  const dispatch = useDispatch();

  const handleDisableLostMode = () => {
    dispatch(
      setModal('DISABLE_LOST_MODE', {
        computerId: computer?.id,
        isNewModal: true,
      }),
    );
  };

  return (
    <MapkitProvider tokenOrCallback={REACT_APP_APPLE_MAPS_JWT}>
      <EnabledTab>
        <p className="enabled-lost-mode__header">
          Location will update automatically every 15 minutes as long as the
          device stays connected to the internet.{' '}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://support.kandji.io/kb/enable-lost-mode"
          >
            Learn more...
          </a>
        </p>
        <div className="enabled-lost-mode__main">
          <p className="enabled-lost-mode__main-header">Last Known Location</p>
          <ErrorStatusBanner computer={computer} />
          <div className="enabled-lost-mode__map-container">
            <LostModeMap computer={computer} />
            <LostModeDetails computer={computer} />
          </div>
          {/* Footer */}
          <div className="enabled-lost-mode__footer">
            <p>Lock Screen</p>
            <dl className="enabled-lost-mode__footer-info">
              <div className="enabled-lost-mode__message">
                <dt>Message</dt>
                <dd className={cn({ gray: !message })}>{message || 'N/A'}</dd>
              </div>
              <div className="enabled-lost-mode__phone">
                <dt>Phone Number</dt>
                <dd className={cn({ gray: !phone })}>{phone || 'N/A'}</dd>
              </div>
              <div className="enabled-lost-mode__footnote">
                <dt>Footnote</dt>
                <dd className={cn({ gray: !footnote })}>{footnote || 'N/A'}</dd>
              </div>
            </dl>
          </div>
        </div>
        <div className="enabled-lost-mode__disable-btn">
          <Button
            disabled={isLostModeDisablePending}
            onClick={handleDisableLostMode}
          >
            {isLostModeDisablePending
              ? 'Disable pending...'
              : 'Disable Lost Mode'}
          </Button>
        </div>
      </EnabledTab>
    </MapkitProvider>
  );
}

export default EnabledLostModeTab;
